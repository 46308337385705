import React, { FC, useState, useEffect } from 'react';
import {
  Box,
  Typography,
  Button,
  // Rating,
  // TextField,
  // Avatar,
  // Divider,
  // useMediaQuery,
} from '@mui/material';
// import LinearProgress from '@mui/material/LinearProgress'; // , { linearProgressClasses }
// import { styled } from '@mui/material/styles';
// import Tabs from '@mui/material/Tabs';
// import MoreHorizIcon from '@mui/icons-material/MoreHoriz';
import FlagModal from './FlagPopup';
// import ErrorIcon from '@mui/icons-material/Error';
// import GradeIcon from '@mui/icons-material/Grade';
// import reliabilityIcon from '../../assets/dashboard/reliabilityIcon.svg';
// import CommunicationIcon from '../../assets/dashboard/communicationIcon.svg';
// import QualityIcon from '../../assets/dashboard/qualityIcon.svg';
// import LeadIcon from '../../assets/dashboard/leadIcon.svg';
// import Tab from '@mui/material/Tab';
import PenddingApproveBgImg from '../../assets/Phase1a/PendingApproveBgImg.svg';
import ReviewDashboardimg from '../../assets/Phase1a/ReviePage_imgDashboard.svg';
import PenddingApproveBgImgMob from '../../assets/Phase1a/DesignUpdate/PendingApproveBgImgMob.svg';

// import ResponseIcon from '../../assets/dashboard/responseIcon.svg';
// import downIcon from '../../assets/nationalSignCompany/filledDownIcon.svg';
// import MenProfileIcon from '../../assets/nationalSignCompany/menProfileIcon.svg';
// import { mediaUrl } from '../../config';
// import subscribing from '../../assets/dashboard/peddingApprovalImg.svg';
// import ReviewMobIcon from '../../assets/reviewMobIcon.svg';
// import ReplayIcon from '../../assets/nationalSignCompany/replyIcon.svg';
// import FlagIcon from '../../assets/nationalSignCompany/flagIcon.svg';
import CopyLinkIcon from '../../assets/Phase1a/DesignUpdate/JobGalleryIeftIcon.svg';
import CalenderIcon from '../../assets/beta/calender_icon.svg';

// import { ReactComponent as CopyIcon } from '../../assets/Phase1a/DesignUpdate/copyIcon.svg';
// import DefaultAvatar from '../../assets/Avatarimg.svg';
import request from '../../api/request';
import { toast } from 'react-toastify';
// import moment from 'moment';
// import RemoveIcon from '../../assets/removeIcon.svg';
// import ReviewDelete from '../../Popups/phase1a/local/ReviewDelete.popups';
// import ReviewCard from '../../components/phase1a/common/ReviewCard.component';
import CustomToast from '../../components/phase1a/common/CustomToast.component';
import PenddingApproval from '../../Popups/phase1a/DesignUpdateModal/PenddingApproval.popup';
import { useNavigate } from 'react-router-dom';
import JobGalleryImg from '../../assets/Phase1a/DesignUpdate/JobGalleryImg1.svg';
import JobGalleryImgPending from '../../assets/Phase1a/DesignUpdate/JobGalleryImgPending.svg';
import AddNewJobShowCase from '../../Popups/phase1a/DesignUpdateModal/AddNewJobShowCase';
import dayjs from 'dayjs';
import { mediaUrl } from '../../config';
import usaStatesAndCanadaProvinces from '../../utils/states';
import ViewContributor from '../../Popups/phase1a/DesignUpdateModal/ViewContributor.popup';
import MessageUs from '../../Popups/phase1a/DesignUpdateModal/MessageUs.popup';

// interface TabPanelProps {
//   children?: React.ReactNode;
//   index: number;
//   value: number;
// }

// function CustomTabPanel(props: TabPanelProps): JSX.Element {
//   const { children, value, index, ...other } = props;

//   return (
//     <div
//       role="tabpanel"
//       hidden={value !== index}
//       id={`simple-tabpanel-${index}`}
//       aria-labelledby={`simple-tab-${index}`}
//       {...other}
//     >
//       {value === index && (
//         <Box width={'100%'} padding={'2% 0'}>
//           <Typography>{children}</Typography>
//         </Box>
//       )}
//     </div>
//   );
// }

// function a11yProps(index: number): { id: string; 'aria-controls': string } {
//   return {
//     id: `simple-tab-${index}`,
//     'aria-controls': `simple-tabpanel-${index}`,
//   };
// }
// const BorderLinearProgress = styled(LinearProgress)(({ theme }) => ({
//   height: 10,
//   borderRadius: 2,
//   [`&.${linearProgressClasses.colorPrimary}`]: {
//     border: '1px solid #E0E1E2',
//     backgroundColor: '#E0E1E2',
//   },
//   [`& .${linearProgressClasses.bar}`]: {
//     borderRadius: 2,
//     backgroundColor: '#00BD57 ',
//   },
// }));
// const getColor = (rating: any): any => {
//   if (!rating) return '#9E9E9E';
//   if (rating >= 4) return '#00BD57';
//   if (rating >= 3) return '#FFB800';
//   return '#FD3E14';
// };

const Reviews: FC = () => {
  const [flagModal, setFlagModal] = React.useState(false);
  const [reviewId, setReviewId] = React.useState<any>();
  // const [reason, setReason] = React.useState("");
  // const [showFullFeedback, setShowFullFeedback] = useState<any>({});
  // const [expandedReplies, setExpandedReplies] = useState<any>({});
  // const [replyFlag, setReplyFlag] = useState(null);
  const [loading, setLoading] = React.useState(false);
  // const [receivedReviews, setReceivedReviews] = React.useState<[]>([]);
  // const [submittedReviews, setsubmittedReviews] = React.useState<[]>([]);
  // const [loadingRecivedReviews, setLoadingRecivedReviews] = useState(true);
  const [isModalOpen, setIsModalOpen] = useState(false);
  const [claimStatus, setClaimStatus] = useState(false);
  const userJSON = localStorage.getItem('user');
  const userData = userJSON ? JSON.parse(userJSON) : '';
  const [companyDetails, setCompanyDetails] = React.useState<any>();
  const [pending, setPending] = useState(localStorage.getItem('pending'));

  window.addEventListener('storage', () => {
    setPending(localStorage.getItem('pending'));
  });

  // const handleOpenModal = (): void => {
  //   setIsModalOpen(true);
  // };

  const handleCloseModal = (): void => {
    setIsModalOpen(false);
    localStorage.setItem('pending', 'false');
    window.dispatchEvent(new Event('storage'));
  };
  console.log('submittedReviews', loading, claimStatus);
  // const [active, setActive] = useState('all');
  const [reply, setReply] = React.useState<string>('');
  console.log('reply', reply);
  // useEffect(() => {
  //   getReviews();
  // }, []);

  const token = localStorage.getItem('token');
  const userJson = localStorage.getItem('user');
  let user: any;

  if (userJson) {
    user = JSON.parse(userJson);
  } else {
    user = null; // Or set to some default value
  }

  const companyType = user?.company?.companyInfo?.companyType;

  // const companyId = user?.company?._id as string;
  const userType = user?.type;
  // const [value, setValue] = React.useState(0);

  console.log('Review user9', user);
  console.log('Review companyType', companyType);
  console.log('Review userType', userType, 'value:');

  // const paramType =
  //   companyType === 'local'
  //     ? 'local-sign-company'
  //     : companyType === 'national'
  //     ? 'national-sign-company'
  //     : companyType === 'supplier'
  //     ? 'supplier'
  //     : 'distributor';
  // const formatSearchString = (str: any): any => {
  //   if (!str) return ''; // Handle cases where str might be null or undefined
  //   const formattedStr = str.toLowerCase().replace(/\s+/g, '-').trim();
  //   return formattedStr;
  // };
  // const handleCopyLink = (): void => {
  //   const companyName = formatSearchString(user?.company?.companyInfo?.name);
  //   const currentDomain = window.location.origin; // This will give you the current domain
  //   // eslint-disable-next-line @typescript-eslint/restrict-template-expressions
  //   const url = `${currentDomain}/${paramType}/${companyName}/${companyId}/review`;

  //   navigator.clipboard
  //     .writeText(url)
  //     .then(() => {
  //       console.log('Link copied to clipboard:', url);
  //       toast(<CustomToast message={'Review Link Copied!'} type="success" />);

  //       // Optionally, show a message to the user that the link has been copied
  //     })
  //     .catch((err) => {
  //       console.error('Failed to copy the link: ', err);
  //     });
  // };

  const getCompanyById = (): void => {
    if (!user?.company) return;
    setLoading(true);

    // eslint-disable-next-line @typescript-eslint/restrict-template-expressions
    request<any>('get', `/companies/${user?.company?._id}`)
      .then((res) => {
        setLoading(false);
        setCompanyDetails(res);
        console.log('review res23', res);
      })
      .catch((err) => {
        setLoading(false);
        console.log('err', err);
        toast(<CustomToast message={err?.response?.data?.message} type="error" />);
      });
  };

  useEffect(() => {
    getCompanyById();
  }, [user?.company?._id]);

  // const getReviews = (): void => {
  //   if (!companyId) return;
  //   setLoading(true);

  //   // eslint-disable-next-line @typescript-eslint/restrict-template-expressions
  //   request<any>('get', `/review/companyReview/${companyId}?userId=${user?._id}`)
  //     .then((res) => {
  //       setLoading(false);
  //       setReceivedReviews(res.receivedReview);
  //       setsubmittedReviews(res.submittedReview);
  //       setLoadingRecivedReviews(false);
  //       console.log('review res23', res);
  //     })
  //     .catch((err) => {
  //       setLoading(false);
  //       console.log('err', err);
  //       toast(<CustomToast message={err?.response?.data?.message} type="error" />);
  //     });
  // };
  // const handleReplyClick = (itemId: any): void => {
  //   setReplyFlag(replyFlag === itemId ? null : itemId);
  //   setReply('');
  // };
  // const isSmallScreen = useMediaQuery('(max-width:500px)');
  // const handleReply = (review: any): void => {
  //   console.log('reviewID', review);
  //   if (!reply) {
  //     toast.error('Field should not empty');
  //     return;
  //   }
  //   const data: any = { reply, company: companyId, email: review?.user?.email };
  //   setLoading(true);
  //   // eslint-disable-next-line @typescript-eslint/restrict-template-expressions
  //   request<any>('put', `/review/reviewReply/${review?._id}`, data)
  //     .then((res) => {
  //       setReply('');
  //       setReplyFlag(null);
  //       setLoading(false);
  //       getReviews();
  //     })
  //     .catch((err) => {
  //       setLoading(false);
  //       setReply('');
  //       console.log('err', err);
  //       toast.error(err?.response?.data?.message);
  //     });
  // };
  console.log('reviewId', reviewId);
  const handleFlag = (reason: string): void => {
    const data: any = { flag: true, reason };
    setLoading(true);
    // eslint-disable-next-line @typescript-eslint/restrict-template-expressions
    request<any>('put', `/review/reviewFlag/${reviewId}`, data)
      .then((res) => {
        toast(<CustomToast message={'Flaged successfully'} type="success" />);

        setLoading(false);
        handleColse();
        // getReviews();
      })
      .catch((err) => {
        setLoading(false);
        setReply('');
        console.log('err', err);
        toast(<CustomToast message={err?.response?.data?.message} type="error" />);
      });
  };
  // const handleRemove = (reviewID: string): void => {
  //   console.log('reviewID', reviewID);

  //   setLoading(true);
  //   // eslint-disable-next-line @typescript-eslint/restrict-template-expressions
  //   request<any>('delete', `/review/${reviewID}`)
  //     .then((res) => {
  //       toast.success('Review removed successfully');
  //       setLoading(false);
  //       getReviews();
  //     })
  //     .catch((err) => {
  //       setLoading(false);
  //       setReply('');
  //       console.log('err', err);
  //       toast.error(err?.response?.data?.message);
  //     });
  // };

  // const handleToggleFeedback = (index: any): void => {
  //   setShowFullFeedback((prev: any) => ({
  //     ...prev,
  //     [index]: !prev[index],
  //   }));
  // };
  // const handleToggleReply = (index: any): void => {
  //   setExpandedReplies((prev: any) => ({
  //     ...prev,
  //     [index]: !prev[index],
  //   }));
  // };

  // const handleChange = (event: React.SyntheticEvent, newValue: number): void => {
  //   console.log('tab value', value);
  //   if (claimStatus) return;
  //   setValue(newValue);
  //   // setExpandedReplies('');
  //   // setShowFullFeedback('');
  // };
  const handleColse = (): void => {
    setFlagModal(false);
    setReviewId('');
  };

  // const handleNewest = (): void => {
  //   setActive('newest');
  //   if (userType === 'member') {
  //     const sortedReviews: any = [...submittedReviews].sort((a, b) => {
  //       const dateA = new Date((a as any).createdAt).getTime();
  //       const dateB = new Date((b as any).createdAt).getTime();
  //       return dateB - dateA; // Newest first
  //     });
  //     setsubmittedReviews(sortedReviews);
  //   } else {
  //     if (value === 1) {
  //       // for submitted reviews
  //       const sortedReviews: any = [...submittedReviews].sort((a, b) => {
  //         const dateA = new Date((a as any).createdAt).getTime();
  //         const dateB = new Date((b as any).createdAt).getTime();
  //         return dateB - dateA; // Newest first
  //       });
  //       setsubmittedReviews(sortedReviews);
  //     } else {
  //       // for received reviews
  //       const sortedReviews: any = [...receivedReviews].sort((a, b) => {
  //         const dateA = new Date((a as any).createdAt).getTime();
  //         const dateB = new Date((b as any).createdAt).getTime();
  //         return dateB - dateA; // Newest first
  //       });
  //       setReceivedReviews(sortedReviews);
  //       setLoadingRecivedReviews(false);
  //     }
  //   }
  // };
  // const handleAllReviews = (): void => {
  //   getReviews();
  //   setActive('all');
  // };
  // console.log('receivedReviews', receivedReviews);
  // console.log('submittedReviews', submittedReviews);
  useEffect(() => {
    if (user?.company && !user?.company?.companyInfo.verification && pending === 'true') {
      setIsModalOpen(true);
    }
  }, [!user?.company?.companyInfo.verification]);
  useEffect(() => {
    if (token?.length) {
      request('get', `/companies/claim/checkRequest`)
        .then((res: any) => {
          if (res?.claim?.status === 'pending' && pending === 'true') {
            setIsModalOpen(true);
            setClaimStatus(true);
          }
        })
        .catch((err) => {
          console.log('err', err);
        });
    }
  }, [token?.length]);
  const nav = useNavigate();

  const handleClick = (): void => {
    nav('/add-business', { state: { screenNumber: 5 } });

    if (!userData?.company) {
      toast(
        <CustomToast message={'Welcome back! Complete your Sign-up process.'} type="success" />,
      );
    }
  };
  const handleNavHome = (): void => {
    nav('/');
  };
  const [showNewShowcase, setShowNewShowcase] = useState(false);
  const [viewCont, setViewCont] = useState(false);
  const [messageUs, setMessageUs] = useState(false);

  const handleButtonClick = (): void => {
    setShowNewShowcase(true);
  };

  const handleButtonClickMessage = (): void => {
    setMessageUs(true);
  };
  // const handleViewCont = (): void => {
  //   setViewCont(true);
  // };

  const handleClose = (): void => {
    setShowNewShowcase(false);
  };
  const handleCloseViewCont = (): void => {
    setViewCont(false);
  };
  const handleCloseMessage = (): void => {
    setMessageUs(false);
  };

  function getStateAbbreviation(additional: string): string {
    if (!additional) return '';

    // Split the 'additional' string by comma and trim any whitespace
    const parts = additional.split(',').map((part: string) => part.trim());

    // Extract the city name (first part) and the state name (last part)
    const cityName = parts[0];
    const stateName = parts[parts?.length - 1];

    // Find the state or province in the array and get its abbreviation
    const state = usaStatesAndCanadaProvinces.find(
      (item: any) => item?.name?.toLowerCase() === stateName?.toLowerCase(),
    );

    // Return formatted string as "City, State Abbreviation"
    return state ? `${cityName}, ${state?.abbreviation}` : additional;
  }

  const [isExpanded, setIsExpanded] = useState<Record<number, boolean>>({});
  const maxLength = 150;

  const handleToggle = (index: number): void => {
    setIsExpanded((prevExpanded) => ({
      ...prevExpanded,
      [index]: !prevExpanded[index], // Toggle the state for the specific index
    }));
  };

  return (
    <>
      {user.company ? (
        <Box
          width={'100%'}
          height={'100%'}
          display={'flex'}
          // gap={'1%'}
          // justifyContent={'space-between'}
          alignItems={'center'}
          justifyContent={'center'}
          sx={{
            '@media (max-width: 500px)': {
              marginBottom: '-10.6%',
            },
          }}
        >
          <Box
            width="100%"
            height="100%"
            paddingRight={user.company?.companyInfo.verification ? '4%' : '0%'}
            sx={{
              overflowY: 'auto',
              scrollbarWidth: 'none',
              '&::-webkit-scrollbar': {
                display: 'none',
              },
              '@media (max-width: 1200px)': {
                padding: '0% 4% 5% 4%',
              },
            }}
          >
            <Box
              width={'100%'}
              margin={'3% 0 2% 0'}
              display={'flex'}
              alignItems={'flex-start'}
              flexDirection="column"
              sx={{
                '@media (max-width: 500px)': {
                  margin: '3% 0 10% 0',
                },
              }}
            >
              <Typography
                fontFamily="SF UI Display"
                fontWeight="800"
                fontSize="36px"
                lineHeight="23px"
                color="black"
                sx={{
                  '@media (max-width:500px)': {
                    fontSize: '30px',
                  },
                }}
              >
                Job Gallery
              </Typography>
              <Typography
                fontFamily="SF UI Display"
                fontWeight="300"
                fontSize="18px"
                lineHeight="21px"
                color="black"
                mt={2}
                sx={{
                  '@media (max-width:500px)': {
                    fontSize: '16px',
                  },
                }}
              >
                Showcase your best work and explain your process.
              </Typography>
            </Box>

            <Box
              width={'100%'}
              borderRadius={'12px'}
              bgcolor={'#F7F7F7'}
              display={'none'}
              padding={'25px'}
              flexDirection={'row'}
              alignItems={'flex-start'}
              gap={'14px'}
              height={'fit-content'}
              marginTop={'10px'}
              sx={{
                '@media (max-width: 1200px)': {
                  display: 'flex',
                },
                '@media (max-width: 768px)': {
                  flexDirection: 'column',
                },
              }}
            >
              <Box
                display={'flex'}
                alignItems={'center'}
                width={'68%'}
                gap={'10px'}
                sx={{
                  '@media (max-width: 768px)': {
                    width: '100%',
                    flexDirection: 'column',
                    alignItems: 'start',
                  },
                }}
              >
                <Box width={'48px'} height={'48px'}>
                  <img style={{ width: '100%', height: '100%' }} src={CopyLinkIcon} alt="copy" />
                </Box>
                <Box>
                  <Typography
                    fontFamily="SF UI Display"
                    fontWeight="700"
                    fontSize="20px"
                    color="#000"
                  >
                    {user.company?.companyInfo.verification &&
                    user.company?.companyInfo.verification.status !== false
                      ? 'Your work experience matters!'
                      : 'Have any questions?'}
                  </Typography>
                  <Typography
                    fontFamily="SF UI Display"
                    fontWeight="300"
                    fontSize="16px"
                    color="#000"
                  >
                    {user.company?.companyInfo.verification &&
                    user.company?.companyInfo.verification.status !== false
                      ? 'Make it easy for companies to get to know you better.'
                      : 'Feel free to reach out to us if you need any support or want to provide feedback!'}
                  </Typography>
                </Box>
              </Box>

              <Button
                onClick={() => {
                  if (
                    user.company?.companyInfo.verification &&
                    user.company?.companyInfo.verification.status !== false
                  ) {
                    handleButtonClick();
                  } else {
                    handleButtonClickMessage();
                  }
                }}
                sx={{
                  width: '180px',
                  display: 'flex',
                  padding: '10px 0',
                  justifyContent: 'center',
                  alignItems: 'center',
                  gap: '10px',
                  borderRadius: '8px',
                  color: '#fff',
                  fontFamily: 'SF UI Display',
                  fontSize: '14px',
                  fontWeight: '700',
                  lineHeight: '20px',
                  textTransform: 'capitalize',
                  marginTop: '10px',
                  cursor: 'pointer',
                  bgcolor: '#00BD57',
                  '&:hover': {
                    bgcolor: '#00BD57',
                  },
                }}
                // onClick={handleOpenModal}
              >
                {/* <CopyIcon color="#fff" /> */}
                {user.company?.companyInfo.verification &&
                user.company?.companyInfo.verification.status !== false
                  ? 'Add New Showcase'
                  : 'Message Us'}
              </Button>
              {/* {showNewShowcase && (
                <AddNewJobShowCase
                  open={showNewShowcase}
                  handleClose={handleClose}
                  getCompanyById={getCompanyById}
                />
              )} */}
            </Box>

            <Box
              width="100%"
              display={'flex'}
              justifyContent={
                user.company?.companyInfo?.verification?.status !== false
                  ? 'flex-start'
                  : 'space-between'
              }
              gap={user.company?.companyInfo.verification?.status !== false ? '35px' : '0px'}
              paddingRight={user.company?.companyInfo?.verification ? '0px' : '30px'}
              // alignItems={'center'}
              sx={{
                '@media (max-width:550px)': {
                  paddingRight: '0px',
                },
              }}
            >
              {!companyDetails?.company?.workGallery?.length ? (
                <Box
                  width={'68%'}
                  mt={'30px'}
                  display={'flex'}
                  justifyContent={'space-between'}
                  border={'solid 1px rgba(232, 232, 232, 1)'}
                  padding={'24px 35px 0px 24px'}
                  borderRadius={'10px'}
                  sx={{
                    '@media (max-width:1200px)': {
                      width: '100%',
                      flexDirection: 'column',
                      padding: '0px',
                      margin: '30px 0',
                    },
                  }}
                >
                  <Box
                    width={'47%'}
                    display={'flex'}
                    alignSelf={'flex-end'}
                    sx={{
                      '@media (max-width:1200px)': {
                        width: '100%',
                      },
                    }}
                  >
                    <Box width={'100%'} height={'100%'}>
                      <img
                        src={
                          user.company?.companyInfo.verification &&
                          user.company?.companyInfo.verification.status !== false
                            ? JobGalleryImg
                            : JobGalleryImgPending
                        }
                        alt="."
                        style={{ width: '100%', height: '100%' }}
                      />
                    </Box>
                  </Box>
                  <Box
                    width={'47%'}
                    display={'flex'}
                    justifyContent={'center'}
                    alignItems={'flex-start'}
                    flexDirection={'column'}
                    gap={'15px'}
                    sx={{
                      '@media (max-width:1200px)': {
                        width: '100%',
                        margin: '30px 0px',
                      },
                      '@media (max-width:500px)': {
                        paddingLeft: '23px',
                        width: user.company?.companyInfo.verification ? '98%' : '97%',
                      },
                    }}
                  >
                    <Typography
                      fontFamily="SF UI Display"
                      fontWeight="800"
                      fontSize="24px"
                      lineHeight="23px"
                      color="black"
                      sx={{
                        '@media (max-width:500px)': {
                          fontSize: '18px',
                        },
                      }}
                    >
                      {user.company?.companyInfo.verification &&
                      user.company?.companyInfo.verification.status !== false
                        ? 'No showcases yet?'
                        : 'Waiting For Page Approval?'}
                    </Typography>
                    <Typography
                      fontFamily="SF UI Display"
                      fontWeight="400"
                      fontSize="16px"
                      lineHeight="20px"
                      color="black"
                      mt={1}
                    >
                      {user.company?.companyInfo.verification &&
                      user.company?.companyInfo.verification.status !== false
                        ? `It takes some time to record jobs in a way that makes your company shine. Speed up the process and view those already in the spotlight.`
                        : 'Speed up the process by creating your job gallery! Adding your work experience now can help us verify your business is legit.'}
                    </Typography>
                    <Button
                      sx={{
                        width:
                          user.company?.companyInfo.verification &&
                          user.company?.companyInfo.verification.status !== false
                            ? '65%'
                            : '58%',
                        padding: '10px',
                        border: 'solid 1px #000000',
                        borderRadius: '8px',
                        fontWeight: '600',
                        fontSize: '16px',
                        mt: 2,
                        color: '#000000',
                        textTransform: 'capitalize',
                        fontFamily: 'SF UI Display',
                        '@media (max-width:500px)': {
                          mt: '28px',
                          width: user.company?.companyInfo.verification ? '50%' : '60%',
                          fontSize: '14px',
                          padding: '10px 0',
                        },
                      }}
                      // onClick={() => {
                      //   if (user.company?.companyInfo.verification) {
                      //     handleViewCont();
                      //   } else handleButtonClick();
                      // }}
                      onClick={() => {
                        if (
                          user.company?.companyInfo.verification &&
                          user.company?.companyInfo.verification.status !== false
                        ) {
                          handleNavHome();
                        } else {
                          handleButtonClick();
                        }
                      }}
                    >
                      {user.company?.companyInfo.verification &&
                      user.company?.companyInfo.verification.status !== false
                        ? 'Search Companies'
                        : 'Get Started'}
                    </Button>
                  </Box>
                  {viewCont && (
                    <ViewContributor open={viewCont} handleClose={handleCloseViewCont} />
                  )}
                </Box>
              ) : (
                <Box
                  width={'68%'}
                  sx={{
                    '@media (max-width: 1200px)': {
                      width: '100%',
                    },
                  }}
                >
                  {companyDetails?.company?.workGallery
                    ?.sort(
                      (a: any, b: any) => new Date(b.start).getTime() - new Date(a.start).getTime(),
                    )
                    .map((item: any, index: number) => (
                      <Box
                        key={index}
                        width={'100%'}
                        margin={'30px 0'}
                        border={'1px solid #DCDEDF'}
                        padding={'20px'}
                        borderRadius={'10px'}
                      >
                        <Typography
                          fontFamily="SF UI Display"
                          fontWeight="700"
                          fontSize="18px"
                          lineHeight="21px"
                          color="rgba(0, 0, 0, 1)"
                        >
                          {item?.service} - {getStateAbbreviation(item?.job_location)}{' '}
                        </Typography>
                        <Box display={'flex'} alignItems={'center'} gap={'9px'} margin={'5px 0'}>
                          <img style={{ width: '25px', height: '25px' }} src={CalenderIcon} />

                          <Typography
                            fontFamily="SF UI Display"
                            fontWeight="400"
                            fontSize="14px"
                            lineHeight="16px"
                            color="rgba(0, 0, 0, 1)"
                          >
                            {dayjs(item.start).format('MMM Do, YYYY')} -{' '}
                            {dayjs(item.end).format('MMM Do, YYYY')}
                          </Typography>
                        </Box>
                        <Typography
                          fontFamily="SF UI Display"
                          fontWeight="300"
                          fontSize="16px"
                          lineHeight="19px"
                          color="rgba(0, 0, 0, 1)"
                          margin="25px 0"
                        >
                          {isExpanded[index]
                            ? item?.description
                            : item?.description?.slice(0, maxLength)}
                          {item?.description?.length > maxLength && (
                            <span
                              style={{
                                fontFamily: 'SF UI Display',
                                color: '#00BD57',
                                textDecoration: 'underline',
                                cursor: 'pointer',
                                marginLeft: '5px',
                              }}
                              onClick={() => {
                                handleToggle(index);
                              }}
                            >
                              {isExpanded[index] ? 'Show Less' : 'See More'}
                            </span>
                          )}
                        </Typography>
                        <Box
                          width={'100%'}
                          display={'flex'}
                          gap={'10px'}
                          sx={{
                            overflowX: 'auto',
                            // '@media (max-width: 1200px)': {
                            //   overflowX: 'scroll',
                            //   display: '-webkit-box',
                            // },
                          }}
                        >
                          {item?.photos.map((pic: any) => {
                            return (
                              <Box
                                key={pic}
                                minWidth={item?.photos?.length > 2 ? '175px' : '300px'}
                                height={item?.photos?.length > 2 ? '130px' : '225px'}
                                borderRadius={'8px'}
                                overflow={'hidden'}
                              >
                                <img
                                  style={{ width: '100%', height: '100%', objectFit: 'cover' }}
                                  /* eslint-disable @typescript-eslint/restrict-template-expressions */
                                  src={`${mediaUrl}/${pic}`}
                                />
                              </Box>
                            );
                          })}
                        </Box>
                      </Box>
                    ))}
                </Box>
              )}
              <Box
                width={'28%'}
                borderRadius={'12px'}
                bgcolor={'#F7F7F7'}
                display={'flex'}
                padding={'25px'}
                flexDirection={'column'}
                alignItems={'flex-start'}
                gap={'7px'}
                height={'fit-content'}
                marginTop={'30px'}
                sx={{
                  '@media (max-width: 1200px)': {
                    display: 'none',
                  },
                }}
              >
                <Box width={'42px'} height={'42px'} mb={'18px'}>
                  <img style={{ width: '100%', height: '100%' }} src={CopyLinkIcon} alt="copy" />
                </Box>
                <Typography
                  fontFamily="SF UI Display"
                  fontWeight="700"
                  fontSize="20px"
                  color="#000"
                >
                  {user.company?.companyInfo.verification &&
                  user.company?.companyInfo.verification.status !== false
                    ? 'Your work experience matters!'
                    : 'Have any questions?'}
                </Typography>
                <Typography
                  fontFamily="SF UI Display"
                  fontWeight="300"
                  fontSize="15px"
                  color="#000"
                  lineHeight="24px"
                >
                  {user.company?.companyInfo.verification &&
                  user.company?.companyInfo.verification.status !== false
                    ? 'Make it easy for companies to get to know you better.'
                    : 'Feel free to reach out to us if you need any support or want to provide feedback!'}
                </Typography>
                <Button
                  // eslint-disable-next-line @typescript-eslint/no-confusing-void-expression
                  // onClick={user.company?.companyInfo.verification ? handleButtonClick() : ''}
                  onClick={() => {
                    if (
                      user.company?.companyInfo.verification &&
                      user.company?.companyInfo.verification.status !== false
                    ) {
                      handleButtonClick();
                    } else {
                      handleButtonClickMessage();
                    }
                  }}
                  sx={{
                    width: '100%',
                    display: 'flex',
                    padding: '10px 0',
                    justifyContent: 'center',
                    alignItems: 'center',
                    gap: '15px',
                    borderRadius: '8px',
                    border: '2px solid #00BD57',
                    color: '#ffffff',
                    fontFamily: 'SF UI Display',
                    fontSize: '14px',
                    fontWeight: '700',
                    lineHeight: '20px',
                    textTransform: 'capitalize',
                    marginTop: '10px',
                    cursor: 'pointer',
                    bgcolor: '#00BD57',
                    '&:hover': {
                      backgroundColor: '#00BD57', // Same color on hover
                      boxShadow: 'none', // Remove hover shadow effect if needed
                    },
                  }}
                >
                  {/* <CopyIcon color="#000" /> */}

                  {user.company?.companyInfo.verification &&
                  user.company?.companyInfo.verification.status !== false
                    ? 'Add New Showcase'
                    : 'Message Us'}
                </Button>
                {messageUs && <MessageUs open={messageUs} handleClose={handleCloseMessage} />}
                {showNewShowcase && (
                  <AddNewJobShowCase
                    open={showNewShowcase}
                    handleClose={handleClose}
                    getCompanyById={getCompanyById}
                  />
                )}
              </Box>

              {/* left content box */}
              {/* <Box
              position={'sticky'}
              top={'0%'}
              bgcolor={'#fff'}
              zIndex={1}
              mt={5}
              sx={{
                pointerEvents: 'none', // Disable interactions
                opacity: 0.5, // Visually indicate the box is disabled
              }}
            >
              <Tabs
                value={value}
                onChange={handleChange}
                aria-label="basic tabs example"
                TabIndicatorProps={{
                  style: {
                    height: '3px',
                    backgroundColor: '#00BD57',
                    cursor: claimStatus ? 'default' : 'pointer',
                  },
                }}
              >
                {userType !== 'member' && (
                  <Tab
                    label={
                      <div style={{ display: 'flex', alignItems: 'center', gap: '5px' }}>
                        Company Reviews&nbsp;
                        <span
                          style={{
                            color: value === 0 ? '#fff' : '#B8B8B8',
                            backgroundColor: value === 0 ? '#00BD57' : '#F7F7F7',
                            borderRadius: '9999px',
                            padding: '0 6px',
                            // border: value === 0 ? '2px solid #00AE50' : '2px solid transparent',
                            display: 'flex',
                            justifyContent: 'center',
                            alignItems: 'center',
                          }}
                        >
                          {/* {receivedReviews?.length} */}
              {/* </span>
                      </div>
                    }
                    // {...a11yProps(0)}
                    sx={{
                      fontSize: '16px',
                      fontFamily: 'SF UI Display',
                      color: '#9A9A9A',
                      fontWeight: '500',
                      textTransform: 'capitalize',
                      padding: '0',
                      marginRight: '3%',
                      cursor: claimStatus ? 'default' : 'pointer',

                      '&.Mui-selected': { color: '#00BD57', fontWeight: '600' },
                    }}
                  />
                )} */}

              {/* <Tab
                  label={
                    <div style={{ display: 'flex', alignItems: 'center', gap: '5px' }}>
                      My Reviews&nbsp;
                      <span
                        style={{
                          color:
                            value === 1 || (value === 0 && userType === 'member')
                              ? '#fff'
                              : '#B8B8B8',
                          backgroundColor:
                            value === 1 || (value === 0 && userType === 'member')
                              ? '#00BD57'
                              : '#F7F7F7',
                          borderRadius: '9999px',
                          padding: '0 6px', // Adds padding to make the circle more defined
                          // border:
                          //   value === 1 || (value === 0 && userType === 'member')
                          //     ? '2px solid #00AE50'
                          //     : '2px solid transparent',
                          display: 'flex',
                          justifyContent: 'center',
                          alignItems: 'center',
                        }}
                      >
                        {/* {submittedReviews?.length} */}
              {/* </span>
                    </div>
                  }
                  {...a11yProps(1)}
                  sx={{
                    fontSize: '16px',
                    fontFamily: 'SF UI Display',
                    color: '#9A9A9A',
                    fontWeight: '500',
                    textTransform: 'capitalize',
                    padding: '0',
                    cursor: claimStatus ? 'default' : 'pointer',

                    '&.Mui-selected': { color: '#00BD57', fontWeight: '600' },
                  }}
                />
              </Tabs>
            </Box> */}
              {/*  */}
              {/* <Box
              display={'flex'}
              gap={'25px'}
              width={'100%'}
              sx={{
                '@media (max-width: 1200px)': {
                  flexDirection: 'column-reverse',
                },
                '@media (max-width: 768px)': {
                  gap: '0',
                },
              }}
            >
              <Box
                display={'flex'}
                width={'70%'}
                justifyContent={'space-evenly'}
                alignItems={'center'}
                border={'solid 1px rgba(232, 232, 232, 1)'}
                borderRadius={'10px'}
                mt={3}
                sx={{
                  '@media (max-width: 1200px)': {
                    width: '100%',
                  },
                  '@media (max-width: 768px)': {
                    mt: 0,
                  },
                  '@media (max-width: 680px)': {
                    flexDirection: 'column',
                  },
                }}
              >
                <Box
                  width={'308px'}
                  height={'308px'}
                  sx={{
                    '@media (max-width: 680px)': {
                      width: '100%',
                    },
                  }}
                >
                  <img
                    src={subscribing}
                    alt="subscribing"
                    style={{
                      width: '100%',
                      height: '100%',
                      opacity: '0.5',
                    }}
                  />
                </Box>
                <Box
                  width={'50%'}
                  borderRadius={'12px'}
                  padding={'25px'}
                  flexDirection={'row'}
                  alignItems={'flex-start'}
                  gap={'14px'}
                  height={'fit-content'}
                  marginTop={'10px'}
                  sx={{
                    opacity: '0.5',
                    '@media (max-width: 1200px)': {
                      display: 'flex',
                      flexDirection: 'column',
                    },
                    '@media (max-width: 680px)': {
                      width: '100%',
                    },
                  }}
                >
                  <Box display={'flex'} alignItems={'center'} width={'100%'} gap={'10px'}>
                    <Box>
                      <Typography
                        fontFamily="SF UI Display"
                        fontWeight="800"
                        fontSize="24px"
                        color="rgba(0, 0, 0, 1)"
                      >
                        No reviews yet?
                      </Typography>
                      <Typography
                        fontFamily="SF UI Display"
                        fontWeight="400"
                        fontSize="16px"
                        lineHeight={'20px'}
                        color="#000"
                        mt={1}
                        mb={1}
                      >
                        It can take some time to get your first review. Speed up the process by
                        sending a review link to past customers!
                      </Typography>
                    </Box>
                  </Box>

                  <Button
                    sx={{
                      width: '45%',
                      display: 'flex',
                      padding: '10px 0',
                      justifyContent: 'center',
                      alignItems: 'center',
                      gap: '10px',
                      borderRadius: '8px',
                      color: '#fff',
                      fontFamily: 'SF UI Display',
                      fontSize: '14px',
                      fontWeight: '700',
                      lineHeight: '20px',
                      textTransform: 'capitalize',
                      marginTop: '10px',
                      cursor: 'pointer',
                      bgcolor: '#00BD57',
                      '&:hover': {
                        bgcolor: '#00BD57',
                      },
                      '@media (max-width: 1300px)': {
                        width: '65%',
                      },
                      '@media (max-width: 680px)': {
                        width: '45%',
                      },
                    }}
                    onClick={handleOpenModal}
                    // onClick={handleCopyLink}
                  >
                    Copy Review Links
                  </Button>
                </Box>
              </Box>{' '}
              <Box
                width={'30%'}
                mt={5}
                sx={{
                  opacity: '0.5',
                  '@media (max-width: 1200px)': {
                    width: '100%',
                  },
                }}
              >
                <Box
                  width={'100%'}
                  borderRadius={'12px'}
                  bgcolor={'rgba(247, 247, 247, 1)'}
                  padding={'25px'}
                  flexDirection="column"
                  // flexDirection={'column'}
                  alignItems={'flex-start'}
                  gap={'14px'}
                  height={'fit-content'}
                  sx={{
                    '@media (max-width: 1200px)': {
                      display: 'none',
                    },
                  }}
                >
                  <Box
                    display={'flex'}
                    alignItems={'flex-start'}
                    width={'100%'}
                    gap={'10px'}
                    flexDirection="column"
                  >
                    <Box width={'48px'} height={'48px'}>
                      <img
                        style={{ width: '100%', height: '100%' }}
                        src={CopyLinkIcon}
                        alt="copy"
                      />
                    </Box>
                    <Box>
                      <Typography
                        fontFamily="SF UI Display"
                        fontWeight="700"
                        fontSize="20px"
                        color="#000"
                        lineHeight={'20px'}
                      >
                        Invite your customers to review you!
                      </Typography>
                      <Typography
                        fontFamily="SF UI Display"
                        fontWeight="400"
                        fontSize="16px"
                        color="#000"
                        mt={1}
                        mb={1}
                      >
                        We make it easy to copy and paste a review link anywhere.
                      </Typography>
                    </Box>
                  </Box>

                  <Button
                    sx={{
                      width: '100%',
                      display: 'flex',
                      padding: '10px 0',
                      justifyContent: 'center',
                      border: 'solid 2px rgba(225, 225, 225, 1)',
                      alignItems: 'center',
                      gap: '10px',
                      borderRadius: '8px',
                      color: 'black',
                      fontFamily: 'SF UI Display',
                      fontSize: '14px',
                      fontWeight: '700',
                      lineHeight: '20px',
                      textTransform: 'capitalize',
                      marginTop: '10px',
                      cursor: 'pointer',
                      bgcolor: 'transparent',
                      '&:hover': {
                        bgcolor: 'transparent',
                      },
                    }}
                    onClick={handleOpenModal}
                  >
                    <CopyIcon color="#000" />
                    Copy Review Link
                  </Button>
                </Box> */}
              {/* mobile */}
              {/* <Box
                  width={'100%'}
                  borderRadius={'12px'}
                  bgcolor={'#F7F7F7'}
                  display={'none'}
                  padding={'25px'}
                  flexDirection={'row'}
                  alignItems={'flex-start'}
                  gap={'14px'}
                  height={'fit-content'}
                  marginTop={'10px'}
                  sx={{
                    '@media (max-width: 1200px)': {
                      display: 'flex',
                    },
                    '@media (max-width: 768px)': {
                      display: 'none',
                    },
                  }}
                >
                  <Box display={'flex'} alignItems={'center'} width={'68%'} gap={'10px'}>
                    <Box width={'48px'} height={'48px'}>
                      <img
                        style={{ width: '100%', height: '100%' }}
                        src={CopyLinkIcon}
                        alt="copy"
                      />
                    </Box>
                    <Box>
                      <Typography
                        fontFamily="SF UI Display"
                        fontWeight="700"
                        fontSize="20px"
                        color="#000"
                      >
                        Invite your customers to review you!
                      </Typography>
                      <Typography
                        fontFamily="SF UI Display"
                        fontWeight="400"
                        fontSize="16px"
                        color="#000"
                      >
                        We make it easy to copy and paste a review link anywhere.
                      </Typography>
                    </Box>
                  </Box>

                  <Button
                    sx={{
                      width: '30%',
                      display: 'flex',
                      padding: '10px 0',
                      justifyContent: 'center',
                      alignItems: 'center',
                      gap: '10px',
                      borderRadius: '8px',
                      color: '#fff',
                      fontFamily: 'SF UI Display',
                      fontSize: '14px',
                      fontWeight: '700',
                      lineHeight: '20px',
                      textTransform: 'capitalize',
                      marginTop: '10px',
                      cursor: 'pointer',
                      bgcolor: '#00BD57',
                      '&:hover': {
                        bgcolor: '#00BD57',
                      },
                    }}
                    onClick={handleOpenModal}
                  >
                    <CopyIcon color="#fff" />
                    Copy Review Link
                  </Button>
                </Box>
              </Box>
            </Box> */}
            </Box>
          </Box>

          {/* <PenddingApproval open={isModalOpen} handleClose={handleCloseModal} user={user} /> */}
        </Box>
      ) : (
        // <Box
        //   width="100%"
        //   padding="2% 2.5% 2.5% 0%"
        //   height="90vh"
        //   // overflow="auto"
        //   borderRadius="10px"
        //   display="flex"
        //   justifyContent="space-between"
        //   sx={{
        //     '@media (max-width: 800px)': {
        //       padding: '0% 3%',
        //       marginTop: '12%',
        //     },
        //     '@media (max-width: 1024px)': {
        //       padding: '0 3%',
        //       marginTop: '0%',
        //     },
        //   }}
        // >
        //   <Box
        //     width="100%"
        //     height="100%"
        //     sx={{
        //       overflowY: 'auto',
        //       scrollbarWidth: 'none',
        //       '&::-webkit-scrollbar': {
        //         display: 'none',
        //       },
        //     }}
        //   >
        //     <Box
        //       width={'100%'}
        //       margin={'2% 0 3% 0'}
        //       display={'flex'}
        //       alignItems={'flex-start'}
        //       flexDirection="column"
        //     >
        //       <Typography
        //         fontFamily="SF UI Display"
        //         fontWeight="800"
        //         fontSize="36px"
        //         lineHeight="23px"
        //         color="black"
        //         sx={{
        //           '@media (max-width:500px)': {
        //             fontSize: '30px',
        //           },
        //         }}
        //       >
        //         Job Gallery
        //       </Typography>
        //       <Typography
        //         fontFamily="SF UI Display"
        //         fontWeight="300"
        //         fontSize="18px"
        //         lineHeight="21px"
        //         color="black"
        //         mt={1}
        //       >
        //         Showcase your best installs and explain your process.
        //       </Typography>
        //     </Box>
        //     <Box
        //       width="100%"
        //       display={'flex'}
        //       justifyContent={'space-between'}
        //       alignItems={'center'}
        //       paddingTop={'3%'}
        //     >
        //       <Box
        //         width={'68%'}
        //         display={'flex'}
        //         justifyContent={'space-between'}
        //         border={'solid 1px rgba(232, 232, 232, 1)'}
        //         padding={'35px'}
        //         borderRadius={'10px'}
        //       >
        //         <Box width={'47%'}>
        //           <Box width={'100%'} height={'100%'}>
        //             <img src={JobGalleryImg} alt="." style={{ width: '100%', height: '100%' }} />
        //           </Box>
        //         </Box>
        //         <Box
        //           width={'47%'}
        //           display={'flex'}
        //           justifyContent={'center'}
        //           alignItems={'flex-start'}
        //           flexDirection={'column'}
        //         >
        //           <Typography
        //             fontFamily="SF UI Display"
        //             fontWeight="800"
        //             fontSize="24px"
        //             lineHeight="23px"
        //             color="black"
        //             sx={{
        //               '@media (max-width:500px)': {
        //                 fontSize: '18px',
        //               },
        //             }}
        //           >
        //             No showcases yet?
        //           </Typography>
        //           <Typography
        //             fontFamily="SF UI Display"
        //             fontWeight="400"
        //             fontSize="16px"
        //             lineHeight="20px"
        //             color="black"
        //             mt={1}
        //           >
        //             It takes some time to record jobs in a way that makes your company shine. Speed
        //             up the process and view those already in the spotlight.
        //           </Typography>
        //           <Button
        //             sx={{
        //               width: '70%',
        //               padding: '10px',
        //               border: 'solid 1px black',
        //               borderRadius: '10px',
        //               fontWeight: '600',
        //               fontSize: '16px',
        //               mt: 2,
        //               color: 'black',
        //               textTransform: 'capitalize',
        //               fontFamily: 'SF UI Display',
        //             }}
        //           >
        //             View Top Contributors
        //           </Button>
        //         </Box>
        //       </Box>
        //       <Box
        //         width={'28%'}
        //         borderRadius={'12px'}
        //         bgcolor={'#F7F7F7'}
        //         display={'flex'}
        //         padding={'25px'}
        //         flexDirection={'column'}
        //         alignItems={'flex-start'}
        //         gap={'14px'}
        //         height={'fit-content'}
        //         marginTop={'0'}
        //         sx={{
        //           '@media (max-width: 1200px)': {
        //             display: 'none',
        //           },
        //         }}
        //       >
        //         <Box width={'42px'} height={'42px'}>
        //           <img style={{ width: '100%', height: '100%' }} src={CopyLinkIcon} alt="copy" />
        //         </Box>
        //         <Typography
        //           fontFamily="SF UI Display"
        //           fontWeight="700"
        //           fontSize="20px"
        //           color="#000"
        //         >
        //           Your work experience matters!
        //         </Typography>
        //         <Typography
        //           fontFamily="SF UI Display"
        //           fontWeight="400"
        //           fontSize="15px"
        //           color="#000"
        //           lineHeight="24px"
        //         >
        //           Make it easy for companies to get to know you better.
        //         </Typography>
        //         <Button
        //           onClick={handleButtonClick}
        //           disabled
        //           sx={{
        //             width: '100%',
        //             display: 'flex',
        //             padding: '10px 0',
        //             justifyContent: 'center',
        //             alignItems: 'center',
        //             gap: '15px',
        //             borderRadius: '8px',
        //             border: '2px solid lightgray',
        //             color: 'rgba(232, 232, 232, 1)',
        //             fontFamily: 'SF UI Display',
        //             fontSize: '14px',
        //             fontWeight: '700',
        //             lineHeight: '20px',
        //             textTransform: 'capitalize',
        //             marginTop: '10px',
        //             cursor: 'pointer',
        //             bgcolor: 'lightgray',
        //             // bgcolor: '#00BD57',
        //             '&:hover': {
        //               backgroundColor: 'lightgray', // Same color on hover
        //               boxShadow: 'none', // Remove hover shadow effect if needed
        //             },
        //           }}
        //         >
        //           {/* <CopyIcon color="#000" /> */}
        //           Add New Showcase
        //         </Button>
        //         {showNewShowcase && (
        //           <AddNewJobShowCase open={showNewShowcase} handleClose={handleClose} />
        //         )}
        //       </Box>
        //     </Box>
        //   </Box>
        // </Box>
        // <>
        //   {companyType === 'supplier' || companyType === 'distributor' ? (
        //     <>
        //       {' '}
        //       <Box
        //         width="100%"
        //         padding="2% 2.5% 2.5% 0%"
        //         height="90vh"
        //         overflow="scroll"
        //         borderRadius="10px"
        //         display="flex"
        //         justifyContent="space-between"
        //         sx={{
        //           opacity: claimStatus ? 0.5 : 1,

        //           '@media (max-width: 800px)': {
        //             padding: '2% 2% 2% 2%',
        //           },
        //           '@media (max-width: 1024px)': {
        //             padding: '1% 0% 2.5% 0%',
        //             display: 'flex',
        //             justifyContent: 'center',
        //             alignItems: 'center',
        //           },
        //         }}
        //       >
        //         {userType !== 'member' && (
        //           <Box
        //             width="100%"
        //             height="100%"
        //             sx={{
        //               overflowY: 'scroll',
        //               scrollbarWidth: 'none',
        //               '&::-webkit-scrollbar': {
        //                 display: 'none',
        //               },
        //               '@media (max-width: 1024px)': {
        //                 width: '95%',
        //               },
        //             }}
        //           >
        //             <Box
        //               width={'100%'}
        //               margin={'2% 0 3% 0'}
        //               display={'flex'}
        //               alignItems={'flex-start'}
        //               flexDirection="column"
        //             >
        //               <Typography
        //                 fontFamily="SF UI Display"
        //                 fontWeight="800"
        //                 fontSize="36px"
        //                 lineHeight="23px"
        //                 color="black"
        //                 sx={{
        //                   '@media (max-width: 500px)': {
        //                     fontSize: '30px',
        //                   },
        //                 }}
        //               >
        //                 Reviews
        //               </Typography>
        //               <Typography
        //                 fontFamily="SF UI Display"
        //                 fontWeight="300"
        //                 fontSize="18px"
        //                 lineHeight="21px"
        //                 color="black"
        //                 mt={1}
        //               >
        //                 Manage reviews from all your customers here.
        //               </Typography>
        //             </Box>
        //             <Box width="100%" display={'flex'} justifyContent={'space-between'}>
        //               <Box
        //                 width="70%"
        //                 height={'70vh'}
        //                 position={'relative'}
        //                 sx={{
        //                   overflowY: 'auto',
        //                   scrollbarWidth: 'none', // For Firefox
        //                   '&::-webkit-scrollbar': {
        //                     display: 'none', // For Chrome, Safari, and Edge
        //                   },
        //                   '@media (max-width: 1200px)': {
        //                     width: '100%',
        //                   },
        //                 }}
        //               >
        //                 <Box display="none" gap="5px" marginTop="2%">
        //                   <Button
        //                     sx={{
        //                       width: '104px',
        //                       height: '25px',
        //                       bgcolor: active === 'all' ? '#00BD57' : 'gray',
        //                       color: 'white',
        //                       fontFamily: 'poppins',
        //                       fontSize: '12px',
        //                       lineHeight: '18px',
        //                       fontWeight: '500',
        //                       textTransform: 'capitalize',
        //                       display: 'flex',
        //                       gap: '5px',
        //                       alignItems: 'center',
        //                       '&:hover': {
        //                         bgcolor: active === 'all' ? '#00BD57' : 'gray',
        //                       },
        //                     }}
        //                     onClick={handleAllReviews}
        //                   >
        //                     All Reviews
        //                     {/* <img src={downIcon} alt="." style={{ width: '7.2px', height: '5.4px' }} /> */}
        //                   </Button>
        //                   <Button
        //                     sx={{
        //                       width: '108px',
        //                       height: '25px',
        //                       bgcolor: active === 'all' ? 'gray' : '#00BD57',
        //                       color: 'white',
        //                       fontFamily: 'poppins',
        //                       fontSize: '12px',
        //                       lineHeight: '18px',
        //                       fontWeight: '500',
        //                       textTransform: 'capitalize',
        //                       display: 'flex',
        //                       gap: '5px',
        //                       alignItems: 'center',
        //                       '&:hover': {
        //                         bgcolor: active === 'all' ? 'gray' : '#00BD57',
        //                       },
        //                     }}
        //                     onClick={handleNewest}
        //                   >
        //                     Newest First
        //                     {/* <img src={downIcon} alt="." style={{ width: '7.2px', height: '5.4px' }} /> */}
        //                   </Button>
        //                 </Box>
        //                 {userType !== 'member' && (
        //                   <>
        //                     {value === 0 && (
        //                       <Box
        //                         width={'100%'}
        //                         borderRadius={'12px'}
        //                         bgcolor={'#F7F7F7'}
        //                         display={'none'}
        //                         padding={'25px'}
        //                         flexDirection={'row'}
        //                         alignItems={'flex-start'}
        //                         gap={'14px'}
        //                         height={'fit-content'}
        //                         marginTop={'10px'}
        //                         sx={{
        //                           '@media (max-width: 1200px)': {
        //                             display: 'flex',
        //                           },
        //                           '@media (max-width: 768px)': {
        //                             display: 'none',
        //                           },
        //                         }}
        //                       >
        //                         <Box
        //                           display={'flex'}
        //                           alignItems={'center'}
        //                           width={'68%'}
        //                           gap={'10px'}
        //                         >
        //                           <Box width={'48px'} height={'48px'}>
        //                             <img
        //                               style={{ width: '100%', height: '100%' }}
        //                               src={CopyLinkIcon}
        //                               alt="copy"
        //                             />
        //                           </Box>
        //                           <Box>
        //                             <Typography
        //                               fontFamily="SF UI Display"
        //                               fontWeight="700"
        //                               fontSize="20px"
        //                               color="#000"
        //                             >
        //                               Invite your customers to review you!
        //                             </Typography>
        //                             <Typography
        //                               fontFamily="SF UI Display"
        //                               fontWeight="400"
        //                               fontSize="16px"
        //                               color="#000"
        //                             >
        //                               We make it easy to copy and paste a review link anywhere.
        //                             </Typography>
        //                           </Box>
        //                         </Box>

        //                         <Button
        //                           sx={{
        //                             width: '30%',
        //                             display: 'flex',
        //                             padding: '10px 0',
        //                             justifyContent: 'center',
        //                             alignItems: 'center',
        //                             gap: '10px',
        //                             borderRadius: '8px',
        //                             color: '#fff',
        //                             fontFamily: 'SF UI Display',
        //                             fontSize: '14px',
        //                             fontWeight: '700',
        //                             lineHeight: '20px',
        //                             textTransform: 'capitalize',
        //                             marginTop: '10px',
        //                             cursor: 'pointer',
        //                             bgcolor: '#00BD57',
        //                             '&:hover': {
        //                               bgcolor: '#00BD57',
        //                             },
        //                           }}
        //                           onClick={() => {
        //                             if (claimStatus) {
        //                               handleOpenModal();
        //                               return;
        //                             }
        //                             handleCopyLink();
        //                           }}
        //                         >
        //                           <CopyIcon color="#fff" />
        //                           Copy Review Link
        //                         </Button>
        //                       </Box>
        //                     )}
        //                     <CustomTabPanel value={value} index={0}>
        //                       <>
        //                         {receivedReviews.length > 0 ? (
        //                           <>
        //                             {receivedReviews.map((item: any, index) => (
        //                               <>
        //                                 {' '}
        //                                 <ReviewCard
        //                                   item={item}
        //                                   key={index}
        //                                   companyData={user?.company}
        //                                   pageType={value}
        //                                   getReviews={getReviews}
        //                                   setFlagModal={setFlagModal}
        //                                   setReviewId={setReviewId}
        //                                   claimStatus={claimStatus}
        //                                 />
        //                               </>
        //                             ))}
        //                           </>
        //                         ) : (
        //                           <>
        //                             <p>
        //                               {loadingRecivedReviews ? (
        //                                 <LinearProgress color="inherit" />
        //                               ) : (
        //                                 'No Review Found'
        //                               )}
        //                             </p>
        //                           </>
        //                         )}
        //                       </>
        //                     </CustomTabPanel>
        //                   </>
        //                 )}
        //               </Box>
        //               {value === 0 && userType !== 'member' && (
        //                 <Box
        //                   width={'28%'}
        //                   borderRadius={'12px'}
        //                   bgcolor={'#F7F7F7'}
        //                   display={'flex'}
        //                   padding={'25px'}
        //                   flexDirection={'column'}
        //                   alignItems={'flex-start'}
        //                   gap={'14px'}
        //                   height={'fit-content'}
        //                   marginTop={'15px'}
        //                   sx={{
        //                     '@media (max-width: 1200px)': {
        //                       display: 'none',
        //                     },
        //                   }}
        //                 >
        //                   <Box width={'55px'} height={'55px'}>
        //                     <img
        //                       style={{ width: '100%', height: '100%' }}
        //                       src={CopyLinkIcon}
        //                       alt="copy"
        //                     />
        //                   </Box>
        //                   <Typography
        //                     fontFamily="SF UI Display"
        //                     fontWeight="700"
        //                     fontSize="20px"
        //                     color="#000"
        //                     lineHeight={'normal'}
        //                   >
        //                     Invite your customers to review you!
        //                   </Typography>
        //                   <Typography
        //                     fontFamily="SF UI Display"
        //                     fontWeight="400"
        //                     fontSize="16px"
        //                     color="#000"
        //                     lineHeight={'normal'}
        //                   >
        //                     We make it easy to copy and paste a review link anywhere.
        //                   </Typography>
        //                   <Button
        //                     sx={{
        //                       width: '100%',
        //                       display: 'flex',
        //                       padding: '10px 0',
        //                       justifyContent: 'center',
        //                       alignItems: 'center',
        //                       gap: '15px',
        //                       borderRadius: '8px',
        //                       border: '2px solid #E1E1E1',
        //                       color: '#000',
        //                       fontFamily: 'SF UI Display',
        //                       fontSize: '14px',
        //                       fontWeight: '700',
        //                       lineHeight: '20px',
        //                       textTransform: 'capitalize',
        //                       marginTop: '10px',
        //                       cursor: 'pointer',
        //                     }}
        //                     onClick={() => {
        //                       if (claimStatus) {
        //                         handleOpenModal();
        //                         return;
        //                       }
        //                       handleCopyLink();
        //                     }}
        //                   >
        //                     <CopyIcon color="#000" />
        //                     Copy Review Link
        //                   </Button>
        //                 </Box>
        //               )}
        //             </Box>
        //           </Box>
        //         )}
        //       </Box>
        //     </>
        //   ) : (
        //     <Box
        //       width="100%"
        //       padding="2% 2.5% 2.5% 0%"
        //       height="90vh"
        //       // overflow="auto"
        //       borderRadius="10px"
        //       display="flex"
        //       justifyContent="space-between"
        //       sx={{
        //         opacity: claimStatus ? 0.5 : 1,

        //         '@media (max-width: 800px)': {
        //           padding: '0% 3%',
        //           marginTop: '12%',
        //         },
        //         '@media (max-width: 1024px)': {
        //           padding: '0 3%',
        //           marginTop: '0%',
        //         },
        //       }}
        //     >
        //       <Box
        //         width="100%"
        //         height="100%"
        //         sx={{
        //           overflowY: 'auto',
        //           scrollbarWidth: 'none',
        //           '&::-webkit-scrollbar': {
        //             display: 'none',
        //           },
        //         }}
        //       >
        //         <Box
        //           width={'100%'}
        //           margin={'2% 0 3% 0'}
        //           display={'flex'}
        //           alignItems={'flex-start'}
        //           flexDirection="column"
        //         >
        //           <Typography
        //             fontFamily="SF UI Display"
        //             fontWeight="800"
        //             fontSize="36px"
        //             lineHeight="23px"
        //             color="black"
        //             sx={{
        //               '@media (max-width:500px)': {
        //                 fontSize: '30px',
        //               },
        //             }}
        //           >
        //             Reviews
        //           </Typography>
        //           <Typography
        //             fontFamily="SF UI Display"
        //             fontWeight="300"
        //             fontSize="18px"
        //             lineHeight="21px"
        //             color="black"
        //             mt={1}
        //           >
        //             Manage reviews from all your customers here.
        //           </Typography>
        //         </Box>
        //         <Box width="100%" display={'flex'} justifyContent={'space-between'}>
        //           <Box
        //             width="70%"
        //             height={'70vh'}
        //             position={'relative'}
        //             sx={{
        //               overflowY: 'auto',
        //               scrollbarWidth: 'none', // For Firefox
        //               '&::-webkit-scrollbar': {
        //                 display: 'none', // For Chrome, Safari, and Edge
        //               },
        //               '@media (max-width: 1200px)': {
        //                 width: '100%',
        //               },
        //             }}
        //           >
        //             <Box position={'sticky'} top={'0%'} bgcolor={'#fff'} zIndex={1}>
        //               <Tabs
        //                 value={value}
        //                 onChange={handleChange}
        //                 aria-label="basic tabs example"
        //                 TabIndicatorProps={{
        //                   style: {
        //                     height: '3px',
        //                     backgroundColor: '#00BD57',
        //                     cursor: claimStatus ? 'default' : 'pointer',
        //                   },
        //                 }}
        //               >
        //                 {userType !== 'member' && (
        //                   <Tab
        //                     label={
        //                       <div style={{ display: 'flex', alignItems: 'center', gap: '5px' }}>
        //                         Company Reviews&nbsp;
        //                         <span
        //                           style={{
        //                             color: value === 0 ? '#fff' : '#B8B8B8',
        //                             backgroundColor: value === 0 ? '#00BD57' : '#F7F7F7',
        //                             borderRadius: '9999px',
        //                             padding: '0 6px', // Adds padding to make the circle more defined
        //                             border:
        //                               value === 0 ? '2px solid #00AE50' : '2px solid transparent',
        //                             display: 'flex',
        //                             justifyContent: 'center',
        //                             alignItems: 'center',
        //                           }}
        //                         >
        //                           {receivedReviews?.length}
        //                         </span>
        //                       </div>
        //                     }
        //                     {...a11yProps(0)}
        //                     sx={{
        //                       fontSize: '16px',
        //                       fontFamily: 'SF UI Display',
        //                       color: '#9A9A9A',
        //                       fontWeight: '500',
        //                       textTransform: 'capitalize',
        //                       padding: '0',
        //                       cursor: claimStatus ? 'default' : 'pointer',

        //                       marginRight: '3%',
        //                       '&.Mui-selected': { color: '#00BD57', fontWeight: '600' },
        //                     }}
        //                   />
        //                 )}

        //                 <Tab
        //                   label={
        //                     <div style={{ display: 'flex', alignItems: 'center', gap: '5px' }}>
        //                       My Reviews&nbsp;
        //                       <span
        //                         style={{
        //                           color:
        //                             value === 1 || (value === 0 && userType === 'member')
        //                               ? '#fff'
        //                               : '#B8B8B8',
        //                           backgroundColor:
        //                             value === 1 || (value === 0 && userType === 'member')
        //                               ? '#00BD57'
        //                               : '#F7F7F7',
        //                           borderRadius: '9999px',
        //                           padding: '0 6px', // Adds padding to make the circle more defined
        //                           border:
        //                             value === 1 || (value === 0 && userType === 'member')
        //                               ? '2px solid #00AE50'
        //                               : '2px solid transparent',
        //                           display: 'flex',
        //                           justifyContent: 'center',
        //                           alignItems: 'center',
        //                         }}
        //                       >
        //                         {submittedReviews?.length}
        //                       </span>
        //                     </div>
        //                   }
        //                   {...a11yProps(1)}
        //                   sx={{
        //                     fontSize: '16px',
        //                     fontFamily: 'SF UI Display',
        //                     color: '#9A9A9A',
        //                     fontWeight: '500',
        //                     textTransform: 'capitalize',
        //                     padding: '0',
        //                     cursor: claimStatus ? 'default' : 'pointer',

        //                     '&.Mui-selected': { color: '#00BD57', fontWeight: '600' },
        //                   }}
        //                 />
        //               </Tabs>
        //             </Box>
        //             <Box
        //               display="none"
        //               gap="5px"
        //               marginTop="2%"
        //               sx={{
        //                 '@media (max-width: 500px)': {
        //                   marginTop: '5%',
        //                 },
        //               }}
        //             >
        //               <Button
        //                 sx={{
        //                   width: '104px',
        //                   height: '25px',
        //                   bgcolor: active === 'all' ? '#00BD57' : 'gray',

        //                   color: 'white',
        //                   fontFamily: 'poppins',
        //                   fontSize: '12px',
        //                   lineHeight: '18px',
        //                   fontWeight: '500',
        //                   textTransform: 'capitalize',
        //                   display: 'flex',
        //                   gap: '5px',
        //                   alignItems: 'center',
        //                   '&:hover': {
        //                     bgcolor: active === 'all' ? '#00BD57' : 'gray',
        //                   },
        //                 }}
        //                 onClick={handleAllReviews}
        //               >
        //                 All Reviews
        //               </Button>
        //               <Button
        //                 sx={{
        //                   width: '108px',
        //                   height: '25px',
        //                   bgcolor: active === 'all' ? 'gray' : '#00BD57',

        //                   color: 'white',
        //                   fontFamily: 'poppins',
        //                   fontSize: '12px',
        //                   lineHeight: '18px',
        //                   fontWeight: '500',
        //                   textTransform: 'capitalize',
        //                   display: 'flex',
        //                   gap: '5px',
        //                   alignItems: 'center',
        //                   '&:hover': {
        //                     bgcolor: active === 'all' ? 'gray' : '#00BD57',
        //                   },
        //                 }}
        //                 onClick={handleNewest}
        //               >
        //                 Newest First
        //               </Button>
        //             </Box>
        //             {userType !== 'member' && (
        //               <>
        //                 {value === 0 && (
        //                   <Box
        //                     width={'100%'}
        //                     borderRadius={'12px'}
        //                     bgcolor={'#F7F7F7'}
        //                     display={'none'}
        //                     padding={'25px'}
        //                     flexDirection={'row'}
        //                     alignItems={'flex-start'}
        //                     gap={'14px'}
        //                     height={'fit-content'}
        //                     marginTop={'10px'}
        //                     sx={{
        //                       '@media (max-width: 1200px)': {
        //                         display: 'flex',
        //                       },
        //                       '@media (max-width: 768px)': {
        //                         display: 'none',
        //                       },
        //                     }}
        //                   >
        //                     <Box display={'flex'} alignItems={'center'} width={'68%'} gap={'10px'}>
        //                       <Box width={'48px'} height={'48px'}>
        //                         <img
        //                           style={{ width: '100%', height: '100%' }}
        //                           src={CopyLinkIcon}
        //                           alt="copy"
        //                         />
        //                       </Box>
        //                       <Box>
        //                         <Typography
        //                           fontFamily="SF UI Display"
        //                           fontWeight="700"
        //                           fontSize="20px"
        //                           color="#000"
        //                         >
        //                           Invite your customers to review you!
        //                         </Typography>
        //                         <Typography
        //                           fontFamily="SF UI Display"
        //                           fontWeight="400"
        //                           fontSize="16px"
        //                           color="#000"
        //                         >
        //                           We make it easy to copy and paste a review link anywhere.
        //                         </Typography>
        //                       </Box>
        //                     </Box>

        //                     <Button
        //                       sx={{
        //                         width: '30%',
        //                         display: 'flex',
        //                         padding: '10px 0',
        //                         justifyContent: 'center',
        //                         alignItems: 'center',
        //                         gap: '10px',
        //                         borderRadius: '8px',
        //                         color: '#fff',
        //                         fontFamily: 'SF UI Display',
        //                         fontSize: '14px',
        //                         fontWeight: '700',
        //                         lineHeight: '20px',
        //                         textTransform: 'capitalize',
        //                         marginTop: '10px',
        //                         cursor: 'pointer',
        //                         bgcolor: '#00BD57',
        //                         '&:hover': {
        //                           bgcolor: '#00BD57',
        //                         },
        //                       }}
        //                       onClick={() => {
        //                         if (claimStatus) {
        //                           handleOpenModal();
        //                           return;
        //                         }
        //                         handleCopyLink();
        //                       }}
        //                     >
        //                       <CopyIcon color="#fff" />
        //                       Copy Review Link
        //                     </Button>
        //                   </Box>
        //                 )}

        //                 <CustomTabPanel value={value} index={0}>
        //                   <>
        //                     {receivedReviews.length > 0 ? (
        //                       <>
        //                         {receivedReviews.map((item: any, index) => (
        //                           <>
        //                             <ReviewCard
        //                               item={item}
        //                               key={index}
        //                               companyData={user?.company}
        //                               pageType={value}
        //                               getReviews={getReviews}
        //                               setFlagModal={setFlagModal}
        //                               setReviewId={setReviewId}
        //                               claimStatus={claimStatus}
        //                             />
        //                           </>
        //                         ))}
        //                       </>
        //                     ) : (
        //                       <>
        //                         <p>
        //                           {loadingRecivedReviews ? (
        //                             <LinearProgress color="inherit" />
        //                           ) : (
        //                             'No Review Found'
        //                           )}
        //                         </p>
        //                       </>
        //                     )}
        //                   </>
        //                 </CustomTabPanel>
        //               </>
        //             )}

        //             <CustomTabPanel value={value} index={userType === 'member' ? 0 : 1}>
        //               <>
        //                 {' '}
        //                 <>
        //                   {submittedReviews?.length > 0 ? (
        //                     <>
        //                       {submittedReviews?.map((item: any, index) => (
        //                         <ReviewCard
        //                           item={item}
        //                           key={index}
        //                           companyData={item?.company}
        //                           pageType={value}
        //                           getReviews={getReviews}
        //                           setFlagModal={setFlagModal}
        //                           setReviewId={setReviewId}
        //                           claimStatus={claimStatus}
        //                         />
        //                       ))}
        //                     </>
        //                   ) : (
        //                     <>
        //                       <p>
        //                         {loadingRecivedReviews ? (
        //                           <LinearProgress color="inherit" />
        //                         ) : (
        //                           'No Review Found'
        //                         )}
        //                       </p>
        //                     </>
        //                   )}
        //                 </>
        //               </>
        //             </CustomTabPanel>
        //           </Box>
        //           {value === 0 && userType !== 'member' && (
        //             <Box
        //               width={'28%'}
        //               borderRadius={'12px'}
        //               bgcolor={'#F7F7F7'}
        //               display={'flex'}
        //               padding={'25px'}
        //               flexDirection={'column'}
        //               alignItems={'flex-start'}
        //               gap={'14px'}
        //               height={'fit-content'}
        //               marginTop={'65px'}
        //               sx={{
        //                 '@media (max-width: 1200px)': {
        //                   display: 'none',
        //                 },
        //               }}
        //             >
        //               <Box width={'55px'} height={'55px'}>
        //                 <img
        //                   style={{ width: '100%', height: '100%' }}
        //                   src={CopyLinkIcon}
        //                   alt="copy"
        //                 />
        //               </Box>
        //               <Typography
        //                 fontFamily="SF UI Display"
        //                 fontWeight="700"
        //                 fontSize="20px"
        //                 color="#000"
        //               >
        //                 Invite your customers to review you!
        //               </Typography>
        //               <Typography
        //                 fontFamily="SF UI Display"
        //                 fontWeight="400"
        //                 fontSize="16px"
        //                 color="#000"
        //               >
        //                 We make it easy to copy and paste a review link anywhere.
        //               </Typography>
        //               <Button
        //                 sx={{
        //                   width: '100%',
        //                   display: 'flex',
        //                   padding: '10px 0',
        //                   justifyContent: 'center',
        //                   alignItems: 'center',
        //                   gap: '15px',
        //                   borderRadius: '8px',
        //                   border: '2px solid #E1E1E1',
        //                   color: '#000',
        //                   fontFamily: 'SF UI Display',
        //                   fontSize: '14px',
        //                   fontWeight: '700',
        //                   lineHeight: '20px',
        //                   textTransform: 'capitalize',
        //                   marginTop: '10px',
        //                   cursor: 'pointer',
        //                 }}
        //                 onClick={() => {
        //                   if (claimStatus) {
        //                     handleOpenModal();
        //                     return;
        //                   }
        //                   handleCopyLink();
        //                 }}
        //               >
        //                 <CopyIcon color="#000" />
        //                 Copy Review Link
        //               </Button>
        //             </Box>
        //           )}
        //         </Box>
        //       </Box>
        //     </Box>
        //   )}
        // </>
        <Box
          width={'100%'}
          height={'100%'}
          display={'flex'}
          // gap={'1%'}
          // justifyContent={'space-between'}
          alignItems={'center'}
          justifyContent={'center'}
          sx={{
            '@media (max-width: 500px)': {
              marginBottom: '-10.6%',
            },
          }}
        >
          <Box width={'100%'} mt={'2%'} height={'100%'}>
            <Box
              width="100%"
              padding="1% 0% 0%"
              height={'100%'}
              // height="86.5vh"
              borderRadius="10px"
              display="flex"
              flexDirection={'column'}
              justifyContent={userData?.company ? 'flex-end' : 'space-between'}
              alignItems={'center'}
              position={'relative'}
              // gap={'26px'}
              // justifyContent={'center'}
              sx={{
                '@media (max-width: 500px)': {
                  height: '70%',
                },
              }}
            >
              <Box width={'100%'} textAlign={'center'}>
                <Typography
                  fontFamily="SF UI Display"
                  fontWeight="800"
                  fontSize="36px"
                  lineHeight="42.96px"
                  color="#000000"
                  sx={{
                    '@media (max-width: 768px)': {
                      fontSize: '30px',
                    },
                    '@media (max-width: 500px)': {
                      fontSize: '20px',
                    },
                  }}
                >
                  {/* Your page is in review! */}
                  {!userData?.company ? 'Finish Your Company Page!' : 'Your page is in review!'}
                </Typography>
                <Typography
                  fontFamily="SF UI Display"
                  fontWeight="300"
                  fontSize="18px"
                  lineHeight="21.48px"
                  color="#000000"
                  mb={3.5}
                  sx={{
                    '@media (max-width: 768px)': {
                      fontSize: '18px',
                      textAlign: 'center',
                    },
                    '@media (max-width: 500px)': {
                      fontSize: '16px',
                    },
                  }}
                >
                  {!userData?.company
                    ? 'Let customers start finding you today.'
                    : 'Look for an approval email soon.'}
                </Typography>
              </Box>
              <Box
                width={'100%'}
                display={'flex'}
                flexDirection={'column'}
                justifyContent={'end'}
                alignItems={'center'}
                position={'relative'}
                bottom={'0'}
                overflow={'hidden'}
              >
                {/* desktop img */}
                <Box
                  width={'68%'}
                  // height={'fit-content'}
                  // height={userData?.company ? '555px' : '605px'}
                  // height={'80%'}
                  display={'flex'}
                  justifyContent={'center'}
                  alignItems={'center'}
                  overflow={'hidden'}
                  sx={{
                    '@media (max-width: 768px)': {
                      display: 'none',
                    },
                  }}
                >
                  <img
                    src={userData?.company ? ReviewDashboardimg : PenddingApproveBgImg}
                    alt="."
                    style={{ width: '100%', height: '100%', marginBottom: '25px' }}
                  />
                </Box>
                {/* Mob img */}
                <Box
                  width={'100%'}
                  height={'100%'}
                  display={'none'}
                  justifyContent={'center'}
                  alignItems={'center'}
                  mt={'30px'}
                  mb={'35px'}
                  sx={{
                    '@media (max-width: 768px)': {
                      display: 'flex',
                    },
                  }}
                >
                  <img
                    src={PenddingApproveBgImgMob}
                    alt="."
                    style={{ width: '100%', height: '100%' }}
                  />
                </Box>
                {!userData?.company ? (
                  <Button
                    variant="contained"
                    color="primary"
                    // Add other styling or props as needed
                    sx={{
                      position: 'fixed',
                      bottom: '0',
                      width: '83%',
                      borderRadius: '0',
                      height: '44px',
                      zIndex: '234',
                      background: '#00BD57',
                      fontWeight: '800',
                      fontSize: '16px',
                      lineHeight: '19.09px',
                      textTransform: 'capitalize',
                      // '@media (max-width: 768px)': {
                      //   position: 'fixed',
                      // },
                      '&:hover': {
                        backgroundColor: '#00BD57', // Same color on hover
                        boxShadow: 'none', // Remove hover shadow effect if needed
                      },
                      '@media (max-width: 1200px)': {
                        width: '100%',
                      },
                    }}
                    onClick={handleClick}
                  >
                    Finish Now
                  </Button>
                ) : (
                  ''
                )}
              </Box>

              {/* left content box */}
              {/* <Box
                position={'sticky'}
                top={'0%'}
                bgcolor={'#fff'}
                zIndex={1}
                mt={5}
                sx={{
                  pointerEvents: 'none', // Disable interactions
                  opacity: 0.5, // Visually indicate the box is disabled
                }}
              >
                <Tabs
                  value={value}
                  onChange={handleChange}
                  aria-label="basic tabs example"
                  TabIndicatorProps={{
                    style: {
                      height: '3px',
                      backgroundColor: '#00BD57',
                      cursor: claimStatus ? 'default' : 'pointer',
                    },
                  }}
                >
                  {userType !== 'member' && (
                    <Tab
                      label={
                        <div style={{ display: 'flex', alignItems: 'center', gap: '5px' }}>
                          Company Reviews&nbsp;
                          <span
                            style={{
                              color: value === 0 ? '#fff' : '#B8B8B8',
                              backgroundColor: value === 0 ? '#00BD57' : '#F7F7F7',
                              borderRadius: '9999px',
                              padding: '0 6px',
                              // border: value === 0 ? '2px solid #00AE50' : '2px solid transparent',
                              display: 'flex',
                              justifyContent: 'center',
                              alignItems: 'center',
                            }}
                          >
                            {/* {receivedReviews?.length} */}
              {/* </span>
                        </div>
                      }
                      // {...a11yProps(0)}
                      sx={{
                        fontSize: '16px',
                        fontFamily: 'SF UI Display',
                        color: '#9A9A9A',
                        fontWeight: '500',
                        textTransform: 'capitalize',
                        padding: '0',
                        marginRight: '3%',
                        cursor: claimStatus ? 'default' : 'pointer',

                        '&.Mui-selected': { color: '#00BD57', fontWeight: '600' },
                      }}
                    />
                  )} */}

              {/* <Tab
                    label={
                      <div style={{ display: 'flex', alignItems: 'center', gap: '5px' }}>
                        My Reviews&nbsp;
                        <span
                          style={{
                            color:
                              value === 1 || (value === 0 && userType === 'member')
                                ? '#fff'
                                : '#B8B8B8',
                            backgroundColor:
                              value === 1 || (value === 0 && userType === 'member')
                                ? '#00BD57'
                                : '#F7F7F7',
                            borderRadius: '9999px',
                            padding: '0 6px', // Adds padding to make the circle more defined
                            // border:
                            //   value === 1 || (value === 0 && userType === 'member')
                            //     ? '2px solid #00AE50'
                            //     : '2px solid transparent',
                            display: 'flex',
                            justifyContent: 'center',
                            alignItems: 'center',
                          }}
                        >
                          {/* {submittedReviews?.length} */}
              {/* </span>
                      </div>
                    }
                    {...a11yProps(1)}
                    sx={{
                      fontSize: '16px',
                      fontFamily: 'SF UI Display',
                      color: '#9A9A9A',
                      fontWeight: '500',
                      textTransform: 'capitalize',
                      padding: '0',
                      cursor: claimStatus ? 'default' : 'pointer',

                      '&.Mui-selected': { color: '#00BD57', fontWeight: '600' },
                    }}
                  />
                </Tabs>
              </Box> */}
              {/*  */}
              {/* <Box
                display={'flex'}
                gap={'25px'}
                width={'100%'}
                sx={{
                  '@media (max-width: 1200px)': {
                    flexDirection: 'column-reverse',
                  },
                  '@media (max-width: 768px)': {
                    gap: '0',
                  },
                }}
              >
                <Box
                  display={'flex'}
                  width={'70%'}
                  justifyContent={'space-evenly'}
                  alignItems={'center'}
                  border={'solid 1px rgba(232, 232, 232, 1)'}
                  borderRadius={'10px'}
                  mt={3}
                  sx={{
                    '@media (max-width: 1200px)': {
                      width: '100%',
                    },
                    '@media (max-width: 768px)': {
                      mt: 0,
                    },
                    '@media (max-width: 680px)': {
                      flexDirection: 'column',
                    },
                  }}
                >
                  <Box
                    width={'308px'}
                    height={'308px'}
                    sx={{
                      '@media (max-width: 680px)': {
                        width: '100%',
                      },
                    }}
                  >
                    <img
                      src={subscribing}
                      alt="subscribing"
                      style={{
                        width: '100%',
                        height: '100%',
                        opacity: '0.5',
                      }}
                    />
                  </Box>
                  <Box
                    width={'50%'}
                    borderRadius={'12px'}
                    padding={'25px'}
                    flexDirection={'row'}
                    alignItems={'flex-start'}
                    gap={'14px'}
                    height={'fit-content'}
                    marginTop={'10px'}
                    sx={{
                      opacity: '0.5',
                      '@media (max-width: 1200px)': {
                        display: 'flex',
                        flexDirection: 'column',
                      },
                      '@media (max-width: 680px)': {
                        width: '100%',
                      },
                    }}
                  >
                    <Box display={'flex'} alignItems={'center'} width={'100%'} gap={'10px'}>
                      <Box>
                        <Typography
                          fontFamily="SF UI Display"
                          fontWeight="800"
                          fontSize="24px"
                          color="rgba(0, 0, 0, 1)"
                        >
                          No reviews yet?
                        </Typography>
                        <Typography
                          fontFamily="SF UI Display"
                          fontWeight="400"
                          fontSize="16px"
                          lineHeight={'20px'}
                          color="#000"
                          mt={1}
                          mb={1}
                        >
                          It can take some time to get your first review. Speed up the process by
                          sending a review link to past customers!
                        </Typography>
                      </Box>
                    </Box>

                    <Button
                      sx={{
                        width: '45%',
                        display: 'flex',
                        padding: '10px 0',
                        justifyContent: 'center',
                        alignItems: 'center',
                        gap: '10px',
                        borderRadius: '8px',
                        color: '#fff',
                        fontFamily: 'SF UI Display',
                        fontSize: '14px',
                        fontWeight: '700',
                        lineHeight: '20px',
                        textTransform: 'capitalize',
                        marginTop: '10px',
                        cursor: 'pointer',
                        bgcolor: '#00BD57',
                        '&:hover': {
                          bgcolor: '#00BD57',
                        },
                        '@media (max-width: 1300px)': {
                          width: '65%',
                        },
                        '@media (max-width: 680px)': {
                          width: '45%',
                        },
                      }}
                      onClick={handleOpenModal}
                      // onClick={handleCopyLink}
                    >
                      Copy Review Links
                    </Button>
                  </Box>
                </Box>{' '}
                <Box
                  width={'30%'}
                  mt={5}
                  sx={{
                    opacity: '0.5',
                    '@media (max-width: 1200px)': {
                      width: '100%',
                    },
                  }}
                >
                  <Box
                    width={'100%'}
                    borderRadius={'12px'}
                    bgcolor={'rgba(247, 247, 247, 1)'}
                    padding={'25px'}
                    flexDirection="column"
                    // flexDirection={'column'}
                    alignItems={'flex-start'}
                    gap={'14px'}
                    height={'fit-content'}
                    sx={{
                      '@media (max-width: 1200px)': {
                        display: 'none',
                      },
                    }}
                  >
                    <Box
                      display={'flex'}
                      alignItems={'flex-start'}
                      width={'100%'}
                      gap={'10px'}
                      flexDirection="column"
                    >
                      <Box width={'48px'} height={'48px'}>
                        <img
                          style={{ width: '100%', height: '100%' }}
                          src={CopyLinkIcon}
                          alt="copy"
                        />
                      </Box>
                      <Box>
                        <Typography
                          fontFamily="SF UI Display"
                          fontWeight="700"
                          fontSize="20px"
                          color="#000"
                          lineHeight={'20px'}
                        >
                          Invite your customers to review you!
                        </Typography>
                        <Typography
                          fontFamily="SF UI Display"
                          fontWeight="400"
                          fontSize="16px"
                          color="#000"
                          mt={1}
                          mb={1}
                        >
                          We make it easy to copy and paste a review link anywhere.
                        </Typography>
                      </Box>
                    </Box>

                    <Button
                      sx={{
                        width: '100%',
                        display: 'flex',
                        padding: '10px 0',
                        justifyContent: 'center',
                        border: 'solid 2px rgba(225, 225, 225, 1)',
                        alignItems: 'center',
                        gap: '10px',
                        borderRadius: '8px',
                        color: 'black',
                        fontFamily: 'SF UI Display',
                        fontSize: '14px',
                        fontWeight: '700',
                        lineHeight: '20px',
                        textTransform: 'capitalize',
                        marginTop: '10px',
                        cursor: 'pointer',
                        bgcolor: 'transparent',
                        '&:hover': {
                          bgcolor: 'transparent',
                        },
                      }}
                      onClick={handleOpenModal}
                    >
                      <CopyIcon color="#000" />
                      Copy Review Link
                    </Button>
                  </Box> */}
              {/* mobile */}
              {/* <Box
                    width={'100%'}
                    borderRadius={'12px'}
                    bgcolor={'#F7F7F7'}
                    display={'none'}
                    padding={'25px'}
                    flexDirection={'row'}
                    alignItems={'flex-start'}
                    gap={'14px'}
                    height={'fit-content'}
                    marginTop={'10px'}
                    sx={{
                      '@media (max-width: 1200px)': {
                        display: 'flex',
                      },
                      '@media (max-width: 768px)': {
                        display: 'none',
                      },
                    }}
                  >
                    <Box display={'flex'} alignItems={'center'} width={'68%'} gap={'10px'}>
                      <Box width={'48px'} height={'48px'}>
                        <img
                          style={{ width: '100%', height: '100%' }}
                          src={CopyLinkIcon}
                          alt="copy"
                        />
                      </Box>
                      <Box>
                        <Typography
                          fontFamily="SF UI Display"
                          fontWeight="700"
                          fontSize="20px"
                          color="#000"
                        >
                          Invite your customers to review you!
                        </Typography>
                        <Typography
                          fontFamily="SF UI Display"
                          fontWeight="400"
                          fontSize="16px"
                          color="#000"
                        >
                          We make it easy to copy and paste a review link anywhere.
                        </Typography>
                      </Box>
                    </Box>

                    <Button
                      sx={{
                        width: '30%',
                        display: 'flex',
                        padding: '10px 0',
                        justifyContent: 'center',
                        alignItems: 'center',
                        gap: '10px',
                        borderRadius: '8px',
                        color: '#fff',
                        fontFamily: 'SF UI Display',
                        fontSize: '14px',
                        fontWeight: '700',
                        lineHeight: '20px',
                        textTransform: 'capitalize',
                        marginTop: '10px',
                        cursor: 'pointer',
                        bgcolor: '#00BD57',
                        '&:hover': {
                          bgcolor: '#00BD57',
                        },
                      }}
                      onClick={handleOpenModal}
                    >
                      <CopyIcon color="#fff" />
                      Copy Review Link
                    </Button>
                  </Box>
                </Box>
              </Box> */}
            </Box>
          </Box>

          {/* <PenddingApproval open={isModalOpen} handleClose={handleCloseModal} user={user} /> */}
        </Box>
      )}
      <PenddingApproval open={isModalOpen} handleClose={handleCloseModal} user={user} />

      <FlagModal
        open={flagModal}
        onClose={handleColse}
        handleFlag={handleFlag}
        reviewId={reviewId}
      />
    </>
  );
};

export default Reviews;
