import React, { FC } from 'react';
import { Box, Button, CircularProgress, Typography } from '@mui/material';
import Logo from '../../assets/SignWiselogo .svg';
import 'react-phone-input-2/lib/style.css';
import { MuiOtpInput } from 'mui-one-time-password-input';
import { useLocation, useNavigate } from 'react-router-dom';
import request from '../../api/request';
import { toast } from 'react-toastify';
import { IAuthResponse } from '../../types/unAuth.type';
import { UpdateStore } from '../../context/store';
import CustomToast from '../../components/phase1a/common/CustomToast.component';
// import MarkEmailReadIcon from '@mui/icons-material/MarkEmailRead';

interface ILogin {
  id: string;
  otp?: string;
  // password?: string;
}

const Otp: FC = () => {
  const nav = useNavigate();
  const [otp, setOtp] = React.useState('');
  const [loading, setLoading] = React.useState(false);
  const location = useLocation();
  const { email } = location.state || {};
  const updateStore = UpdateStore();
  const handleChange = (newValue: string): void => {
    const numericValue = newValue.replace(/\D/g, '');
    setOtp(numericValue);
  };

  const isButtonDisabled = otp?.length !== 6;

  const handleSignup = (): void => {
    setLoading(true);
    const id = email;

    const data = { id, otp };

    request<IAuthResponse, ILogin>('post', '/auth/login', data)
      .then((res) => {
        localStorage.setItem('token', res.token);
        localStorage.setItem('user', JSON.stringify(res.user));
        if (res?.user) {
          updateStore?.({ user: res?.user });
        }
        setLoading(false);
        console.log('res?.user?.type', res?.user);
        // if (!res?.user?.company) {
        //   // nav('/add-business', { state: { screenNumber: 4 } });
        //   toast(
        //     <CustomToast message={'Welcome back! Complete your Sign-up process.'} type="success" />,
        //   );
        // } else {
        if (
          (res?.user?.company?.companyInfo?.companyType === 'supplier' ||
            res?.user?.company?.companyInfo?.companyType === 'distributor') &&
          res?.user?.type === 'member'
        ) {
          nav('/localpageowner/SettingPagesetup');
        } else {
          nav('/localpageowner/job-gallery');
        }
        // toast.success('You have logged in successfully.');
        toast(<CustomToast message={'You have logged in successfully.'} type="success" />);
        // }
      })
      .catch(() => {
        setLoading(false);
        // toast.error('Invalid OTP');
        toast(<CustomToast message={'Invalid OTP'} type="error" />);
      });
  };

  return (
    <Box
      width="100vw"
      display="flex"
      height="100vh"
      justifyContent="center"
      alignItems="center"
      bgcolor="#FFFFFF"
    >
      <Box
        display="flex"
        justifyContent="center"
        alignItems="center"
        flexDirection="column"
        padding="30px"
        borderRadius="20px"
      >
        <Box
          display="flex"
          flexDirection="column"
          gap="25px"
          width="100%"
          justifyContent="center"
          alignItems="center"
        >
          <Box width="50%" display="flex" justifyContent="center">
            <img src={Logo} alt="Logo" />
          </Box>
          <Box
            width={'100%'}
            display={'flex'}
            flexDirection={'column'}
            alignItems={'center'}
            gap={'5px'}
            mb={3}
          >
            {/* <MarkEmailReadIcon sx={{ fontSize: '40px', color: '#00BD57' }} /> */}
            <Typography
              fontSize="16px"
              fontWeight="500"
              color="rgba(0, 0, 0, 1)"
              width="60%"
              textAlign="center"
              fontFamily="SF UI Display"
              sx={{
                // '@media (max-width: 1024px)': {
                //   width: '50%',
                // },
                '@media (max-width: 650px)': {
                  width: '70%',
                },
                '@media (max-width: 570px)': {
                  width: '100%',
                },
              }}
            >
              We Send a temporary login code to {email}
            </Typography>
          </Box>
          {/* <Typography
            fontSize="16px"
            fontWeight="400"
            color="#000"
            width="40%"
            textAlign="center"
            fontFamily="SF UI Display"
            sx={{
              '@media (max-width: 570px)': {
                width: '100%',
              },
            }}
          >
            Enter OTP Here.
          </Typography> */}
        </Box>
        <Box
          display="flex"
          flexDirection="column"
          width="32%"
          gap="28px"
          justifyContent="center"
          alignItems="center"
          marginTop="2%"
          sx={{
            '@media (max-width: 1090px)': {
              width: '40%',
            },
            '@media (max-width: 840px)': {
              width: '60%',
            },
            '@media (max-width: 570px)': {
              width: '100%',
            },
          }}
        >
          <MuiOtpInput
            TextFieldsProps={{
              inputProps: {
                inputMode: 'numeric',
              },
            }}
            length={6}
            value={otp}
            onChange={handleChange}
            sx={{
              '& .css-9ddj71-MuiInputBase-root-MuiOutlinedInput-root': {
                borderRadius: '12px !important',
              },
              '& .css-1t8l2tu-MuiInputBase-input-MuiOutlinedInput-input': {
                padding: '16.5px 10px',
                borderRadius: '12px !important',
              },
              '& .css-1v4ccyo': {
                borderRadius: '12px !important',
              },
              '@media (max-width: 370px)': {
                gap: '9px',
              },
            }}
          />

          <Box>
            <Typography
              textAlign={'center'}
              mt={3}
              fontSize={'16px'}
              fontWeight={'300'}
              marginTop={'20px'}
              fontFamily="SF UI Display"
              sx={{ textAlign: 'left' }}
            >
              {' '}
              We have emailed you a magic code for a password-free sign in. Or you can
              <span
                onClick={() => {
                  nav('/loginmanually', { state: { userEmail: email } });
                }}
                style={{
                  // textAlign: 'center',
                  fontSize: '16px',
                  fontWeight: '300',
                  color: 'rgba(0, 189, 87, 1)',
                  marginTop: '20px',
                  fontFamily: 'SF UI Display',
                  textDecoration: 'underline',
                  cursor: 'pointer',
                  marginLeft: '5px',
                }}
              >
                sign in manually with your password.
              </span>
            </Typography>
          </Box>
          <Button
            fullWidth
            disabled={isButtonDisabled}
            onClick={handleSignup}
            sx={{
              padding: '12px 24px',
              bgcolor: isButtonDisabled ? '#D0D5DD' : 'black',
              borderRadius: '15px',
              color: 'white',
              textTransform: 'unset',
              fontSize: '14px',
              fontFamily: 'Poppins',
              fontWeight: '600',
              boxShadow: '0 2px 4px rgba(0, 0, 0, 0.1)',
              '&:hover': {
                bgcolor: 'black',
                color: 'white',
              },
            }}
          >
            {loading ? <CircularProgress size={15} color="inherit" /> : 'Continue'}
          </Button>
        </Box>
      </Box>
    </Box>
  );
};

export default Otp;
