/* eslint-disable @typescript-eslint/restrict-template-expressions */
import React, { FC, useEffect, useState } from 'react';
import {
  Box,
  Button,
  Typography,
  MenuItem,
  Select,
  LinearProgress,
  Checkbox,
  ListItemText,
  SelectChangeEvent,
  ListItemIcon,
} from '@mui/material';
import HeaderSignCompany from '../../../components/phase1a/local/Header.component';
import KeyboardArrowDownIcon from '@mui/icons-material/KeyboardArrowDown';
// import Logo from '../../../assets/nationalSignCompany/signLogo.svg';
// import defaultCompany from '../../../assets/defaultCompany.png';
// import CommentOutlinedIcon from '@mui/icons-material/CommentOutlined';
// import StarBorderIcon from '@mui/icons-material/StarBorder';
// import { styled } from '@mui/material/styles';
// import Rating from '@mui/material/Rating';
// import ResponseIcon from '../../../assets/nationalSignCompany/responseIcon.svg';
// import LicenseIdIcon from '../../../assets/nationalSignCompany/licenseIdIcon.svg';
// import CheckmarkFilled from '../../../assets/nationalSignCompany/checkmarkFilled.svg';
// import Complaint from '../../../assets/complaint.svg';
import { Link, useLocation, useNavigate } from 'react-router-dom';
import request from '../../../api/request';
import { ILocal } from '../../../types/user';
// import { mediaUrl } from '../../../config';
import serviceFilter1 from '../../../assets/serviceFilter/serviceFilter1.svg';
import serviceFilter2 from '../../../assets/serviceFilter/serviceFilter2.svg';
import serviceFilter3 from '../../../assets/serviceFilter/serviceFilter3.svg';
import serviceFilter4 from '../../../assets/serviceFilter/serviceFilter4.svg';
import serviceFilter5 from '../../../assets/serviceFilter/serviceFilter5.svg';
import printProduction from '../../../assets/serviceFilter/serviceFilter6.svg';
import PrintInstallation from '../../../assets/serviceFilter/serviceFilter7.svg';
import WrapInstallation from '../../../assets/serviceFilter/serviceFilter8.svg';
import defaultCompany from '../../../assets/defaultCompany.png';
import usaStatesAndCanadaProvinces from '../../../utils/states';
import { ReactComponent as CardTopIcon } from '../../../assets/Phase1a/DesignUpdate/DesignUpdateCardIcon.svg';
import LoginPopup from '../../../Popups/phase1a/local/SignIn.popups';
import SubmitComReq from '../../../Popups/phase1a/local/SubmitComReq.popup';
import ListingCard from '../../../components/phase1a/common/ListingCard.component';
import { mediaUrl } from '../../../config';
// import { toast } from 'react-toastify';
// import CustomToast from '../../../components/phase1a/common/CustomToast.component';

// const CustomStarBorderIcon = styled(StarBorderIcon)({
//   color: '#ffffff', // Set the outline color to white
//   stroke: '#ffffff', // Ensure the outline is white
//   strokeWidth: 0.5, // Adjust stroke width as needed
// });
// const CustomStarBorder = styled(StarBorderIcon)({
//   color: '#e5e7eb', // Set the outline color to white
//   stroke: '#e5e7eb', // Ensure the outline is white
//   strokeWidth: 0.5, // Adjust stroke width as needed
// });

const LocalCompanyListing: FC = () => {
  const nav = useNavigate();
  const location = useLocation();
  const {
    addSelected,
    myCurrLoc,
    nearData,
    selectedAdd,
    companyType,
    searchData,
    locationsParam,
    searchText,
  } = location.state || {};
  // const userJSON = localStorage.getItem('user');
  // const userData = userJSON ? JSON.parse(userJSON) : '';
  // const queryParams = new URLSearchParams(location.search);
  // const paramType = queryParams.get('type') ?? undefined;
  // const paramSearch = queryParams.get('search') ?? undefined;
  // const paramAdditional = queryParams.get('additional') ?? undefined;
  // const paramlocation = queryParams.get('location') ?? undefined;
  const jsonPaser = (raw?: string): number[] | undefined => {
    if (!raw) return undefined;
    const data = JSON?.parse(raw);

    if (data) return data;
    else return undefined;
  };
  // console.log(
  //   'parameters params',
  //   'paramType',
  //   paramType,
  //   'paramSearch',
  //   paramSearch,
  //   'paramlocation',
  //   paramlocation,
  // );
  console.log(
    'parameters states',
    'companyType',
    companyType,
    'searchData',
    searchData,
    'locationsParam',
    locationsParam,
    'nearData',
    nearData,
    selectedAdd,
  );
  const [type, setType] = useState<string | undefined>(companyType);
  console.log('🚀 ~ setType:', setType);
  const [search, setSearch] = useState<any>(searchData);
  const [searchTypo, setSearchTypo] = useState<any>(searchText);
  const [additional, setAdditional] = useState<string | undefined>(nearData);
  const [coordinates, setCoordinates] = useState<string | undefined>(locationsParam ?? '');
  const [selectedAddress, setSelectedAddress] = useState<any>(selectedAdd ?? addSelected ?? null);
  const [near, setNear] = useState<string>(nearData ?? '');
  const [myLocation, setMyLocation] = useState<any>(myCurrLoc);
  const [companyData, setCompanyData] = useState<ILocal[] | null>(null);
  const [complaint, setComplaint] = useState<boolean>(false);
  const [rating, setRating] = useState<boolean>(false);
  // const [sortBy, setSortBy] = useState('rating');
  const [loadingTopCompanies, setLoadingTopCompanies] = useState(true);

  console.log(
    'mnbvcccxsdfggh =',
    'selectedAdd:',
    selectedAdd,
    'addSelected:',
    addSelected,
    'selectedAddress:',
    selectedAddress,
    'myLocation:',
    myLocation,
  );
  const [selectedServices, setSelectedServices] = useState<string[]>([]);
  console.log('selectedServices additional', additional, 'coordinates:', coordinates);
  console.log('searchTypo', searchTypo, 'search', search);
  const handleChange = (event: SelectChangeEvent<typeof selectedServices>): void => {
    setSelectedServices(event.target.value as string[]);
  };
  console.log('myLocationmyLocation', myLocation);

  // const handleSortChange = (event: any): void => {
  //   setSortBy(event.target.value);
  // };

  console.log('great coordinates', coordinates);
  console.log('great near', near);
  console.log('great myLocation', myLocation);
  console.log('great search', search);
  console.log('great searchTypo', searchTypo);
  console.log('great selectedAddress', selectedAddress);
  // const findType =
  //   type === 'local'
  //     ? search?.name === 'Service' ||
  //       search?.name === 'Fabricators' ||
  //       search?.name === 'Installers' ||
  //       search?.name === 'Surveys' ||
  //       search?.name === 'Full Service Companies' ||
  //       searchTypo.name === 'Service' ||
  //       searchTypo.name === 'Fabricators' ||
  //       searchTypo.name === 'Installers' ||
  //       searchTypo.name === 'Surveys' ||
  //       searchTypo.name === 'Full Service Companies'
  //       ? 'service'
  //       : 'company'
  //     : 'company';
  const searchName = search?.name?.toLowerCase();
  const searchTypoName = searchTypo?.name?.toLowerCase();

  const serviceNames = [
    'service',
    'fabricators',
    'installers',
    'surveys',
    'full service companies',
  ];
  const findType =
    type === 'local' && (serviceNames.includes(searchName) || serviceNames.includes(searchTypoName))
      ? 'service'
      : 'company';
  console.log('companyData', companyData);
  console.log('searchsearchsearch', search);
  // console.log('paramAdditional', paramAdditional);
  const filters = {
    distance: ['10', '50', '100', '200', '300', 'Distance'],
    signType: ['Awnings', 'Banners', 'Cabinet Signs', 'Channel Letters', 'SignType'],
  };
  // console.log('setType', setType, setCoordinates);
  const distanceRanges = ['0-10', '10-50', '50-100', '100-200', '200-300'];
  // const [search, setSearch] = useState<string | undefined>(paramSearch);
  const [selectedValues, setSelectedValues] = useState({
    distance: 'Distance',
    signType: 'SignType',
  });

  console.log('selectedValues', selectedValues?.distance);
  console.log('setSelectedValsetSelectedValuesues', setSelectedValues);
  useEffect(() => {
    // Perform any actions based on the search parameters
    console.log('RR Type:', type);
    console.log('RR Search:', search);
    console.log('RR Additional:', additional);
  }, [type, search, additional]);
  const handleGetData = (): void => {
    const encodedType = encodeURIComponent(type ?? '');
    const encodedSearch = encodeURIComponent(search?.name ?? searchTypo?.name ?? '');
    const encodedLocation = coordinates
      ? encodeURIComponent(coordinates)
      : encodeURIComponent(locationsParam as string);
    const encodedAdditional = encodeURIComponent(additional ? additional.split(',')[0] : '');
    const filter = {
      rating,
      no_complaint: complaint,
    };
    const distanceFilter = {
      distance: selectedValues?.distance === 'Distance' ? '' : selectedValues?.distance,
      no_complaint: complaint,
      services: selectedServices, // Add selected services here
    };

    const encodedFilter = encodeURIComponent(
      JSON.stringify(
        type === 'local'
          ? // By Awais
            distanceFilter && !distanceFilter?.no_complaint
            ? { distance: distanceFilter?.distance, services: selectedServices }
            : distanceFilter
          : filter && !filter?.no_complaint
          ? { rating: filter?.rating }
          : filter,
      ),
    );
    const paramType =
      type === 'local'
        ? 'local-sign-company'
        : type === 'national'
        ? 'national-sign-company'
        : type === 'supplier'
        ? 'supplier'
        : 'distributor';
    if (search?.id) {
      nav(`/${paramType}/${formatSearchString(search?.name)}`, {
        state: {
          searchID: search?.id,
          type,
          search,
          coords: coordinates ?? '',
          near: near !== '' ? near : '',
          searchTypo,
          myCurrLoc: myLocation,
        },
      });
      return;
    }
    // const url = `/localCompany/unAuthenticatedDashboard?search=${searchEncoded}&filter=${filterEncoded}&location=${locationEncoded}`;
    const url = `/companies/search/${encodedType}?search=${encodedSearch}&location=${encodedLocation}&additional=${encodedAdditional}&filter=${encodedFilter}&sortOrder=desc`;
    // if (encodedType === 'local') url += `&sort=${sortBy}&sortOrder=desc`;
    console.log('url', url);
    request('get', url)
      .then((response: any) => {
        setCompanyData(response?.companies);
        setLoadingTopCompanies(false);
      })
      .catch((error: any) => {
        console.log(error);
        setLoadingTopCompanies(false);
      });
  };

  const handleChangeFilter = (value: string, title: string): void => {
    if (title === 'Distance') {
      setSelectedValues((prev) => ({ ...prev, distance: value }));
    }
  };

  // const obj = {
  //   Logo,
  //   title: "D's Sign & Lighting LLC",
  //   desp: 'Local Install Company',
  //   rating: 4.9,
  //   status: 'Recommended',
  //   statusBg: '#3040CE',
  //   tag1: '#93456',
  //   tagIcon1: <img src={LicenseIdIcon} alt="License Id Icon" />,
  //   tag2: 'No Complaints',
  //   tagIcon2: <img src={CheckmarkFilled} alt="Checkmark Filled" />,
  //   tag3: '24hr Response Rate',
  //   tagIcon3: <img src={ResponseIcon} alt="Response Icon" />,
  //   review:
  //     '“We’ve been using D’s Sign & Lighting for years now. They do great work and always make sure were happy!”',
  // };

  const tabs =
    type === 'local'
      ? [
          {
            title: 'Map View',
          },
          {
            title: 'Distance',
            Icon: <KeyboardArrowDownIcon />,
          },
          // {
          //   title: 'No Complaints',
          // },
          {
            title: 'Services',
            Icon: <KeyboardArrowDownIcon />,
          },
        ]
      : [
          {
            title: 'Map View',
          },
          {
            title: 'Rating',
          },
          {
            title: 'No Complaints',
          },
        ];

  const handleAddressSelect = (address: any): void => {
    setSelectedAddress(address);
  };

  useEffect(() => {
    handleGetData();
  }, [
    searchData,
    type,
    complaint,
    rating,
    selectedValues?.distance,
    // sortBy,
    search,
    near,
    additional,
    selectedServices,
  ]);

  useEffect(() => {
    if (selectedAddress?.coordinates?.length === 2) {
      const [lan, lat] = selectedAddress.coordinates;
      const cor = `[${lan}, ${lat}]`;
      setCoordinates(cor);
    }
  }, [selectedAddress?.coordinates]);
  useEffect(() => {
    if (locationsParam) {
      setCoordinates(locationsParam);
    }
  }, [locationsParam]);
  useEffect(() => {
    setMyLocation(myCurrLoc);
  }, [myCurrLoc]);
  useEffect(() => {
    if (!jsonPaser(coordinates)?.length) {
      setSelectedValues((prev) => ({ ...prev, distance: 'Distance' }));
      // setSortBy('rating');
    }
  }, [!jsonPaser(coordinates)?.length]);

  useEffect(() => {
    if (searchData) {
      setSearch(searchData);
    }
  }, searchData);
  // const isMobile = useMediaQuery('(max-width:768px)');

  // const serviceOptions = [
  //   { name: 'Full Service Companies', image: serviceFilter1 },
  //   { name: 'Fabricators', image: serviceFilter2 },
  //   { name: 'Installers', image: serviceFilter3 },
  //   { name: 'Service', image: serviceFilter4 },
  //   { name: 'Surveys', image: serviceFilter5 },
  // ];

  const serviceOptions = [
    { name: 'Sign Fabrication', logo: 'fabricatorsicon' },
    { name: 'Sign Installation', logo: 'installerIcon' },
    { name: 'Print Production', logo: 'printersIcon' },
    { name: 'Print Installation', logo: 'printInstallersIcon' },
    { name: 'Wrap Installation', logo: 'wrapInstallersIcon' },
    { name: 'Permits', logo: 'permitsIcon' },
    { name: 'Surveys', logo: 'surveyIcon' },
    { name: 'Service', logo: 'serviceIcon' },
  ];
  const formatSearchString = (str: string): string => {
    if (!str) return ''; // Handle cases where str might be null or undefined
    // Trim, lowercase, and replace spaces with hyphens
    const formattedStr = str.toLowerCase().replace(/\s+/g, '-').trim();
    switch (formattedStr) {
      case 'fabricators':
        return 'sign-fabricators';
      case 'installers':
        return 'sign-installers';
      case 'full-service-companies': // Handle the formatted string directly
        return 'full-service';
      default:
        return formattedStr;
    }
  };
  function getStateAbbreviation(additional: string): string {
    if (!additional) return '';

    // Split the 'additional' string by comma and trim any whitespace
    const parts = additional.split(',').map((part: string) => part.trim());

    // Extract the city name (first part) and the state name (last part)
    const cityName = parts[0];
    const stateName = parts[parts?.length - 1];

    // Find the state or province in the array and get its abbreviation
    const state = usaStatesAndCanadaProvinces.find(
      (item: any) => item?.name?.toLowerCase() === stateName?.toLowerCase(),
    );

    // Return formatted string as "City, State Abbreviation"
    return state ? `${cityName}, ${state?.abbreviation}` : additional;
  }
  const [openPopup, setOpenPopup] = useState<number | null>(null);
  const navigate = useNavigate();
  console.log(navigate);
  const handleClick = (): void => {
    window.open('https://signwise.fibery.io/@public/forms/NVLyyAAd', '_blank');
    // setOpenPopup(2);
    // #Beta
    // if (!userData) {
    //   setOpenPopup(1); // Set state to render LoginPopup
    // } else if (!userData?.company) {
    //   toast(<CustomToast message={'Complete your Sign-Up process first.'} type="error" />);
    // } else {
    //   setOpenPopup(2); // Show second popup
    // }
  };

  const handleClose = (): void => {
    setOpenPopup(null);
  };

  return (
    <Box width="100%">
      <HeaderSignCompany
        listingGrid
        search={search}
        searchData={searchData}
        setSearch={setSearch}
        searchTypo={searchTypo}
        setSearchTypo={setSearchTypo}
        additional={additional}
        setAdditional={setAdditional}
        type={type}
        handleAddressSelect={handleAddressSelect}
        handleGetData={handleGetData}
        setNear={setNear}
        near={near}
        setCoordinates={setCoordinates}
        coordinates={coordinates}
        myLocation={myLocation}
        setMyLocation={setMyLocation}
        selectedAddress={selectedAddress}
        setSelectedAddress={setSelectedAddress}
      />
      <Box
        width={'100%'}
        padding={'3% 10%'}
        display={'flex'}
        alignItems={'flex-start'}
        gap={'4%'}
        marginTop={'80px'}
        sx={{
          '@media (max-width: 1200px)': {
            padding: '3%',
          },
          '@media (max-width: 900px)': {
            flexDirection: 'column',
          },
        }}
      >
        <Box
          width={'70%'}
          sx={{
            '@media (max-width: 900px)': {
              width: '100%',
            },
          }}
        >
          <Box
            width={'100%'}
            display={'flex'}
            alignItems={'center'}
            mb={'10px'}
            // ml={'5px'}
            justifyContent={'space-between'}
            sx={{
              '@media (max-width: 500px)': {
                flexDirection: 'column-reverse',
                alignItems: 'start',
                mt: '10px',
              },
            }}
          >
            <Typography
              fontSize={'14px'}
              fontWeight={'500'}
              fontFamily={'SF UI Display'}
              color={'#000000'}
            >
              <Link to="/">Home </Link>{' '}
              {`${
                additional && type === 'local'
                  ? additional.includes(',')
                    ? `> ${additional.split(',')[1].trim()} > ${additional.split(',')[0].trim()}`
                    : `> ${additional}`
                  : ''
              } ${
                type === 'local'
                  ? `${search?.name ? `> ${search?.name}` : ''}`
                  : type === 'national' && additional
                  ? `> ${additional}`
                  : type === 'national'
                  ? '> Nationals'
                  : type === 'supplier'
                  ? '> Suppliers'
                  : '> Distributor'
              }`}
            </Typography>
            {/* {type === 'local' ? ( */}
            {/* <Box
              display={'flex'}
              alignItems={'center'}
              gap={'10px'}
              sx={{
                '@media (max-width: 500px)': {
                  width: '100%',
                  display: 'flex',
                  justifyContent: 'flex-end',
                },
              }}
            >
              <Typography
                fontSize={'14px'}
                fontWeight={'500'}
                fontFamily={'SF UI Display'}
                color={'#000000'}
              >
                Sort by:
              </Typography>
              <Select
                value={sortBy}
                onChange={handleSortChange}
                displayEmpty
                // disabled={!coordinates}
                IconComponent={KeyboardArrowDownIcon}
                MenuProps={{
                  disableScrollLock: true,
                }}
                sx={{
                  // marginLeft: '8px',
                  fontWeight: 600,
                  fontFamily: 'SF UI Display',
                  '& .MuiSelect-select': {
                    padding: '8px 0px',
                  },
                  '& .MuiOutlinedInput-notchedOutline': {
                    border: 'none',
                  },
                  '&:hover .MuiOutlinedInput-notchedOutline': {
                    border: 'none',
                  },
                  '&.Mui-focused .MuiOutlinedInput-notchedOutline': {
                    border: 'none',
                  },
                }}
              >

                <MenuItem value="rating">Rating</MenuItem>
                <MenuItem
                  disabled={!jsonPaser(coordinates)?.length || type !== 'local'}
                  value="distance"
                >
                  Distance
                </MenuItem>
              </Select>
            </Box> */}
            {/* // ) : (
            //   ''
            // )} */}
          </Box>

          <Typography
            fontSize={'32px'}
            fontWeight={'800'}
            fontFamily={'SF UI Display'}
            color={'#000000'}
            lineHeight={'30px'}
            sx={{
              '@media (max-width: 900px)': {
                fontSize: '22px',
              },
            }}
          >
            {type === 'local'
              ? search?.name && selectedAddress
                ? `${search?.name} ${
                    findType === 'company' && selectedAddress ? 'in' : 'Near'
                  } ${getStateAbbreviation(
                    `${selectedAddress?.contexts?.place}, ${selectedAddress?.contexts?.region}`,
                  )}`
                : `Sign Companies ${
                    selectedAddress
                      ? `Near ${getStateAbbreviation(
                          `${selectedAddress?.contexts?.place}, ${selectedAddress?.contexts?.region}`,
                        )}`
                      : `Near ${getStateAbbreviation(`${myLocation?.city}, ${myLocation?.state}`)}`
                  }`
              : type === 'national'
              ? 'National Sign Companies'
              : type === 'supplier'
              ? additional !== ''
                ? `${additional} Companies`
                : 'Supplier Companies'
              : type === 'distributor'
              ? additional !== ''
                ? `${additional} Companies`
                : 'Distributor Companies'
              : additional !== undefined && additional !== null
              ? `${additional} Companies`
              : ''}
          </Typography>
          {type === 'local' && (
            <Box
              width={'100%'}
              display={'flex'}
              alignItems={'center'}
              gap={'10px'}
              marginTop={'4%'}
              sx={{
                overflowX: 'scroll',
                '&::-webkit-scrollbar': {
                  display: 'none',
                },
                '-ms-overflow-style': 'none',
                'scrollbar-width': 'none',
              }}
            >
              {tabs.map((item, index) => (
                <React.Fragment key={index}>
                  {item.title === 'Distance' || item.title === 'Sign Type' ? (
                    <Select
                      labelId={`select-${item.title.toLowerCase()}-label`}
                      id={`select-${item.title.toLowerCase()}`}
                      value={
                        item.title === 'Distance'
                          ? selectedValues.distance
                          : selectedValues.signType
                      }
                      onChange={(e) => {
                        handleChangeFilter(e.target.value, item.title);
                      }}
                      className="Select_Box"
                      MenuProps={{
                        disableScrollLock: true,
                      }}
                      name={item.title === 'Distance' ? 'distance' : 'signType'}
                      disabled={item?.title === 'Distance' && !jsonPaser(coordinates)?.length}
                      sx={{
                        '&.MuiInputBase-root': {
                          borderRadius: '50px',
                        },
                        textTransform: 'capitalize',
                        color: '#000000',
                        fontSize: '14px',
                        fontWeight: '600',
                        width: '105px',
                        lineHeight: '20px',
                        fontFamily: 'SF UI Display',
                        backgroundColor: '#FFFFFF',
                        '& .MuiSelect-select': {
                          padding: '6px 12px',
                          border: 'none',
                        },
                        '& .MuiOutlinedInput-root': {
                          '& fieldset': {
                            border: '1px solid #D0D5DD',
                            outline: 'none',
                          },
                          '&:hover fieldset': {
                            borderColor: '#D0D5DD',
                          },
                          '&.Mui-focused fieldset': {
                            borderColor: '#D0D5DD',
                          },
                          '&.MuiInputBase-root:focus-visible': {
                            borderColor: '#D0D5DD',
                          },
                        },
                      }}
                      IconComponent={(props) => (
                        <KeyboardArrowDownIcon
                          {...props}
                          style={{
                            color:
                              item?.title === 'Distance' && !jsonPaser(coordinates)?.length
                                ? '#B8B8B8'
                                : '#000000',
                            cursor: 'pointer',
                          }}
                        />
                      )}
                    >
                      {item.title === 'Distance'
                        ? filters.distance.map((value, idx) => {
                            const range = distanceRanges[idx];
                            const isDistance = value === 'Distance';
                            return (
                              <MenuItem
                                key={idx}
                                sx={{
                                  color: '#344054',
                                  fontSize: '14px',
                                  fontFamily: 'SF UI Display',

                                  fontWeight: '600',
                                  lineHeight: '20px',
                                  textTransform: 'capitalize',
                                }}
                                value={value}
                                selected={isDistance}
                              >
                                {isDistance ? 'Distance' : range}
                              </MenuItem>
                            );
                          })
                        : filters.signType.map((value, idx) => (
                            <MenuItem
                              key={idx}
                              sx={{
                                color: '#344054',
                                fontSize: '14px',
                                fontWeight: '600',
                                fontFamily: 'SF UI Display',

                                lineHeight: '20px',
                                textTransform: 'capitalize',
                              }}
                              value={value}
                            >
                              {value}
                            </MenuItem>
                          ))}
                    </Select>
                  ) : item.title === 'Services' ? (
                    <Select
                      disabled={findType === 'service'}
                      className="ServicesMuiListning"
                      key={item.title}
                      multiple
                      displayEmpty
                      value={selectedServices}
                      onChange={handleChange}
                      renderValue={() => 'Services'}
                      // IconComponent={item.Icon ? () => item.Icon : undefined}
                      MenuProps={{
                        PaperProps: {
                          sx: {
                            borderRadius: '16px',
                          },
                        },
                      }}
                      sx={{
                        '&.MuiInputBase-root': {
                          borderRadius: '50px',
                        },
                        textTransform: 'capitalize',
                        color: '#000000',
                        fontSize: '14px',
                        fontWeight: '600',
                        width: '105px',
                        lineHeight: '20px',
                        fontFamily: 'SF UI Display',
                        backgroundColor: '#FFFFFF',
                        '& .MuiSelect-select': {
                          padding: '6px 12px',
                          border: 'none',
                        },
                        '& .MuiOutlinedInput-root': {
                          '& fieldset': {
                            border: '1px solid #D0D5DD',
                            outline: 'none',
                          },
                          '&:hover fieldset': {
                            borderColor: '#D0D5DD',
                          },
                          '&.Mui-focused fieldset': {
                            borderColor: '#D0D5DD',
                          },
                          '&.MuiInputBase-root:focus-visible': {
                            borderColor: '#D0D5DD',
                          },
                        },
                      }}
                      IconComponent={(props) => (
                        <KeyboardArrowDownIcon
                          {...props}
                          style={{
                            color:
                              item?.title === 'Services' && findType === 'service'
                                ? '#B8B8B8'
                                : '#000000',
                            cursor: 'pointer',
                          }}
                        />
                      )}
                    >
                      {serviceOptions.map((service) => (
                        <MenuItem
                          key={service.name}
                          value={service.name}
                          sx={{
                            paddingRight: '18px',
                            fontSize: '12px',
                            display: 'flex',
                            justifyContent: 'center',
                            alignItems: 'center',
                          }}
                        >
                          <Checkbox
                            checked={selectedServices.includes(service?.name)}
                            sx={{
                              color: 'rgba(233, 233, 233, 1)',
                              '&.Mui-checked': {
                                color: '#00BD57',
                              },
                            }}
                          />
                          <ListItemIcon>
                            <img
                              style={{ width: '40px', height: '40px', objectFit: 'cover' }}
                              src={
                                service?.logo
                                  ? service?.logo === 'fabricatorsicon'
                                    ? serviceFilter2
                                    : service?.logo === 'installerIcon'
                                    ? serviceFilter3
                                    : service?.logo === 'surveyIcon'
                                    ? serviceFilter5
                                    : service?.logo === 'serviceIcon'
                                    ? serviceFilter4
                                    : service?.logo === 'fullserviceicon'
                                    ? serviceFilter1
                                    : service?.logo === 'printersIcon'
                                    ? printProduction
                                    : service?.logo === 'printInstallersIcon'
                                    ? PrintInstallation
                                    : service?.logo === 'wrapInstallersIcon'
                                    ? WrapInstallation
                                    : service?.logo === 'permitsIcon'
                                    ? serviceFilter1
                                    : `${mediaUrl}/${service?.logo}`
                                  : defaultCompany
                              }
                            />
                          </ListItemIcon>
                          <ListItemText primary={service?.name} />
                        </MenuItem>
                      ))}
                    </Select>
                  ) : (
                    // <Link key={index} to={item?.link ? item.link : ''}>
                    <Box
                      key={index}
                      onClick={() => {
                        if (item?.title === 'No Complaints') {
                          setComplaint(!complaint);
                        }
                        if (item?.title === 'Rating') {
                          setRating(!rating);
                        }
                        if (item?.title === 'Map View') {
                          let url = '/mapview';
                          const formattedSearch =
                            search || searchTypo
                              ? formatSearchString(search?.name ?? searchTypo.name)
                              : '';
                          // let find = '';
                          const country =
                            selectedAddress?.contexts?.country === 'United States'
                              ? 'us'
                              : selectedAddress?.contexts?.country === 'canada'
                              ? 'canada'
                              : !selectedAddress
                              ? formatSearchString(myLocation?.country)
                              : '';

                          if (type === 'local') {
                            const searchName = search?.name?.toLowerCase();
                            const searchTypoName = searchTypo?.name?.toLowerCase();

                            const serviceNames = [
                              'service',
                              'fabricators',
                              'installers',
                              'surveys',
                              'full service companies',
                            ];
                            const find =
                              serviceNames.includes(searchName) ||
                              serviceNames.includes(searchTypoName)
                                ? 'service'
                                : 'company';
                            if (near && formattedSearch) {
                              url += `?${find}=${encodeURIComponent(
                                formattedSearch,
                              )}&location=${country}&city=${encodeURIComponent(
                                formatSearchString(near?.split(',')[0]),
                              )}&state=${encodeURIComponent(
                                formatSearchString(near?.split(', ')[1]),
                              )}`;
                            } else if (!formattedSearch && (near || coordinates)) {
                              url +=
                                near !== ''
                                  ? `?location=${country}&city=${encodeURIComponent(
                                      formatSearchString(near?.split(',')[0]),
                                    )}&state=${encodeURIComponent(
                                      formatSearchString(near?.split(', ')[1]),
                                    )}`
                                  : `?location=${encodeURIComponent(
                                      formatSearchString(myLocation?.country),
                                    )}&city=${encodeURIComponent(
                                      formatSearchString(myLocation?.city),
                                    )}&state=${encodeURIComponent(
                                      formatSearchString(myLocation?.state),
                                    )}
                                  `;
                            } else if (formattedSearch) {
                              url += `?${find}=${encodeURIComponent(
                                formattedSearch,
                              )}&location=${encodeURIComponent(
                                formatSearchString(myLocation?.country),
                              )}&city=${encodeURIComponent(
                                formatSearchString(myLocation?.city),
                              )}&state=${encodeURIComponent(formatSearchString(myLocation?.state))}
                                  `;
                            } else if (myLocation?.city?.length) {
                              url += `?location=${encodeURIComponent(
                                formatSearchString(myLocation?.country),
                              )}&city=${encodeURIComponent(
                                formatSearchString(myLocation?.city),
                              )}&state=${encodeURIComponent(formatSearchString(myLocation?.state))}
                                  `;
                            }
                          }
                          // if (type === 'national') {
                          //   const formattedNear = near ? formatSearchString(near) : '';
                          //   const locations =
                          //     near === 'Canada'
                          //       ? 'canada'
                          //       : near === 'United States'
                          //       ? 'us'
                          //       : formattedNear;
                          //   if (near && formattedSearch) {
                          //     url += `?company=${encodeURIComponent(
                          //       formattedSearch,
                          //     )}&location=${encodeURIComponent(locations)}`;
                          //   } else if (!near && !formattedSearch) {
                          //     url += '?national-sign-companies';
                          //   } else if (near) {
                          //     url += `?location=${encodeURIComponent(locations)}`;
                          //   } else if (formattedSearch) {
                          //     url += `?company=${encodeURIComponent(formattedSearch)}`;
                          //   }
                          // }
                          // if (type === 'supplier' || type === 'distributor') {
                          //   const formattedNear = near ? formatSearchString(near) : '';
                          //   if (near && formattedSearch) {
                          //     url += `?company=${encodeURIComponent(
                          //       formattedSearch,
                          //     )}&category=${encodeURIComponent(formattedNear)}`;
                          //   } else if (near) {
                          //     url += `?category=${encodeURIComponent(formattedNear)}`;
                          //   } else if (formattedSearch) {
                          //     url += `?company=${encodeURIComponent(formattedSearch)}`;
                          //   }
                          // }
                          nav(url, {
                            state: {
                              nearData:
                                additional !== '' || additional !== undefined
                                  ? additional
                                  : type === 'local'
                                  ? `${myLocation?.city}, ${myLocation?.state}`
                                  : '',
                              companyType: type,
                              locationsParam: `${coordinates ?? ''}`,
                              searchData: search,
                              searchText: searchTypo,
                              myCurrLoc: myLocation,
                            },
                          });
                        }
                      }}
                      border={'1px solid #CCCCCC'}
                      borderRadius={'30px'}
                      padding={'3px 15px'}
                      whiteSpace={'nowrap'}
                      display={'flex'}
                      alignItems={'center'}
                      justifyContent={'center'}
                      gap={'5px'}
                      bgcolor={
                        complaint && item?.title === 'No Complaints'
                          ? 'green'
                          : rating && item?.title === 'Rating'
                          ? 'green'
                          : '#ffffff'
                      }
                      sx={{ cursor: 'pointer' }}
                    >
                      <Typography
                        fontSize={'14px'}
                        fontWeight={'600'}
                        fontFamily="SF UI Display"
                        color={
                          complaint && item?.title === 'No Complaints'
                            ? '#ffffff'
                            : rating && item?.title === 'Rating'
                            ? '#ffffff'
                            : '#000000'
                        }
                      >
                        {item.title}
                      </Typography>
                      {item.Icon && <KeyboardArrowDownIcon />}
                    </Box>
                    // </Link>
                  )}
                </React.Fragment>
              ))}
            </Box>
          )}

          {companyData?.length ? (
            <Box
              width={'100%'}
              // marginTop={companyType === 'national' ? '1%' : '3%'}
              marginTop={'3%'}
              display={'flex'}
              flexDirection={'column'}
              gap={'20px'}
              sx={{
                '@media (max-width: 500px)': {
                  marginTop: '8%',
                },
              }}
            >
              {companyData?.map((item, index) => (
                <ListingCard
                  key={index}
                  item={item}
                  type={type}
                  searchTypo={searchTypo}
                  near={near}
                  search={search}
                  additional={additional}
                  coordinates={coordinates}
                  myLocation={myLocation}
                />
              ))}
            </Box>
          ) : (
            <Box
              width={'100%'}
              marginTop={'3%'}
              display={'flex'}
              flexDirection={'column'}
              gap={'20px'}
            >
              <Typography
                fontSize={'40px'}
                fontWeight={'700'}
                fontFamily={'SF UI Display'}
                color={'#bac3ca'}
                sx={{
                  '@media (max-width: 768px)': {
                    fontSize: '30px',
                  },
                }}
              >
                {loadingTopCompanies ? <LinearProgress color="inherit" /> : 'No Companies Found'}
              </Typography>
            </Box>
          )}
        </Box>
        {/* {!userData && ( */}
        <Box
          width={'28%'}
          bgcolor={'#F7F7F7'}
          padding={'2%'}
          borderRadius={'var(--radius-xl, 12px)'}
          sx={{
            '@media (max-width: 900px)': {
              width: '65%',
              marginTop: '3%',
            },
            '@media (max-width: 600px)': {
              width: '100%',
              margin: '8% 0',
              padding: '5%',
            },
          }}
        >
          <Box
            width={'100%'}
            display={'flex'}
            justifyContent={'flex-start'}
            alignItems={'flex-start'}
          >
            <CardTopIcon
              // src={CardTopIcon}
              // alt="."
              style={{
                width: '52px',
                height: '46px',
                flexShrink: 0,
              }}
            />
          </Box>
          <Typography
            fontSize={'20px'}
            fontWeight={'700'}
            fontFamily={'SF UI Display'}
            color={'rgba(0, 0, 0, 1)'}
            lineHeight={'normal'}
            margin={'5% 0'}
          >
            {/* Can&apos;t find the sign company your looking for? */}
            {type === 'national'
              ? "Can't find the national sign company you're looking for?"
              : type === 'local'
              ? 'Need more local companies in this area?'
              : type === 'supplier'
              ? "Can't find the supplier you're looking for?"
              : "Can't find the distributor your looking for?"}
          </Typography>
          <Typography
            fontSize={'16px'}
            fontWeight={'300'}
            fontFamily={'SF UI Display'}
            color={'#000000'}
            margin={'3% 0'}
          >
            We&apos;re adding new local partners everyday as the platform becomes more well known.
          </Typography>
          <Box width={'100%'} display={'flex'} justifyContent={'center'} marginTop={'5%'}>
            <Button
              sx={{
                width: '100%',
                fontSize: '16px',
                fontWeight: '700',
                fontFamily: 'SF UI Display',
                color: '#ffffff',
                bgcolor: '#00BD57',
                borderRadius: '8px',
                padding: '10px 0',
                // height: '39px',
                margin: '2% 0',
                '&:hover': {
                  bgcolor: '#00BD57',
                },
                textTransform: 'unset',
              }}
              onClick={handleClick}
              // onClick={() => {
              //   nav('/signup');
              // }}
            >
              Send Request
            </Button>
          </Box>
          <LoginPopup open={openPopup === 1} setOpenPopup={setOpenPopup} onClose={handleClose} />
          <SubmitComReq open={openPopup === 2} onClose={handleClose} type={type} />
        </Box>
        {/* )} */}
      </Box>
    </Box>
  );
};

export default LocalCompanyListing;
