import React, { FC, useEffect, useRef, useState } from 'react';
import mapboxgl from 'mapbox-gl';
import 'mapbox-gl/dist/mapbox-gl.css';

const AccessTOKEN =
  'pk.eyJ1IjoiZmFsY29uaXQiLCJhIjoiY2x4MXI5MW85MGNxbzJoc2Z1enRsanBhZSJ9.d9xvqAxL0P4ToV5p5wsOuA';
mapboxgl.accessToken = AccessTOKEN;

interface editServiceAreaMapProps {
  data?: any;
  setData?: React.Dispatch<React.SetStateAction<any>>;
  radiusInMiles: number; // New prop for radius in miles
}

const EditServiceAreaMap: FC<editServiceAreaMapProps> = ({ data, radiusInMiles, setData }) => {
  console.log(radiusInMiles, 'data radiusInMiles', data);
  const mapContainerRef = useRef<HTMLDivElement | null>(null);
  const [markers, setMarkers] = useState<mapboxgl.Marker[]>([]);
  const [map, setMap] = useState<mapboxgl.Map | null>(null);

  // Convert miles to meters (1 mile = 1609.34 meters)
  const radiusInMeters = radiusInMiles * 1609.34;

  useEffect(() => {
    if (mapContainerRef?.current && data?.coordinates?.length) {
      const mapInstance = new mapboxgl.Map({
        container: mapContainerRef?.current,
        style: 'mapbox://styles/mapbox/streets-v11',
        attributionControl: false,
      });

      mapInstance?.on('load', () => {
        const bounds = new mapboxgl.LngLatBounds();

        const { coordinates }: { coordinates: [number, number] } = data || [0, 0];

        const el = document.createElement('div');
        el.innerHTML = `
  <svg width="38" height="38" viewBox="0 0 38 38" fill="none" xmlns="http://www.w3.org/2000/svg">
<g clip-path="url(#clip0_4043_81575)">
<path d="M7.55672 17.142C7.55672 21.9365 13.5975 30.4723 16.2461 33.9942C16.8812 34.8335 18.0584 34.8335 18.6934 33.9942C21.3421 30.4723 27.3828 21.9365 27.3828 17.142C27.3828 11.3271 22.9426 6.60938 17.4698 6.60938C11.9969 6.60938 7.55672 11.3271 7.55672 17.142Z" fill="#00BD57"/>
</g>
<path fill-rule="evenodd" clip-rule="evenodd" d="M16.2868 33.5404L17.4286 32.2538C18.7243 30.7712 19.8902 29.3604 20.9264 28.0213L21.7824 26.8922C25.3568 22.0777 27.144 18.2578 27.144 15.4325C27.144 9.40315 22.2836 4.51562 16.2868 4.51562C10.2901 4.51562 5.42969 9.40315 5.42969 15.4325C5.42969 18.2578 7.21689 22.0783 10.7913 26.894L11.6472 28.0231C13.1266 29.9183 14.6741 31.7573 16.2868 33.5404Z" stroke="black" stroke-linecap="round" stroke-linejoin="round"/>
<path d="M16.2894 19.8992C18.7879 19.8992 20.8132 17.8738 20.8132 15.3754C20.8132 12.8769 18.7879 10.8516 16.2894 10.8516C13.791 10.8516 11.7656 12.8769 11.7656 15.3754C11.7656 17.8738 13.791 19.8992 16.2894 19.8992Z" stroke="black" stroke-linecap="round" stroke-linejoin="round"/>
<ellipse cx="16.25" cy="15.3984" rx="4.25" ry="4" fill="white"/>
<defs>
<clipPath id="clip0_4043_81575">
<rect width="19.8261" height="28.087" fill="white" transform="matrix(-1 0 0 1 27.3828 6.60938)"/>
</clipPath>
</defs>
</svg>`;
        el.style.width = '40px';
        el.style.height = '60px';

        // Add the marker
        const marker = new mapboxgl.Marker({
          element: el,
        })?.setLngLat(coordinates);
        marker?.addTo(mapInstance); // Add the marker to the map
        bounds?.extend(coordinates); // Extend the bounds to include the marker

        mapInstance?.fitBounds(bounds, { padding: 50, maxZoom: 5.15 }); // Fit map to marker
        setMarkers([marker]);
        setMap(mapInstance);

        // Add the circle layer to represent the radius
        mapInstance?.addSource('radius-circle', {
          type: 'geojson',
          data: createCircle(coordinates, radiusInMeters),
        });
        mapInstance?.addLayer({
          id: 'radius-circle-fill',
          type: 'fill',
          source: 'radius-circle', // Your existing source name
          paint: {
            'fill-color': '#00BD57', // Set fill color
            'fill-opacity': 0.2, // Adjust opacity
          },
        });

        mapInstance?.addLayer({
          id: 'radius-circle-stroke',
          type: 'line',
          source: 'radius-circle', // Your existing source name
          paint: {
            'line-color': '#000000', // Set stroke color
            'line-width': 2, // Set stroke width
          },
        });
      });

      // Cleanup function
      return () => {
        if (mapInstance) {
          markers?.forEach((marker) => marker?.remove()); // Remove each marker from the map
          mapInstance?.remove(); // Remove the map instance
        }
      };
    }
  }, [data]);

  // Update the circle when the slider value changes
  useEffect(() => {
    if (map && data?.coordinates?.length) {
      const { coordinates }: { coordinates: [number, number] } = data ?? [0, 0];
      console.log('coordinates', coordinates);
      const source = map.getSource('radius-circle');
      if (source) {
        // Update the circle source with the new radius

        const updatedCircle = createCircle(coordinates, radiusInMeters);
        if (
          updatedCircle &&
          typeof updatedCircle !== 'string' &&
          'geometry' in updatedCircle &&
          updatedCircle.geometry?.type === 'Polygon'
        ) {
          // const polygonGeometry = updatedCircle.geometry as GeoJSON.Polygon;
          const polygonGeometry = updatedCircle.geometry;
          console.log('polygonGeometry', polygonGeometry);
          // Set the new coordinates in the setAccount state
          setData?.((prevAccount: any) => ({
            ...prevAccount,
            serviceArea: [
              {
                location: {
                  type: 'Polygon',
                  coordinates: [polygonGeometry.coordinates[0]], // Set the circle's coordinates
                },
                distance: radiusInMiles, // Set the distance if needed
                // name: 'Polygon',
              },
            ],
          }));

          // Update the source data with the updated circle
          (source as mapboxgl.GeoJSONSource)?.setData(updatedCircle);
        }
      }
    }
  }, [radiusInMiles]);

  // Helper function to create a GeoJSON circle
  const createCircle = (
    center: [number, number],
    radiusInMeters: number,
  ): mapboxgl.GeoJSONSourceRaw['data'] => {
    const points = 64; // Number of points in the circle
    const coords = {
      latitude: center[1],
      longitude: center[0],
    };

    const km = radiusInMeters / 1000;
    const ret = [];
    const distanceX = km / (111.32 * Math.cos((coords.latitude * Math.PI) / 180));
    const distanceY = km / 110.574;

    for (let i = 0; i < points; i++) {
      const theta = (i / points) * (2 * Math.PI);
      const x = distanceX * Math.cos(theta);
      const y = distanceY * Math.sin(theta);

      ret.push([coords.longitude + x, coords.latitude + y]);
    }
    ret.push(ret[0]); // Close the circle

    return {
      type: 'Feature',
      geometry: {
        type: 'Polygon',
        coordinates: [ret],
      },
      properties: {}, // You can add custom properties here if needed
    };
  };

  return (
    <div ref={mapContainerRef} style={{ width: '100%', height: '100%', borderRadius: '10px' }} />
  );
};

export default EditServiceAreaMap;
