import React, { useState } from 'react';
import { Modal, Box, Typography, TextField, Button } from '@mui/material';
import request from '../../../api/request';
import { toast } from 'react-toastify';
import CustomToast from '../../../components/phase1a/common/CustomToast.component';
interface AddNewJobShowCaseProps {
  open: boolean;
  handleClose?: () => void;
}
const MessageUs: React.FC<AddNewJobShowCaseProps> = ({ open, handleClose }) => {
  const [message, setMessage] = useState('');
  //   const userJSON = localStorage.getItem('user');
  //   const signIn = userJSON ? JSON.parse(userJSON) : '';

  const handleMessage = (): void => {
    const data: any = {
      message: message,
    };
    // eslint-disable-next-line @typescript-eslint/restrict-template-expressions
    request<any>('post', `/users/sendMessageToAdmin`, data)
      .then((res) => {
        toast(<CustomToast message={'Message sent successfully'} type="success" />);

        handleClose?.();
        // getReviews();
      })
      .catch((err) => {
        console.log('err', err);
        toast(<CustomToast message={err?.response?.data?.message} type="error" />);
      });
  };
  return (
    <Modal
      open={open}
      onClose={handleClose}
      aria-labelledby="modal-title"
      aria-describedby="modal-description"
    >
      <Box
        sx={{
          position: 'absolute',
          top: '50%',
          left: '50%',
          transform: 'translate(-50%, -50%)',
          width: 400,
          bgcolor: 'background.paper',
          borderRadius: '8px',
          boxShadow: 24,
          //   height: '579px',
          border: 'none',
          display: 'flex',
          flexDirection: 'column',
          gap: '10px',
          p: 4,
          '@media (max-width:500px)': {
            width: '90%',
          },
        }}
      >
        <Typography>Message</Typography>
        <TextField
          value={message}
          placeholder="Enter your Message"
          onChange={(e) => {
            setMessage(e.target.value);
          }}
          multiline
          rows={4}
        />
        <Button
          variant="contained"
          disabled={!message.length}
          onClick={handleMessage}
          sx={{
            bgcolor: '#00BD57',

            '&:hover': {
              bgcolor: '#00BD57',
            },
          }}
        >
          Submit Message
        </Button>
      </Box>
    </Modal>
  );
};
export default MessageUs;
