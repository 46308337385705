import React, { FC, useState } from 'react';
import { Box, Grid, Typography, Button } from '@mui/material';
import { Link, useNavigate } from 'react-router-dom';

// import Sidebarlogo from '../../../assets/LogoDashoard.svg';
import logoout from '../../../assets/Logoutimg.svg';
import { mediaUrl } from '../../../config';
import { LocalSignRoutes } from '../../../routes/routes';
import Dummyimage from '../../../assets/dummyimg.png';
import { UpdateStore } from '../../../context/store';

interface SidebarProps {
  claimStatus?: any;
}
const LocalPageSidebar: FC<SidebarProps> = ({ claimStatus }) => {
  console.log('🚀 ~ claimStatus:', claimStatus);
  const updateStore = UpdateStore();
  const userJSON = localStorage.getItem('user');
  const nav = useNavigate();
  const userData = userJSON ? JSON.parse(userJSON) : '';
  const userType = userData?.type;
  const userCompany = userData?.company?.companyInfo?.companyType;
  const [activePath, setActivePath] = useState(location.pathname);
  console.log('userData sidebar', userData?.company, activePath);
  const concatedUrl = (layout: string, path: string): string => {
    return layout + path;
  };
  const handleClick = (path: string): void => {
    setActivePath(path);
  };

  const handleLogout = (): void => {
    localStorage.removeItem('user');
    localStorage.removeItem('token');
    window.location.href = '/';
    if (updateStore) {
      updateStore({ user: null });
    }
  };
  const paramType =
    userData?.company?.companyInfo?.companyType === 'local'
      ? 'local-sign-company'
      : userData?.company?.companyInfo?.companyType === 'national'
      ? 'national-sign-company'
      : userData?.company?.companyInfo?.companyType === 'supplier'
      ? 'supplier'
      : 'distributor';
  const formatSearchString = (str: string): string => {
    if (!str) return ''; // Handle cases where str might be null or undefined
    const formattedStr = str.toLowerCase().replace(/\s+/g, '-').trim();
    return formattedStr;
  };
  const checkUserCompany = (): any => {
    if (userType === 'member' && (userCompany === 'distributor' || userCompany === 'supplier')) {
      const filterRoute = LocalSignRoutes.filter((item) => item.name !== 'Reviews');
      return filterRoute;
    } else {
      return LocalSignRoutes;
    }
  };
  const logoPath = mediaUrl + '/' + String(userData?.company?.companyInfo?.logo);
  return (
    <Box
      width="25%"
      bgcolor="#FFFFFF"
      borderRight={'1px solid #E8E8E8 '}
      sx={{
        '@media (max-width: 1024px)': {
          width: '100%',
        },
      }}
    >
      <Grid
        container
        height="100%"
        display="flex"
        flexDirection="row"
        position="relative"
        sx={{
          '@media (max-width: 1024px)': {
            flexDirection: 'column',
          },
        }}
      >
        <Box
          width="100%"
          display="flex"
          alignItems="center"
          flexDirection="column"
          bgcolor="F5F3F3"
          padding={'38px 4% 11px 4%'}
          gap={'20px'}
        >
          <Box
            width={'100%'}
            display="flex"
            flexDirection="column"
            alignItems="center"
            gap={'0px'}
            sx={{
              '@media (max-width: 1024px)': {
                flexWrap: 'wrap',
                flexDirection: 'row',
                alignItems: 'center',
                justifyContent: 'start',
              },
            }}
          >
            <Box
              width={'100%'}
              display={'flex'}
              justifyContent={'center'}
              alignItems={'center'}
              flexDirection={'column'}
              sx={{
                // opacity:
                //   claimStatus || !userData?.company?.companyInfo?.verification?.status ? 0.3 : 1,
                '@media (max-width: 1024px)': {
                  flexDirection: 'row',
                  gap: '20px',
                  justifyContent: 'flex-start',
                },
              }}
            >
              <Box width={'81px'} height={'81px'} display={'flex'} justifyContent={'center'}>
                <img
                  src={userData?.company?.companyInfo?.logo ? logoPath : Dummyimage}
                  alt="Logo"
                  style={{
                    width: '100%',
                    height: '100%',
                    objectFit: 'cover',
                    borderRadius: '10px',
                  }}
                />
              </Box>
              <Box
                display="flex"
                flexDirection="column"
                alignItems={'center'}
                gap={'0px'}
                width={'80%'}
                mt={3}
                mb={3}
                sx={{
                  '@media (max-width: 1024px)': {
                    alignItems: 'flex-start',
                  },
                }}
              >
                <Typography
                  fontFamily="Poppins"
                  fontWeight="800"
                  fontSize="20px"
                  lineHeight="21.48px"
                  color="#000000"
                  textAlign={'center'}
                  sx={{
                    '@media (max-width: 1024px)': {
                      textAlign: 'left',
                    },
                  }}
                >
                  {userData?.company
                    ? userData?.company?.companyInfo?.name
                    : // eslint-disable-next-line @typescript-eslint/restrict-template-expressions
                      `${userData?.first_name} ${userData?.last_name}  `}
                </Typography>
                <Typography
                  fontFamily="Poppins"
                  fontWeight="300"
                  fontSize="16px"
                  lineHeight="21.48px"
                  color="#000000"
                  textTransform={'capitalize'}
                >
                  {/* Phoenix, AZ{' '} */}
                  {/* {userData?.company?.companyInfo?.companyType
                  ? (userData.company.companyInfo.companyType as string).charAt(0).toUpperCase() +
                    (userData.company.companyInfo.companyType as string).slice(1)
                  : ''}{' '} */}
                  {userData?.company
                    ? userData?.company?.companyInfo?.companyType
                      ? userData.company.companyInfo.companyType === 'local'
                        ? 'Local Sign Company'
                        : userData.company.companyInfo.companyType === 'national'
                        ? 'National Sign Company'
                        : userData.company.companyInfo.companyType === 'distributor' ||
                          userData.company.companyInfo.companyType === 'supplier'
                        ? 'Supplier/Distributor'
                        : ''
                      : ''
                    : userData?.type}
                </Typography>
              </Box>
            </Box>

            {/* Mobile responsive buttons */}
            <Box
              display={'none'}
              sx={{
                '@media (max-width: 1024px)': {
                  display: 'flex',
                  flexWrap: 'wrap',
                  width: '100%',
                  gap: '10px',
                  marginTop: '10px',
                },
              }}
            >
              {/* edit button */}
              {userData.type !== 'member' && !claimStatus
                ? ''
                : // <Button
                  //   onClick={() => {
                  //     nav('/localpageowner/SettingPagesetup');
                  //   }}
                  //   disabled={!userData?.company?.companyInfo?.verification?.status}
                  //   sx={{
                  //     bgcolor: '#FFFFFF',
                  //     width: '135px',
                  //     height: '41px',
                  //     fontFamily: 'SF UI Display',
                  //     fontSize: '16px',
                  //     fontWeight: '700',
                  //     border: '1px solid #E8E8E8 ',
                  //     lineHeight: '19.09px',
                  //     textAlign: 'center',
                  //     color: '#000000',
                  //     padding: '10px 14px',
                  //     textTransform: 'capitalize',
                  //     '&:hover': {
                  //       bgcolor: '#FFFFFF',
                  //     },
                  //     '@media (max-width: 500px)': {
                  //       width: '48%',
                  //       fontSize: '14px',
                  //     },
                  //   }}
                  // >
                  //   Edit Profile
                  // </Button>
                  ''}
              {userData.type !== 'member' && !claimStatus ? (
                <Button
                  onClick={() => {
                    nav('/localpageowner/SettingPagesetup');
                  }}
                  disabled={!userData?.company?.companyInfo?.verification?.status}
                  sx={{
                    bgcolor: '#FFFFFF',
                    width: '135px',
                    height: '41px',
                    fontFamily: 'SF UI Display',
                    fontSize: '16px',
                    fontWeight: '700',
                    border: '1px solid #E8E8E8 ',
                    lineHeight: '19.09px',
                    textAlign: 'center',
                    color: '#000000',
                    padding: '10px 14px',
                    textTransform: 'capitalize',
                    '&:hover': {
                      bgcolor: '#FFFFFF',
                    },
                    '@media (max-width: 500px)': {
                      width: '48%',
                      fontSize: '14px',
                    },
                  }}
                >
                  Edit Page
                </Button>
              ) : (
                ''
              )}
              {!claimStatus ? (
                <Button
                  onClick={() => {
                    nav(
                      // eslint-disable-next-line @typescript-eslint/restrict-template-expressions
                      `/${paramType}/${formatSearchString(userData?.company?.companyInfo?.name)}`,
                      {
                        state: {
                          item: userData?.company,
                          type: userData?.company?.companyInfo?.companyType,
                        },
                      },
                    );
                  }}
                  disabled={!userData?.company?.companyInfo?.verification?.status}
                  sx={{
                    bgcolor: '#FFFFFF',
                    fontFamily: 'SF UI Display',
                    width: '135px',
                    height: '41px',
                    fontSize: '16px',
                    fontWeight: '600',
                    border: '1px solid #E8E8E8 ',
                    lineHeight: '19.09px',
                    textAlign: 'center',
                    color: '#000000',
                    padding: '10px 14px',

                    textTransform: 'capitalize',
                    '&:hover': {
                      bgcolor: '#FFFFFF',
                    },
                    '@media (max-width: 500px)': {
                      width: '48%',
                      fontSize: '14px',
                    },
                  }}
                >
                  View Page
                </Button>
              ) : (
                ''
              )}
              {/* tab buttons mobile */}
              {checkUserCompany()?.map((item: any, index: any) => {
                const path = concatedUrl(item.layout, item.path);
                const isDisabled = claimStatus;

                return (
                  <Button
                    key={item.path}
                    component={Link}
                    to={isDisabled ? '#' : path}
                    disabled={isDisabled}
                    onClick={() => {
                      if (!isDisabled) handleClick(path);
                    }}
                    sx={{
                      textDecoration: 'none',
                      height: '41px',
                      color: 'inherit',
                      cursor: isDisabled ? 'default' : 'pointer',
                      width: '156px',
                      padding: '10px 14px',
                      border: '1px solid #E8E8E8',
                      textTransform: 'capitalize',
                      // borderTop: index === 0 ? '1px solid #E8E8E8' : 'none',
                      gap: '5px',
                      '@media (max-width: 500px)': {
                        width: '48%',
                      },
                    }}
                  >
                    <Box
                      display={'flex'}
                      gap={'15px'}
                      alignItems={'center'}
                      justifyContent={'center'}
                      sx={{
                        cursor: isDisabled ? 'default' : 'pointer',
                        opacity: isDisabled ? 0.5 : 1,
                      }}
                    >
                      {!userData?.company?.companyInfo?.verification ? (
                        <item.icon />
                      ) : activePath === path ? (
                        <item.icon />
                      ) : !userData?.company?.companyInfo?.verification ? (
                        <item.coloredIcon />
                      ) : (
                        <item.icon />
                      )}
                      <Typography
                        fontFamily="SF UI Display"
                        fontWeight={'600'}
                        // fontWeight={activePath === path ? '700' : '500'}
                        fontSize="16px"
                        lineHeight="19.09px"
                        color={
                          !userData?.company
                            ? '#00000030'
                            : activePath === path
                            ? '#00BD57'
                            : '#000000'
                        }
                        sx={{
                          '@media (max-width: 500px)': {
                            fontSize: '14px',
                          },
                        }}
                      >
                        {item.name}
                      </Typography>
                    </Box>
                  </Button>
                );
              })}
              {/* log out button mobile */}
              {/* <Button
                  onClick={handleLogout}
                  style={{
                    width: '135px%',
                    padding: '10px 14px',
                    border: '1px solid #E8E8E8',
                    display: 'flex',
                    gap: '5px',
                    justifyContent: 'flex-start',
                    alignItems: 'center',
                  }}
                >
                  <Box
                    display={'flex'}
                    gap={'15px'}
                    alignItems={'center'}
                    justifyContent={'center'}
                    sx={{ cursor: 'pointer' }}
                  >
                    <img src={logoout} alt="Logo" style={{ width: '20px', height: '20px' }} />
                    <Typography
                      fontFamily="SF UI Display"
                      fontWeight={'500'}
                      fontSize="16px"
                      lineHeight="19.09px"
                      color={'#000000'}
                    >
                      Log Out
                    </Typography>
                  </Box>
                </Button> */}
            </Box>

            {/* end here */}
            {/* edit and profile button */}
            {userData?.company && (
              <Box
                display="flex"
                alignItems={'center'}
                justifyContent={'center'}
                gap={'10px'}
                paddingInline="30px"
                width={`${userData?.type === 'member' && !claimStatus ? '50%' : '100%'}`}
                sx={{
                  '@media (max-width: 1024px)': {
                    display: 'none',
                  },
                }}
              >
                {userData.type !== 'member' &&
                userData?.company?.companyInfo?.verification?.status &&
                !claimStatus
                  ? ''
                  : // <Button
                    //   onClick={() => {
                    //     nav('/localpageowner/SettingPagesetup');
                    //   }}
                    //   disabled={!userData?.company?.companyInfo?.verification?.status}
                    //   sx={{
                    //     bgcolor: '#FFFFFF',
                    //     flex: 1,
                    //     width: '135px',
                    //     height: '41px',
                    //     fontFamily: 'SF UI Display',
                    //     fontSize: '16px',
                    //     fontWeight: '700',
                    //     border: '1px solid #E8E8E8 ',
                    //     lineHeight: '19.09px',
                    //     textAlign: 'center',
                    //     color: '#000000',
                    //     padding: '10px 14px',
                    //     textTransform: 'capitalize',
                    //     '&:hover': {
                    //       bgcolor: '#FFFFFF',
                    //     },
                    //   }}
                    // >
                    //   Edit Profile
                    // </Button>
                    ''}
                {/* {!claimStatus ? (
                  <Button
                    onClick={() => {
                      nav('/localpageowner/SettingPagesetup');
                    }}
                    disabled={!userData?.company?.companyInfo?.verification?.status}
                    sx={{
                      bgcolor: '#FFFFFF',
                      flex: 1,
                      width: '100px',
                      minWidth: '100px',
                      maxWidth: '100px',
                      height: '30px',
                      fontFamily: 'SF UI Display',
                      fontSize: '14px',
                      fontWeight: '600',
                      border: '0.5px solid #000000 ',
                      lineHeight: '19.09px',
                      textAlign: 'center',
                      color: '#000000',
                      padding: '10px 14px',
                      borderRadius: '8px',
                      textTransform: 'capitalize',
                      '&:hover': {
                        bgcolor: '#FFFFFF',
                      },
                    }}
                  >
                    Edit Page
                  </Button>
                ) : (
                  ''
                )} */}
                {!userData?.company?.companyInfo?.verification?.status || claimStatus ? (
                  <Button
                    disabled
                    sx={{
                      width: '100%',
                      bgcolor: '#F7F7F7',
                      fontFamily: 'SF UI Display',
                      height: '35px',
                      fontSize: '14px',
                      fontWeight: '600',
                      border: '1px solid #E8E8E8 ',
                      lineHeight: '19.09px',
                      textAlign: 'center',
                      borderRadius: '8px',
                      color: '#00000080',
                      padding: '6px 15px',
                      textTransform: 'capitalize',
                      '&:hover': {
                        bgcolor: '#FFFFFF',
                      },
                      '@media (max-width: 1024px)': {
                        width: '80%',
                        padding: '18px 14px',
                      },
                      ':disabled': {
                        color: '#00000080',
                        bgcolor: '#F7F7F7',
                      },
                    }}
                  >
                    Pending Approval...
                  </Button>
                ) : (
                  <Button
                    onClick={() => {
                      nav(
                        // eslint-disable-next-line @typescript-eslint/restrict-template-expressions
                        `/${paramType}/${formatSearchString(userData?.company?.companyInfo?.name)}`,
                        {
                          state: {
                            item: userData?.company,
                            type: userData?.company?.companyInfo?.companyType,
                          },
                        },
                      );
                    }}
                    fullWidth
                    disabled={!userData?.company?.companyInfo?.verification?.status}
                    sx={{
                      bgcolor: '#FFFFFF',
                      flex: 1,
                      fontFamily: 'SF UI Display',
                      width: '100px',
                      minWidth: '100px',
                      maxWidth: '100px',
                      height: '30px',
                      fontSize: '14px',
                      fontWeight: '600',
                      border: '0.5px solid #000000 ',
                      lineHeight: '19.09px',
                      textAlign: 'center',
                      borderRadius: '8px',
                      color: '#000000',
                      padding: '10px 14px',
                      textTransform: 'capitalize',
                      '&:hover': {
                        bgcolor: '#FFFFFF',
                      },
                    }}
                  >
                    View Page
                  </Button>
                )}
              </Box>
            )}
            <Box width={'100%'}>
              {checkUserCompany()?.map((item: any, index: any) => {
                const path = concatedUrl(item.layout, item.path);
                const isDisabled = !userData?.company;
                // ?.companyInfo?.verification?.status || claimStatus;

                return (
                  // tab buttons
                  <Box
                    key={item.path}
                    width={'100%'}
                    padding={'0px 30px'}
                    mt={index === 0 ? '9%' : '0'}
                    // padding={'0px 20px'}
                    sx={{
                      '@media (max-width: 1024px)': {
                        display: 'none',
                      },
                    }}
                  >
                    <Link
                      to={isDisabled ? '#' : path}
                      style={{
                        textDecoration: 'none',
                        color: 'inherit',
                        cursor: isDisabled ? 'default' : 'pointer',
                      }}
                      onClick={() => {
                        if (!isDisabled) handleClick(path);
                      }}
                    >
                      <Box
                        display={'flex'}
                        gap={'5px'}
                        borderBottom={'1px solid #E8E8E8'}
                        borderTop={index === 0 ? '1px solid #E8E8E8' : 'none'}
                        alignItems={'center'}
                        justifyContent={'flex-start'}
                        padding={index === 0 ? '20px 10px' : '20px 10px '}
                        // paddingBottom={index === 0 ? '8%' : '8%'}
                        sx={{
                          '@media (max-width: 1024px)': {
                            border: '1px solid #E8E8E8 ',
                            padding: '9px 10px',
                            marginTop: '0',
                            borderRadius: '4px',
                          },
                          '@media (max-width: 450px)': {
                            width: '135px',
                          },
                        }}
                      >
                        <Box
                          display={'flex'}
                          gap={' 15px'}
                          alignItems={'center'}
                          justifyContent={'center'}
                          sx={{
                            cursor: isDisabled ? 'default' : 'pointer',
                            opacity: isDisabled ? 0.5 : 1,
                          }}
                        >
                          {!userData?.company?.companyInfo?.verification ? (
                            <item.icon />
                          ) : activePath === path ? (
                            <item.icon />
                          ) : !userData?.company?.companyInfo?.verification ? (
                            <item.coloredIcon />
                          ) : (
                            <item.icon />
                          )}
                          <Typography
                            fontFamily="SF UI Display"
                            fontWeight={activePath === path ? '700' : '600'}
                            fontSize="16px"
                            lineHeight="19.09px"
                            color={
                              !userData?.company
                                ? '#00000030'
                                : activePath === path
                                ? '#00BD57'
                                : '#000000'
                            }
                          >
                            {item.name}
                          </Typography>
                        </Box>
                      </Box>
                    </Link>
                  </Box>
                );
              })}
              <Box
                width={'100%'}
                padding={'0px 30px '}
                sx={{
                  '@media (max-width: 1024px)': {
                    display: 'none',
                  },
                }}
              >
                <Box
                  display={'flex'}
                  gap={'5px'}
                  borderBottom={'1px solid #E8E8E8'}
                  borderTop={'none'}
                  alignItems={'center'}
                  justifyContent={'flex-start'}
                  padding={'20px 10px '}
                  onClick={handleLogout}
                  sx={{
                    '@media (max-width: 1024px)': {
                      width: 'fit-content',
                      border: '1px solid #E8E8E8',
                      borderRadius: '4px',
                    },
                    '@media (max-width: 450px)': {
                      width: '135px',
                    },
                  }}
                >
                  <Box
                    display={'flex'}
                    gap={' 15px'}
                    alignItems={'center'}
                    justifyContent={'center'}
                    sx={{ cursor: 'pointer' }}
                  >
                    <img src={logoout} alt="Logo" style={{ width: '20px', height: '20px' }} />
                    <Typography
                      fontFamily="SF UI Display"
                      fontWeight={'500'}
                      fontSize="16px"
                      lineHeight="19.09px"
                      color={'#000000'}
                    >
                      Log Out
                    </Typography>
                  </Box>
                </Box>
              </Box>
            </Box>
          </Box>
        </Box>
      </Grid>
    </Box>
  );
};

export default LocalPageSidebar;
