import React, { FC } from 'react';
import { Box, Typography } from '@mui/material';
import { useNavigate } from 'react-router-dom';
import ArrowBackIcon from '@mui/icons-material/ArrowBack';

const TermsAndConditions: FC = () => {
  const navigate = useNavigate();

  const handleBackClick = (): void => {
    navigate(-1); // Goes back to the previous page
  };
  return (
    <Box
      width="100%"
      mt="3%"
      p="5% 15%"
      bgcolor="white"
      overflow="auto"
      display="flex"
      flexDirection="column"
      alignItems="flex-start"
      position={'relative'}
      sx={{
        '@media (max-width: 768px)': {
          padding: '10% 5% 5% 5%',
        },
      }}
    >
      <Box
        position={'absolute'}
        top={'0'}
        left={'50px'}
        display={'flex'}
        alignItems={'center'}
        gap={'10px'}
        sx={{ cursor: 'pointer' }}
        onClick={handleBackClick}
      >
        <ArrowBackIcon /> Back
      </Box>
      {/* Main Heading */}
      <Typography
        variant="h5"
        gutterBottom
        width={'100%'}
        textAlign={'center'}
        fontWeight={'600'}
        mb={5}
        sx={{
          '@media (max-width: 500px)': {
            marginTop: '20px',
          },
        }}
      >
        TERMS AND CONDITIONS
      </Typography>

      {/* Sections */}
      <Section number={1} title="INTRODUCTION">
        <Typography variant="body1" gutterBottom>
          These Terms and Conditions (&quot;<span style={{ fontWeight: '600' }}>Terms</span>&quot;)
          govern your access to and use of Signwise Inc.&apos;s platform at www.signwise.com
          (hereinafter referred to as &quot;<span style={{ fontWeight: '600' }}>Signwise</span>
          ,&quot; &quot;<span style={{ fontWeight: '600' }}>we</span>,&quot; &quot;
          <span style={{ fontWeight: '600' }}>us</span>,&quot; &quot;
          <span style={{ fontWeight: '600' }}>our</span>,&quot; or the &quot;
          <span style={{ fontWeight: '600' }}>Platform</span>&quot;), including our website, mobile
          applications, and all related services. By accessing or using the Platform, you
          acknowledge that you have read, understood, and agree to be bound by these Terms and all
          applicable laws and regulations. If you do not agree with any part of these Terms, you
          must not use our Platform. Signwise Inc. reserves the right to modify these Terms at any
          time, and your continued use of the Platform following any changes constitutes your
          acceptance of such changes.
        </Typography>
      </Section>

      <Section number={2} title="DEFINITIONS">
        <Typography variant="body1" gutterBottom>
          For the purposes of these Terms, &quot;Platform&quot; encompasses Signwise Inc.&apos;s
          www.signwise.com, including its website, mobile applications, and all related services.
          &quot;Service Providers&quot; refers to businesses that list their services on the
          Platform, while &quot;Users&quot; refers to customers who access the Platform to find and
          contact Service Providers. &quot;Content&quot; encompasses all information, text,
          photographs, graphics, videos, and other materials uploaded to or appearing on the
          Platform, whether generated by users, Service Providers, or the Platform itself.
          &quot;User Content&quot; refers to any content submitted, posted, or transmitted by users
          through the Platform.
        </Typography>
      </Section>

      <Section number={3} title="ROLE OF THE PLATFORM">
        <Typography variant="body1" gutterBottom>
          Signwise Inc. operates exclusively as an intermediary service that facilitates connections
          between Service Providers and potential customers. We do not provide, perform, contract
          for, or guarantee any of the services listed on the Platform, nor do we act as an agent
          for any Service Provider or User. Users acknowledge and agree that we function solely as a
          venue for Service Providers to list their services and for Users to discover and contact
          such Service Providers. Any agreement formed between a User and a Service Provider is
          strictly between those parties, and Signwise Inc. is not a party to any such agreement or
          any disputes that may arise between Users and Service Providers.
        </Typography>
      </Section>

      <Section number={4} title="USER CONTENT AND SUBMISSIONS">
        <Typography variant="body1" gutterBottom>
          By submitting content to our Platform, you acknowledge and agree that you are solely
          responsible for all User Content you submit. Once User Content is submitted to Signwise
          Inc., it cannot be withdrawn. You represent and warrant that:
        </Typography>
        <Typography variant="body1" component="ul" gutterBottom margin={'20px 0'}>
          <li style={{ position: 'relative', paddingLeft: '20px' }}>
            <span style={{ position: 'absolute', left: 0 }}>-</span>
            All User Content you submit is accurate, complete, and up to date
          </li>
          <li style={{ position: 'relative', paddingLeft: '20px' }}>
            <span style={{ position: 'absolute', left: 0 }}>-</span>
            You have all required rights, licenses, and permissions to submit, use, or disseminate
            such User Content without violating any third-party rights
          </li>
          <li style={{ position: 'relative', paddingLeft: '20px' }}>
            <span style={{ position: 'absolute', left: 0 }}>-</span>
            Your User Content does not infringe upon any intellectual property rights or other
            rights of any third party
          </li>
          <li style={{ position: 'relative', paddingLeft: '20px' }}>
            <span style={{ position: 'absolute', left: 0 }}>-</span>
            Your User Content does not infringe upon any intellectual property rights or other
            rights of any third party
          </li>
        </Typography>
        <Typography variant="body1" gutterBottom>
          You assume all risk for any User Content you submit, including anyone&apos;`s reliance
          upon such User Content. You may expose yourself to liability if you submit User Content
          that is false, misleading, defamatory, violates any third-party right or contractual
          restriction, or contains unlawful material.
        </Typography>
        <Typography variant="body1" gutterBottom>
          Signwise Inc. does not verify, adopt, ratify, or sanction User Content, and you must
          evaluate and bear all risks associated with your use of or reliance on User Content.
          Reference to any products, services, processes, or other information by trade name,
          trademark, manufacturer, supplier, or otherwise does not constitute or imply endorsement
          or recommendation thereof by Signwise Inc. You understand that by using the Services, you
          may be exposed to User Content that you may consider offensive or objectionable.{' '}
        </Typography>
      </Section>
      <Section number={5} title="PROHIBITED USE OF PLATFORM">
        <Typography variant="body1" gutterBottom>
          Users shall not engage in any of the following activities while using SignWise.com
          (&quot;the Platform&quot;):
        </Typography>
        <Typography variant="body1" component="ul" gutterBottom margin={'20px 0'}>
          <li style={{ position: 'relative', paddingLeft: '20px' }}>
            <span style={{ position: 'absolute', left: 0 }}>-</span>
            Users shall not engage in any of the following activities while using SignWise.com
            (&quot;the Platform&quot;):
          </li>
          <li style={{ position: 'relative', paddingLeft: '20px' }}>
            <span style={{ position: 'absolute', left: 0 }}>-</span>
            Disclose confidential, proprietary, or insider information without proper authorization
            or in violation of legal or contractual obligations;
          </li>
          <li style={{ position: 'relative', paddingLeft: '20px' }}>
            <span style={{ position: 'absolute', left: 0 }}>-</span>
            Violate intellectual property rights, including copyrights, trademarks, patents, trade
            secrets, or privacy and publicity rights;
          </li>
          <li style={{ position: 'relative', paddingLeft: '20px' }}>
            <span style={{ position: 'absolute', left: 0 }}>-</span>
            Post or distribute unsolicited advertisements, spam, junk mail, chain letters, pyramid
            schemes, or other unauthorized solicitations;
          </li>
          <li style={{ position: 'relative', paddingLeft: '20px' }}>
            <span style={{ position: 'absolute', left: 0 }}>-</span>
            Introduce malicious software, such as viruses, worms, or other harmful code, that may
            disrupt or damage the Platform’s functionality or compromise user security;
          </li>
          <li style={{ position: 'relative', paddingLeft: '20px' }}>
            <span style={{ position: 'absolute', left: 0 }}>-</span>
            Copy, distribute, sell, license, or rebrand content from the Platform without explicit
            authorization from SignWise.com or the content owner;
          </li>
          <li style={{ position: 'relative', paddingLeft: '20px' }}>
            <span style={{ position: 'absolute', left: 0 }}>-</span>
            Modify, remove, or tamper with copyright, trademark, or proprietary notices displayed on
            the Platform;
          </li>
          <li style={{ position: 'relative', paddingLeft: '20px' }}>
            <span style={{ position: 'absolute', left: 0 }}>-</span>
            Attempt to reverse engineer, decompile, or derive the source code of the Platform or its
            systems;
          </li>
          <li style={{ position: 'relative', paddingLeft: '20px' }}>
            <span style={{ position: 'absolute', left: 0 }}>-</span>
            Access the Platform, accounts, or systems through unauthorized means, such as hacking or
            password mining;
          </li>
          <li style={{ position: 'relative', paddingLeft: '20px' }}>
            <span style={{ position: 'absolute', left: 0 }}>-</span>
            Use bots, scrapers, or automated tools to collect data, index pages, or interfere with
            the Platform’s operations;
          </li>
          <li style={{ position: 'relative', paddingLeft: '20px' }}>
            <span style={{ position: 'absolute', left: 0 }}>-</span>
            Engage in activities that disrupt, damage, or place an unreasonable load on the
            Platform’s infrastructure.
          </li>
        </Typography>
      </Section>
      <Section number={6} title="INDEMNIFICATION FOR USER CONTENT">
        <Typography variant="body1" gutterBottom>
          You agree to defend, indemnify, and hold harmless Signwise Inc., its officers, directors,
          employees, and agents from and against any claims, liabilities, damages, losses, and
          expenses, including without limitation reasonable legal and accounting fees, arising out
          of or in any way connected with:
        </Typography>
        <Typography variant="body1" component="ul" gutterBottom margin={'20px 0'}>
          <li style={{ position: 'relative', paddingLeft: '20px' }}>
            <span style={{ position: 'absolute', left: 0 }}>-</span>
            Your User Content
          </li>
          <li style={{ position: 'relative', paddingLeft: '20px' }}>
            <span style={{ position: 'absolute', left: 0 }}>-</span>
            Your violation of these Terms
          </li>
          <li style={{ position: 'relative', paddingLeft: '20px' }}>
            <span style={{ position: 'absolute', left: 0 }}>-</span>
            Your interaction with any other Platform participant
          </li>
          <li style={{ position: 'relative', paddingLeft: '20px' }}>
            <span style={{ position: 'absolute', left: 0 }}>-</span>
            Claims alleging defamation, misrepresentation, or inaccuracy in User Content you
            submitted
          </li>
          <li style={{ position: 'relative', paddingLeft: '20px' }}>
            <span style={{ position: 'absolute', left: 0 }}>-</span>
            Any services provided or promised through our Platform
          </li>
          <li style={{ position: 'relative', paddingLeft: '20px' }}>
            <span style={{ position: 'absolute', left: 0 }}>-</span>
            Any interaction between Users and Service Providers that originated through our Platform
          </li>
        </Typography>
        <Typography variant="body1" gutterBottom>
          Signwise Inc. reserves the right, at its own expense, to assume the exclusive defense and
          control of such disputes, and in any event, you will cooperate with us in asserting any
          available defenses.
        </Typography>
      </Section>
      <Section number={7} title="PLATFORM RULES AND ACCEPTABLE USE">
        <Typography variant="body1" gutterBottom>
          All Platform participants agree to use our services in accordance with applicable laws and
          regulations and solely for legitimate business purposes. This includes:
        </Typography>
        <Typography variant="body1" component="ul" gutterBottom margin={'20px 0'}>
          <li style={{ position: 'relative', paddingLeft: '20px' }}>
            <span style={{ position: 'absolute', left: 0 }}>-</span>
            Maintaining professional conduct in all communications
          </li>
          <li style={{ position: 'relative', paddingLeft: '20px' }}>
            <span style={{ position: 'absolute', left: 0 }}>-</span>
            Respecting the privacy and rights of other users
          </li>
          <li style={{ position: 'relative', paddingLeft: '20px' }}>
            <span style={{ position: 'absolute', left: 0 }}>-</span>
            Refraining from activities that could harm or disrupt our Platform or its participants
          </li>
          <li style={{ position: 'relative', paddingLeft: '20px' }}>
            <span style={{ position: 'absolute', left: 0 }}>-</span>
            Not engaging in any form of harassment, discrimination, or abuse
          </li>
          <li style={{ position: 'relative', paddingLeft: '20px' }}>
            <span style={{ position: 'absolute', left: 0 }}>-</span>
            Not using our Platform to promote or engage in fraudulent or deceptive practices
          </li>
          <li style={{ position: 'relative', paddingLeft: '20px' }}>
            <span style={{ position: 'absolute', left: 0 }}>-</span>
            Not attempting to manipulate Platform rankings, reviews, or other features
          </li>
          <li style={{ position: 'relative', paddingLeft: '20px' }}>
            <span style={{ position: 'absolute', left: 0 }}>-</span>
            Not circumventing our intended functionality
          </li>
        </Typography>
        <Typography variant="body1" gutterBottom>
          Any violation of these rules may result in immediate account termination.{' '}
        </Typography>
      </Section>
      <Section number={8} title="CONTENT MONITORING AND REMOVAL">
        <Typography variant="body1" gutterBottom>
          While Signwise Inc. is not obligated to monitor User Content, we reserve the right to:
        </Typography>
        <Typography variant="body1" component="ul" gutterBottom margin={'20px 0'}>
          <li style={{ position: 'relative', paddingLeft: '20px' }}>
            <span style={{ position: 'absolute', left: 0 }}>-</span>
            Review, monitor, or remove any content that we determine, in our sole discretion,
            violates these Terms
          </li>
          <li style={{ position: 'relative', paddingLeft: '20px' }}>
            <span style={{ position: 'absolute', left: 0 }}>-</span>
            Remove content that may be harmful to other users or to our Platform
          </li>
          <li style={{ position: 'relative', paddingLeft: '20px' }}>
            <span style={{ position: 'absolute', left: 0 }}>-</span>
            Suspend or terminate accounts that violate these Terms
          </li>
          <li style={{ position: 'relative', paddingLeft: '20px' }}>
            <span style={{ position: 'absolute', left: 0 }}>-</span>
            Take any action we deem necessary to protect the Platform and its users
          </li>
        </Typography>
        <Typography variant="body1" gutterBottom>
          The decision to remove content or terminate accounts lies solely with Signwise Inc., and
          such decisions are final and non-negotiable.
        </Typography>
      </Section>
      <Section number={9} title="DISCLAIMERS AND LIMITATIONS OF LIABILITY">
        <Typography variant="body1" gutterBottom>
          Our Platform is provided on an &quot;as is&quot; and &quot;as available&quot; basis,
          without any warranties or representations, express or implied. Signwise Inc. explicitly
          disclaims:
        </Typography>
        <Typography variant="body1" component="ul" gutterBottom margin={'20px 0'}>
          <li style={{ position: 'relative', paddingLeft: '20px' }}>
            <span style={{ position: 'absolute', left: 0 }}>-</span>
            Any warranties of merchantability, fitness for a particular purpose, or non-infringement
          </li>
          <li style={{ position: 'relative', paddingLeft: '20px' }}>
            <span style={{ position: 'absolute', left: 0 }}>-</span>
            Any warranties arising out of course of dealing or usage of trade
          </li>
          <li style={{ position: 'relative', paddingLeft: '20px' }}>
            <span style={{ position: 'absolute', left: 0 }}>-</span>
            The accuracy, reliability, or completeness of User Content
          </li>
          <li style={{ position: 'relative', paddingLeft: '20px' }}>
            <span style={{ position: 'absolute', left: 0 }}>-</span>
            The quality, safety, or legality of services offered by Service Providers
          </li>
          <li style={{ position: 'relative', paddingLeft: '20px' }}>
            <span style={{ position: 'absolute', left: 0 }}>-</span>
            The verification or validation of Service Provider credentials
          </li>
          <li style={{ position: 'relative', paddingLeft: '20px' }}>
            <span style={{ position: 'absolute', left: 0 }}>-</span>
            Any responsibility for the outcome of interactions between Users and Service Providers
          </li>
        </Typography>
        <Typography variant="body1" gutterBottom>
          Users acknowledge that they use our Platform at their own risk and discretion, and are
          solely responsible for verifying Service Provider credentials and conducting due
          diligence.
        </Typography>
      </Section>
      <Section number={10} title="INTELLECTUAL PROPERTY RIGHTS">
        <Typography variant="body1" gutterBottom>
          All rights, title, and interest in and to the Platform, including all intellectual
          property rights, are and will remain the exclusive property of Signwise Inc. and its
          licensors. The Platform&apos;s services and content, including but not limited to:
        </Typography>
        <Typography variant="body1" component="ul" gutterBottom margin={'20px 0'}>
          <li style={{ position: 'relative', paddingLeft: '20px' }}>
            <span style={{ position: 'absolute', left: 0 }}>-</span>
            Text, graphics, and logos
          </li>
          <li style={{ position: 'relative', paddingLeft: '20px' }}>
            <span style={{ position: 'absolute', left: 0 }}>-</span>
            Button icons and images
          </li>
          <li style={{ position: 'relative', paddingLeft: '20px' }}>
            <span style={{ position: 'absolute', left: 0 }}>-</span>
            Audio clips and digital downloads
          </li>
          <li style={{ position: 'relative', paddingLeft: '20px' }}>
            <span style={{ position: 'absolute', left: 0 }}>-</span>
            Data compilations and software
          </li>
          <li style={{ position: 'relative', paddingLeft: '20px' }}>
            <span style={{ position: 'absolute', left: 0 }}>-</span>
            Platform architecture and functionality
          </li>
          <li style={{ position: 'relative', paddingLeft: '20px' }}>
            <span style={{ position: 'absolute', left: 0 }}>-</span>
            Compilation of all content and materials
          </li>
        </Typography>
        <Typography variant="body1" gutterBottom>
          are protected by copyright, trademark, and other intellectual property laws. Users
          acknowledge that such compilation is the exclusive property of Signwise Inc. and protected
          by United States and international intellectual property laws. Nothing in these Terms
          grants users any rights to use Signwise Inc.&apos;s trademarks, service marks, logos,
          domain names, or other distinctive brand features without our prior written consent.{' '}
        </Typography>
      </Section>
      <Section number={11} title="DATA PRIVACY AND SECURITY">
        <Typography variant="body1" gutterBottom>
          Signwise Inc. collects and processes personal information in accordance with our Privacy
          Policy, which is incorporated by reference into these Terms. By using our Platform:
        </Typography>
        <Typography variant="body1" component="ul" gutterBottom margin={'20px 0'}>
          <li style={{ position: 'relative', paddingLeft: '20px' }}>
            <span style={{ position: 'absolute', left: 0 }}>-</span>
            You consent to our collection, use, and disclosure of personal information as described
            in our Privacy Policy
          </li>
          <li style={{ position: 'relative', paddingLeft: '20px' }}>
            <span style={{ position: 'absolute', left: 0 }}>-</span>
            You acknowledge that while we implement reasonable security measures, we cannot
            guarantee absolute security
          </li>
          <li style={{ position: 'relative', paddingLeft: '20px' }}>
            <span style={{ position: 'absolute', left: 0 }}>-</span>
            You understand that any information provided through the Platform may be accessed by
            other users as intended by our service&apos;s functionality
          </li>
          <li style={{ position: 'relative', paddingLeft: '20px' }}>
            <span style={{ position: 'absolute', left: 0 }}>-</span>
            You agree to maintain the confidentiality of any sensitive information you receive
            through the Platform
          </li>
        </Typography>
      </Section>
      {/* Repeat for other sections like "Information Sharing and Disclosure," "Your Privacy Rights," etc. */}

      <Section number={12} title="DISPUTE RESOLUTION">
        <Typography variant="body1">
          Any dispute arising from these Terms or the use of our Platform shall be governed by the
          laws of the State of California, without regard to its conflict of law provisions. Users
          and Service Providers agree to:
        </Typography>
        <Typography variant="body1" component="ul" gutterBottom margin={'20px 0'}>
          <li style={{ position: 'relative', paddingLeft: '20px' }}>
            <span style={{ position: 'absolute', left: 0 }}>-</span>
            Submit to the personal and exclusive jurisdiction of the state and federal courts
            located within California
          </li>
          <li style={{ position: 'relative', paddingLeft: '20px' }}>
            <span style={{ position: 'absolute', left: 0 }}>-</span>
            Commence any claim or dispute within one year after the cause of action accrues
          </li>
          <li style={{ position: 'relative', paddingLeft: '20px' }}>
            <span style={{ position: 'absolute', left: 0 }}>-</span>
            Waive any right to participate in class actions or class-wide arbitration
          </li>
          <li style={{ position: 'relative', paddingLeft: '20px' }}>
            <span style={{ position: 'absolute', left: 0 }}>-</span>
            Any claim or cause of action not commenced within one year shall be permanently barred
          </li>
        </Typography>
      </Section>
      <Section number={13} title="MODIFICATIONS TO SERVICE">
        <Typography variant="body1" gutterBottom>
          Signwise Inc. reserves the right to:
        </Typography>

        <Typography
          variant="body1"
          component="ul"
          gutterBottom
          sx={{
            listStyle: 'none', // Remove default bullet points
          }}
          margin={'20px 0'}
        >
          <li style={{ position: 'relative', paddingLeft: '20px' }}>
            <span style={{ position: 'absolute', left: 0 }}>-</span>
            Modify, suspend, or discontinue any part of our Platform at any time, with or without
            notice
          </li>
          <li style={{ position: 'relative', paddingLeft: '20px' }}>
            <span style={{ position: 'absolute', left: 0 }}>-</span>
            Impose limits on certain features and services
          </li>
          <li style={{ position: 'relative', paddingLeft: '20px' }}>
            <span style={{ position: 'absolute', left: 0 }}>-</span>
            Restrict access to parts or all of the Platform without notice or liability
          </li>
          <li style={{ position: 'relative', paddingLeft: '20px' }}>
            <span style={{ position: 'absolute', left: 0 }}>-</span>
            Update these Terms at any time
          </li>
          <li style={{ position: 'relative', paddingLeft: '20px' }}>
            <span style={{ position: 'absolute', left: 0 }}>-</span>
            Change our fee structure or payment methods
          </li>
        </Typography>
        <Typography variant="body1" gutterBottom>
          Users acknowledge that Signwise Inc. shall not be liable for any modification, suspension,
          or discontinuance of the Platform or any part thereof.
        </Typography>
      </Section>
      <Section number={14} title="TERMINATION">
        <Typography variant="body1" gutterBottom mt={'20px'}>
          Signwise Inc. reserves the right to suspend or terminate your access to our Platform:
        </Typography>
        <Typography
          variant="body1"
          component="ul"
          gutterBottom
          sx={{
            listStyle: 'none', // Remove default bullet points
          }}
        >
          <li style={{ position: 'relative', paddingLeft: '20px' }}>
            <span style={{ position: 'absolute', left: 0 }}>-</span>
            With or without notice
          </li>
          <li style={{ position: 'relative', paddingLeft: '20px' }}>
            <span style={{ position: 'absolute', left: 0 }}>-</span>
            For any reason or no reason
          </li>
          <li style={{ position: 'relative', paddingLeft: '20px' }}>
            <span style={{ position: 'absolute', left: 0 }}>-</span>
            Immediately upon any violation of these Terms
          </li>
          <li style={{ position: 'relative', paddingLeft: '20px' }}>
            <span style={{ position: 'absolute', left: 0 }}>-</span>
            Upon discovery of false or misleading User Content
          </li>
          <li style={{ position: 'relative', paddingLeft: '20px' }}>
            <span style={{ position: 'absolute', left: 0 }}>-</span>
            For repeated negative feedback or complaints
          </li>
        </Typography>
        <Typography variant="body1" gutterBottom mt={'20px'}>
          The following provisions shall survive termination:
        </Typography>
        <Typography
          variant="body1"
          component="ul"
          gutterBottom
          sx={{
            listStyle: 'none', // Remove default bullet points
          }}
        >
          <li style={{ position: 'relative', paddingLeft: '20px' }}>
            <span style={{ position: 'absolute', left: 0 }}>-</span>
            Ownership provisions
          </li>
          <li style={{ position: 'relative', paddingLeft: '20px' }}>
            <span style={{ position: 'absolute', left: 0 }}>-</span>
            Warranty disclaimers
          </li>
          <li style={{ position: 'relative', paddingLeft: '20px' }}>
            <span style={{ position: 'absolute', left: 0 }}>-</span>
            Indemnity obligations
          </li>
          <li style={{ position: 'relative', paddingLeft: '20px' }}>
            <span style={{ position: 'absolute', left: 0 }}>-</span>
            Limitations of liability
          </li>
          <li style={{ position: 'relative', paddingLeft: '20px' }}>
            <span style={{ position: 'absolute', left: 0 }}>-</span>
            User Content responsibilities
          </li>
          <li style={{ position: 'relative', paddingLeft: '20px' }}>
            <span style={{ position: 'absolute', left: 0 }}>-</span>
            Dispute resolution terms
          </li>
        </Typography>
      </Section>
      <Section number={15} title="RELATIONSHIP OF PARTIES">
        <Typography variant="body1" gutterBottom>
          The relationship between Signwise Inc. and Platform users is strictly that of independent
          parties. Specifically:
        </Typography>
        <Typography
          variant="body1"
          component="ul"
          gutterBottom
          sx={{
            listStyle: 'none', // Remove default bullet points
          }}
          margin={'20px 0'}
        >
          <li style={{ position: 'relative', paddingLeft: '20px' }}>
            <span style={{ position: 'absolute', left: 0 }}>-</span>
            No agency, partnership, joint venture, employee-employer or franchisor-franchisee
            relationship is created
          </li>
          <li style={{ position: 'relative', paddingLeft: '20px' }}>
            <span style={{ position: 'absolute', left: 0 }}>-</span>
            Users and Service Providers are independent contractors, not employees or agents
          </li>
          <li style={{ position: 'relative', paddingLeft: '20px' }}>
            <span style={{ position: 'absolute', left: 0 }}>-</span>
            Signwise Inc. does not exercise control over Service Providers&apos; methods or services
          </li>
          <li style={{ position: 'relative', paddingLeft: '20px' }}>
            <span style={{ position: 'absolute', left: 0 }}>-</span>
            Neither party has the authority to bind the other or create obligations on the
            other&apos;s behalf
          </li>
        </Typography>
      </Section>
      <Section number={16} title="THIRD-PARTY LINKS AND CONTENT">
        <Typography variant="body1" gutterBottom>
          Regarding third-party content and links on our Platform:
        </Typography>
        <Typography
          variant="body1"
          component="ul"
          gutterBottom
          sx={{
            listStyle: 'none', // Remove default bullet points
          }}
        >
          <li style={{ position: 'relative', paddingLeft: '20px' }}>
            <span style={{ position: 'absolute', left: 0 }}>-</span>
            Signwise Inc. has no control over third-party websites or services
          </li>
          <li style={{ position: 'relative', paddingLeft: '20px' }}>
            <span style={{ position: 'absolute', left: 0 }}>-</span>
            We assume no responsibility for third-party content, privacy policies, or practices
          </li>
          <li style={{ position: 'relative', paddingLeft: '20px' }}>
            <span style={{ position: 'absolute', left: 0 }}>-</span>
            Links to third-party sites do not constitute endorsement
          </li>
          <li style={{ position: 'relative', paddingLeft: '20px' }}>
            <span style={{ position: 'absolute', left: 0 }}>-</span>
            Users access third-party content at their own risk
          </li>
          <li style={{ position: 'relative', paddingLeft: '20px' }}>
            <span style={{ position: 'absolute', left: 0 }}>-</span>
            Rankings and reviews reflect third-party opinions and may contain inaccuracies
          </li>
          <li style={{ position: 'relative', paddingLeft: '20px' }}>
            <span style={{ position: 'absolute', left: 0 }}>-</span>
            Signwise Inc. makes no assurances regarding third-party content quality or reliability
          </li>
        </Typography>
      </Section>
      <Section number={17} title="SEVERABILITY AND WAIVER">
        <Typography variant="body1" gutterBottom>
          If any provision of these Terms is found unenforceable:
        </Typography>
        <Typography
          variant="body1"
          component="ul"
          gutterBottom
          sx={{
            listStyle: 'none', // Remove default bullet points
          }}
        >
          <li style={{ position: 'relative', paddingLeft: '20px' }}>
            <span style={{ position: 'absolute', left: 0 }}>-</span>
            Such provision shall be modified to the minimum extent necessary
          </li>
          <li style={{ position: 'relative', paddingLeft: '20px' }}>
            <span style={{ position: 'absolute', left: 0 }}>-</span>
            The remaining provisions shall remain in full force and effect
          </li>
          <li style={{ position: 'relative', paddingLeft: '20px' }}>
            <span style={{ position: 'absolute', left: 0 }}>-</span>
            The unenforceable provision shall be deleted if modification is not possible
          </li>
          <li style={{ position: 'relative', paddingLeft: '20px' }}>
            <span style={{ position: 'absolute', left: 0 }}>-</span>
            The invalidity of one provision does not affect other provisions
          </li>
        </Typography>
        <Typography variant="body1" gutterBottom mt={'20px'}>
          No waiver shall be effective unless:
        </Typography>
        <Typography
          variant="body1"
          component="ul"
          gutterBottom
          sx={{
            listStyle: 'none', // Remove default bullet points
          }}
        >
          <li style={{ position: 'relative', paddingLeft: '20px' }}>
            <span style={{ position: 'absolute', left: 0 }}>-</span>
            Executed in writing
          </li>
          <li style={{ position: 'relative', paddingLeft: '20px' }}>
            <span style={{ position: 'absolute', left: 0 }}>-</span>
            Signed by an authorized representative of Signwise Inc.
          </li>
          <li style={{ position: 'relative', paddingLeft: '20px' }}>
            <span style={{ position: 'absolute', left: 0 }}>-</span>
            Specifically referencing the right being waived
          </li>
        </Typography>
      </Section>
      <Section number={18} title="ENTIRE AGREEMENT">
        <Typography variant="body1" gutterBottom>
          These Terms, together with:
        </Typography>
        <Typography
          variant="body1"
          component="ul"
          gutterBottom
          sx={{
            listStyle: 'none', // Remove default bullet points
          }}
        >
          <li style={{ position: 'relative', paddingLeft: '20px' }}>
            <span style={{ position: 'absolute', left: 0 }}>-</span>
            The Privacy Policy
          </li>
          <li style={{ position: 'relative', paddingLeft: '20px' }}>
            <span style={{ position: 'absolute', left: 0 }}>-</span>
            Any additional terms and conditions
          </li>
          <li style={{ position: 'relative', paddingLeft: '20px' }}>
            <span style={{ position: 'absolute', left: 0 }}>-</span>
            All legal notices published on the Platform
          </li>
          <li style={{ position: 'relative', paddingLeft: '20px' }}>
            <span style={{ position: 'absolute', left: 0 }}>-</span>
            All legal notices published on the Platform
          </li>
        </Typography>
        <Typography variant="body1" gutterBottom mt={'20px'}>
          constitute the entire agreement between you and Signwise Inc. concerning the Platform and
          its use.
        </Typography>
      </Section>
      <Section number={19} title="CONTACT INFORMATION">
        <Typography variant="body1" gutterBottom>
          For questions about these Terms, please contact Signwise Inc. at:
        </Typography>
        <Typography variant="body1" gutterBottom>
          <a href="mailto:info@signwise.com">info@signwise.com</a>
        </Typography>
        <Typography variant="body1" gutterBottom mt={'20px'}>
          For legal notices:
        </Typography>
        <Typography variant="body1" gutterBottom>
          Signwise Inc.
          <br />
          <a href="mailto:support@signwise.com">support@signwise.com</a>
        </Typography>
        <Typography variant="body1" gutterBottom mt={'20px'}>
          For support inquiries:
        </Typography>
        <Typography variant="body1" gutterBottom>
          <a href="mailto:support@signwise.com">support@signwise.com</a>
        </Typography>
        <Typography variant="body1" gutterBottom mt={'20px'}>
          By using our Platform, you acknowledge that you have read, understood, and agree to be
          bound by these Terms and Conditions.
        </Typography>
        <Typography variant="body1" gutterBottom>
          Copyright © 2024 Signwise Inc. All rights reserved.
        </Typography>
      </Section>
    </Box>
  );
};

const Section: FC<{ number: number; title: string; children: React.ReactNode }> = ({
  number,
  title,
  children,
}) => (
  <Box mb={4}>
    <Typography variant="h5" gutterBottom ml={2} fontWeight={600} fontSize={'20px'} color={'#000'}>
      {number}. {title}
    </Typography>
    {children}
  </Box>
);

export default TermsAndConditions;
