import * as React from 'react';
import Box from '@mui/material/Box';
import Typography from '@mui/material/Typography';
import Modal from '@mui/material/Modal';
import Logo from '../../../assets/SignWiselogo .svg'; // Ensure the path is correct
import {
  Button,
  CircularProgress,
  FormControl,
  IconButton,
  InputAdornment,
  OutlinedInput,
  TextField,
} from '@mui/material';
import { MuiOtpInput } from 'mui-one-time-password-input';
import request from '../../../api/request';
import { toast } from 'react-toastify';
import { Link } from 'react-router-dom';
import { IAuthResponse } from '../../../types/unAuth.type';
// import { VisibilityOff, Visibility } from '@mui/icons-material';
import Visibility from '../../../assets/eyeicon.svg';
import VisibilityOff from '../../../assets/EyeoffIcon.svg';
import CustomToast from '../../../components/phase1a/common/CustomToast.component';
// import { UpdateStore } from '../../../context/store';

const style = {
  position: 'absolute',
  top: '50%',
  left: '50%',
  transform: 'translate(-50%, -50%)',
  width: '600px',
  bgcolor: 'background.paper',
  boxShadow: 24,
  outline: 'none',
  p: 4,
  // '@media (max-width: 900px)': {
  //   width: '55vw',
  // },
  '@media (max-width: 768px)': {
    width: '500px',
  },
  '@media (max-width: 500px)': {
    width: '90%',
    padding: '32px 0 !important',
  },
};

interface SignInPopupProps {
  open: boolean;
  onClose: () => void;
  setOpenPopup?: any;
}

interface ILogin {
  id: string;
  otp?: string;
  password?: string;
}

const SignInPopup: React.FC<SignInPopupProps> = ({ open, onClose, setOpenPopup }) => {
  // const nav = useNavigate();
  const [email, setEmail] = React.useState('');
  // const [emailError, setEmailError] = React.useState('');
  const [otp, setOtp] = React.useState('');
  const [password, setPassword] = React.useState('');
  const [loading, setLoading] = React.useState(false);
  const [isEmailSubmitted, setIsEmailSubmitted] = React.useState(false);
  const [isPasswordMode, setIsPasswordMode] = React.useState(false);

  const validateEmail = (email: string): boolean => {
    const emailRegex = /^[^\s@]+@[^\s@]+\.[^\s@]+$/;
    return emailRegex.test(email);
  };

  // console.log('🚀 ~ emailError:', emailError);

  const handleEmailChange = (e: React.ChangeEvent<HTMLInputElement>): void => {
    const newEmail = e.target.value;
    setEmail(newEmail);
    // if (!validateEmail(newEmail)) {
    //   setEmailError('Invalid email address');
    // } else {
    //   setEmailError('');
    // }
  };

  const handleChange = (newValue: string): void => {
    const numericValue = newValue.replace(/\D/g, '');
    setOtp(numericValue);
  };

  const handlePasswordChange = (e: React.ChangeEvent<HTMLInputElement>): void => {
    setPassword(e.target.value);
  };

  const isButtonDisabled = otp.length !== 6;
  const isButtonDisabled1 = !password;

  const handleSubmit = (): void => {
    setLoading(true);
    if (!email) {
      // toast.error('Enter email');
      toast(<CustomToast message={'Enter Email'} type="error" />);

      setLoading(false);
      return;
    }

    if (!validateEmail(email)) {
      setLoading(false); // Ensure loading state is reset before returning
      toast(<CustomToast message={'Invalid email address'} type="error" />);
      return;
    }
    request('post', '/auth/generateOTP', { id: email, userWithoutCompany: true })
      .then(() => {
        // toast.success(`OTP generated and sent successfully to ${email}`);
        toast(
          <CustomToast
            message={`OTP generated and sent successfully to ${email}`}
            type="success"
          />,
        );

        setLoading(false);
        setIsEmailSubmitted(true);
      })
      .catch((err) => {
        setLoading(false);
        // toast.error(err?.response?.data?.message || 'Error generating OTP');
        toast(
          <CustomToast
            message={err?.response?.data?.message || 'Error generating OTP'}
            type="error"
          />,
        );
      });
  };

  const handleSignup = (): void => {
    setLoading(true);
    const id = email;
    const data: ILogin = { id, otp, password };

    request<IAuthResponse, ILogin>('post', '/auth/login', data)
      .then((res) => {
        localStorage.setItem('token', res.token);
        localStorage.setItem('user', JSON.stringify(res.user));
        setLoading(false);
        // toast.success('Logged In Successfully');
        toast(<CustomToast message={'Logged In Successfully'} type="success" />);
        onClose();
        setOpenPopup?.(2);
      })
      .catch(() => {
        setLoading(false);
        // toast.error('Invalid Password');
        toast(<CustomToast message={'Invalid Password'} type="success" />);
      });
  };
  const [showPassword, setShowPassword] = React.useState(false);
  const handleClickShowPassword = (): void => {
    setShowPassword((show) => !show);
  };

  const handleMouseDownPassword = (event: React.MouseEvent<HTMLButtonElement>): void => {
    event.preventDefault();
  };
  const handleSubmitPassword = (): void => {
    setLoading(true);
    const id = email;

    const data: ILogin = { id, otp, password };

    request<IAuthResponse, ILogin>('post', '/auth/login', data)
      .then((res) => {
        localStorage.setItem('token', res.token);
        localStorage.setItem('user', JSON.stringify(res.user));
        // if (res?.user) {
        //   UpdateStore?.({ user: res?.user });
        // }
        setLoading(false);
        console.log('res?.user?.type', res?.user);
        onClose();
        setOpenPopup?.(2);

        // if (
        //   (res?.user?.company?.companyInfo?.companyType === 'supplier' ||
        //     res?.user?.company?.companyInfo?.companyType === 'distributor') &&
        //   res?.user?.type === 'member'
        // ) {
        //   nav('/localpageowner/SettingPagesetup');
        // } else {
        //   nav('/localpageowner/job-gallery');
        // }
        // toast.success('You have logged in successfully.');
        toast(<CustomToast message={'You have logged in successfully.'} type="success" />);
      })
      .catch(() => {
        setLoading(false);
        // toast.error('Invalid OTP');
        toast(<CustomToast message={'Invalid OTP'} type="error" />);
      });
  };

  return (
    <div>
      <Modal
        open={open}
        onClose={onClose}
        aria-labelledby="modal-modal-title"
        aria-describedby="modal-modal-description"
        BackdropProps={{
          sx: {
            backgroundColor: 'var(#0C111D)',
            opacity: 0.7,
            backdropFilter: 'blur(8px)', // Adjust the blur level as needed
          },
        }}
      >
        <Box sx={style} borderRadius={'5px'}>
          <Box
            display={'flex'}
            flexDirection={'column'}
            padding={'30px'}
            borderRadius={'20px'}
            alignItems={'center'}
            gap={'20px'}
            width={'100%'}
            sx={{
              '@media (max-width: 900px)': {
                padding: '0px',
              },
            }}
          >
            <Box
              display={'flex'}
              flexDirection={'column'}
              gap={'40px'}
              width={'100%'}
              justifyContent={'center'}
              alignItems={'center'}
            >
              <Box width={'50%'} display={'flex'} justifyContent={'center'}>
                <img src={Logo} alt="Logo" />
              </Box>

              <Typography
                fontSize={'16px'}
                fontWeight={'500'}
                color={'#000000'}
                width={'100%'}
                textAlign={'center'}
                fontFamily="SF UI Display"
                sx={{
                  '@media (max-width: 1024px)': {
                    width: '50%',
                  },
                  '@media (max-width: 768px)': {
                    width: '70%',
                  },
                }}
              >
                {!isEmailSubmitted
                  ? 'Sign in to access your account.'
                  : isPasswordMode
                  ? 'Log in to access your account.'
                  : 'Enter OTP Here.'}
              </Typography>
            </Box>
            {!isEmailSubmitted ? (
              <Box
                display={'flex'}
                flexDirection={'column'}
                width={'100%'}
                gap={'10px'}
                justifyContent={'center'}
                alignItems={'center'}
                marginTop={'4%'}
              >
                <Box width={'90%'}>
                  <TextField
                    required
                    type="email"
                    placeholder="Email"
                    value={email}
                    name="email"
                    onChange={handleEmailChange}
                    // error={!!emailError}
                    // helperText={emailError}
                    fullWidth
                    sx={{
                      width: '100%',
                      fontSize: '16px',
                      fontWeight: '400',
                      lineHeight: '24px',
                      color: 'rgba(215, 216, 221, 1)',
                      borderRadius: '12px',
                      '.MuiOutlinedInput-input': {
                        // padding: ' 14px',
                      },
                      '& .MuiOutlinedInput-root': {
                        '& fieldset': {
                          border: '1px solid #D0D5DD',
                          outline: 'none',
                          borderRadius: '12px',
                        },
                        '&:hover fieldset': {
                          borderColor: '#D0D5DD',
                        },
                        '&.Mui-focused fieldset': {
                          borderColor: '#D0D5DD',
                        },
                        '&.MuiInputBase-root:focus-visible': {
                          borderColor: '#D0D5DD',
                        },
                      },
                    }}
                  />
                </Box>

                <Box width={'90%'} marginTop={'3%'}>
                  <Button
                    fullWidth
                    onClick={handleSubmit}
                    sx={{
                      padding: '16px 24px',
                      bgcolor: 'black',
                      borderRadius: '8px',
                      color: 'white',
                      textTransform: 'unset',
                      fontSize: '14px',
                      fontFamily: 'Poppins',
                      fontWeight: '600',
                      boxShadow: '0 2px 4px rgba(0, 0, 0, 0.1)',
                      '&:hover': {
                        bgcolor: 'black',
                        color: 'white',
                      },
                    }}
                  >
                    {loading ? <CircularProgress size={15} color="inherit" /> : ' Log In '}
                  </Button>
                </Box>
                <Typography
                  textAlign={'center'}
                  mt={3}
                  fontSize={'16px'}
                  fontWeight={'500'}
                  color={'rgba(0, 0, 0, 1)'}
                  marginTop={'20px'}
                  fontFamily="SF UI Display"

                  // sx={{
                  //   '@media (max-width: 900px)': {
                  //     width: '100%',
                  //   },
                  // }}
                >
                  {`Don't have an account? `}
                  <Link to="/signup">
                    <span
                      style={{
                        fontWeight: '500',
                        cursor: 'pointer',
                        color: 'rgba(0, 189, 87, 1)',

                        textDecoration: 'underline',
                      }}
                    >
                      Sign up here
                    </span>
                  </Link>
                </Typography>
              </Box>
            ) : isPasswordMode ? (
              <Box
                display="flex"
                flexDirection="column"
                width="100%"
                gap="28px"
                justifyContent="center"
                marginTop="2%"
                sx={{
                  '@media (max-width: 500px)': {
                    width: '90%',
                  },
                }}
              >
                <TextField
                  required
                  type="email"
                  placeholder="Email"
                  value={email}
                  disabled
                  fullWidth
                  sx={{
                    width: '100%',
                    fontSize: '16px',
                    fontWeight: '400',
                    lineHeight: '24px',
                    color: 'rgba(215, 216, 221, 1)',
                    borderRadius: '12px',
                    '.MuiOutlinedInput-input': {
                      // padding: ' 14px',
                    },
                    '& .MuiOutlinedInput-root': {
                      '& fieldset': {
                        border: '1px solid #D0D5DD',
                        outline: 'none',
                        borderRadius: '12px',
                      },
                      '&:hover fieldset': {
                        borderColor: '#D0D5DD',
                      },
                      '&.Mui-focused fieldset': {
                        borderColor: '#D0D5DD',
                      },
                      '&.MuiInputBase-root:focus-visible': {
                        borderColor: '#D0D5DD',
                      },
                    },
                  }}
                />
                <FormControl
                  sx={{
                    width: '100%',
                    fontSize: '16px',
                    fontWeight: '400',
                    lineHeight: '24px',
                    color: '#667085',
                    marginTop: '1%',
                    borderRadius: '12px',
                    '.MuiOutlinedInput-input': {
                      // padding: ' 14px',
                    },
                    '& .MuiOutlinedInput-root': {
                      '& fieldset': {
                        border: '1px solid #D0D5DD',
                        outline: 'none',
                        borderRadius: '12px',
                      },
                      '&:hover fieldset': {
                        borderColor: '#D0D5DD',
                      },
                      '&.Mui-focused fieldset': {
                        borderColor: '#D0D5DD',
                      },
                      '&.MuiInputBase-root:focus-visible': {
                        borderColor: '#D0D5DD',
                      },
                    },
                  }}
                  variant="outlined"
                >
                  <OutlinedInput
                    id="outlined-adornment-password"
                    type={showPassword ? 'text' : 'password'}
                    value={password}
                    onChange={handlePasswordChange}
                    endAdornment={
                      <InputAdornment position="end">
                        <IconButton
                          aria-label="toggle password visibility"
                          onClick={handleClickShowPassword}
                          onMouseDown={handleMouseDownPassword}
                          edge="end"
                        >
                          {showPassword ? (
                            <img src={VisibilityOff} alt="." />
                          ) : (
                            <img src={Visibility} alt="." />
                          )}
                        </IconButton>
                      </InputAdornment>
                    }
                    placeholder="Password"
                  />
                </FormControl>
                <Box width={'100%'} marginTop={'1%'}>
                  <Button
                    fullWidth
                    onClick={handleSignup}
                    disabled={isButtonDisabled1}
                    sx={{
                      padding: '16px 24px',
                      bgcolor: isButtonDisabled1 ? '#D0D5DD' : 'black',
                      borderRadius: '8px',
                      color: 'white',
                      textTransform: 'unset',
                      fontSize: '14px',
                      fontFamily: 'Poppins',
                      fontWeight: '600',
                      boxShadow: '0 2px 4px rgba(0, 0, 0, 0.1)',
                      '&:hover': {
                        bgcolor: 'black',
                        color: 'white',
                      },
                    }}
                  >
                    {loading ? <CircularProgress size={15} color="inherit" /> : ' Log In '}
                  </Button>
                </Box>
              </Box>
            ) : (
              <Box
                display="flex"
                flexDirection="column"
                width="100%"
                gap="28px"
                justifyContent="center"
                alignItems={'center'}
                marginTop="2%"
                sx={{
                  '@media (max-width: 500px)': {
                    width: '90%',
                    gap: '15px',
                  },
                }}
              >
                <Box
                  width={'95%'}
                  sx={{
                    '@media (max-width: 768px)': {
                      width: '100%',
                    },
                  }}
                >
                  <MuiOtpInput
                    value={otp}
                    onChange={handleChange}
                    length={6}
                    sx={{
                      '& .css-9ddj71-MuiInputBase-root-MuiOutlinedInput-root': {
                        borderRadius: '12px !important',
                      },
                      '& .css-1t8l2tu-MuiInputBase-input-MuiOutlinedInput-input': {
                        padding: '16.5px 10px',
                        borderRadius: '12px !important',
                      },
                      '& .css-1v4ccyo': {
                        borderRadius: '12px !important',
                      },
                      '@media (max-width: 470px)': {
                        gap: '9px',
                      },
                    }}
                  />
                </Box>
                <Box width={'95%'}>
                  <Typography
                    mt={1}
                    fontSize={'16px'}
                    fontWeight={'300'}
                    marginTop={'20px'}
                    fontFamily="SF UI Display"
                    sx={{ textAlign: 'left' }}
                  >
                    We have emailed you a magic code for a password-free sign in. Or you can
                    <Link
                      to="#"
                      onClick={() => {
                        setIsPasswordMode(true);
                      }}
                      style={{
                        fontSize: '16px',
                        fontWeight: '300',
                        color: 'rgba(0, 189, 87, 1)',
                        marginTop: '20px',
                        fontFamily: 'SF UI Display',
                        textDecoration: 'underline',
                        cursor: 'pointer',
                        marginLeft: '5px',
                      }}
                    >
                      sign in manually with your password.
                    </Link>
                  </Typography>
                </Box>
                <Box width={'95%'} marginTop={'1%'}>
                  <Button
                    fullWidth
                    onClick={handleSubmitPassword}
                    disabled={isButtonDisabled}
                    sx={{
                      padding: '16px 24px',
                      // bgcolor: 'black',
                      bgcolor: isButtonDisabled ? '#D0D5DD' : 'black',
                      borderRadius: '8px',
                      color: 'white',
                      textTransform: 'unset',
                      fontSize: '14px',
                      fontFamily: 'Poppins',
                      fontWeight: '600',
                      boxShadow: '0 2px 4px rgba(0, 0, 0, 0.1)',
                      '&:hover': {
                        bgcolor: 'black',
                        color: 'white',
                      },
                    }}
                  >
                    {loading ? <CircularProgress size={15} color="inherit" /> : 'Continue'}
                  </Button>
                </Box>
              </Box>
            )}
          </Box>
        </Box>
      </Modal>
    </div>
  );
};

export default SignInPopup;
