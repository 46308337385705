// let baseUrl = 'http://localhost:5000/api/v1';
// let mediaUrl = 'http://localhost:5000';

let baseUrl = 'https://signwise-api-dev.falconweb.app/api/v1';
let mediaUrl = 'https://signwise-api-dev.falconweb.app';

// let baseUrl = 'http://192.168.0.249:5000/api/v1';
// let mediaUrl = 'http://192.168.0.249:5000';

const appName = 'signwise';

let env = 'dev';
// Update the baseUrl and mediaUrl on every environment
switch (window.location.host) {
  case 'signwise-dev.falconweb.app':
    baseUrl = 'https://signwise-api-dev.falconweb.app/api/v1';
    mediaUrl = 'https://signwise-api-dev.falconweb.app';
    break;

  case 'signwise-preprod.falconweb.app':
    baseUrl = 'https://signwise-api-preprod.falconweb.app/api/v1';
    mediaUrl = 'https://api.signwise.com';
    env = 'staging';
    break;

  // case 'reactapp-staging.falconweb.app':
  //   baseUrl = 'https://reactapp-api-staging.falconweb.app/api/v1';
  //   mediaUrl = 'https://reactapp-api-staging.falconweb.app';
  //   env = 'staging';
  //   break;

  case 'signwise.com':
    baseUrl = 'https://api.signwise.com/api/v1';
    mediaUrl = 'https://api.signwise.com';
    env = 'prod';
    break;
}

export { baseUrl, mediaUrl, appName, env };
