import React, { useEffect, useState } from 'react';
import { Modal, Box, CircularProgress, Typography } from '@mui/material';
import request from '../../../api/request';
import Close from '@mui/icons-material/Close';

interface AddNewJobShowCaseProps {
  open: boolean;
  handleClose: () => void;
}

interface CompanyInfo {
  name: string;
  // Add other properties if needed, e.g., `id: number; location: string;`
}

interface IContributorResponse {
  companyInfo: CompanyInfo[];
}

const ViewContributor: React.FC<AddNewJobShowCaseProps> = ({ open, handleClose }) => {
  const [contributorData, setContributorData] = useState<IContributorResponse[] | null>(null);
  const [loading, setLoading] = useState(true);

  useEffect(() => {
    if (open) {
      setLoading(true);

      request<any>('get', '/companies/contributor')
        .then((response) => {
          console.log('responseresponse', response);
          setContributorData(response.contributors);
          setLoading(false);
        })
        .catch(() => {
          setLoading(false);
        });
    }
  }, [open]);

  return (
    <Modal
      open={open}
      onClose={handleClose}
      aria-labelledby="modal-title"
      aria-describedby="modal-description"
    >
      <Box
        sx={{
          position: 'absolute',
          top: '50%',
          left: '50%',
          transform: 'translate(-50%, -50%)',
          width: 400,
          bgcolor: 'background.paper',
          borderRadius: '8px',
          boxShadow: 24,
          msxHeight: '450px',
          border: 'none',
          overflowY: 'auto',
          p: 3,
          display: 'flex',
          justifyContent: 'center',
          alignItems: 'center',
          '@media (max-width:500px)': {
            width: '90%',
          },
        }}
      >
        {loading ? (
          <CircularProgress />
        ) : contributorData ? (
          <Box
            sx={{
              display: 'flex',
              flexDirection: 'column',
              gap: '10px',
              alignItems: 'flex-start',
              justifyContent: 'flex-start',
              width: '100%',
            }}
          >
            <Box
              sx={{
                display: 'flex',
                gap: '10px',
                alignItems: 'flex-start',
                justifyContent: 'space-between',
                width: '100%',
              }}
            >
              <h1>
                <b>Top Contributors list</b>
              </h1>
              <Close sx={{ cursor: 'pointer', marginRight: '-8px' }} onClick={handleClose} />
            </Box>

            <Box
              sx={{
                display: 'flex',
                gap: '10px',
                alignItems: 'flex-start',
                justifyContent: 'space-between',
                width: '100%',
                marginTop: '20px',
              }}
            >
              <Typography
                fontFamily="SF UI Display"
                fontSize="18px"
                fontWeight="600"
                color="#000000"
              >
                Company Name
              </Typography>
              <Typography
                fontFamily="SF UI Display"
                fontSize="18px"
                fontWeight="600"
                color="#000000"
                width={'74px'}
              >
                Gallery #
              </Typography>
            </Box>

            {contributorData?.map((item: any) => {
              return (
                <Box
                  key={item.companyInfo?._id}
                  sx={{
                    display: 'flex',
                    alignItems: 'center',
                    justifyContent: 'space-between',
                    width: '100%',
                  }}
                >
                  <Typography
                    fontFamily="SF UI Display"
                    fontSize="16px"
                    fontWeight="300"
                    color="#000000"
                  >
                    {item?.companyInfo?.name}
                  </Typography>
                  <Typography
                    fontFamily="SF UI Display"
                    fontSize="16px"
                    fontWeight="300"
                    color="#000000"
                    width={'74px'}
                    textAlign={'center'}
                  >
                    {item?.workGallery?.length}
                  </Typography>
                </Box>
              );
            })}
          </Box>
        ) : (
          <Typography>No data available</Typography>
        )}
      </Box>
    </Modal>
  );
};

export default ViewContributor;
