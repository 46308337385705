import React, { FC } from 'react';
import { Box, Grid, MenuItem, ListItemIcon, Typography } from '@mui/material';
import ArrowForwardIosIcon from '@mui/icons-material/ArrowForwardIos';
import Sidebarlogo from '../../../assets/Settings..svg';
import ArrowBackIcon from '@mui/icons-material/ArrowBack';

interface RoutesProps {
  setSelected: any;
  selected: string;
}
const LocalSetUpSidebar: FC<RoutesProps> = ({ setSelected, selected }) => {
  const userJSON = localStorage.getItem('user');
  const userData = userJSON ? JSON.parse(userJSON) : '';
  const userType = userData?.type;
  const userCompany = userData?.company?.companyInfo?.companyType;
  const checkUserCompany = (): any => {
    if (userType === 'member' && (userCompany === 'distributor' || userCompany === 'supplier')) {
      return false;
    } else {
      return true;
    }
  };
  return (
    <Box
      width="25%"
      bgcolor=" #F5F3F3"
      sx={{
        '@media (max-width: 1024px)': {
          width: '100%',
        },
      }}
    >
      <Grid container height="100%" display="flex" flexDirection="row" position="relative">
        <Box
          width="100%"
          display="flex"
          alignItems="center"
          flexDirection="column"
          bgcolor="F5F3F3"
          padding={'38px 11px 11px 11px'}
          gap={'10px'}
        >
          <Box
            display="flex"
            flexDirection="column"
            alignItems="flex-start"
            gap={'10px'}
            width={'87%'}
            sx={{
              '@media (max-width: 1024px)': {
                width: '96%',
              },
            }}
          >
            <Box
              width={'100%'}
              display={'flex'}
              alignItems={'start'}
              justifyContent={'start'}
              gap={'10px'}
            >
              <ArrowBackIcon
                onClick={() => {
                  window.history.back();
                }}
                style={{ cursor: 'pointer' }}
              />
              <Box
                width={'100%'}
                display={'flex'}
                flexDirection={'column'}
                alignItems={'start'}
                justifyContent={'start'}
                gap={'10px'}
              >
                <Box width={'80%'}>
                  <img src={Sidebarlogo} alt="Logo" />
                </Box>

                <Box marginBottom="20px">
                  <Typography
                    fontSize={'16px'}
                    fontWeight={'400'}
                    color={'#000000'}
                    lineHeight={'19px'}
                    fontFamily={'SF UI Display'}
                  >
                    Edit or update any of your account and team information here.
                  </Typography>
                </Box>
              </Box>
            </Box>
          </Box>

          <Box width="86%" textAlign="left" display={'flex'} flexDirection={'column'}>
            <MenuItem
              style={{
                display: 'flex',
                justifyContent: 'space-between',
                alignItems: 'center',
                borderBottom: '1px solid #ccc',
                fontSize: '16px',
                fontWeight: '700',
                color: `${selected === 'accountinfo' ? 'green' : 'black'}`,
                padding: '25px 0px ',
                lineHeight: '19.09px',
              }}
              onClick={() => setSelected('accountinfo')}
            >
              <Box
                display={'flex'}
                gap={'20px'}
                lineHeight={'19.09px'}
                justifyContent={'center'}
                alignItems={'center'}
              >
                <span>Account Info</span>
              </Box>

              <ListItemIcon
                sx={{
                  minWidth: '12px !important',
                }}
              >
                <ArrowForwardIosIcon
                  sx={{
                    color: '#000000 ',
                    width: '21.07px',
                    height: '17.39px',
                  }}
                />{' '}
              </ListItemIcon>
            </MenuItem>
            {/* <MenuItem
              style={{
                display: 'flex',
                justifyContent: 'space-between',
                alignItems: 'center',
                borderBottom: '1px solid #ccc',
                fontSize: '16px',
                fontWeight: '700',
                padding: '20px 0px ',

                color: `${selected === 'billing' ? 'green' : 'black'}`,
                lineHeight: '19.09px',
              }}
              onClick={() => setSelected('billing')}
            >
              <Box
                display={'flex'}
                gap={'20px'}
                lineHeight={'19.09px'}
                justifyContent={'center'}
                alignItems={'center'}
              >
                <span>Billing</span>
              </Box>

              <ListItemIcon
                sx={{
                  minWidth: '12px !important',
                }}
              >
                <ArrowForwardIosIcon
                  sx={{
                    color: '#000000 ',
                    width: '21.07px',
                    height: '17.39px',
                  }}
                />{' '}
              </ListItemIcon>
            </MenuItem> */}
            <MenuItem
              style={{
                display: 'flex',
                justifyContent: 'space-between',
                alignItems: 'center',
                borderBottom: '1px solid #ccc',
                fontSize: '16px',
                fontWeight: '700',
                padding: '20px 0px ',

                color: `${selected === 'teamMember' ? 'green' : 'black'}`,
                lineHeight: '19.09px',
              }}
              onClick={() => setSelected('teamMember')}
            >
              <Box
                display={'flex'}
                gap={'20px'}
                lineHeight={'19.09px'}
                justifyContent={'center'}
                alignItems={'center'}
              >
                <span>Members</span>
              </Box>

              <ListItemIcon
                sx={{
                  minWidth: '12px !important',
                }}
              >
                <ArrowForwardIosIcon
                  sx={{
                    color: '#000000 ',
                    width: '21.07px',
                    height: '17.39px',
                  }}
                />{' '}
              </ListItemIcon>
            </MenuItem>
            {/* <MenuItem
              style={{
                display: 'flex',
                justifyContent: 'space-between',
                alignItems: 'center',
                borderBottom: '1px solid #ccc',
                fontSize: '16px',
                fontWeight: '700',
                padding: '25px 0px ',

                color: `${selected === 'membership' ? 'green' : 'black'}`,
                lineHeight: '19.09px',
              }}
              onClick={() => setSelected('membership')}
            >
              <Box
                display={'flex'}
                gap={'20px'}
                lineHeight={'19.09px'}
                justifyContent={'center'}
                alignItems={'center'}
              >
                <span>Membership Benefits</span>
              </Box>

              <ListItemIcon
                sx={{
                  minWidth: '12px !important',
                }}
              >
                <ArrowForwardIosIcon
                  sx={{
                    color: '#000000 ',
                    width: '21.07px',
                    height: '17.39px',
                  }}
                />{' '}
              </ListItemIcon>
            </MenuItem> */}
            {checkUserCompany() && (
              <MenuItem
                style={{
                  display: 'flex',
                  justifyContent: 'space-between',
                  alignItems: 'center',
                  borderBottom: '1px solid #ccc',
                  fontSize: '16px',
                  fontWeight: '700',
                  padding: '25px 0px ',

                  color: `${selected === 'notification' ? 'green' : 'black'}`,
                  lineHeight: '19.09px',
                }}
                onClick={() => setSelected('notification')}
              >
                <Box
                  display={'flex'}
                  gap={'20px'}
                  lineHeight={'19.09px'}
                  justifyContent={'center'}
                  alignItems={'center'}
                >
                  <span>Notification Settings </span>
                </Box>

                <ListItemIcon
                  sx={{
                    minWidth: '12px !important',
                  }}
                >
                  <ArrowForwardIosIcon
                    sx={{
                      color: '#000000 ',
                      width: '21.07px',
                      height: '17.39px',
                    }}
                  />{' '}
                </ListItemIcon>
              </MenuItem>
            )}
          </Box>
        </Box>
      </Grid>
    </Box>
  );
};

export default LocalSetUpSidebar;
