import React, { FC, useEffect, useState } from 'react';

import {
  Box,
  Avatar,
  Popover,
  Typography,
  Divider,
  Button,
  Autocomplete,
  TextField,
  createFilterOptions,
  useMediaQuery,
  IconButton,
} from '@mui/material';
import Notification from '../../assets/notifications.svg';
import logo from '../../assets/beta/signwise-logo-beta.svg';
import SearchIcon from '@mui/icons-material/Search';
import CloseIcon from '@mui/icons-material/Close';
// import search from '../../assets/search.svg';
import SettingIcon from '../../assets/header/settingIcon.svg';
import SignOutIcon from '../../assets/header/signoutIcon.svg';
import { Link, useNavigate } from 'react-router-dom';
import DashBoardIcon from '../../assets/header/DashboardIcon.svg';
import io from 'socket.io-client';
import { mediaUrl } from '../../config';
import request from '../../api/request';
import { UpdateStore } from '../../context/store';
import { IAddress, IAddressResponse } from '../../types/user';
import ServiceIcon1 from '../../assets/serviceFilter/serviceFilter1.svg';
import ServiceIcon2 from '../../assets/serviceFilter/serviceFilter2.svg';
import ServiceIcon3 from '../../assets/serviceFilter/serviceFilter3.svg';
import ServiceIcon4 from '../../assets/serviceFilter/serviceFilter4.svg';
import ServiceIcon5 from '../../assets/serviceFilter/serviceFilter5.svg';
import printProduction from '../../assets/serviceFilter/serviceFilter6.svg';
import PrintInstallation from '../../assets/serviceFilter/serviceFilter7.svg';
import WrapInstallation from '../../assets/serviceFilter/serviceFilter8.svg';
import defaultCompany from '../../assets/defaultCompany.png';
import { toast } from 'react-toastify';
import CustomToast from '../phase1a/common/CustomToast.component';

interface HeaderProps {
  claimStatus?: any;
}
const Header: FC<HeaderProps> = ({ claimStatus }) => {
  const updateStore = UpdateStore();
  const nav = useNavigate();
  const userJSON = localStorage.getItem('user');
  const signIn = userJSON ? JSON.parse(userJSON) : '';
  console.log('🚀 ~ signIn:', signIn);
  const [count, setCount] = useState<any>(0);
  const [notifications, setNotification] = useState<any>([]);
  const [openPopover, setOpenPopover] = useState<HTMLDivElement | null>(null);
  const [profilePopupAnchor, setProfilePopupAnchor] = useState<HTMLDivElement | null>(null);
  // const [user, setUser] = useState<any>();
  const [allowNotification, setAllowNotification] = useState(signIn);
  // const pathname = window.location.pathname;
  const states = ['Canada', 'United States'];
  const navigate = useNavigate();
  const [search, setSearch] = useState<any>(undefined);
  const [searchTypo, setSearchTypo] = useState<any>({});
  const [companyId, setCompanyId] = useState<string>('');
  const [allAddresses, setAllAddresses] = useState<IAddress[]>([]);
  const [type, setType] = useState<string>('');
  const [activeIndex, setActiveIndex] = useState<number | null>(0);
  const [selectedAddress, setSelectedAddress] = useState<any>(null);
  const [category, setCategory] = useState<any>([]);
  const [companiesNames, setCompaniesNames] = useState<any>([]);
  const [near, setNear] = useState<string>('');
  const [currentLocation, setCurrentLocation] = useState<any>();
  const [coordinates, setCoordinates] = useState<{ lan?: number; lat?: number }>({
    lan: undefined,
    lat: undefined,
  });
  const [myLocation, setMyLocation] = useState<any>({
    city: '',
    state: '',
    country: '',
  });
  const isMobile = useMediaQuery('(max-width:768px)');
  const [mobileOpen, setMobileOpen] = useState(false);

  const toggleMobileMenu = (): void => {
    setMobileOpen(!mobileOpen);
  };

  console.log(companyId, setActiveIndex);
  const getNotificationCount = (): void => {
    request('get', '/notifications/unreadCount')
      .then((res: any) => {
        setCount(res?.unread);
      })
      .catch((err) => {
        console.log('err', err);
      });
  };
  const getNotification = (): void => {
    request('get', '/notifications/')
      .then((res: any) => {
        setNotification(res?.notifications);
      })
      .catch((err) => {
        console.log('err', err);
      });
  };
  // const getUserData = (): void => {
  //   request('get', '/auth/userDetails')
  //     .then((res: any) => {
  //       setUser(res?.user);
  //     })
  //     .catch((err) => {
  //       console.log('err', err);
  //     });
  // };
  const handleAvatarClick = (event: React.MouseEvent<HTMLDivElement>): void => {
    setProfilePopupAnchor(profilePopupAnchor ? null : event.currentTarget);
  };
  const handleNotificationClick = (event: React.MouseEvent<HTMLDivElement>): void => {
    setOpenPopover(profilePopupAnchor ? null : event.currentTarget);
    request('patch', '/notifications/:id')
      .then((res) => {
        console.log('err', res);
        getNotificationCount();
      })
      .catch((err) => {
        console.log('err', err);
      });
  };

  const uniqueServices = [
    { name: 'Sign Fabrication', logo: 'fabricatorsicon' },
    { name: 'Sign Installation', logo: 'installerIcon' },
    { name: 'Print Production', logo: 'printersIcon' },
    { name: 'Print Installation', logo: 'printInstallersIcon' },
    { name: 'Wrap Installation', logo: 'wrapInstallersIcon' },
    { name: 'Permits', logo: 'permitsIcon' },
    { name: 'Surveys', logo: 'surveyIcon' },
    { name: 'Service', logo: 'serviceIcon' },
  ];

  const typographyItems = [
    { name: 'local', text: 'Local Companies', searchTerm: '' },
    { name: 'national', text: 'National Companies', searchTerm: 'Installation' },
    // { name: 'wholesaler', text: 'Wholesale Companies', searchTerm: 'Fabrication' },
    { name: 'supplier', text: 'Suppliers', searchTerm: 'Service' },
    { name: 'distributor', text: 'Distributors', searchTerm: 'Surveys/Permits' },
  ];

  const handleCategoryChange = (event: any, value: any): void => {
    setNear(value ?? '');
  };

  const handleGetAddress = (value: string): void => {
    request<IAddressResponse>('get', `/address/search?text=${value}`)
      .then((response) => {
        const filteredResults = response?.results?.filter((result) => result?.contexts?.place);
        setAllAddresses(filteredResults);
      })
      .catch((error) => {
        console.log(error);
      });
  };

  const handleSearchName = (event: any, value: any): void => {
    console.log('valuuue', value?.id);
    setCompanyId(value?.id ?? '');
    setSearch(value ?? '');
  };

  const handleInputSearch = (event: React.SyntheticEvent, newInputValue: string): void => {
    console.log('valuuue2', newInputValue);

    setSearchTypo({ name: newInputValue, logo: '' });
    if (newInputValue === '') {
      setSearch(undefined);
    }
  };

  const handleStateChange = (event: any, value: any): void => {
    setNear(value ?? '');
  };

  const getCompaniesName = (): void => {
    if (type?.length) {
      request('get', `companies/searchAutoCompleteList/${type}`)
        .then((response: any) => {
          console.log(response, 'companiesNames res');
          const { uniqueNames } = response;
          if (type === 'local') {
            setCompaniesNames([...uniqueServices, ...uniqueNames]);
          } else {
            setCompaniesNames(uniqueNames);
          }
        })
        .catch((error) => {
          console.log(error, 'companiesNames err');

          console.log(error);
        });
    }
  };

  const handleNearChange = (event: any, value: string | null): void => {
    setNear(value ?? '');
    if (value) {
      handleGetAddress(value);
    }
  };

  const formatAddressOption = (address: IAddress): string => {
    // const postalCode = address.contexts?.postcode || '';
    const region = address.contexts?.region || '';
    // const country = address.contexts?.country || '';
    const place = address.contexts?.place || '';
    // const city = address.contexts?.city || '';
    // const street = address.contexts?.street || '';
    // const district = address.contexts?.district || '';
    // const locality = address.contexts?.locality || '';
    /* eslint-disable @typescript-eslint/restrict-template-expressions */
    return place && `${place}, ${region}`;
    // return `${place}, ${region}`;
  };
  const handleAddressSelect = (address: any): void => {
    setSelectedAddress(address);
  };

  const handleSearch = (): void => {
    // Perform search logic here let locationParam = here;
    // if (!search) {
    //   toast.error('Find field is required.');
    //   return;
    // }
    if (!selectedAddress && !search?.id) {
      if (type === 'local' && currentLocation === undefined) {
        // toast.error('Must select a nearby location or enable your current location');
        toast(
          <CustomToast
            message={'Must select a nearby location or enable your current location'}
            type="error"
          />,
        );

        if ('geolocation' in navigator) {
          navigator.geolocation.getCurrentPosition(function (position) {
            console.log(position, 'kasgffkajfgf');
            setCurrentLocation(position);
          });
        } else {
          console.log('Geolocation is not available in your browser.');
        }
        return;
      }

      if (type === 'supplier' || type === 'distributor') {
        if (!searchTypo?.name?.length && !near) {
          // toast.error('Category is required');
          toast(<CustomToast message={'Category is required'} type="error" />);
          return;
        }
      }
    }

    let locationParam;

    if (coordinates.lan !== undefined && coordinates.lat !== undefined) {
      locationParam = `${coordinates.lan},${coordinates.lat}`;
    }
    const formatSearchString = (str: string): string => {
      if (!str) return ''; // Handle cases where str might be null or undefined
      // Trim, lowercase, and replace spaces with hyphens
      const formattedStr = str.toLowerCase().replace(/\s+/g, '-').trim();
      switch (formattedStr) {
        case 'fabricators':
          return 'sign-fabricators';
        case 'installers':
          return 'sign-installers';
        case 'full-service-companies': // Handle the formatted string directly
          return 'full-service';
        default:
          return formattedStr;
      }
    };
    /* eslint-disable @typescript-eslint/restrict-template-expressions */
    // navigate(
    //   `/companylisting?type=${type}&location=[${locationParam ?? ''}]&search=${search}&additional=${
    //     near?.split(',')[0] ?? ''
    //   }`,
    // navigate(`/companylisting${search ? `/${search}` : ''}${near ? `/${near}` : ''}`, {
    const paramType =
      type === 'local'
        ? 'local-sign-company'
        : type === 'national'
        ? 'national-sign-company'
        : type === 'supplier'
        ? 'supplier'
        : 'distributor';
    if (search?.id) {
      navigate(`/${paramType}/${formatSearchString(search?.name)}`, {
        state: {
          searchID: search?.id,
          type,
          search,
          coords: `[${locationParam ?? ''}]`,
          near:
            near !== ''
              ? near
              : type === 'local' && myLocation?.city
              ? `${myLocation?.city}, ${myLocation?.state}`
              : '',
          searchTypo,
          myCurrLoc: myLocation,
        },
      });
      return;
    }
    let url = '/search';
    const formattedSearch =
      search || searchTypo ? formatSearchString(search?.name ?? searchTypo.name) : '';
    // let find = '';
    const country =
      selectedAddress?.contexts?.country === 'United States'
        ? 'us'
        : selectedAddress?.contexts?.country === 'Canada'
        ? 'canada'
        : '';

    if (type === 'local') {
      const searchName = search?.name?.toLowerCase();
      const searchTypoName = searchTypo?.name?.toLowerCase();

      const serviceNames = [
        'service',
        'fabricators',
        'installers',
        'surveys',
        'full service companies',
      ];
      const find =
        serviceNames.includes(searchName) || serviceNames.includes(searchTypoName)
          ? 'service'
          : 'company';
      if (selectedAddress && formattedSearch) {
        url += `?${find}=${encodeURIComponent(
          formattedSearch,
        )}&location=${country}&city=${encodeURIComponent(
          formatSearchString(selectedAddress?.contexts?.place),
        )}&state=${encodeURIComponent(formatSearchString(selectedAddress?.contexts?.region))}`;
      } else if (!formattedSearch && (selectedAddress || locationParam)) {
        url += selectedAddress
          ? `?location=${country}&city=${encodeURIComponent(
              formatSearchString(selectedAddress?.contexts?.place),
            )}&state=${encodeURIComponent(formatSearchString(selectedAddress?.contexts?.region))}`
          : `?location=${encodeURIComponent(
              formatSearchString(myLocation?.country),
            )}&city=${encodeURIComponent(
              formatSearchString(myLocation?.city),
            )}&state=${encodeURIComponent(formatSearchString(myLocation?.state))}
              `;
      } else if (formattedSearch) {
        url += `?${find}=${encodeURIComponent(formattedSearch)}&location=${encodeURIComponent(
          formatSearchString(myLocation?.country),
        )}&city=${encodeURIComponent(
          formatSearchString(myLocation?.city),
        )}&state=${encodeURIComponent(formatSearchString(myLocation?.state))}
              `;
      }
    }
    if (type === 'national') {
      const formattedNear = near ? formatSearchString(near) : '';
      const locations =
        near === 'Canada' ? 'canada' : near === 'United States' ? 'us' : formattedNear;
      if (near && formattedSearch) {
        url += `?company=${encodeURIComponent(formattedSearch)}&location=${encodeURIComponent(
          locations,
        )}`;
      } else if (!near && !formattedSearch) {
        url += '?national-sign-companies';
      } else if (near) {
        url += `?location=${encodeURIComponent(locations)}`;
      } else if (formattedSearch) {
        url += `?company=${encodeURIComponent(formattedSearch)}`;
      }
    }
    if (type === 'supplier' || type === 'distributor') {
      const formattedNear = near ? formatSearchString(near) : '';
      if (near && formattedSearch) {
        url += `?company=${encodeURIComponent(formattedSearch)}&category=${encodeURIComponent(
          formattedNear,
        )}`;
      } else if (near) {
        url += `?category=${encodeURIComponent(formattedNear)}`;
      } else if (formattedSearch) {
        url += `?company=${encodeURIComponent(formattedSearch)}`;
      }
    }
    console.log(search, '8888888');
    navigate(url, {
      state: {
        nearData: selectedAddress
          ? `${selectedAddress?.contexts?.place}, ${selectedAddress?.contexts?.region}`
          : type === 'local'
          ? `${myLocation?.city}, ${myLocation?.state}`
          : near !== ''
          ? near
          : '',
        myCurrLoc: myLocation,
        companyType: type,
        locationsParam: `[${locationParam ?? ''}]`,
        searchData: search ?? searchTypo,
        searchText: searchTypo,
        addSelected: selectedAddress,
      },
    });
  };

  const filterOptions = createFilterOptions({
    limit: 8,
    stringify: (option: any) => option?.name,
  });
  console.log(filterOptions);

  const getCategories = (): void => {
    request('get', `/companies/categories`)
      .then((res: any) => {
        const categories: string[] = res?.categories.reduce((acc: string[], obj: any) => {
          acc.push(obj?.category);

          obj.subCategories.forEach((subCategory: string) => {
            acc.push(subCategory);
          });

          return acc;
        }, []);
        setCategory(categories);
      })
      .catch((err) => {
        console.log('err', err);
      });
  };

  useEffect(() => {
    if (selectedAddress?.coordinates?.length === 2) {
      const [lan, lat] = selectedAddress.coordinates;
      setCoordinates({ lan, lat });
    } else {
      setCoordinates({ lan: undefined, lat: undefined });
    }
    // setNear(
    //   selectedAddress?.contexts?.city ||
    //     selectedAddress?.contexts?.region ||
    //     selectedAddress?.contexts?.street,
    // );
  }, [selectedAddress?.coordinates]);

  useEffect(() => {
    getCategories();
  }, []);

  useEffect(() => {
    if (type?.length) {
      getCompaniesName();
      console.log('companiesNames type', type);
    }
  }, [type?.length]);

  useEffect(() => {
    if (activeIndex !== null) {
      setType(typographyItems[activeIndex].name);
    }
  }, [activeIndex]);

  useEffect(() => {
    if (currentLocation?.coords?.latitude) {
      request('post', '/address/currentLocation', {
        long: currentLocation?.coords?.longitude,
        lat: currentLocation?.coords?.latitude,
      })
        .then((res: any) => {
          const locationParts = res?.location.split(',').map((part: any) => part.trim());
          setMyLocation({
            city: locationParts[0] || '',
            state: locationParts[1] || '',
            country: locationParts[2] || '',
          });
          setCoordinates({
            lan: currentLocation?.coords?.longitude,
            lat: currentLocation?.coords?.latitude,
          });

          console.log('currentLocation', res);
        })
        .catch((err) => {
          console.log('err', err);
        });
      // setCoordinates({
      //   lan: currentLocation?.coords?.longitude,
      //   lat: currentLocation?.coords?.latitude,
      // });
    }
    // else {
    //   setCoordinates({ lan: undefined, lat: undefined });
    // }
  }, [currentLocation?.coords?.latitude]);

  useEffect(() => {
    if ('geolocation' in navigator) {
      navigator.permissions
        .query({ name: 'geolocation' })
        .then(function (result) {
          if (result.state === 'granted') {
            navigator.geolocation.getCurrentPosition(function (position) {
              console.log(position, 'kasgffkajfgf');
              setCurrentLocation(position);
            });
          } else {
            // Permission is 'prompt' or 'denied'
            console.log('Geolocation permission is not granted');
          }
        })
        .catch(function (error) {
          console.error('Error querying geolocation permissions:', error);
        });
    }
  }, []);

  useEffect(() => {
    getNotificationCount();
    getNotification();
  }, []);
  // useEffect(() => {
  //   getUserData();
  // }, []);
  console.log(count);
  useEffect(() => {
    const socket = io(mediaUrl); // Replace with your server URL

    // Listen for the specific notification event
    const userId = signIn?._id; // Replace with the actual user ID
    /* eslint-disable @typescript-eslint/restrict-template-expressions */
    const eventName = `notification_${userId}`;
    socket.on(eventName, (notification) => {
      getNotification();
      getNotificationCount();
      console.log(notification);
    });

    // Cleanup on unmount
    return () => {
      socket.off(eventName);
      socket.close();
    };
  }, []);
  useEffect(() => {
    const handleStorageEvent = (): void => {
      const userJSON = localStorage.getItem('user');
      if (userJSON) {
        setAllowNotification(JSON.parse(userJSON));
      } else {
        setAllowNotification(signIn);
      }
    };

    // Add event listener for 'storage' event
    window.addEventListener('notify', handleStorageEvent);

    // Cleanup the event listener on component unmount
    return () => {
      window.removeEventListener('notify', handleStorageEvent);
    };
  }, []);
  const checkUserCompany = (): any => {
    if (
      signIn?.type === 'member' &&
      (signIn?.company?.companyInfo?.companyType === 'distributor' ||
        signIn?.company?.companyInfo?.companyType === 'supplier')
    ) {
      return false;
    } else {
      return true;
    }
  };

  // Cleanup on unmountreturn () => { newSocket.close(); }; }, [url]);
  return (
    <Box
      width="100%"
      display="flex"
      height={'80px'}
      justifyContent="center"
      alignItems="center"
      alignSelf="center"
      padding="1.5% 3%"
      borderBottom={'1px solid #E8E8E8'}
    >
      <Box width="100%" display="flex" justifyContent="space-between" alignItems="center">
        <Box display={'flex'} gap={'20px'}>
          <Box width="145px">
            <Link to="/">
              <img
                src={logo}
                alt="Logo"
                style={{ width: '100%', cursor: claimStatus ? 'default' : 'pointer' }}
              />
            </Link>
          </Box>
          {/* {pathname === '/localpageowner/SettingPagesetup' ? (
            <Button
              sx={{
                width: '103px',
                height: '33px',
                borderRadius: '50px',
                bgcolor: '#00BD57',
                fontSize: '14px',
                lineHeight: '16.71px',
                textAlign: 'center',
                fontWeight: '700',
                textTransform: 'unset',
                fontFamily: 'SF UI Display',
                color: 'white',
                '&:hover': {
                  bgcolor: '#00BD57',
                },
              }}
            >
              Settings
            </Button>
          ) : (
            ''
          )} */}
        </Box>

        {!isMobile && (
          <Box
            width={'60%'}
            display={'flex'}
            flexDirection={'column'}
            alignItems={'center'}
            // gap={'30px'}
          >
            {/* <Box
      width={'90%'}
      display={'flex'}
      justifyContent={'space-between'}
      alignItems={'center'}
      gap={'32px'}
      sx={{
        '@media (max-width: 768px)': {
          width: '65%',
        },
        '@media (max-width: 500px)': {
          width: '95%',
          overflowX: 'scroll',
          '&::-webkit-scrollbar': {
            display: 'none',
          },
          '-ms-overflow-style': 'none',
          'scrollbar-width': 'none',
        },
      }}
    >
      {typographyItems.map((item, index) => (
        <Box
          key={index}
          onClick={(): void => {
            handleClick(index);
          }}
          sx={{ cursor: 'pointer', display: 'inline-block' }}
        >
          <Typography
            fontFamily="SF UI Display"
            fontSize={'18px'}
            fontWeight={activeIndex === index ? '500' : '400'}
            lineHeight={'21px'}
            color={activeIndex === index ? '#00BD57' : '#000000'}
            whiteSpace={'nowrap'}
            padding={'5px 0'}
            borderBottom={
              activeIndex === index ? '3px solid #00BD57' : '3px solid transparent'
            }
            sx={{
              '@media (max-width: 1200px)': {
                fontSize: '16px',
              },
              '@media (max-width: 900px)': {
                fontSize: '14px',
              },
            }}
          >
            {isMobile ? item?.text.split(' ')[0] : item?.text}
          </Typography>
        </Box>
      ))}
    </Box> */}

            <Box display={'flex'} width={'100%'} height={'50px'}>
              <Box
                display={'flex'}
                width={'100%'}
                borderBottom={'1px solid #D7D8DD'}
                borderTop={'1px solid #D7D8DD'}
                borderLeft={'1px solid #D7D8DD'}
                sx={{
                  borderTopLeftRadius: '5px',
                  borderBottomLeftRadius: '5px',
                }}
              >
                <Box
                  display={'flex'}
                  alignItems={'center'}
                  width={'50%'}
                  borderRight={'1px solid #D7D8DD'}
                >
                  <Typography
                    fontSize={'16px'}
                    fontWeight={'700'}
                    fontFamily={'SFProDisplay'}
                    color={'#00BD57'}
                    padding={'0 0 0 3%'}
                    sx={{
                      '@media (max-width: 900px)': {
                        fontSize: '14px',
                      },
                    }}
                  >
                    Find
                  </Typography>
                  <Autocomplete
                    fullWidth
                    freeSolo
                    disableClearable
                    options={companiesNames}
                    value={search ?? searchTypo}
                    onChange={handleSearchName}
                    onInputChange={handleInputSearch}
                    getOptionLabel={(option: any) => option?.name || ''}
                    filterOptions={(options, state) => {
                      if (state?.inputValue === '' && type !== 'local') {
                        return []; // Return an empty array if the input is empty
                      }
                      const inputValue = state.inputValue.toLowerCase();
                      const startsWithMatch = options.filter((option) =>
                        option.name?.toLowerCase().startsWith(inputValue),
                      );

                      // Filter for options containing the input value but not starting with it
                      const containsMatch = options.filter(
                        (option) =>
                          option.name?.toLowerCase().includes(inputValue) &&
                          !option.name?.toLowerCase().startsWith(inputValue),
                      );

                      // Combine the filtered results, prioritize starting matches first
                      const filteredOptions = [...startsWithMatch, ...containsMatch];

                      return filteredOptions.slice(0, 8);
                      // return options
                      //   .filter((option) => option.name?.toLowerCase().startsWith(inputValue))
                      //   .slice(0, 8);
                      // const filtered = filterOptions(options, state);
                      // return filtered?.slice(0, 8); // Ensures the maximum number of options is limited to 5
                    }}
                    renderOption={(props: any, option: any) => (
                      <Box
                        component="li"
                        {...props}
                        sx={{ display: 'flex', alignItems: 'center', gap: '15px' }}
                      >
                        <img
                          style={{ width: '40px', height: '40px', objectFit: 'cover' }}
                          src={
                            option?.logo
                              ? option?.logo === 'fabricatorsicon'
                                ? ServiceIcon2
                                : option?.logo === 'installerIcon'
                                ? ServiceIcon3
                                : option?.logo === 'surveyIcon'
                                ? ServiceIcon5
                                : option?.logo === 'serviceIcon'
                                ? ServiceIcon4
                                : option?.logo === 'fullserviceicon'
                                ? ServiceIcon1
                                : option?.logo === 'printersIcon'
                                ? printProduction
                                : option?.logo === 'printInstallersIcon'
                                ? PrintInstallation
                                : option?.logo === 'wrapInstallersIcon'
                                ? WrapInstallation
                                : option?.logo === 'permitsIcon'
                                ? ServiceIcon1
                                : `${mediaUrl}/${option?.logo}`
                              : defaultCompany
                          }
                        />
                        {option?.name}
                      </Box>
                    )}
                    renderInput={(params) => (
                      <TextField
                        {...params}
                        placeholder={
                          activeIndex !== 0
                            ? 'Enter Name [Optional]'
                            : 'Installers, Fabricators, Printers, Service....'
                        }
                        sx={{
                          width: '100%',
                          fontFamily: 'SF UI Display',
                          fontSize: '15px !important',
                          fontWeight: '400',
                          lineHeight: '24px',
                          color: '#667085',
                          '& .MuiAutocomplete-input': {
                            padding: '7.5px 4px 7.5px 5px !important',
                          },
                          '.MuiOutlinedInput-input': {
                            fontSize: '15px ',
                          },
                          '& .MuiOutlinedInput-root': {
                            '& fieldset': {
                              border: 'none',
                              outline: 'none',
                            },
                          },
                          '@media (max-width: 900px)': {
                            fontSize: '14px',
                          },
                        }}
                        type="search"
                      />
                    )}
                  />
                  {/* <TextField
            placeholder={
              activeIndex !== 0
                ? 'Enter Name [Optional]'
                : 'Installers, Fabricators, Service, Permitt...'
            }
            value={search}
            onChange={(e): void => {
              setSearch(e.target.value);
            }}
            sx={{
              width: '100%',
              fontSize: '16px',
              fontWeight: '400',
              lineHeight: '24px',
              color: '#667085',
              '.MuiOutlinedInput-input': {
                padding: '16.5px 10px 16.5px 5px',
              },
              '& .MuiOutlinedInput-root': {
                '& fieldset': {
                  border: 'none',
                  outline: 'none',
                },
              },
              '@media (max-width: 900px)': {
                fontSize: '14px',
              },
            }}
            type="search"
          /> */}
                </Box>
                <Box display={'flex'} alignItems={'center'} width={'50%'}>
                  <Typography
                    fontSize={'16px'}
                    fontWeight={'700'}
                    fontFamily={'SFProDisplay'}
                    color={'#00BD57'}
                    padding={'0 0 0 3%'}
                    sx={{
                      '@media (max-width: 900px)': {
                        fontSize: '14px',
                      },
                    }}
                  >
                    {activeIndex === 1
                      ? 'Location'
                      : activeIndex === 2 || activeIndex === 3
                      ? 'Category'
                      : 'Near'}
                  </Typography>
                  {activeIndex === 1 ? (
                    <Autocomplete
                      fullWidth
                      options={states}
                      getOptionLabel={(option: string) => option}
                      value={near}
                      onInputChange={handleNearChange}
                      onChange={handleStateChange}
                      renderInput={(params) => (
                        <TextField
                          {...params}
                          placeholder="North America"
                          variant="outlined"
                          sx={{
                            width: '100%',
                            fontSize: '16px',
                            fontWeight: '400',
                            lineHeight: '24px',
                            color: '#667085',
                            borderRadius: '8px',
                            '@media (max-width: 900px)': {
                              fontSize: '14px',
                            },
                            '.MuiOutlinedInput-input': {
                              padding: '10px 14px',
                            },
                            '& .MuiOutlinedInput-root': {
                              '& fieldset': {
                                border: 'none',
                                outline: 'none',
                              },
                            },
                            '& .MuiAutocomplete-input': {
                              padding: '7.5px 4px 7.5px 5px !important',
                            },
                          }}
                        />
                      )}
                    />
                  ) : activeIndex === 2 || activeIndex === 3 ? (
                    <Autocomplete
                      fullWidth
                      // options={category}
                      // getOptionLabel={(option: any) => option?.category ?? near}
                      options={category}
                      // getOptionLabel={(option: any) => (option.subCategory || option.category) ?? near}
                      value={near}
                      // name="category"
                      onChange={handleCategoryChange}
                      renderInput={(params) => (
                        <TextField
                          {...params}
                          placeholder="Software, Equipment, Displays..."
                          variant="outlined"
                          sx={{
                            width: '100%',
                            fontSize: '16px',
                            fontWeight: '400',
                            lineHeight: '24px',
                            color: '#667085',
                            borderRadius: '8px',
                            '@media (max-width: 900px)': {
                              fontSize: '14px',
                            },
                            '.MuiOutlinedInput-input': {
                              padding: '10px 14px',
                            },
                            '& .MuiOutlinedInput-root': {
                              '& fieldset': {
                                border: 'none',
                                outline: 'none',
                              },
                            },
                            '& .MuiAutocomplete-input': {
                              padding: '7.5px 4px 7.5px 5px !important',
                            },
                          }}
                        />
                      )}
                    />
                  ) : (
                    <Autocomplete
                      fullWidth
                      disableClearable
                      freeSolo
                      // options={allAddresses.map((address) => address?.contexts?.region)}
                      options={allAddresses.map(formatAddressOption)}
                      value={near}
                      onInputChange={handleNearChange}
                      onChange={(event, value): void => {
                        handleAddressSelect(
                          allAddresses.find((address) => formatAddressOption(address) === value) ??
                            null,
                        );
                      }}
                      renderInput={(params) => (
                        <TextField
                          {...params}
                          InputProps={{
                            ...params.InputProps,
                            endAdornment: null, // Ensure no end adornment is displayed
                          }}
                          placeholder={
                            activeIndex === 1
                              ? 'North America'
                              : activeIndex === 3
                              ? 'Software, Equipment, Displays...'
                              : 'City & State or Province'
                          }
                          sx={{
                            width: '100%',
                            fontSize: '16px',
                            fontWeight: '400',
                            lineHeight: '24px',
                            color: '#667085',
                            '@media (max-width: 900px)': {
                              fontSize: '14px',
                            },
                            '.MuiOutlinedInput-input': {
                              padding: '16.5px 10px 16.5px 5px',
                            },
                            '& .MuiOutlinedInput-root': {
                              '& fieldset': {
                                border: 'none',
                                outline: 'none',
                              },
                            },
                            '& .MuiAutocomplete-input': {
                              padding: '7.5px 4px 7.5px 5px !important',
                            },
                          }}
                          type="search"
                        />
                      )}
                    />
                  )}
                </Box>{' '}
              </Box>
              <Button
                sx={{
                  width: '14%',
                  paddingTop: '5px',
                  paddingLeft: '5px',
                  border: '1px solid #000000',
                  fontFamily: 'SF UI Display',
                  fontSize: '16px',
                  fontWeight: '600',
                  lineHeight: '19.09px',
                  textAlign: 'center',
                  color: '#ffffff',
                  backgroundColor: 'transparent', // White background for top and left padding area
                  borderTopLeftRadius: '0',
                  borderBottomLeftRadius: '0',
                  textTransform: 'capitalize',
                  position: 'relative',
                  overflow: 'visible', // Allow overflow of pseudo-element
                  '&::before': {
                    content: '""',
                    position: 'absolute',
                    top: '3px', // Align with the padding
                    left: '3px', // Align with the padding
                    right: '-3px', // Extend 5px to the right for overflow
                    bottom: '-3px', // Extend 5px to the bottom for overflow
                    backgroundColor: '#00BD57', // Green color for overflow
                    zIndex: -1,
                    borderRadius: 'inherit',
                  },
                  '&:hover::before': {
                    backgroundColor: '#00BD57', // Ensure hover behavior matches overflow
                  },
                  '@media (max-width: 900px)': {
                    fontWeight: '500',
                  },
                }}
                onClick={handleSearch}
              >
                Search
              </Button>
            </Box>
            <Box
              display={'none'}
              width={'100%'}
              flexDirection={'column'}
              gap={'15px'}
              alignItems={'center'}
            >
              <Box
                width={'300px'}
                display={'flex'}
                alignItems={'center'}
                border={'1px solid #D7D8DD'}
                borderRadius={'5px'}
                sx={{
                  '@media (max-width: 768px)': {
                    width: '70%',
                  },
                  '@media (max-width: 500px)': {
                    width: '90%',
                  },
                }}
              >
                <Typography
                  fontSize={'16px'}
                  fontWeight={'700'}
                  fontFamily={'SFProDisplay'}
                  color={'#00BD57'}
                  padding={'18px 0 18px 3%'}
                  sx={{
                    '@media (max-width: 900px)': {
                      fontSize: '14px',
                    },
                  }}
                >
                  Find
                </Typography>
                <Autocomplete
                  fullWidth
                  freeSolo
                  disableClearable
                  options={companiesNames}
                  value={search ?? searchTypo}
                  onChange={handleSearchName}
                  onInputChange={handleInputSearch}
                  getOptionLabel={(option: any) => option?.name || ''}
                  filterOptions={(options, state) => {
                    if (state?.inputValue === '' && type !== 'local') {
                      return []; // Return an empty array if the input is empty
                    }
                    const inputValue = state.inputValue.toLowerCase();
                    const startsWithMatch = options.filter((option) =>
                      option.name?.toLowerCase().startsWith(inputValue),
                    );

                    // Filter for options containing the input value but not starting with it
                    const containsMatch = options.filter(
                      (option) =>
                        option.name?.toLowerCase().includes(inputValue) &&
                        !option.name?.toLowerCase().startsWith(inputValue),
                    );

                    // Combine the filtered results, prioritize starting matches first
                    const filteredOptions = [...startsWithMatch, ...containsMatch];

                    return filteredOptions.slice(0, 8);
                    // const filtered = filterOptions(options, state);
                    // return filtered?.slice(0, 8); // Ensures the maximum number of options is limited to 5
                  }}
                  renderOption={(props: any, option: any) => (
                    <Box
                      component="li"
                      {...props}
                      sx={{ display: 'flex', alignItems: 'center', gap: '15px' }}
                    >
                      <img
                        style={{ width: '40px', height: '40px', objectFit: 'cover' }}
                        src={
                          option?.logo
                            ? option?.logo === 'fabricatorsicon'
                              ? ServiceIcon2
                              : option?.logo === 'installerIcon'
                              ? ServiceIcon3
                              : option?.logo === 'surveyIcon'
                              ? ServiceIcon5
                              : option?.logo === 'serviceIcon'
                              ? ServiceIcon4
                              : option?.logo === 'fullserviceicon'
                              ? ServiceIcon1
                              : option?.logo === 'printersIcon'
                              ? printProduction
                              : option?.logo === 'printInstallersIcon'
                              ? PrintInstallation
                              : option?.logo === 'wrapInstallersIcon'
                              ? WrapInstallation
                              : option?.logo === 'permitsIcon'
                              ? ServiceIcon1
                              : // eslint-disable-next-line @typescript-eslint/restrict-template-expressions
                                `${mediaUrl}/${option?.logo}`
                            : defaultCompany
                        }
                      />
                      {option?.name}
                    </Box>
                  )}
                  renderInput={(params) => (
                    <TextField
                      {...params}
                      placeholder={
                        activeIndex !== 0
                          ? 'Enter Name [Optional]'
                          : 'Installers, Fabricators, Service, Surveys, Full Service...'
                      }
                      sx={{
                        width: '100%',
                        fontSize: '15px !important',
                        fontWeight: '400',
                        fontFamily: 'SF UI Display',
                        lineHeight: '24px',
                        color: '#667085',
                        '.MuiOutlinedInput-input': {
                          padding: '0px 10px 0px 0px !important',
                          fontSize: '15px ',
                        },
                        '& .MuiOutlinedInput-root': {
                          '& fieldset': {
                            border: 'none',
                            outline: 'none',
                          },
                        },
                        '@media (max-width: 900px)': {
                          fontSize: '14px',
                        },
                        '& .MuiAutocomplete-input': {
                          padding: '7.5px 4px 7.5px 5px !important',
                        },
                      }}
                      type="search"
                    />
                  )}
                />
              </Box>
              <Box
                display={'flex'}
                alignItems={'center'}
                width={'300px'}
                border={'1px solid #D7D8DD'}
                borderRadius={'5px'}
                sx={{
                  '@media (max-width: 768px)': {
                    width: '70%',
                  },
                  '@media (max-width: 500px)': {
                    width: '90%',
                  },
                }}
              >
                <Typography
                  fontSize={'16px'}
                  fontWeight={'700'}
                  fontFamily={'SFProDisplay'}
                  color={'#00BD57'}
                  padding={'11.5px 0 11.5px 3%'}
                  sx={{
                    '@media (max-width: 900px)': {
                      fontSize: '14px',
                    },
                  }}
                >
                  {activeIndex === 1
                    ? 'Location'
                    : activeIndex === 2 || activeIndex === 3
                    ? 'Category'
                    : 'Near'}
                </Typography>
                {activeIndex === 1 ? (
                  <Autocomplete
                    fullWidth
                    options={states}
                    getOptionLabel={(option: string) => option}
                    value={near}
                    onInputChange={handleNearChange}
                    onChange={handleStateChange}
                    renderInput={(params) => (
                      <TextField
                        {...params}
                        placeholder="North America"
                        variant="outlined"
                        sx={{
                          width: '100%',
                          fontSize: '16px',
                          fontWeight: '400',
                          lineHeight: '24px',
                          color: '#667085',
                          borderRadius: '8px',
                          '@media (max-width: 900px)': {
                            fontSize: '14px',
                          },
                          '.MuiOutlinedInput-input': {
                            padding: '10px 14px',
                          },
                          '& .MuiOutlinedInput-root': {
                            '& fieldset': {
                              border: 'none',
                              outline: 'none',
                            },
                          },
                          '& .MuiAutocomplete-input': {
                            padding: '7.5px 4px 7.5px 5px !important',
                          },
                        }}
                      />
                    )}
                  />
                ) : activeIndex === 2 || activeIndex === 3 ? (
                  <Autocomplete
                    fullWidth
                    // options={category}
                    // getOptionLabel={(option: any) => option?.category ?? near}
                    options={category}
                    // getOptionLabel={(option: any) => (option.subCategory || option.category) ?? near}
                    value={near}
                    // name="category"
                    onChange={handleCategoryChange}
                    renderInput={(params) => (
                      <TextField
                        {...params}
                        placeholder="Software, Equipment..."
                        variant="outlined"
                        sx={{
                          width: '100%',
                          fontSize: '16px',
                          fontWeight: '400',
                          lineHeight: '24px',
                          color: '#667085',
                          borderRadius: '8px',
                          '@media (max-width: 900px)': {
                            fontSize: '14px',
                          },
                          '.MuiOutlinedInput-input': {
                            padding: '10px 14px',
                          },
                          '& .MuiOutlinedInput-root': {
                            '& fieldset': {
                              border: 'none',
                              outline: 'none',
                            },
                          },
                          '& .MuiAutocomplete-input': {
                            padding: '7.5px 4px 7.5px 5px !important',
                          },
                        }}
                      />
                    )}
                  />
                ) : (
                  <Autocomplete
                    fullWidth
                    disableClearable
                    freeSolo
                    // options={allAddresses.map((address) => address?.contexts?.region)}
                    options={allAddresses.map(formatAddressOption)}
                    value={near}
                    onInputChange={handleNearChange}
                    onChange={(event, value): void => {
                      handleAddressSelect(
                        allAddresses.find((address) => formatAddressOption(address) === value) ??
                          null,
                      );
                    }}
                    renderInput={(params) => (
                      <TextField
                        {...params}
                        InputProps={{
                          ...params.InputProps,
                          endAdornment: null, // Ensure no end adornment is displayed
                        }}
                        placeholder={
                          activeIndex === 1
                            ? 'North America'
                            : activeIndex === 3
                            ? 'Software, Equipment...'
                            : 'City, State or Province'
                        }
                        sx={{
                          width: '100%',
                          fontSize: '16px',
                          fontWeight: '400',
                          lineHeight: '24px',
                          color: '#667085',
                          '@media (max-width: 900px)': {
                            fontSize: '14px',
                          },
                          '.MuiOutlinedInput-input': {
                            padding: '16.5px 5px',
                          },
                          '& .MuiOutlinedInput-root': {
                            '& fieldset': {
                              border: 'none',
                              outline: 'none',
                            },
                          },
                          '& .MuiAutocomplete-input': {
                            padding: '7.5px 4px 7.5px 5px !important',
                          },
                        }}
                        type="search"
                      />
                    )}
                  />
                )}
              </Box>{' '}
              <Button
                sx={{
                  width: '300px',
                  bgcolor: '#00BD57',
                  borderRadius: '5px',
                  fontFamily: 'SF UI Display',
                  fontSize: '16px',
                  fontWeight: '600',
                  lineheight: '19.09px',
                  textAlign: 'center',
                  color: '#ffffff',
                  textTransform: 'capitalize',
                  '&:hover': {
                    bgcolor: '#00BD57',
                  },
                  '@media (max-width: 900px)': {
                    fontWeight: '500',
                    width: '300px',
                  },
                  '@media (max-width: 400px)': {
                    width: '90%',
                  },
                }}
                onClick={handleSearch}
              >
                Search
              </Button>
            </Box>
          </Box>
        )}
        <Box display="flex" alignItems="center" gap="16px">
          {isMobile && (
            <Box display="flex" alignItems="center">
              <IconButton onClick={toggleMobileMenu} sx={{ color: '#000000' }}>
                {mobileOpen ? <CloseIcon /> : <SearchIcon />}
              </IconButton>
              {mobileOpen && (
                <Box
                  position="absolute"
                  top="70px"
                  right="0"
                  bgcolor="white"
                  boxShadow={3}
                  zIndex={999}
                  width="100%"
                  display="flex"
                  flexDirection="column"
                  p={2}
                  borderRadius="8px"
                >
                  <Box
                    display={'flex'}
                    alignItems={'center'}
                    flexDirection={'column'}
                    borderRadius={'5px'}
                    width={'100%'}
                    gap={'15px'}
                  >
                    <Box
                      display={'flex'}
                      alignItems={'center'}
                      width={'100%'}
                      border={'1px solid #D7D8DD'}
                    >
                      <Typography
                        fontSize={'16px'}
                        fontWeight={'700'}
                        fontFamily="SF UI Display"
                        color={'#00BD57'}
                        padding={'0 0 0 3%'}
                      >
                        Find
                      </Typography>
                      <Autocomplete
                        fullWidth
                        freeSolo
                        disableClearable
                        options={companiesNames}
                        value={search ?? searchTypo}
                        onChange={handleSearchName}
                        onInputChange={handleInputSearch}
                        getOptionLabel={(option: any) => option.name ?? ''}
                        filterOptions={(options, state) => {
                          if (state?.inputValue === '' && type !== 'local') {
                            return []; // Return an empty array if the input is empty
                          }
                          const inputValue = state.inputValue.toLowerCase();
                          const startsWithMatch = options.filter((option) =>
                            option.name?.toLowerCase().startsWith(inputValue),
                          );

                          // Filter for options containing the input value but not starting with it
                          const containsMatch = options.filter(
                            (option) =>
                              option.name?.toLowerCase().includes(inputValue) &&
                              !option.name?.toLowerCase().startsWith(inputValue),
                          );

                          // Combine the filtered results, prioritize starting matches first
                          const filteredOptions = [...startsWithMatch, ...containsMatch];

                          return filteredOptions.slice(0, 8);
                          // return options
                          //   .filter((option) => option.name?.toLowerCase().startsWith(inputValue))
                          //   .slice(0, 8);
                          // const filtered = filterOptions(options, state);
                          // return filtered.slice(0, 8); // Ensures the maximum number of options is limited to 5
                        }}
                        renderOption={(props, option) => (
                          <Box
                            component="li"
                            {...props}
                            sx={{ display: 'flex', alignItems: 'center', gap: '15px' }}
                          >
                            <img
                              style={{ width: '40px', height: '40px', objectFit: 'cover' }}
                              src={
                                option?.logo
                                  ? option?.logo === 'fabricatorsicon'
                                    ? ServiceIcon2
                                    : option?.logo === 'installerIcon'
                                    ? ServiceIcon3
                                    : option?.logo === 'surveyIcon'
                                    ? ServiceIcon5
                                    : option?.logo === 'serviceIcon'
                                    ? ServiceIcon4
                                    : option?.logo === 'fullserviceicon'
                                    ? ServiceIcon1
                                    : option?.logo === 'printersIcon'
                                    ? printProduction
                                    : option?.logo === 'printInstallersIcon'
                                    ? PrintInstallation
                                    : option?.logo === 'wrapInstallersIcon'
                                    ? WrapInstallation
                                    : option?.logo === 'permitsIcon'
                                    ? ServiceIcon1
                                    : `${mediaUrl}/${option?.logo}`
                                  : defaultCompany
                              }
                              alt=""
                            />
                            {option.name}
                          </Box>
                        )}
                        renderInput={(params) => (
                          <TextField
                            {...params}
                            placeholder={
                              type !== 'local'
                                ? 'Enter Name [Optional]'
                                : 'Installers, Fabricators, Service...'
                            }
                            sx={{
                              width: '100%',
                              fontSize: '15px !important',
                              fontWeight: '400',
                              lineHeight: '24px',
                              color: '#667085',
                              '.MuiOutlinedInput-input': {
                                padding: '7.5px 4px 7.5px 0px !important',
                                fontSize: '15px !important',
                              },
                              '& .MuiOutlinedInput-root': {
                                '& fieldset': {
                                  border: 'none',
                                  outline: 'none',
                                },
                              },
                              '@media (max-width: 900px)': {
                                fontSize: '14px',
                              },
                            }}
                            type="search"
                          />
                        )}
                      />
                      {/* <TextField
                              placeholder={
                                type !== 'local'
                                  ? 'Enter Name [Optional]'
                                  : 'Installers, Fabricators, Service...'
                              }
                              value={tempSearch}
                              onChange={handleSearchChange}
                              sx={{
                                width: '100%',
                                fontSize: '16px',
                                fontWeight: '400',
                                lineHeight: '24px',
                                color: '#667085',

                                '& .MuiOutlinedInput-root': {
                                  '& fieldset': {
                                    border: 'none',
                                    outline: 'none',
                                  },
                                  '& .MuiOutlinedInput-input': {
                                    padding: '16.5px 10px 16.5px 5px',
                                  },
                                },
                              }}
                              type="search"
                            /> */}
                    </Box>
                    <Box
                      display={'flex'}
                      alignItems={'center'}
                      width={'100%'}
                      border={'1px solid #D7D8DD'}
                    >
                      <Typography
                        fontSize={'16px'}
                        fontWeight={'700'}
                        fontFamily="SF UI Display"
                        color={'#00BD57'}
                        padding={'0 0 0 3%'}
                      >
                        {type === 'national' ? 'Location' : type === 'local' ? 'Near' : 'Category'}
                      </Typography>
                      {type === 'national' ? (
                        <Autocomplete
                          fullWidth
                          options={states}
                          getOptionLabel={(option: string) => option}
                          value={near}
                          onInputChange={handleNearChange}
                          onChange={handleStateChange}
                          renderInput={(params) => (
                            <TextField
                              {...params}
                              placeholder="North America"
                              variant="outlined"
                              sx={{
                                width: '100%',
                                fontSize: '16px',
                                fontWeight: '400',
                                lineHeight: '24px',
                                color: '#667085',
                                borderRadius: '8px',
                                '.MuiOutlinedInput-input': {
                                  padding: '10px 14px',
                                },
                                '& .MuiOutlinedInput-root': {
                                  '& fieldset': {
                                    border: 'none',
                                    outline: 'none',
                                  },
                                },
                                '& .MuiAutocomplete-input': {
                                  padding: '7.5px 4px 7.5px 0px !important',
                                },
                              }}
                            />
                          )}
                        />
                      ) : type === 'distributor' || type === 'supplier' ? (
                        <Autocomplete
                          fullWidth
                          // options={category}
                          // getOptionLabel={(option: any) => option?.category ?? near}
                          options={category}
                          // getOptionLabel={(option: any) =>
                          //   (option.subCategory || option.category) ?? near
                          // }
                          value={near}
                          // name="category"
                          onChange={handleCategoryChange}
                          renderInput={(params) => (
                            <TextField
                              {...params}
                              placeholder="Software, Equipment, Displays..."
                              variant="outlined"
                              sx={{
                                width: '100%',
                                fontSize: '16px',
                                fontWeight: '400',
                                lineHeight: '24px',
                                color: '#667085',
                                borderRadius: '8px',
                                '.MuiOutlinedInput-input': {
                                  padding: '10px 14px',
                                },
                                '& .MuiOutlinedInput-root': {
                                  '& fieldset': {
                                    border: 'none',
                                    outline: 'none',
                                  },
                                },
                                '& .MuiAutocomplete-input': {
                                  padding: '7.5px 4px 7.5px 0px !important',
                                },
                              }}
                            />
                          )}
                        />
                      ) : (
                        <Autocomplete
                          fullWidth
                          disableClearable
                          freeSolo
                          // options={allAddresses.map((address) => address?.contexts?.region)}
                          options={allAddresses.map(formatAddressOption)}
                          value={near}
                          onInputChange={handleNearChange}
                          onChange={(event, value): void => {
                            handleAddressSelect(
                              allAddresses.find(
                                (address) => formatAddressOption(address) === value,
                              ) ?? null,
                            );
                          }}
                          renderInput={(params) => (
                            <TextField
                              {...params}
                              InputProps={{
                                ...params.InputProps,
                                endAdornment: null, // Ensure no end adornment is displayed
                              }}
                              placeholder={
                                type === 'national'
                                  ? 'North America'
                                  : type === 'distributor'
                                  ? 'Software, Equipment, Displays...'
                                  : 'City, State or Province'
                              }
                              sx={{
                                width: '100%',
                                fontSize: '16px',
                                fontWeight: '400',
                                lineHeight: '24px',
                                color: '#667085',
                                '.MuiOutlinedInput-input': {
                                  padding: '16.5px 5px',
                                },
                                '& .MuiOutlinedInput-root': {
                                  '& fieldset': {
                                    border: 'none',
                                    outline: 'none',
                                  },
                                },
                                '& .MuiAutocomplete-input': {
                                  padding: '7.5px 4px 7.5px 0px !important',
                                },
                              }}
                              type="search"
                            />
                          )}
                        />
                      )}
                    </Box>{' '}
                    <Button
                      onClick={() => {
                        handleSearch();
                        setMobileOpen(false);
                      }}
                      sx={{
                        width: '150px',
                        paddingTop: '5px',
                        paddingLeft: '5px',
                        border: '1px solid #000000',
                        fontFamily: 'SF UI Display',
                        fontSize: '16px',
                        fontWeight: '600',
                        lineHeight: '19.09px',
                        textAlign: 'center',
                        color: '#ffffff',
                        backgroundColor: 'transparent', // White background for top and left padding area
                        borderTopLeftRadius: '0',
                        borderBottomLeftRadius: '0',
                        textTransform: 'capitalize',
                        position: 'relative',
                        overflow: 'visible', // Allow overflow of pseudo-element
                        '&::before': {
                          content: '""',
                          position: 'absolute',
                          top: '3px', // Align with the padding
                          left: '3px', // Align with the padding
                          right: '-3px', // Extend 5px to the right for overflow
                          bottom: '-3px', // Extend 5px to the bottom for overflow
                          backgroundColor: '#00BD57', // Green color for overflow
                          zIndex: -1,
                          borderRadius: 'inherit',
                        },
                        '&:hover::before': {
                          backgroundColor: '#00BD57', // Ensure hover behavior matches overflow
                        },
                        '@media (max-width: 900px)': {
                          fontWeight: '500',
                          width: '40%',
                          padding: '8px 0',
                        },
                      }}
                    >
                      Search
                    </Button>
                  </Box>
                </Box>
              )}
            </Box>
          )}
          {/* <Box
                width="25px"
                onClick={() => {
                  nav('/');
                }}
              >
                <img src={search} alt="Search" style={{ width: '100%', cursor: 'pointer' }} />
              </Box> */}
          {allowNotification?.notification &&
          signIn?.company?.companyInfo?.verification?.status &&
          checkUserCompany() ? (
            <Box
              width="25px"
              position="relative"
              onClick={(e) => {
                if (claimStatus) return;
                handleNotificationClick(e);
              }}
            >
              <img
                src={Notification}
                alt="Notification"
                style={{
                  width: '100%',
                  cursor: claimStatus ? 'default' : 'pointer',
                  opacity: claimStatus ? 0.5 : 1,
                }}
              />
              {count !== 0 && (
                <>
                  {' '}
                  <Box
                    display="flex"
                    justifyContent="center"
                    alignItems="center"
                    bgcolor="#00BD57"
                    color="#FFFFFF"
                    fontSize="9px"
                    fontWeight="500"
                    fontFamily="Inter"
                    width="14px"
                    height="14px"
                    borderRadius="50%"
                    position="absolute"
                    top="0"
                    right="0"
                  >
                    {count ?? '0'}
                  </Box>
                </>
              )}
            </Box>
          ) : (
            ''
          )}
          {openPopover && (
            <Popover
              open={Boolean(openPopover)}
              anchorEl={openPopover}
              onClose={() => {
                setOpenPopover(null);
                getNotification();
              }}
              anchorOrigin={{
                vertical: 'bottom',
                horizontal: 'right',
              }}
              transformOrigin={{
                vertical: 'top',
                horizontal: 'right',
              }}
              sx={{
                '& .MuiPaper-root': {
                  borderRadius: '10px',
                  border: 'solid 1px #EAECF0',
                },
              }}
            >
              <Box
                width="350px"
                display="flex"
                // justifyContent="center"
                alignItems="center"
                flexDirection="column"
                borderRadius="10px"
                maxHeight="60vh"
                padding={'13px'}
              >
                {notifications?.length ? (
                  notifications.map((item: any, index: number) => {
                    return (
                      <>
                        <Box
                          key={index}
                          bgcolor={item?.read === false ? '#DFFFED' : '#f8f8f8'}
                          padding={'10px'}
                        >
                          <Typography
                            fontSize={'18px'}
                            fontWeight={'600'}
                            textTransform={'capitalize'}
                          >
                            {/* {item?.data?.type} */}
                            Notification
                          </Typography>
                          <Typography fontSize={'16px'} fontWeight={'400'} padding={'0% 4%'}>
                            {item?.message}
                          </Typography>
                        </Box>
                        <Divider sx={{ bgcolor: '#EBEEF1', width: '100%' }} />
                      </>
                    );
                  })
                ) : (
                  <Typography textAlign={'left'} width={'100%'}>
                    No Notification Found
                  </Typography>
                )}
              </Box>
            </Popover>
          )}

          <Box width="40px" height="40px" onClick={handleAvatarClick} sx={{ cursor: 'pointer' }}>
            <Avatar
              src={signIn?.photo ? `${mediaUrl}/${signIn.photo}` : undefined}
              alt={signIn?.first_name}
              sx={{
                bgcolor: '#00BD57',
                fontSize: '19px',
                width: '100%',
                height: '100%',
                fontFamily: 'inter',
                fontWeight: '600',
              }}
            >
              {!signIn?.photo && `${signIn?.first_name?.[0] ?? ''}${signIn?.last_name?.[0] ?? ''}`}
            </Avatar>
          </Box>
          {profilePopupAnchor && (
            <Popover
              open={Boolean(profilePopupAnchor)}
              anchorEl={profilePopupAnchor}
              onClose={() => {
                setProfilePopupAnchor(null);
              }}
              anchorOrigin={{
                vertical: 'bottom',
                horizontal: 'right',
              }}
              transformOrigin={{
                vertical: 'top',
                horizontal: 'right',
              }}
              sx={{
                '& .MuiPaper-root': {
                  borderRadius: '10px',
                  border: 'solid 1px #EAECF0',
                },
              }}
            >
              <Box
                width="263px"
                display="flex"
                justifyContent="center"
                alignItems="center"
                flexDirection="column"
                borderRadius="10px"
              >
                <Box
                  width="100%"
                  p="0 4%"
                  m="4% 0"
                  display="flex"
                  justifyContent="space-between"
                  alignItems="center"
                  sx={{ cursor: 'pointer' }}
                  onClick={() => {
                    if (checkUserCompany()) {
                      nav('/localpageowner/job-gallery');
                    } else {
                      nav('/localpageowner/SettingPagesetup');
                    }
                    setProfilePopupAnchor(null);
                  }}
                >
                  <Box width="40px" height="40px">
                    <Avatar
                      src={signIn?.photo ? `${mediaUrl}/${signIn.photo}` : undefined}
                      alt={signIn?.first_name}
                      sx={{
                        bgcolor: '#00BD57',
                        fontSize: '19px',
                        width: '100%',
                        height: '100%',
                        fontFamily: 'inter',
                        fontWeight: '600',
                      }}
                    >
                      {!signIn?.photo &&
                        `${signIn?.first_name?.[0] ?? ''}${signIn?.last_name?.[0] ?? ''}`}
                    </Avatar>
                  </Box>
                  <Box width="80%">
                    <Typography
                      fontFamily="SF UI Display"
                      fontSize="14px"
                      fontWeight="600"
                      lineHeight="20px"
                      color="#344054"
                      textTransform={'capitalize'}
                    >
                      {signIn?.first_name} {signIn?.last_name}
                    </Typography>
                    <Typography
                      fontFamily="SF UI Display"
                      fontSize="14px"
                      fontWeight="400"
                      lineHeight="20px"
                      color="#475467"
                      textTransform={'capitalize'}
                    >
                      {signIn?.company?.companyInfo?.name}
                    </Typography>
                  </Box>
                </Box>
                <Divider sx={{ bgcolor: '#EBEEF1', width: '100%' }} />
                <Box
                  width="100%"
                  p="0 4%"
                  m="4% 0"
                  display="flex"
                  alignItems="center"
                  gap="5px"
                  sx={{ cursor: 'pointer' }}
                  onClick={() => {
                    if (checkUserCompany()) {
                      nav('/localpageowner/job-gallery');
                    } else {
                      nav('/localpageowner/SettingPagesetup');
                    }
                    setProfilePopupAnchor(null);
                  }}
                >
                  <img
                    src={DashBoardIcon}
                    alt=".."
                    style={{ width: '13.33px', height: '13.33px' }}
                  />
                  <Typography
                    fontFamily="SF UI Display"
                    fontWeight="500"
                    lineHeight="20px"
                    fontSize="14px"
                  >
                    Dashboard
                  </Typography>
                </Box>
                <Divider sx={{ bgcolor: '#EBEEF1', width: '100%' }} />
                <Box
                  width="100%"
                  p="0 4%"
                  m="4% 0"
                  display="flex"
                  alignItems="center"
                  gap="5px"
                  sx={{
                    cursor: claimStatus ? 'default' : 'pointer',
                    opacity: claimStatus ? 0.5 : 1,
                  }}
                  onClick={() => {
                    if (!claimStatus) {
                      nav('/localpageowner/SettingPagesetup');
                      setProfilePopupAnchor(null);
                    }
                  }}
                >
                  <img src={SettingIcon} alt=".." style={{ width: '13.33px', height: '13.33px' }} />
                  <Typography
                    fontFamily="SF UI Display"
                    fontWeight="500"
                    lineHeight="20px"
                    fontSize="14px"
                  >
                    Account Settings
                  </Typography>
                </Box>
                <Divider sx={{ bgcolor: '#EBEEF1', width: '100%' }} />
                <Box
                  width="100%"
                  p="0 4%"
                  m="4% 0"
                  display="flex"
                  alignItems="center"
                  gap="5px"
                  onClick={(): void => {
                    localStorage.removeItem('user');
                    localStorage.removeItem('token');
                    window.location.href = '/';
                    if (updateStore) {
                      updateStore({ user: null });
                    }
                  }}
                  sx={{
                    cursor: 'pointer',
                  }}
                >
                  <img src={SignOutIcon} alt=".." style={{ width: '13.33px', height: '13.33px' }} />
                  <Typography
                    fontFamily="SF UI Display"
                    fontWeight="500"
                    lineHeight="20px"
                    fontSize="14px"
                    sx={{ cursor: 'pointer' }}
                  >
                    Sign Out
                  </Typography>
                </Box>
              </Box>
            </Popover>
          )}
        </Box>
      </Box>
    </Box>
  );
};
export default Header;
