import React, { FC, useEffect, useState } from 'react';
import {
  Autocomplete,
  Avatar,
  Box,
  Button,
  createFilterOptions,
  Divider,
  IconButton,
  Popover,
  TextField,
  Typography,
  useMediaQuery,
} from '@mui/material';
// import logo from '../../../assets/SignWiselogo .svg';
import logo from '../../../assets/beta/signwise-logo-beta.svg';
import Notification from '../../../assets/notifications.svg';
import SettingIcon from '../../../assets/header/settingIcon.svg';
import SignOutIcon from '../../../assets/header/signoutIcon.svg';
import DashBoardIcon from '../../../assets/header/DashboardIcon.svg';
import defaultCompany from '../../../assets/defaultCompany.png';
import ServiceIcon1 from '../../../assets/serviceFilter/serviceFilter1.svg';
import ServiceIcon2 from '../../../assets/serviceFilter/serviceFilter2.svg';
import ServiceIcon3 from '../../../assets/serviceFilter/serviceFilter3.svg';
import ServiceIcon4 from '../../../assets/serviceFilter/serviceFilter4.svg';
import ServiceIcon5 from '../../../assets/serviceFilter/serviceFilter5.svg';
import printProduction from '../../../assets/serviceFilter/serviceFilter6.svg';
import PrintInstallation from '../../../assets/serviceFilter/serviceFilter7.svg';
import WrapInstallation from '../../../assets/serviceFilter/serviceFilter8.svg';
// import KeyboardArrowDownIcon from '@mui/icons-material/KeyboardArrowDown';
import { Link, useLocation, useNavigate } from 'react-router-dom';
import { DataStructure, User } from '../../../unAuth/phase1a/PersonalInformation.unAuth';
import { mediaUrl } from '../../../config';
import io from 'socket.io-client';
import request from '../../../api/request';
import { IAddress, IAddressResponse } from '../../../types/user';
import CloseIcon from '@mui/icons-material/Close';
import MenuIcon from '@mui/icons-material/Menu';
import SearchIcon from '@mui/icons-material/Search';
import { UpdateStore } from '../../../context/store';
import { toast } from 'react-toastify';
import CustomToast from '../common/CustomToast.component';
// import { toast } from 'react-toastify';

interface HeaderSignCompanyProps {
  listingGrid?: boolean;
  signedIn?: boolean;
  noAccount?: boolean;
  signUp?: boolean;
  search?: any;
  searchData?: any;
  screenId?: number;
  onNextClick?: () => void;
  setSearch?: (data: any) => void;
  setAdditional?: (data: any) => void;
  setCoordinates?: (data: any) => void;
  coordinates?: string;
  user?: User;
  addUser?: () => void;
  data?: DataStructure;
  additional?: string;
  type?: string;
  handleGetData?: () => void;
  setNear?: (str: string) => void;
  near?: string;
  searchTypo?: any;
  setSearchTypo?: (data: any) => void;
  handleAddressSelect?: (address: any) => void;
  myLocation?: any;
  setMyLocation?: (data: any) => void;
  setSelectedAddress?: (data: any) => void;
  selectedAddress?: any;
  companyData?: any;
}
// interface HeaderSignCompanyProps {
//   signUp: boolean;
//   onNextClick: () => void; // Add this line
// }
const HeaderSignCompany: FC<HeaderSignCompanyProps> = ({
  listingGrid,
  signUp,
  signedIn,
  search,
  searchData,
  setSearch,
  noAccount,
  onNextClick,
  screenId,
  user,
  addUser,
  data,
  additional,
  setAdditional,
  handleAddressSelect,
  type,
  handleGetData,
  setNear,
  near,
  setCoordinates,
  coordinates,
  searchTypo,
  setSearchTypo,
  myLocation,
  setMyLocation,
  selectedAddress,
  setSelectedAddress,
  companyData,
}) => {
  const updateStore = UpdateStore();
  const location = useLocation();
  const isMapViewPage = location.pathname.includes('mapview');
  const userJSON = localStorage.getItem('user');
  const token = localStorage.getItem('token');
  const signIn = userJSON ? JSON.parse(userJSON) : '';
  const nav = useNavigate();
  const [category, setCategory] = useState<any>([]);
  const [claimStatus, setClaimStatus] = useState<any>(false);
  const [allAddresses, setAllAddresses] = useState<IAddress[]>([]);
  const states = ['Canada', 'United States'];
  const [profilePopupAnchor, setProfilePopupAnchor] = useState<HTMLDivElement | null>(null);
  const [tempSearch, setTempSearch] = useState<any>(search ?? searchTypo);
  const [tempNear, setTempNear] = useState<any>(near);
  const [tempAdditional, setTempAdditional] = useState<any>(additional);
  const [companiesNames, setCompaniesNames] = useState<any>([]);
  const [selectedAdd, setSelectedAdd] = useState<any>(selectedAddress ?? null);
  const [count, setCount] = useState<any>(0);
  const [openPopover, setOpenPopover] = useState<HTMLDivElement | null>(null);
  const [notifications, setNotification] = useState<any>([]);
  const [currentLocation, setCurrentLocation] = useState<any>();

  const [allowNotification, setAllowNotification] = useState(signIn);
  console.log(
    'lojujdnah',
    'type:',
    type,
    'search:',
    search,
    'additional',
    additional,
    'near:',
    near,
    'coordinates:',
    coordinates,
    'searchTypo:',
    searchTypo,
  );
  console.log('searchTypo coordinates3123', coordinates);
  console.log('searchTypo selectedAdd', selectedAdd);
  const path = location.pathname;
  const pathSegments = path.split('/').filter(Boolean); //
  const hasDynamicID = pathSegments.length === 2 && pathSegments[0] && pathSegments[1];

  // const handleSearchChange = (event: React.ChangeEvent<HTMLInputElement>): void => {
  //   setSearch(event.target.value);
  // };
  const handleSearchChange = (event: any, value: any): void => {
    setTempSearch?.(value ?? '');
  };
  const handleInputSearch = (event: React.SyntheticEvent, newInputValue: string): void => {
    setSearchTypo?.({ name: newInputValue, logo: '' });
    setTempSearch?.({ name: newInputValue, logo: '' });
  };
  console.log('tempSearchtempSearch:', tempSearch);
  const handleAvatarClick = (event: React.MouseEvent<HTMLDivElement>): void => {
    setProfilePopupAnchor(profilePopupAnchor ? null : event.currentTarget);
  };

  console.log(coordinates, 'coordinates23123123');

  const handleNearChange = (event: any, value: string | null): void => {
    setTempNear?.(value ?? '');
    setTempAdditional?.(value ?? '');
    if (value === '') {
      setSelectedAdd(null);
    }
    if (value) {
      handleGetAddress(value);
    }
  };

  const handleStateChange = (event: any, value: any): void => {
    setTempNear?.(value ?? '');
    setTempAdditional?.(value ?? '');
  };
  // const uniqueServices = [
  //   { name: 'Full Service Companies', logo: 'fullserviceicon' },
  //   { name: 'Fabricators', logo: 'fabricatorsicon' },
  //   { name: 'Installers', logo: 'installerIcon' },
  //   { name: 'Service', logo: 'serviceIcon' },
  //   { name: 'Surveys', logo: 'surveyIcon' },
  // ];
  const uniqueServices = [
    { name: 'Sign Fabrication', logo: 'fabricatorsicon' },
    { name: 'Sign Installation', logo: 'installerIcon' },
    { name: 'Print Production', logo: 'printersIcon' },
    { name: 'Print Installation', logo: 'printInstallersIcon' },
    { name: 'Wrap Installation', logo: 'wrapInstallersIcon' },
    { name: 'Permits', logo: 'permitsIcon' },
    { name: 'Surveys', logo: 'surveyIcon' },
    { name: 'Service', logo: 'serviceIcon' },
  ];
  const getCompaniesName = (): void => {
    request('get', `companies/searchAutoCompleteList/${type ?? ''}`)
      .then((response: any) => {
        console.log(response);
        const { uniqueNames } = response;
        if (type === 'local') {
          setCompaniesNames([...uniqueServices, ...uniqueNames]);
        } else {
          setCompaniesNames(uniqueNames);
        }
      })
      .catch((error) => {
        console.log(error);
      });
  };
  useEffect(() => {
    if (type?.length) {
      getCompaniesName();
    }
  }, [type]);

  const getCategories = (): void => {
    request('get', `/companies/categories`)
      .then((res: any) => {
        // toast.success('Success');
        console.log('res searcchh', res);
        // Process categories and subcategories
        const categories: string[] = res.categories.reduce((acc: string[], obj: any) => {
          acc.push(obj.category);

          obj.subCategories.forEach((subCategory: string) => {
            acc.push(subCategory);
          });

          return acc;
        }, []);
        setCategory(categories);
      })
      .catch((err) => {
        console.log('err', err);
      });
  };
  // const handleCurrentLocation = (): void => {
  //   request('post', '/address/currentLocation', {
  //     long: currentLocation?.coords?.longitude,
  //     lat: currentLocation?.coords?.latitude,
  //   })
  //     .then((res: any) => {
  //       const locationParts = res?.location.split(',').map((part: any) => part.trim());
  //       setMyLocation?.({
  //         city: locationParts[0] || '',
  //         state: locationParts[1] || '',
  //         country: locationParts[2] || '',
  //       });

  //       console.log('currentLocation', res);
  //     })
  //     .catch((err) => {
  //       console.log('err', err);
  //     });
  // };
  const handleGetAddress = (value: string): void => {
    request<IAddressResponse>('get', `/address/search?text=${value}`)
      .then((response) => {
        console.log(response);
        const filteredResults = response?.results?.filter((result) => result?.contexts?.place);
        setAllAddresses(filteredResults);
      })
      .catch((error) => {
        console.log(error);
      });
  };

  const handleCategoryChange = (event: any, value: any): void => {
    setTempNear?.(value ?? '');
    setTempAdditional?.(value ?? '');
  };

  const formatAddressOption = (address: IAddress): string => {
    // const postalCode = address.contexts?.postcode || '';
    const region = address.contexts?.region || '';
    // const country = address.contexts?.country || '';
    const place = address.contexts?.place || '';
    // const city = address.contexts?.city || '';
    // const street = address.contexts?.street || '';
    // const district = address.contexts?.district || '';
    // const locality = address.contexts?.locality || '';
    /* eslint-disable @typescript-eslint/restrict-template-expressions */
    return place && `${place}, ${region}`;
    // return place ? `${place}, ${region}` : `${region}, ${country}`;
  };

  useEffect(() => {
    getCategories();
  }, []);
  useEffect(() => {
    setTempSearch(search);
  }, [search]);
  // useEffect(() => {
  //   if (coordinates) handleCurrentLocation();
  // }, [coordinates]);
  const [mobileOpen, setMobileOpen] = useState(false);
  const isMobile = useMediaQuery('(max-width:768px)');

  const toggleMobileMenu = (): void => {
    setMobileOpen(!mobileOpen);
  };
  const handleSearchData = (): void => {
    handleAddressSelect?.(selectedAdd);
    if (!selectedAdd && !tempSearch?.id) {
      if (type === 'local' && currentLocation === undefined) {
        // toast.error('Must select a nearby location or enable your current location');
        toast(
          <CustomToast
            message={'Must select a nearby location or enable your current location'}
            type="error"
          />,
        );

        if ('geolocation' in navigator) {
          navigator.geolocation.getCurrentPosition(function (position) {
            console.log(position, 'kasgffkajfgf');
            setCurrentLocation(position);
          });
        } else {
          console.log('Geolocation is not available in your browser.');
        }
        return;
      }

      if (type === 'supplier' || type === 'distributor') {
        if (!tempNear?.length) {
          // toast.error('Category is required');
          toast(<CustomToast message={'Category is required'} type="error" />);

          return;
        }
      }
    }
    const formatSearchString = (str: string): string => {
      if (!str) return ''; // Handle cases where str might be null or undefined
      // Trim, lowercase, and replace spaces with hyphens
      const formattedStr = str.toLowerCase().replace(/\s+/g, '-').trim();
      switch (formattedStr) {
        case 'fabricators':
          return 'sign-fabricators';
        case 'installers':
          return 'sign-installers';
        case 'full-service-companies': // Handle the formatted string directly
          return 'full-service';
        default:
          return formattedStr;
      }
    };
    let url = isMapViewPage ? '/mapview' : '/search';
    const formattedSearch = tempSearch ? formatSearchString(tempSearch?.name) : '';
    // let find = '';
    const country =
      selectedAdd?.contexts?.country === 'United States'
        ? 'us'
        : selectedAdd?.contexts?.country === 'canada'
        ? 'canada'
        : !selectedAdd
        ? formatSearchString(myLocation?.country)
        : '';

    if (type === 'local') {
      const searchName = tempSearch?.name?.toLowerCase();
      const searchTypoName = tempSearch?.name?.toLowerCase();

      const serviceNames = [
        'service',
        'fabricators',
        'installers',
        'surveys',
        'full service companies',
      ];
      const find =
        serviceNames.includes(searchName) || serviceNames.includes(searchTypoName)
          ? 'service'
          : 'company';
      if (selectedAdd && formattedSearch) {
        url += `?${find}=${encodeURIComponent(
          formattedSearch,
        )}&location=${country}&city=${encodeURIComponent(
          formatSearchString(selectedAdd?.contexts?.place),
        )}&state=${encodeURIComponent(formatSearchString(selectedAdd?.contexts?.region))}`;
      } else if (!formattedSearch && (selectedAdd || coordinates)) {
        url += selectedAdd
          ? `?location=${country}&city=${encodeURIComponent(
              formatSearchString(selectedAdd?.contexts?.place),
            )}&state=${encodeURIComponent(formatSearchString(selectedAdd?.contexts?.region))}`
          : `?location=${encodeURIComponent(
              formatSearchString(myLocation?.country),
            )}&city=${encodeURIComponent(
              formatSearchString(myLocation?.city),
            )}&state=${encodeURIComponent(formatSearchString(myLocation?.state))}
              `;
      } else if (formattedSearch) {
        url += `?${find}=${encodeURIComponent(formattedSearch)}&location=${encodeURIComponent(
          formatSearchString(myLocation?.country),
        )}&city=${encodeURIComponent(
          formatSearchString(myLocation?.city),
        )}&state=${encodeURIComponent(formatSearchString(myLocation?.state))}
              `;
      }
    }
    if (type === 'national') {
      const formattedNear = tempNear ? formatSearchString(tempNear) : '';
      const locations =
        tempNear === 'Canada' ? 'canada' : tempNear === 'United States' ? 'us' : formattedNear;
      if (tempNear && formattedSearch) {
        url += `?company=${encodeURIComponent(formattedSearch)}&location=${encodeURIComponent(
          locations,
        )}`;
      } else if (!tempNear && !formattedSearch) {
        url += '?national-sign-companies';
      } else if (tempNear) {
        url += `?location=${encodeURIComponent(locations)}`;
      } else if (formattedSearch) {
        url += `?company=${encodeURIComponent(formattedSearch)}`;
      }
    }
    if (type === 'supplier' || type === 'distributor') {
      const formattedNear = tempNear ? formatSearchString(tempNear) : '';
      if (tempNear && formattedSearch) {
        url += `?company=${encodeURIComponent(formattedSearch)}&category=${encodeURIComponent(
          formattedNear,
        )}`;
      } else if (tempNear) {
        url += `?category=${encodeURIComponent(formattedNear)}`;
      } else if (formattedSearch) {
        url += `?company=${encodeURIComponent(formattedSearch)}`;
      }
    }

    if (hasDynamicID) {
      handleSearchNav();
    } else {
      setSearch?.(tempSearch);
      setNear?.(
        selectedAdd
          ? `${selectedAdd?.contexts?.place}, ${selectedAdd?.contexts?.region}`
          : type === 'local'
          ? `${myLocation?.city}, ${myLocation?.state}`
          : tempNear ?? '',
      );
      // setFilterServices?.(selectedServices);
      setAdditional?.(
        selectedAdd
          ? `${selectedAdd?.contexts?.place}, ${selectedAdd?.contexts?.region}`
          : type === 'local'
          ? `${myLocation?.city}, ${myLocation?.state}`
          : tempAdditional ?? '',
      );
      if (!selectedAdd && type === 'local') {
        const cor = `[${currentLocation?.coords?.longitude}, ${currentLocation?.coords?.latitude}]`;
        setSelectedAdd(null);
        setSelectedAddress?.(null);
        setCoordinates?.(cor);
      }
    }
    nav(url, {
      state: {
        nearData: selectedAdd
          ? `${selectedAdd?.contexts?.place}, ${selectedAdd?.contexts?.region}`
          : type === 'local'
          ? `${myLocation?.city}, ${myLocation?.state}`
          : tempNear ?? '',
        myCurrLoc: myLocation,
        companyType: type,
        locationsParam: coordinates ?? '',
        searchData: tempSearch,
        searchText: tempSearch,
        addSelected: selectedAdd,
      },
    });
  };
  const getNotification = (): void => {
    request('get', '/notifications/')
      .then((res: any) => {
        setNotification(res?.notifications);
      })
      .catch((err) => {
        console.log('err', err);
      });
  };
  const getNotificationCount = (): void => {
    request('get', '/notifications/unreadCount')
      .then((res: any) => {
        setCount(res?.unread);
      })
      .catch((err) => {
        console.log('err', err);
      });
  };
  const handleNotificationClick = (event: React.MouseEvent<HTMLDivElement>): void => {
    setOpenPopover(profilePopupAnchor ? null : event.currentTarget);
    request('patch', '/notifications/:id')
      .then((res) => {
        console.log('err', res);
        getNotificationCount();
      })
      .catch((err) => {
        console.log('err', err);
      });
  };

  const handleSearchNav = (): void => {
    if (!tempNear && !tempSearch?.id) {
      if (type === 'local' && currentLocation === undefined) {
        // toast.error('Must select a nearby location or enable your current location');
        toast(
          <CustomToast
            message={'Must select a nearby location or enable your current location'}
            type="error"
          />,
        );

        if ('geolocation' in navigator) {
          navigator.geolocation.getCurrentPosition(function (position) {
            console.log(position, 'kasgffkajfgf');
            setCurrentLocation(position);
          });
        } else {
          console.log('Geolocation is not available in your browser.');
        }
        return;
      }

      if (type === 'supplier' || type === 'distributor') {
        if (!searchTypo?.name?.length) {
          // toast.error('Category is required');
          toast(<CustomToast message={'Category is required'} type="error" />);

          return;
        }
      }
    }
    const formatSearchString = (str: string): string => {
      if (!str) return ''; // Handle cases where str might be null or undefined
      // Trim, lowercase, and replace spaces with hyphens
      const formattedStr = str.toLowerCase().replace(/\s+/g, '-').trim();
      switch (formattedStr) {
        case 'fabricators':
          return 'sign-fabricators';
        case 'installers':
          return 'sign-installers';
        case 'full-service-companies': // Handle the formatted string directly
          return 'full-service';
        default:
          return formattedStr;
      }
    };
    let url = '/search';
    const formattedSearch = tempSearch ? formatSearchString(tempSearch?.name) : '';
    const country =
      selectedAdd?.contexts?.country === 'United States'
        ? 'us'
        : selectedAdd?.contexts?.country === 'canada'
        ? 'canada'
        : !selectedAdd
        ? formatSearchString(myLocation?.country)
        : '';
    if (type === 'local') {
      const searchName = tempSearch?.name?.toLowerCase();
      const searchTypoName = tempSearch?.name?.toLowerCase();

      const serviceNames = [
        'service',
        'fabricators',
        'installers',
        'surveys',
        'full service companies',
      ];
      const find =
        serviceNames.includes(searchName) || serviceNames.includes(searchTypoName)
          ? 'service'
          : 'company';
      if (tempNear && formattedSearch) {
        url += `?${find}=${encodeURIComponent(
          formattedSearch,
        )}&location=${country}&city=${encodeURIComponent(
          formatSearchString(tempNear?.split(',')[0]),
        )}&state=${encodeURIComponent(formatSearchString(tempNear?.split(', ')[1]))}`;
      } else if (!formattedSearch && (tempNear || coordinates)) {
        url +=
          tempNear !== ''
            ? `?location=${country}&city=${encodeURIComponent(
                formatSearchString(tempNear?.split(',')[0]),
              )}&state=${encodeURIComponent(formatSearchString(tempNear?.split(', ')[1]))}`
            : `?location=${encodeURIComponent(
                formatSearchString(myLocation?.country),
              )}&city=${encodeURIComponent(
                formatSearchString(myLocation?.city),
              )}&state=${encodeURIComponent(formatSearchString(myLocation?.state))}
              `;
      } else if (formattedSearch) {
        url += `?${find}=${encodeURIComponent(formattedSearch)}&location=${encodeURIComponent(
          formatSearchString(myLocation?.country),
        )}&city=${encodeURIComponent(
          formatSearchString(myLocation?.city),
        )}&state=${encodeURIComponent(formatSearchString(myLocation?.state))}
              `;
      }
    }
    if (type === 'national') {
      const formattedNear = tempNear ? formatSearchString(tempNear) : '';
      const locations =
        tempNear === 'Canada' ? 'canada' : tempNear === 'United States' ? 'us' : formattedNear;
      if (tempNear && formattedSearch) {
        url += `?company=${encodeURIComponent(formattedSearch)}&location=${encodeURIComponent(
          locations,
        )}`;
      } else if (!tempNear && !formattedSearch) {
        url += '?national-sign-companies';
      } else if (tempNear) {
        url += `?location=${encodeURIComponent(locations)}`;
      } else if (formattedSearch) {
        url += `?company=${encodeURIComponent(formattedSearch)}`;
      }
    }
    if (type === 'supplier' || type === 'distributor') {
      const formattedNear = tempNear ? formatSearchString(tempNear) : '';
      if (tempNear && formattedSearch) {
        url += `?company=${encodeURIComponent(formattedSearch)}&category=${encodeURIComponent(
          formattedNear,
        )}`;
      } else if (tempNear) {
        url += `?category=${encodeURIComponent(formattedNear)}`;
      } else if (formattedSearch) {
        url += `?company=${encodeURIComponent(formattedSearch)}`;
      }
    }
    setSearch?.(tempSearch);
    setNear?.(
      selectedAdd
        ? `${selectedAdd?.contexts?.place}, ${selectedAdd?.contexts?.region}`
        : type === 'local'
        ? `${myLocation?.city}, ${myLocation?.state}`
        : tempNear ?? '',
    );
    setAdditional?.(
      selectedAdd
        ? `${selectedAdd?.contexts?.place}, ${selectedAdd?.contexts?.region}`
        : type === 'local'
        ? `${myLocation?.city}, ${myLocation?.state}`
        : tempAdditional ?? '',
    );
    if (!selectedAdd && type === 'local') {
      const cor = `[${currentLocation?.coords?.longitude}, ${currentLocation?.coords?.latitude}]`;
      console.log('cor', cor);
      setSelectedAdd(null);
      setCoordinates?.(cor);
    }
    nav(url, {
      state: {
        nearData: selectedAdd
          ? `${selectedAdd?.contexts?.place}, ${selectedAdd?.contexts?.region}`
          : type === 'local'
          ? `${myLocation?.city}, ${myLocation?.state}`
          : tempNear ?? '',
        myCurrLoc: myLocation,
        companyType: type,
        locationsParam: coordinates ?? '',
        searchData: tempSearch,
        searchText: tempSearch,
        addSelected: selectedAdd ?? null,
      },
    });
  };
  const filterOptions = createFilterOptions({
    limit: 8,
    stringify: (option: any) => option?.name,
  });
  console.log(filterOptions);
  useEffect(() => {
    if ('geolocation' in navigator) {
      navigator.permissions
        .query({ name: 'geolocation' })
        .then(function (result) {
          if (result.state === 'granted') {
            navigator.geolocation.getCurrentPosition(function (position) {
              console.log(position, 'kasgffkajfgf');
              setCurrentLocation(position);
            });
          } else {
            // Permission is 'prompt' or 'denied'
            console.log('Geolocation permission is not granted');
          }
        })
        .catch(function (error) {
          console.error('Error querying geolocation permissions:', error);
        });
    }
  }, []);
  useEffect(() => {
    if (
      selectedAdd &&
      Array.isArray(selectedAdd.coordinates) &&
      selectedAdd.coordinates.length === 2
    ) {
      const [lan, lat] = selectedAdd.coordinates;
      /* eslint-disable @typescript-eslint/restrict-template-expressions */
      const cor = `[${lan}, ${lat}]`;
      console.log('selectedServices additional corcorcor', cor);
      setCoordinates?.(cor);
    } else {
      setCoordinates?.(coordinates ?? '');
    }
  }, [selectedAdd, coordinates]);

  useEffect(() => {
    if (currentLocation?.coords?.latitude) {
      request('post', '/address/currentLocation', {
        long: currentLocation?.coords?.longitude,
        lat: currentLocation?.coords?.latitude,
      })
        .then((res: any) => {
          const locationParts = res?.location.split(',').map((part: any) => part.trim());
          setMyLocation?.({
            city: locationParts[0] || '',
            state: locationParts[1] || '',
            country: locationParts[2] || '',
          });

          const cor = `[${currentLocation?.coords?.longitude}, ${currentLocation?.coords?.latitude}]`;
          setCoordinates?.(cor);
          console.log('currentLocation', res);
        })
        .catch((err) => {
          console.log('err', err);
        });
    }
  }, [currentLocation?.coords?.latitude]);
  useEffect(() => {
    if (signIn?._id) {
      getNotificationCount();
      getNotification();
    }
  }, [signIn?._id]);
  useEffect(() => {
    if (signIn?._id) {
      const socket = io(mediaUrl); // Replace with your server URL

      // Listen for the specific notification event
      const userId = signIn?._id; // Replace with the actual user ID
      /* eslint-disable @typescript-eslint/restrict-template-expressions */
      const eventName = `notification_${userId}`;
      socket.on(eventName, (notification) => {
        getNotification();
        getNotificationCount();
        console.log(notification);
      });

      // Cleanup on unmount
      return () => {
        socket.off(eventName);
        socket.close();
      };
    }
  }, [signIn?._id]);
  useEffect(() => {
    const handleStorageEvent = (): void => {
      const userJSON = localStorage.getItem('user');
      if (userJSON) {
        setAllowNotification(JSON.parse(userJSON));
      } else {
        setAllowNotification(signIn);
      }
    };

    // Add event listener for 'storage' event
    window.addEventListener('notify', handleStorageEvent);

    // Cleanup the event listener on component unmount
    return () => {
      window.removeEventListener('notify', handleStorageEvent);
    };
  }, []);
  useEffect(() => {
    if (token?.length) {
      request('get', `/companies/claim/checkRequest`)
        .then((res: any) => {
          if (res?.claim?.status === 'pending') {
            setClaimStatus(true);
          }
        })
        .catch((err) => {
          console.log('err', err);
        });
    }
  }, [token?.length]);

  useEffect(() => {
    if (companyData) {
      setSelectedAdd({
        contexts: {
          country: companyData?.companyInfo?.address?.country,
          district: companyData?.companyInfo?.address?.city,
          place: companyData?.companyInfo?.address?.street,
          region: companyData?.companyInfo?.address?.state,
        },
        coordinates: companyData?.companyInfo?.location?.coordinates,
        full_address: `${companyData?.companyInfo?.address?.street}, ${companyData?.companyInfo?.address?.state}, ${companyData?.companyInfo?.address?.country}`,
        name: companyData?.companyInfo?.address?.street,
      });
      setTempNear(
        `${companyData?.companyInfo?.address?.street ?? companyData?.companyInfo?.address?.city}, ${
          companyData?.companyInfo?.address?.state ?? companyData?.companyInfo?.address?.region
        }`,
      );
    }
  }, [companyData]);

  const handleSelectaddress = (value: any): void => {
    const selectedAddress =
      allAddresses?.find((address) => formatAddressOption(address) === value) ?? null;

    // Call handleAddressSelect if it exists

    // Set the selected address
    setSelectedAdd(selectedAddress);
  };

  console.log(tempNear, 'tempNear');

  const checkUserCompany = (): any => {
    if (
      signIn?.type === 'member' &&
      (signIn?.company?.companyInfo?.companyType === 'distributor' ||
        signIn?.company?.companyInfo?.companyType === 'supplier')
    ) {
      return false;
    } else {
      return true;
    }
  };

  return (
    <>
      {window.location.pathname === '/add-business' && (
        <Box
          bgcolor={'#00BD57'}
          height={'51px'}
          display="flex"
          justifyContent="center"
          alignItems="center"
        >
          <Typography
            fontFamily={'SF UI Display'}
            color={'#FFFFFF'}
            fontSize={'20px'}
            fontWeight={700}
            sx={{
              '@media (max-width: 520px)': {
                fontSize: '18px',
              },
            }}
          >
            Are you a National or Supplier?{' '}
            <Typography
              onClick={() =>
                window.open('https://signwise.fibery.io/@public/forms/Mt4jLDiQ', '_blank')
              }
              fontFamily={'SF UI Display'}
              color={'#FFFFFF'}
              fontSize={'20px'}
              component={'span'}
              fontWeight={700}
              sx={{
                textDecoration: 'underline',
                cursor: 'pointer',

                '@media (max-width: 520px)': {
                  fontSize: '18px',
                },
              }}
            >
              Click Here
            </Typography>
          </Typography>
        </Box>
      )}
      <Box
        width="100%"
        display="flex"
        justifyContent="center"
        alignItems="center"
        alignSelf="center"
        padding="1.5% 3%"
        position={window.location.pathname === '/add-business' ? 'sticky' : 'fixed'}
        bgcolor={'#ffffff'}
        height={'80px'}
        zIndex={'9'}
        top={'0'}
        sx={{
          borderBottom: window?.location?.pathname !== '/' ? '1px solid #EEEEEE' : undefined,
        }}
      >
        <Box width="100%" display="flex" justifyContent={'space-between'} alignItems="center">
          <Box width="147px">
            <Link to="/">
              <img src={logo} alt="Logo" style={{ width: '100%', cursor: 'pointer' }} />
            </Link>
          </Box>
          {signUp ? (
            <Box
              width="90%"
              display="flex"
              justifyContent={'space-between'}
              alignItems="center"
              pl="2%"
            >
              <Button
                sx={{
                  width: '103px',
                  height: '33px',
                  borderRadius: '50px',
                  bgcolor: '#00BF63',
                  fontSize: '14px',
                  lineHeight: '16.71px',
                  textAlign: 'center',
                  fontWeight: '700',
                  textTransform: 'unset',
                  fontFamily: 'SF UI Display',
                  color: 'white',
                  '&:hover': {
                    bgcolor: '#00BF63',
                  },
                }}
              >
                Page Setup
              </Button>
              {user?.company?.length ? (
                <Button
                  sx={{
                    width: '122px',
                    height: '33px',
                    borderRadius: '5px',
                    bgcolor: 'transparent',
                    border: 'solid 1px #00BF63',
                    fontSize: '14px',
                    lineHeight: '16.71px',
                    textAlign: 'center',
                    fontWeight: '700',
                    fontFamily: 'SF UI Display',
                    color: '#00BF63',
                    textTransform: 'unset',
                    '&:hover': {
                      bgcolor: 'transparent',
                    },
                  }}
                  onClick={addUser}
                >
                  Finish
                </Button>
              ) : (
                <Button
                  sx={{
                    width:
                      screenId === 5 ||
                      (data?.companyInfo?.companyType === 'national' && screenId === 3) ||
                      (data?.companyInfo?.companyType === 'supplier' && screenId === 4) ||
                      (data?.companyInfo?.companyType === 'distributor' && screenId === 4)
                        ? '180px'
                        : '122px',
                    height:
                      screenId === 5 ||
                      (data?.companyInfo?.companyType === 'national' && screenId === 3) ||
                      (data?.companyInfo?.companyType === 'supplier' && screenId === 4) ||
                      (data?.companyInfo?.companyType === 'distributor' && screenId === 4)
                        ? '38px'
                        : '33px',
                    borderRadius: '5px',
                    bgcolor: 'transparent',
                    border: 'solid 1px #00BF63',
                    fontSize: '14px',
                    lineHeight: '16.71px',
                    textAlign: 'center',
                    fontWeight: '700',
                    fontFamily: 'SF UI Display',
                    color: '#00BF63',
                    textTransform: 'unset',
                    '&:hover': {
                      bgcolor: 'transparent',
                    },
                  }}
                  onClick={onNextClick}
                >
                  {screenId === 5 ||
                  (data?.companyInfo?.companyType === 'national' && screenId === 3) ||
                  (data?.companyInfo?.companyType === 'supplier' && screenId === 4) ||
                  (data?.companyInfo?.companyType === 'distributor' && screenId === 4)
                    ? 'Save Company Details'
                    : 'Next'}
                </Button>
              )}
            </Box>
          ) : !isMobile ? (
            <>
              {!signIn?._id && (
                <Box
                  width="80%"
                  display="flex"
                  justifyContent={listingGrid ? 'space-between' : 'right'}
                  alignItems="center"
                  gap={'1%'}
                >
                  {listingGrid && (
                    <Box
                      display={'flex'}
                      border={'1px solid #D7D8DD'}
                      borderRadius={'5px'}
                      width={'70%'}
                      height={'50px'}
                    >
                      <Box
                        display={'flex'}
                        alignItems={'center'}
                        width={'43%'}
                        borderRight={'1px solid #D7D8DD'}
                      >
                        <Typography
                          fontSize={'16px'}
                          fontWeight={'700'}
                          fontFamily={'SF UI Display'}
                          color={'#00BD57'}
                          padding={'0 0 0 3%'}
                        >
                          Find
                        </Typography>
                        <Autocomplete
                          fullWidth
                          freeSolo
                          disableClearable
                          options={companiesNames}
                          value={tempSearch ?? searchTypo}
                          onChange={handleSearchChange}
                          onInputChange={handleInputSearch}
                          getOptionLabel={(option: any) => option.name ?? ''}
                          filterOptions={(options, state) => {
                            if (state?.inputValue === '' && type !== 'local') {
                              return []; // Return an empty array if the input is empty
                            }
                            const inputValue = state.inputValue.toLowerCase();
                            const startsWithMatch = options.filter((option) =>
                              option.name?.toLowerCase().startsWith(inputValue),
                            );

                            // Filter for options containing the input value but not starting with it
                            const containsMatch = options.filter(
                              (option) =>
                                option.name?.toLowerCase().includes(inputValue) &&
                                !option.name?.toLowerCase().startsWith(inputValue),
                            );

                            // Combine the filtered results, prioritize starting matches first
                            const filteredOptions = [...startsWithMatch, ...containsMatch];

                            return filteredOptions.slice(0, 8);
                            // return options
                            //   .filter((option) => option.name?.toLowerCase().startsWith(inputValue))
                            //   .slice(0, 8);
                            // const filtered = filterOptions(options, state);
                            // return filtered.slice(0, 8); // Ensures the maximum number of options is limited to 5
                          }}
                          renderOption={(props, option) => (
                            <Box
                              component="li"
                              {...props}
                              sx={{ display: 'flex', alignItems: 'center', gap: '15px' }}
                            >
                              <img
                                style={{ width: '40px', height: '40px', objectFit: 'cover' }}
                                src={
                                  option?.logo
                                    ? option?.logo === 'fabricatorsicon'
                                      ? ServiceIcon2
                                      : option?.logo === 'installerIcon'
                                      ? ServiceIcon3
                                      : option?.logo === 'surveyIcon'
                                      ? ServiceIcon5
                                      : option?.logo === 'serviceIcon'
                                      ? ServiceIcon4
                                      : option?.logo === 'fullserviceicon'
                                      ? ServiceIcon1
                                      : option?.logo === 'printersIcon'
                                      ? printProduction
                                      : option?.logo === 'printInstallersIcon'
                                      ? PrintInstallation
                                      : option?.logo === 'wrapInstallersIcon'
                                      ? WrapInstallation
                                      : option?.logo === 'permitsIcon'
                                      ? ServiceIcon1
                                      : `${mediaUrl}/${option?.logo}`
                                    : defaultCompany
                                }
                                alt=""
                              />
                              {option.name}
                            </Box>
                          )}
                          renderInput={(params) => (
                            <TextField
                              {...params}
                              placeholder={
                                type !== 'local'
                                  ? 'Enter Name [Optional]'
                                  : 'Installers, Fabricators, Service...'
                              }
                              sx={{
                                width: '100%',
                                fontSize: '15px !important',
                                fontWeight: '400',
                                lineHeight: '24px',
                                color: '#667085',
                                '.MuiOutlinedInput-input': {
                                  padding: '7.5px 4px 7.5px 0px !important',
                                  fontSize: '15px !important',
                                },
                                '& .MuiOutlinedInput-root': {
                                  '& fieldset': {
                                    border: 'none',
                                    outline: 'none',
                                  },
                                },
                                '@media (max-width: 900px)': {
                                  fontSize: '14px',
                                },
                              }}
                              type="search"
                            />
                          )}
                        />
                        {/* <TextField
                        placeholder={
                          type !== 'local'
                            ? 'Enter Name [Optional]'
                            : 'Installers, Fabricators, Service...'
                        }
                        value={tempSearch}
                        onChange={handleSearchChange}
                        sx={{
                          width: '100%',
                          fontSize: '16px',
                          fontWeight: '400',
                          lineHeight: '24px',
                          color: '#667085',
                          '& .MuiOutlinedInput-root': {
                            '& fieldset': {
                              border: 'none',
                              outline: 'none',
                            },
                            '& .MuiOutlinedInput-input': {
                              padding: '16.5px 10px 16.5px 5px',
                            },
                          },
                        }}
                        type="search"
                      /> */}
                      </Box>
                      <Box display={'flex'} alignItems={'center'} width={'43%'}>
                        <Typography
                          fontSize={'16px'}
                          fontWeight={'700'}
                          fontFamily={'SFProDisplay'}
                          color={'#00BD57'}
                          padding={'0 0 0 3%'}
                        >
                          {type === 'national'
                            ? 'Location'
                            : type === 'local'
                            ? 'Near'
                            : 'Category'}
                        </Typography>
                        {type === 'national' ? (
                          <Autocomplete
                            fullWidth
                            disableClearable
                            options={states}
                            getOptionLabel={(option: string) => option}
                            value={tempNear}
                            onInputChange={handleNearChange}
                            onChange={handleStateChange}
                            renderInput={(params) => (
                              <TextField
                                {...params}
                                placeholder="North America"
                                variant="outlined"
                                sx={{
                                  width: '100%',
                                  fontSize: '16px',
                                  fontWeight: '400',
                                  lineHeight: '24px',
                                  color: '#667085',
                                  borderRadius: '8px',
                                  '.MuiOutlinedInput-input': {
                                    padding: '10px 14px',
                                  },
                                  '& .MuiOutlinedInput-root': {
                                    '& fieldset': {
                                      border: 'none',
                                      outline: 'none',
                                    },
                                  },
                                  '& .MuiAutocomplete-input': {
                                    padding: '7.5px 4px 7.5px 0px !important',
                                  },
                                }}
                              />
                            )}
                          />
                        ) : type === 'distributor' ? (
                          <Autocomplete
                            fullWidth
                            // options={category}
                            // getOptionLabel={(option: any) => option?.category ?? near}
                            options={category}
                            // getOptionLabel={(option: any) =>
                            //   (option.subCategory || option.category) ?? near
                            // }
                            value={tempNear}
                            disableClearable
                            // name="category"
                            onChange={handleCategoryChange}
                            renderInput={(params) => (
                              <TextField
                                {...params}
                                placeholder="Software, Equipment, Displays..."
                                variant="outlined"
                                sx={{
                                  width: '100%',
                                  fontSize: '16px',
                                  fontWeight: '400',
                                  lineHeight: '24px',
                                  color: '#667085',
                                  borderRadius: '8px',
                                  '.MuiOutlinedInput-input': {
                                    padding: '10px 14px',
                                  },
                                  '& .MuiOutlinedInput-root': {
                                    '& fieldset': {
                                      border: 'none',
                                      outline: 'none',
                                    },
                                  },
                                  '& .MuiAutocomplete-input': {
                                    padding: '7.5px 4px 7.5px 0px !important',
                                  },
                                }}
                              />
                            )}
                          />
                        ) : type === 'supplier' ? (
                          <Autocomplete
                            fullWidth
                            options={category}
                            disableClearable
                            value={tempNear}
                            onChange={handleCategoryChange}
                            renderInput={(params) => (
                              <TextField
                                {...params}
                                placeholder="Software, Equipment, Displays..."
                                variant="outlined"
                                sx={{
                                  width: '100%',
                                  fontSize: '16px',
                                  fontWeight: '400',
                                  lineHeight: '24px',
                                  color: '#667085',
                                  borderRadius: '8px',
                                  '.MuiOutlinedInput-input': {
                                    padding: '10px 14px',
                                  },
                                  '& .MuiOutlinedInput-root': {
                                    '& fieldset': {
                                      border: 'none',
                                      outline: 'none',
                                    },
                                  },
                                  '& .MuiAutocomplete-input': {
                                    padding: '7.5px 4px 7.5px 0px !important',
                                  },
                                }}
                              />
                            )}
                          />
                        ) : (
                          <Autocomplete
                            fullWidth
                            disableClearable
                            freeSolo
                            // options={allAddresses.map((address) => address?.contexts?.region)}
                            options={allAddresses.map(formatAddressOption)}
                            value={tempNear}
                            onInputChange={handleNearChange}
                            onChange={(event, value) => {
                              handleSelectaddress(value);
                            }}
                            renderInput={(params) => (
                              <TextField
                                {...params}
                                InputProps={{
                                  ...params.InputProps,
                                  endAdornment: null, // Ensure no end adornment is displayed
                                }}
                                placeholder={
                                  type === 'national'
                                    ? 'North America'
                                    : type === 'distributor'
                                    ? 'Software, Equipment, Displays...'
                                    : 'City, State or Province'
                                }
                                sx={{
                                  width: '100%',
                                  fontSize: '16px',
                                  fontWeight: '400',
                                  lineHeight: '24px',
                                  color: '#667085',
                                  '.MuiOutlinedInput-input': {
                                    padding: '16.5px 5px',
                                  },
                                  '& .MuiOutlinedInput-root': {
                                    '& fieldset': {
                                      border: 'none',
                                      outline: 'none',
                                    },
                                  },
                                  '& .MuiAutocomplete-input': {
                                    padding: '7.5px 4px 7.5px 0px !important',
                                  },
                                }}
                                type="search"
                              />
                            )}
                          />
                        )}
                      </Box>{' '}
                      <Button
                        onClick={handleSearchData}
                        sx={{
                          width: '14%',
                          paddingTop: '5px',
                          paddingLeft: '5px',
                          border: '1px solid #000000',
                          fontFamily: 'SF UI Display',
                          fontSize: '16px',
                          fontWeight: '600',
                          lineHeight: '19.09px',
                          textAlign: 'center',
                          color: '#ffffff',
                          backgroundColor: 'transparent', // White background for top and left padding area
                          borderTopLeftRadius: '0',
                          borderBottomLeftRadius: '0',
                          textTransform: 'capitalize',
                          position: 'relative',
                          overflow: 'visible', // Allow overflow of pseudo-element
                          '&::before': {
                            content: '""',
                            position: 'absolute',
                            top: '3px', // Align with the padding
                            left: '3px', // Align with the padding
                            right: '-3px', // Extend 5px to the right for overflow
                            bottom: '-3px', // Extend 5px to the bottom for overflow
                            backgroundColor: '#00BD57', // Green color for overflow
                            zIndex: -1,
                            borderRadius: 'inherit',
                          },
                          '&:hover::before': {
                            backgroundColor: '#00BD57', // Ensure hover behavior matches overflow
                          },
                          '@media (max-width: 900px)': {
                            fontWeight: '500',
                          },
                        }}
                      >
                        Search
                      </Button>
                    </Box>
                  )}

                  <Box display={'flex'} gap={'10px'} alignItems={'center'}>
                    {window.location.pathname !== '/add-business' &&
                      window.location.pathname !== '/claim-company' && (
                        <Link to="/add-business">
                          <Button
                            sx={{
                              bgcolor: 'transparent',
                              borderRadius: '8px',
                              color: '#000000',
                              fontFamily: 'SF UI Display',
                              fontWeight: '600',
                              fontSize: '14px',
                              lineHeight: '16.71px',
                              textAlign: 'center',
                              textTransform: 'capitalize',
                              margin: '0px 10px 0px 5px',
                              whiteSpace: 'nowrap',
                              cursor: 'pointer',
                              '&:hover': {
                                bgcolor: 'transparent',
                              },
                            }}
                          >
                            Add Your Business
                          </Button>
                        </Link>
                      )}
                    {!['/add-business', '/claim-company'].includes(window.location.pathname) ||
                    screenId === 1 ? (
                      <Link to="/login">
                        <Button
                          // sx={{
                          //   bgcolor: 'transparent',
                          //   borderRadius: '8px',
                          //   color: '#00BD57',
                          //   fontFamily: 'Poppins',
                          //   fontWeight: '700',
                          //   fontSize: '14px',
                          //   lineHeight: '16.71px',
                          //   textAlign: 'center',
                          //   textTransform: 'capitalize',
                          //   padding: '10px 1.5%',
                          //   '&:hover': {
                          //     bgcolor: 'transparent',
                          //   },
                          // }}
                          sx={{
                            bgcolor: 'transparent',
                            borderRadius: '8px',
                            // color: '#00BD57',
                            color: 'rgba(0, 0, 0, 1)',
                            fontFamily: 'SF UI Display',
                            fontWeight: '600',
                            fontSize: '14px',
                            lineHeight: '16.71px',
                            textAlign: 'center',
                            textTransform: 'none',
                            // border: 'solid #00BD57 1px',
                            border: 'solid rgba(0, 0, 0, 1) 1px',
                            padding: '10px 1.5%',
                            width: '100px',
                            '&:hover': {
                              bgcolor: 'transparent',
                            },
                          }}
                        >
                          Log in
                        </Button>
                      </Link>
                    ) : null}
                    {/* {!['/add-business', '/claim-company'].includes(window.location.pathname) ||
                  screenId === 1 ? (
                    <Link to="/signup">
                      <Button
                        sx={{
                          bgcolor: 'transparent',
                          borderRadius: '8px',
                          color: '#00BD57',
                          fontFamily: 'Poppins',
                          fontWeight: '700',
                          fontSize: '14px',
                          lineHeight: '16.71px',
                          textAlign: 'center',
                          textTransform: 'capitalize',
                          border: 'solid #00BD57 1px',
                          padding: '10px 1.5%',
                          width: '100px',
                          '&:hover': {
                            bgcolor: 'transparent',
                          },
                        }}
                      >
                        Sign Up
                      </Button>
                    </Link>
                  ) : null} */}
                  </Box>
                </Box>
              )}
            </>
          ) : (
            <>
              {!signIn?._id && (
                <Box display="flex" alignItems="center">
                  <IconButton onClick={toggleMobileMenu} sx={{ color: '#00BD57' }}>
                    {mobileOpen ? <CloseIcon /> : <MenuIcon />}
                  </IconButton>
                  {mobileOpen && (
                    <Box
                      position="absolute"
                      top="70px"
                      right="0"
                      bgcolor="white"
                      boxShadow={3}
                      zIndex={999}
                      width="100vw"
                      display="flex"
                      flexDirection="column"
                      p={2}
                      borderRadius="8px"
                    >
                      {!signIn?._id && (
                        <Box
                          width="100%"
                          display="flex"
                          flexDirection="column"
                          justifyContent="center"
                          alignItems="center"
                          gap={'20px'}
                        >
                          {listingGrid && (
                            <Box
                              display={'flex'}
                              flexDirection={'column'}
                              alignItems={'center'}
                              borderRadius={'5px'}
                              width={'100%'}
                              gap={'15px'}
                            >
                              <Box
                                display={'flex'}
                                alignItems={'center'}
                                width={'100%'}
                                border={'1px solid #D7D8DD'}
                                sx={{
                                  borderRadius: '5px',
                                }}
                              >
                                <Typography
                                  fontSize={'16px'}
                                  fontWeight={'700'}
                                  fontFamily="SF UI Display"
                                  color={'#00BD57'}
                                  padding={'0 0 0 3%'}
                                >
                                  Find
                                </Typography>
                                <Autocomplete
                                  fullWidth
                                  freeSolo
                                  disableClearable
                                  options={companiesNames}
                                  value={tempSearch ?? searchTypo}
                                  onChange={handleSearchChange}
                                  onInputChange={handleInputSearch}
                                  getOptionLabel={(option: any) => option.name ?? ''}
                                  filterOptions={(options, state) => {
                                    if (state?.inputValue === '' && type !== 'local') {
                                      return []; // Return an empty array if the input is empty
                                    }
                                    const inputValue = state.inputValue.toLowerCase();
                                    const startsWithMatch = options.filter((option) =>
                                      option.name?.toLowerCase().startsWith(inputValue),
                                    );

                                    // Filter for options containing the input value but not starting with it
                                    const containsMatch = options.filter(
                                      (option) =>
                                        option.name?.toLowerCase().includes(inputValue) &&
                                        !option.name?.toLowerCase().startsWith(inputValue),
                                    );

                                    // Combine the filtered results, prioritize starting matches first
                                    const filteredOptions = [...startsWithMatch, ...containsMatch];

                                    return filteredOptions.slice(0, 8);
                                    // const filtered = filterOptions(options, state);
                                    // return filtered.slice(0, 8); // Ensures the maximum number of options is limited to 5
                                  }}
                                  renderOption={(props, option) => (
                                    <Box
                                      component="li"
                                      {...props}
                                      sx={{ display: 'flex', alignItems: 'center', gap: '15px' }}
                                    >
                                      <img
                                        style={{
                                          width: '40px',
                                          height: '40px',
                                          objectFit: 'cover',
                                        }}
                                        src={
                                          option?.logo
                                            ? option?.logo === 'fabricatorsicon'
                                              ? ServiceIcon2
                                              : option?.logo === 'installerIcon'
                                              ? ServiceIcon3
                                              : option?.logo === 'surveyIcon'
                                              ? ServiceIcon5
                                              : option?.logo === 'serviceIcon'
                                              ? ServiceIcon4
                                              : option?.logo === 'fullserviceicon'
                                              ? ServiceIcon1
                                              : option?.logo === 'printersIcon'
                                              ? printProduction
                                              : option?.logo === 'printInstallersIcon'
                                              ? PrintInstallation
                                              : option?.logo === 'wrapInstallersIcon'
                                              ? WrapInstallation
                                              : option?.logo === 'permitsIcon'
                                              ? ServiceIcon1
                                              : `${mediaUrl}/${option?.logo}`
                                            : defaultCompany
                                        }
                                        alt=""
                                      />
                                      {option.name}
                                    </Box>
                                  )}
                                  renderInput={(params) => (
                                    <TextField
                                      {...params}
                                      placeholder={
                                        type !== 'local'
                                          ? 'Enter Name [Optional]'
                                          : 'Installers, Fabricators, Service...'
                                      }
                                      sx={{
                                        width: '100%',
                                        fontSize: '15px !important',
                                        fontWeight: '400',
                                        lineHeight: '24px',
                                        color: '#667085',
                                        '.MuiOutlinedInput-input': {
                                          padding: '16.5px 10px 16.5px 0px',
                                          fontSize: '15px !important',
                                        },
                                        '& .MuiOutlinedInput-root': {
                                          '& fieldset': {
                                            border: 'none',
                                            outline: 'none',
                                          },
                                        },
                                        '@media (max-width: 900px)': {
                                          fontSize: '14px',
                                        },
                                      }}
                                      type="search"
                                    />
                                  )}
                                />
                                {/* <TextField
                                placeholder={
                                  type !== 'local'
                                    ? 'Enter Name [Optional]'
                                    : 'Installers, Fabricators, Service...'
                                }
                                value={tempSearch}
                                onChange={handleSearchChange}
                                sx={{
                                  width: '100%',
                                  fontSize: '16px',
                                  fontWeight: '400',
                                  lineHeight: '24px',
                                  color: '#667085',
                                  '& .MuiOutlinedInput-root': {
                                    '& fieldset': {
                                      border: 'none',
                                      outline: 'none',
                                    },
                                  },
                                  '& .MuiOutlinedInput-input': {
                                    padding: '16.5px 10px 16.5px 5px',
                                  },
                                }}
                                type="search"
                              /> */}
                              </Box>
                              <Box
                                display={'flex'}
                                alignItems={'center'}
                                width={'100%'}
                                border={'1px solid #D7D8DD'}
                                sx={{
                                  borderRadius: '5px',
                                }}
                              >
                                <Typography
                                  fontSize={'16px'}
                                  fontWeight={'700'}
                                  fontFamily="SF UI Display"
                                  color={'#00BD57'}
                                  padding={'15px 0 15px 3%'}
                                >
                                  {type === 'national'
                                    ? 'Location'
                                    : type === 'local'
                                    ? 'Near'
                                    : 'Category'}
                                </Typography>
                                {type === 'national' ? (
                                  <Autocomplete
                                    fullWidth
                                    disableClearable
                                    options={states}
                                    getOptionLabel={(option: string) => option}
                                    value={tempNear}
                                    onInputChange={handleNearChange}
                                    onChange={handleStateChange}
                                    renderInput={(params) => (
                                      <TextField
                                        {...params}
                                        placeholder="North America"
                                        variant="outlined"
                                        sx={{
                                          width: '100%',
                                          fontSize: '16px',
                                          fontWeight: '400',
                                          lineHeight: '24px',
                                          color: '#667085',
                                          borderRadius: '8px',
                                          '& .MuiOutlinedInput-root': {
                                            '& fieldset': {
                                              border: 'none',
                                              outline: 'none',
                                            },
                                          },
                                          '& .MuiAutocomplete-input': {
                                            padding: '7.5px 4px 7.5px 0px !important',
                                          },
                                        }}
                                      />
                                    )}
                                  />
                                ) : type === 'distributor' || type === 'supplier' ? (
                                  <Autocomplete
                                    fullWidth
                                    // options={category}
                                    // getOptionLabel={(option: any) => option?.category ?? near}
                                    options={category}
                                    // getOptionLabel={(option: any) =>
                                    //   (option.subCategory || option.category) ?? near
                                    // }
                                    disableClearable
                                    value={tempNear}
                                    // name="category"
                                    onChange={handleCategoryChange}
                                    renderInput={(params) => (
                                      <TextField
                                        {...params}
                                        placeholder="Software, Equipment, Displays..."
                                        variant="outlined"
                                        sx={{
                                          width: '100%',
                                          fontSize: '16px',
                                          fontWeight: '400',
                                          lineHeight: '24px',
                                          color: '#667085',
                                          borderRadius: '8px',
                                          '.MuiOutlinedInput-input': {
                                            padding: '10px 14px',
                                          },
                                          '& .MuiOutlinedInput-root': {
                                            '& fieldset': {
                                              border: 'none',
                                              outline: 'none',
                                            },
                                          },
                                          '& .MuiAutocomplete-input': {
                                            padding: '7.5px 4px 7.5px 0px !important',
                                          },
                                        }}
                                      />
                                    )}
                                  />
                                ) : (
                                  <Autocomplete
                                    fullWidth
                                    disableClearable
                                    freeSolo
                                    // options={allAddresses.map((address) => address?.contexts?.region)}
                                    options={allAddresses.map(formatAddressOption)}
                                    value={tempNear}
                                    onInputChange={handleNearChange}
                                    onChange={(event, value) => {
                                      handleSelectaddress(value);
                                    }}
                                    renderInput={(params) => (
                                      <TextField
                                        {...params}
                                        InputProps={{
                                          ...params.InputProps,
                                          endAdornment: null, // Ensure no end adornment is displayed
                                        }}
                                        placeholder={
                                          type === 'national'
                                            ? 'North America'
                                            : type === 'distributor'
                                            ? 'Software, Equipment, Displays...'
                                            : 'City, State or Province'
                                        }
                                        sx={{
                                          width: '100%',
                                          fontSize: '16px',
                                          fontWeight: '400',
                                          lineHeight: '24px',
                                          color: '#667085',
                                          '.MuiOutlinedInput-input': {
                                            padding: '16.5px 5px',
                                          },
                                          '& .MuiOutlinedInput-root': {
                                            '& fieldset': {
                                              border: 'none',
                                              outline: 'none',
                                            },
                                          },
                                          '& .MuiAutocomplete-input': {
                                            padding: '7.5px 4px 7.5px 0px !important',
                                          },
                                        }}
                                        type="search"
                                      />
                                    )}
                                  />
                                )}
                              </Box>
                              <Button
                                onClick={() => {
                                  handleSearchData?.();
                                  setMobileOpen(false);
                                }}
                                sx={{
                                  width: '150px',
                                  paddingTop: '5px',
                                  paddingLeft: '5px',
                                  border: '1px solid #000000',
                                  fontFamily: 'SF UI Display',
                                  fontSize: '16px',
                                  fontWeight: '600',
                                  lineHeight: '19.09px',
                                  textAlign: 'center',
                                  color: '#ffffff',
                                  backgroundColor: 'transparent', // White background for top and left padding area
                                  borderTopLeftRadius: '0',
                                  borderBottomLeftRadius: '0',
                                  textTransform: 'capitalize',
                                  position: 'relative',
                                  overflow: 'visible', // Allow overflow of pseudo-element
                                  '&::before': {
                                    content: '""',
                                    position: 'absolute',
                                    top: '3px', // Align with the padding
                                    left: '3px', // Align with the padding
                                    right: '-3px', // Extend 5px to the right for overflow
                                    bottom: '-3px', // Extend 5px to the bottom for overflow
                                    backgroundColor: '#00BD57', // Green color for overflow
                                    zIndex: -1,
                                    borderRadius: 'inherit',
                                  },
                                  '&:hover::before': {
                                    backgroundColor: '#00BD57', // Ensure hover behavior matches overflow
                                  },
                                  '@media (max-width: 900px)': {
                                    fontWeight: '500',
                                    width: '40%',
                                    padding: '8px 0',
                                  },
                                }}
                              >
                                Search
                              </Button>
                            </Box>
                          )}
                          {window.location.pathname !== '/add-business' &&
                            window.location.pathname !== '/claim-company' && (
                              <Link to="/add-business">
                                <Button
                                  sx={{
                                    bgcolor: 'transparent',
                                    borderRadius: '8px',
                                    color: '#000000',
                                    fontFamily: 'SF UI Display',
                                    fontWeight: '400',
                                    fontSize: '14px',
                                    lineHeight: '16.71px',
                                    textAlign: 'center',
                                    textTransform: 'capitalize',
                                    margin: '0px 10px 0px 5px',
                                    whiteSpace: 'nowrap',
                                    cursor: 'pointer',
                                    '&:hover': {
                                      bgcolor: 'transparent',
                                    },
                                  }}
                                >
                                  Add Your Business
                                </Button>
                              </Link>
                            )}
                          <Link to="/login">
                            <Button
                              // sx={{
                              //   bgcolor: 'transparent',
                              //   borderRadius: '8px',
                              //   color: '#00BD57',
                              //   fontFamily: 'Poppins',
                              //   fontWeight: '700',
                              //   fontSize: '14px',
                              //   lineHeight: '16.71px',
                              //   textAlign: 'center',
                              //   textTransform: 'capitalize',
                              //   padding: '10px 1.5%',
                              //   '&:hover': {
                              //     bgcolor: 'transparent',
                              //   },
                              // }}
                              sx={{
                                bgcolor: 'transparent',
                                borderRadius: '8px',
                                color: '#00BD57',
                                fontFamily: 'Poppins',
                                fontWeight: '700',
                                fontSize: '14px',
                                lineHeight: '16.71px',
                                textAlign: 'center',
                                textTransform: 'capitalize',
                                border: 'solid #00BD57 1px',
                                padding: '10px 1.5%',
                                width: '100px',
                                '&:hover': {
                                  bgcolor: 'transparent',
                                },
                              }}
                            >
                              Log In
                            </Button>
                          </Link>
                          {/* <Link to="/signup">
                          <Button
                            sx={{
                              bgcolor: 'transparent',
                              borderRadius: '8px',
                              color: '#00BD57',
                              fontFamily: 'Poppins',
                              fontWeight: '700',
                              fontSize: '14px',
                              lineHeight: '16.71px',
                              textAlign: 'center',
                              textTransform: 'capitalize',
                              border: 'solid #00BD57 1px',
                              padding: '10px 1.5%',
                              width: '100px',
                              '&:hover': {
                                bgcolor: 'transparent',
                              },
                            }}
                          >
                            Sign Up
                          </Button>
                        </Link> */}
                        </Box>
                      )}
                    </Box>
                  )}
                </Box>
              )}
            </>
          )}
          {signIn?._id && (
            <>
              {listingGrid && !isMobile && (
                <Box
                  display={'flex'}
                  border={'1px solid #D7D8DD'}
                  borderRadius={'5px'}
                  width={'60%'}
                  height={'50px'}
                >
                  <Box width={'100%'} display={'flex'}>
                    <Box
                      display={'flex'}
                      alignItems={'center'}
                      width={'50%'}
                      borderRight={'1px solid #D7D8DD'}
                    >
                      <Typography
                        fontSize={'16px'}
                        fontWeight={'700'}
                        fontFamily="SF UI Display"
                        color={'#00BD57'}
                        padding={'0 0 0 3%'}
                      >
                        Find
                      </Typography>
                      <Autocomplete
                        fullWidth
                        freeSolo
                        disableClearable
                        options={companiesNames}
                        value={tempSearch ?? searchTypo}
                        onChange={handleSearchChange}
                        onInputChange={handleInputSearch}
                        getOptionLabel={(option: any) => option.name ?? ''}
                        filterOptions={(options, state) => {
                          if (state?.inputValue === '' && type !== 'local') {
                            return []; // Return an empty array if the input is empty
                          }
                          const inputValue = state.inputValue.toLowerCase();
                          const startsWithMatch = options.filter((option) =>
                            option.name?.toLowerCase().startsWith(inputValue),
                          );

                          // Filter for options containing the input value but not starting with it
                          const containsMatch = options.filter(
                            (option) =>
                              option.name?.toLowerCase().includes(inputValue) &&
                              !option.name?.toLowerCase().startsWith(inputValue),
                          );

                          // Combine the filtered results, prioritize starting matches first
                          const filteredOptions = [...startsWithMatch, ...containsMatch];

                          return filteredOptions.slice(0, 8);
                          // return options
                          //   .filter((option) => option.name?.toLowerCase().startsWith(inputValue))
                          //   .slice(0, 8);
                          // const filtered = filterOptions(options, state);
                          // return filtered.slice(0, 8); // Ensures the maximum number of options is limited to 5
                        }}
                        renderOption={(props, option) => (
                          <Box
                            component="li"
                            {...props}
                            sx={{ display: 'flex', alignItems: 'center', gap: '15px' }}
                          >
                            <img
                              style={{ width: '40px', height: '40px', objectFit: 'cover' }}
                              src={
                                option?.logo
                                  ? option?.logo === 'fabricatorsicon'
                                    ? ServiceIcon2
                                    : option?.logo === 'installerIcon'
                                    ? ServiceIcon3
                                    : option?.logo === 'surveyIcon'
                                    ? ServiceIcon5
                                    : option?.logo === 'serviceIcon'
                                    ? ServiceIcon4
                                    : option?.logo === 'fullserviceicon'
                                    ? ServiceIcon1
                                    : option?.logo === 'printersIcon'
                                    ? printProduction
                                    : option?.logo === 'printInstallersIcon'
                                    ? PrintInstallation
                                    : option?.logo === 'wrapInstallersIcon'
                                    ? WrapInstallation
                                    : option?.logo === 'permitsIcon'
                                    ? ServiceIcon1
                                    : `${mediaUrl}/${option?.logo}`
                                  : defaultCompany
                              }
                              alt=""
                            />
                            {option.name}
                          </Box>
                        )}
                        renderInput={(params) => (
                          <TextField
                            {...params}
                            placeholder={
                              type !== 'local'
                                ? 'Enter Name [Optional]'
                                : 'Installers, Fabricators, Service...'
                            }
                            sx={{
                              width: '100%',
                              fontSize: '15px !important',
                              fontWeight: '400',
                              lineHeight: '24px',
                              color: '#667085',
                              '.MuiOutlinedInput-input': {
                                padding: '7.5px 4px 7.5px 0px',
                                fontSize: '15px !important',
                              },
                              '& .MuiOutlinedInput-root': {
                                '& fieldset': {
                                  border: 'none',
                                  outline: 'none',
                                },
                              },
                              '@media (max-width: 900px)': {
                                fontSize: '14px',
                              },
                            }}
                            type="search"
                          />
                        )}
                      />
                      {/* <TextField
                      placeholder={
                        type !== 'local'
                          ? 'Enter Name [Optional]'
                          : 'Installers, Fabricators, Service...'
                      }
                      value={tempSearch}
                      onChange={handleSearchChange}
                      sx={{
                        width: '100%',
                        fontSize: '16px',
                        fontWeight: '400',
                        lineHeight: '24px',
                        color: '#667085',
                        '& .MuiOutlinedInput-root': {
                          '& fieldset': {
                            border: 'none',
                            outline: 'none',
                          },
                          '& .MuiOutlinedInput-input': {
                            padding: '16.5px 0px',
                          },
                        },
                      }}
                      type="search"
                    /> */}
                    </Box>
                    <Box display={'flex'} alignItems={'center'} width={'50%'}>
                      <Typography
                        fontSize={'16px'}
                        fontFamily="SF UI Display"
                        fontWeight={'700'}
                        color={'#00BD57'}
                        padding={'0 0 0 3%'}
                      >
                        {type === 'national' ? 'Location' : type === 'local' ? 'Near' : 'Category'}
                      </Typography>
                      {type === 'national' ? (
                        <Autocomplete
                          fullWidth
                          options={states}
                          disableClearable
                          getOptionLabel={(option: string) => option}
                          value={tempNear}
                          onInputChange={handleNearChange}
                          onChange={handleStateChange}
                          renderInput={(params) => (
                            <TextField
                              {...params}
                              placeholder="North America"
                              variant="outlined"
                              sx={{
                                width: '100%',
                                fontSize: '16px',
                                fontWeight: '400',
                                lineHeight: '24px',
                                color: '#667085',
                                borderRadius: '8px',
                                '.MuiOutlinedInput-input': {
                                  padding: '10px 14px',
                                },
                                '& .MuiOutlinedInput-root': {
                                  '& fieldset': {
                                    border: 'none',
                                    outline: 'none',
                                  },
                                },
                                '& .MuiAutocomplete-input': {
                                  padding: '7.5px 4px 7.5px 0px !important',
                                },
                              }}
                            />
                          )}
                        />
                      ) : type === 'distributor' || type === 'supplier' ? (
                        <Autocomplete
                          fullWidth
                          // options={category}
                          // getOptionLabel={(option: any) => option?.category ?? near}
                          options={category}
                          // getOptionLabel={(option: any) =>
                          //   (option.subCategory || option.category) ?? near
                          // }
                          value={tempNear}
                          // name="category"
                          onChange={handleCategoryChange}
                          renderInput={(params) => (
                            <TextField
                              {...params}
                              placeholder="Software, Equipment, Displays..."
                              variant="outlined"
                              sx={{
                                width: '100%',
                                fontSize: '16px',
                                fontWeight: '400',
                                lineHeight: '24px',
                                color: '#667085',
                                borderRadius: '8px',
                                '.MuiOutlinedInput-input': {
                                  padding: '10px 14px',
                                },
                                '& .MuiOutlinedInput-root': {
                                  '& fieldset': {
                                    border: 'none',
                                    outline: 'none',
                                  },
                                },
                                '& .MuiAutocomplete-input': {
                                  padding: '7.5px 4px 7.5px 0px !important',
                                },
                              }}
                            />
                          )}
                        />
                      ) : (
                        <Autocomplete
                          fullWidth
                          disableClearable
                          freeSolo
                          // options={allAddresses.map((address) => address?.contexts?.region)}
                          options={allAddresses.map(formatAddressOption)}
                          value={tempNear}
                          onInputChange={handleNearChange}
                          onChange={(event, value) => {
                            handleSelectaddress(value);
                          }}
                          renderInput={(params) => (
                            <TextField
                              {...params}
                              InputProps={{
                                ...params.InputProps,
                                endAdornment: null, // Ensure no end adornment is displayed
                              }}
                              placeholder={
                                type === 'national'
                                  ? 'North America'
                                  : type === 'local'
                                  ? 'City, State or Province'
                                  : 'Software, Equipment, Displays...'
                              }
                              sx={{
                                width: '100%',
                                fontSize: '16px',
                                fontWeight: '400',
                                lineHeight: '24px',
                                color: '#667085',
                                '.MuiOutlinedInput-input': {
                                  padding: '16.5px 5px',
                                },
                                '& .MuiOutlinedInput-root': {
                                  '& fieldset': {
                                    border: 'none',
                                    outline: 'none',
                                  },
                                },
                                '& .MuiAutocomplete-input': {
                                  padding: '7.5px 4px 7.5px 0px !important',
                                },
                              }}
                              type="search"
                            />
                          )}
                        />
                      )}
                    </Box>{' '}
                  </Box>
                  <Button
                    onClick={handleSearchData}
                    sx={{
                      width: '14%',
                      paddingTop: '5px',
                      paddingLeft: '5px',
                      border: '1px solid #000000',
                      fontFamily: 'SF UI Display',
                      fontSize: '16px',
                      fontWeight: '600',
                      lineHeight: '19.09px',
                      textAlign: 'center',
                      color: '#ffffff',
                      backgroundColor: 'transparent', // White background for top and left padding area
                      borderTopLeftRadius: '0',
                      borderBottomLeftRadius: '0',
                      textTransform: 'capitalize',
                      position: 'relative',
                      overflow: 'visible', // Allow overflow of pseudo-element
                      '&::before': {
                        content: '""',
                        position: 'absolute',
                        top: '3px', // Align with the padding
                        left: '3px', // Align with the padding
                        right: '-3px', // Extend 5px to the right for overflow
                        bottom: '-3px', // Extend 5px to the bottom for overflow
                        backgroundColor: '#00BD57', // Green color for overflow
                        zIndex: -1,
                        borderRadius: 'inherit',
                      },
                      '&:hover::before': {
                        backgroundColor: '#00BD57', // Ensure hover behavior matches overflow
                      },
                      '@media (max-width: 900px)': {
                        fontWeight: '500',
                      },
                    }}
                  >
                    Search
                  </Button>
                </Box>
              )}
              <Box display={'flex'} alignItems={'center'} gap={'16px'}>
                {isMobile && (
                  <Box display="flex" alignItems="center">
                    {window.location.pathname !== '/' && (
                      <IconButton onClick={toggleMobileMenu} sx={{ color: '#000000' }}>
                        {mobileOpen ? <CloseIcon /> : <SearchIcon />}
                      </IconButton>
                    )}
                    {mobileOpen && (
                      <Box
                        position="absolute"
                        top="70px"
                        right="0"
                        bgcolor="white"
                        boxShadow={3}
                        zIndex={999}
                        width="100vw"
                        display="flex"
                        flexDirection="column"
                        p={2}
                        borderRadius="8px"
                      >
                        <Box
                          display={'flex'}
                          alignItems={'center'}
                          flexDirection={'column'}
                          borderRadius={'5px'}
                          width={'100%'}
                          gap={'15px'}
                        >
                          <Box
                            display={'flex'}
                            alignItems={'center'}
                            width={'100%'}
                            border={'1px solid #D7D8DD'}
                          >
                            <Typography
                              fontSize={'16px'}
                              fontWeight={'700'}
                              fontFamily="SF UI Display"
                              color={'#00BD57'}
                              padding={'0 0 0 3%'}
                            >
                              Find
                            </Typography>
                            <Autocomplete
                              fullWidth
                              freeSolo
                              disableClearable
                              options={companiesNames}
                              value={tempSearch ?? searchTypo}
                              onChange={handleSearchChange}
                              onInputChange={handleInputSearch}
                              getOptionLabel={(option: any) => option.name ?? ''}
                              filterOptions={(options, state) => {
                                if (state?.inputValue === '' && type !== 'local') {
                                  return []; // Return an empty array if the input is empty
                                }
                                const inputValue = state.inputValue.toLowerCase();
                                const startsWithMatch = options.filter((option) =>
                                  option.name?.toLowerCase().startsWith(inputValue),
                                );

                                // Filter for options containing the input value but not starting with it
                                const containsMatch = options.filter(
                                  (option) =>
                                    option.name?.toLowerCase().includes(inputValue) &&
                                    !option.name?.toLowerCase().startsWith(inputValue),
                                );

                                // Combine the filtered results, prioritize starting matches first
                                const filteredOptions = [...startsWithMatch, ...containsMatch];

                                return filteredOptions.slice(0, 8);
                                // const filtered = filterOptions(options, state);
                                // return filtered.slice(0, 8); // Ensures the maximum number of options is limited to 5
                              }}
                              renderOption={(props, option) => (
                                <Box
                                  component="li"
                                  {...props}
                                  sx={{ display: 'flex', alignItems: 'center', gap: '15px' }}
                                >
                                  <img
                                    style={{ width: '40px', height: '40px', objectFit: 'cover' }}
                                    src={
                                      option?.logo
                                        ? option?.logo === 'fabricatorsicon'
                                          ? ServiceIcon2
                                          : option?.logo === 'installerIcon'
                                          ? ServiceIcon3
                                          : option?.logo === 'surveyIcon'
                                          ? ServiceIcon5
                                          : option?.logo === 'serviceIcon'
                                          ? ServiceIcon4
                                          : option?.logo === 'fullserviceicon'
                                          ? ServiceIcon1
                                          : option?.logo === 'printersIcon'
                                          ? printProduction
                                          : option?.logo === 'printInstallersIcon'
                                          ? PrintInstallation
                                          : option?.logo === 'wrapInstallersIcon'
                                          ? WrapInstallation
                                          : option?.logo === 'permitsIcon'
                                          ? ServiceIcon1
                                          : `${mediaUrl}/${option?.logo}`
                                        : defaultCompany
                                    }
                                    alt=""
                                  />
                                  {option.name}
                                </Box>
                              )}
                              renderInput={(params) => (
                                <TextField
                                  {...params}
                                  placeholder={
                                    type !== 'local'
                                      ? 'Enter Name [Optional]'
                                      : 'Installers, Fabricators, Service...'
                                  }
                                  sx={{
                                    width: '100%',
                                    fontSize: '15px !important',
                                    fontWeight: '400',
                                    lineHeight: '24px',
                                    color: '#667085',
                                    '.MuiOutlinedInput-input': {
                                      padding: '7.5px 4px 7.5px 0px !important',
                                      fontSize: '15px !important',
                                    },
                                    '& .MuiOutlinedInput-root': {
                                      '& fieldset': {
                                        border: 'none',
                                        outline: 'none',
                                      },
                                    },
                                    '@media (max-width: 900px)': {
                                      fontSize: '14px',
                                    },
                                  }}
                                  type="search"
                                />
                              )}
                            />
                            {/* <TextField
                              placeholder={
                                type !== 'local'
                                  ? 'Enter Name [Optional]'
                                  : 'Installers, Fabricators, Service...'
                              }
                              value={tempSearch}
                              onChange={handleSearchChange}
                              sx={{
                                width: '100%',
                                fontSize: '16px',
                                fontWeight: '400',
                                lineHeight: '24px',
                                color: '#667085',

                                '& .MuiOutlinedInput-root': {
                                  '& fieldset': {
                                    border: 'none',
                                    outline: 'none',
                                  },
                                  '& .MuiOutlinedInput-input': {
                                    padding: '16.5px 10px 16.5px 5px',
                                  },
                                },
                              }}
                              type="search"
                            /> */}
                          </Box>
                          <Box
                            display={'flex'}
                            alignItems={'center'}
                            width={'100%'}
                            border={'1px solid #D7D8DD'}
                          >
                            <Typography
                              fontSize={'16px'}
                              fontWeight={'700'}
                              fontFamily="SF UI Display"
                              color={'#00BD57'}
                              padding={'0 0 0 3%'}
                            >
                              {type === 'national'
                                ? 'Location'
                                : type === 'local'
                                ? 'Near'
                                : 'Category'}
                            </Typography>
                            {type === 'national' ? (
                              <Autocomplete
                                fullWidth
                                options={states}
                                getOptionLabel={(option: string) => option}
                                value={tempNear}
                                onInputChange={handleNearChange}
                                onChange={handleStateChange}
                                renderInput={(params) => (
                                  <TextField
                                    {...params}
                                    placeholder="North America"
                                    variant="outlined"
                                    sx={{
                                      width: '100%',
                                      fontSize: '16px',
                                      fontWeight: '400',
                                      lineHeight: '24px',
                                      color: '#667085',
                                      borderRadius: '8px',
                                      '.MuiOutlinedInput-input': {
                                        padding: '10px 14px',
                                      },
                                      '& .MuiOutlinedInput-root': {
                                        '& fieldset': {
                                          border: 'none',
                                          outline: 'none',
                                        },
                                      },
                                      '& .MuiAutocomplete-input': {
                                        padding: '7.5px 4px 7.5px 0px !important',
                                      },
                                    }}
                                  />
                                )}
                              />
                            ) : type === 'distributor' || type === 'supplier' ? (
                              <Autocomplete
                                fullWidth
                                // options={category}
                                // getOptionLabel={(option: any) => option?.category ?? near}
                                options={category}
                                // getOptionLabel={(option: any) =>
                                //   (option.subCategory || option.category) ?? near
                                // }
                                value={tempNear}
                                // name="category"
                                onChange={handleCategoryChange}
                                renderInput={(params) => (
                                  <TextField
                                    {...params}
                                    placeholder="Software, Equipment, Displays..."
                                    variant="outlined"
                                    sx={{
                                      width: '100%',
                                      fontSize: '16px',
                                      fontWeight: '400',
                                      lineHeight: '24px',
                                      color: '#667085',
                                      borderRadius: '8px',
                                      '.MuiOutlinedInput-input': {
                                        padding: '10px 14px',
                                      },
                                      '& .MuiOutlinedInput-root': {
                                        '& fieldset': {
                                          border: 'none',
                                          outline: 'none',
                                        },
                                      },
                                      '& .MuiAutocomplete-input': {
                                        padding: '7.5px 4px 7.5px 0px !important',
                                      },
                                    }}
                                  />
                                )}
                              />
                            ) : (
                              <Autocomplete
                                fullWidth
                                disableClearable
                                freeSolo
                                // options={allAddresses.map((address) => address?.contexts?.region)}
                                options={allAddresses.map(formatAddressOption)}
                                value={tempNear}
                                onInputChange={handleNearChange}
                                onChange={(event, value) => {
                                  handleSelectaddress(value);
                                }}
                                renderInput={(params) => (
                                  <TextField
                                    {...params}
                                    InputProps={{
                                      ...params.InputProps,
                                      endAdornment: null, // Ensure no end adornment is displayed
                                    }}
                                    placeholder={
                                      type === 'national'
                                        ? 'North America'
                                        : type === 'distributor'
                                        ? 'Software, Equipment, Displays...'
                                        : 'City, State or Province'
                                    }
                                    sx={{
                                      width: '100%',
                                      fontSize: '16px',
                                      fontWeight: '400',
                                      lineHeight: '24px',
                                      color: '#667085',
                                      '.MuiOutlinedInput-input': {
                                        padding: '16.5px 5px',
                                      },
                                      '& .MuiOutlinedInput-root': {
                                        '& fieldset': {
                                          border: 'none',
                                          outline: 'none',
                                        },
                                      },
                                      '& .MuiAutocomplete-input': {
                                        padding: '7.5px 4px 7.5px 0px !important',
                                      },
                                    }}
                                    type="search"
                                  />
                                )}
                              />
                            )}
                          </Box>{' '}
                          <Button
                            onClick={() => {
                              handleSearchData?.();
                              setMobileOpen(false);
                            }}
                            sx={{
                              width: '150px',
                              paddingTop: '5px',
                              paddingLeft: '5px',
                              border: '1px solid #000000',
                              fontFamily: 'SF UI Display',
                              fontSize: '16px',
                              fontWeight: '600',
                              lineHeight: '19.09px',
                              textAlign: 'center',
                              color: '#ffffff',
                              backgroundColor: 'transparent', // White background for top and left padding area
                              borderTopLeftRadius: '0',
                              borderBottomLeftRadius: '0',
                              textTransform: 'capitalize',
                              position: 'relative',
                              overflow: 'visible', // Allow overflow of pseudo-element
                              '&::before': {
                                content: '""',
                                position: 'absolute',
                                top: '3px', // Align with the padding
                                left: '3px', // Align with the padding
                                right: '-3px', // Extend 5px to the right for overflow
                                bottom: '-3px', // Extend 5px to the bottom for overflow
                                backgroundColor: '#00BD57', // Green color for overflow
                                zIndex: -1,
                                borderRadius: 'inherit',
                              },
                              '&:hover::before': {
                                backgroundColor: '#00BD57', // Ensure hover behavior matches overflow
                              },
                              '@media (max-width: 900px)': {
                                fontWeight: '500',
                                width: '40%',
                                padding: '8px 0',
                              },
                            }}
                          >
                            Search
                          </Button>
                        </Box>
                      </Box>
                    )}
                  </Box>
                )}
                {allowNotification?.notification &&
                signIn?.company?.companyInfo?.verification?.status &&
                checkUserCompany() ? (
                  <Box width="25px" position="relative" onClick={handleNotificationClick}>
                    <img
                      src={Notification}
                      alt="Notification"
                      style={{ width: '100%', cursor: 'pointer' }}
                    />
                    {count !== 0 && (
                      <>
                        {' '}
                        <Box
                          display="flex"
                          justifyContent="center"
                          alignItems="center"
                          bgcolor="#00BD57"
                          color="#FFFFFF"
                          fontSize="9px"
                          fontWeight="500"
                          fontFamily="Inter"
                          width="14px"
                          height="14px"
                          borderRadius="50%"
                          position="absolute"
                          top="0"
                          right="0"
                        >
                          {count ?? '0'}
                        </Box>
                      </>
                    )}
                  </Box>
                ) : (
                  ''
                )}
                {openPopover && (
                  <Popover
                    open={Boolean(openPopover)}
                    anchorEl={openPopover}
                    onClose={() => {
                      setOpenPopover(null);
                      getNotification();
                    }}
                    anchorOrigin={{
                      vertical: 'bottom',
                      horizontal: 'right',
                    }}
                    transformOrigin={{
                      vertical: 'top',
                      horizontal: 'right',
                    }}
                    sx={{
                      '& .MuiPaper-root': {
                        borderRadius: '10px',
                        border: 'solid 1px #EAECF0',
                      },
                    }}
                  >
                    <Box
                      width="350px"
                      display="flex"
                      // justifyContent="center"
                      alignItems="center"
                      flexDirection="column"
                      borderRadius="10px"
                      maxHeight="60vh"
                      padding={'13px'}
                    >
                      {notifications?.length ? (
                        notifications.map((item: any, index: number) => {
                          return (
                            <>
                              <Box
                                key={index}
                                bgcolor={item?.read === false ? '#DFFFED' : '#f8f8f8'}
                                padding={'10px'}
                              >
                                <Typography
                                  fontSize={'18px'}
                                  fontWeight={'600'}
                                  textTransform={'capitalize'}
                                >
                                  {/* {item?.data?.type} */}
                                  Notification
                                </Typography>
                                <Typography fontSize={'16px'} fontWeight={'400'} padding={'0% 4%'}>
                                  {item?.message}
                                </Typography>
                              </Box>
                              <Divider sx={{ bgcolor: '#EBEEF1', width: '100%' }} />
                            </>
                          );
                        })
                      ) : (
                        <Typography textAlign={'left'} width={'100%'}>
                          No Notification Found
                        </Typography>
                      )}
                    </Box>
                  </Popover>
                )}
                <Box
                  display="flex"
                  alignItems="center"
                  justifyContent={'right'}
                  gap={'8%'}
                  // width={'15%'}
                >
                  {/* <Typography
                fontFamily={'Poppins'}
                fontSize={'15px'}
                fontWeight={'500'}
                lineHeight={'21px'}
                textAlign={'center'}
                display={'flex'}
                alignItems={'center'}
                gap={'2%'}
                whiteSpace={'nowrap'}
                margin={'0 1%'}
                color={'#000000'}
              >
                Resources
                <KeyboardArrowDownIcon />
              </Typography> */}
                  <Avatar
                    src={signIn?.photo ? `${mediaUrl}/${signIn.photo}` : undefined}
                    alt={signIn?.first_name}
                    onClick={handleAvatarClick}
                    sx={{
                      bgcolor: '#00BD57',
                      fontSize: '19px',
                      width: '40px',
                      height: '40px',
                      fontFamily: 'inter',
                      fontWeight: '600',
                      cursor: 'pointer',
                    }}
                  >
                    {!signIn?.photo &&
                      `${signIn?.first_name?.[0] ?? ''}${signIn?.last_name?.[0] ?? ''}`}
                  </Avatar>

                  {profilePopupAnchor && (
                    <Popover
                      open={Boolean(profilePopupAnchor)}
                      anchorEl={profilePopupAnchor}
                      onClose={() => {
                        setProfilePopupAnchor(null);
                      }}
                      anchorOrigin={{
                        vertical: 'bottom',
                        horizontal: 'right',
                      }}
                      transformOrigin={{
                        vertical: 'top',
                        horizontal: 'right',
                      }}
                      sx={{
                        '& .MuiPaper-root': {
                          borderRadius: '10px',
                          border: 'solid 1px #EAECF0',
                        },
                      }}
                    >
                      <Box
                        width="263px"
                        display="flex"
                        justifyContent="center"
                        alignItems="center"
                        flexDirection="column"
                        borderRadius="10px"
                      >
                        <Box
                          width="100%"
                          p="0 4%"
                          m="4% 0"
                          display="flex"
                          justifyContent="space-between"
                          alignItems="center"
                          sx={{ cursor: 'pointer' }}
                          onClick={() => {
                            // if (!signIn?.company) {
                            //   nav('/add-business', { state: { screenNumber: 4 } });
                            //   return;
                            // }
                            if (checkUserCompany()) {
                              nav('/localpageowner/job-gallery');
                            } else {
                              nav('/localpageowner/SettingPagesetup');
                            }
                            setProfilePopupAnchor(null);
                          }}
                        >
                          <Box width="40px" height="40px">
                            <Avatar
                              src={signIn?.photo ? `${mediaUrl}/${signIn.photo}` : undefined}
                              alt={signIn?.first_name}
                              sx={{
                                bgcolor: '#00BD57',
                                fontSize: '19px',
                                width: '100%',
                                height: '100%',
                                fontFamily: 'inter',
                                fontWeight: '600',
                              }}
                            >
                              {!signIn?.photo &&
                                `${signIn?.first_name?.[0] ?? ''}${signIn?.last_name?.[0] ?? ''}`}
                            </Avatar>
                          </Box>
                          <Box width="80%">
                            <Typography
                              fontFamily="SF UI Display"
                              fontSize="14px"
                              fontWeight="600"
                              lineHeight="20px"
                              color="#344054"
                              textTransform={'capitalize'}
                            >
                              {/* Gloria Ice */}
                              {signIn?.first_name} {signIn?.last_name}
                            </Typography>
                            <Typography
                              fontFamily="SF UI Display"
                              fontSize="14px"
                              fontWeight="400"
                              lineHeight="20px"
                              color="#475467"
                              textTransform={'capitalize'}
                            >
                              {signIn?.company?.companyInfo?.name}
                              {/* D’s Signs & Lighting LLC */}
                            </Typography>
                          </Box>
                        </Box>
                        <Divider sx={{ bgcolor: '#EBEEF1', width: '100%' }} />
                        <Box
                          width="100%"
                          p="0 4%"
                          m="4% 0"
                          display="flex"
                          alignItems="center"
                          gap="5px"
                          // sx={{
                          //   cursor: !signIn?.company ? 'default' : 'pointer',
                          //   opacity: !signIn?.company ? 0.5 : 1,
                          // }}
                          sx={{
                            cursor: 'pointer',
                            opacity: 1,
                          }}
                          onClick={() => {
                            // if (!signIn?.company) return;
                            if (checkUserCompany()) {
                              nav('/localpageowner/job-gallery');
                            } else {
                              nav('/localpageowner/SettingPagesetup');
                            }
                            setProfilePopupAnchor(null);
                          }}
                        >
                          <img
                            src={DashBoardIcon}
                            alt=".."
                            style={{ width: '13.33px', height: '13.33px' }}
                          />
                          <Typography
                            fontFamily="SF UI Display"
                            fontWeight="500"
                            lineHeight="20px"
                            fontSize="14px"
                          >
                            Dashboard
                          </Typography>
                        </Box>
                        <Divider sx={{ bgcolor: '#EBEEF1', width: '100%' }} />
                        <Box
                          width="100%"
                          p="0 4%"
                          m="4% 0"
                          display="flex"
                          alignItems="center"
                          gap="5px"
                          sx={{
                            cursor: claimStatus ? 'default' : 'pointer',
                            opacity: claimStatus ? 0.5 : 1,
                          }}
                          onClick={() => {
                            if (!claimStatus) {
                              nav('/localpageowner/SettingPagesetup');
                              setProfilePopupAnchor(null);
                            }
                          }}
                        >
                          <img
                            src={SettingIcon}
                            alt=".."
                            style={{ width: '13.33px', height: '13.33px' }}
                          />
                          <Typography
                            fontFamily="SF UI Display"
                            fontWeight="500"
                            lineHeight="20px"
                            fontSize="14px"
                          >
                            Account Settings
                          </Typography>
                        </Box>
                        <Divider sx={{ bgcolor: '#EBEEF1', width: '100%' }} />
                        <Box
                          width="100%"
                          p="0 4%"
                          m="4% 0"
                          display="flex"
                          alignItems="center"
                          gap="5px"
                          onClick={(): void => {
                            localStorage.removeItem('user');
                            localStorage.removeItem('token');
                            window.location.href = '/';
                            if (updateStore) {
                              updateStore({ user: null });
                            }
                          }}
                          sx={{
                            cursor: 'pointer',
                          }}
                        >
                          <img
                            src={SignOutIcon}
                            alt=".."
                            style={{ width: '13.33px', height: '13.33px' }}
                          />
                          <Typography
                            fontFamily="SF UI Display"
                            fontWeight="500"
                            lineHeight="20px"
                            fontSize="14px"
                            sx={{
                              cursor: 'pointer',
                            }}
                          >
                            Sign Out
                          </Typography>
                        </Box>
                      </Box>
                    </Popover>
                  )}
                </Box>
              </Box>
            </>
          )}
          {/* header for sign up */}
        </Box>
      </Box>
    </>
  );
};

export default HeaderSignCompany;
