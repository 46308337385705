import React, { FC } from 'react';
import { Box, Typography } from '@mui/material';
import ArrowBackIcon from '@mui/icons-material/ArrowBack';
import { useNavigate } from 'react-router-dom';

const PrivacyPolicy: FC = () => {
  const navigate = useNavigate();

  const handleBackClick = (): void => {
    navigate(-1); // Goes back to the previous page
  };
  return (
    <Box
      width="100%"
      mt="3%"
      p="5% 15%"
      bgcolor="white"
      overflow="auto"
      display="flex"
      flexDirection="column"
      alignItems="flex-start"
      position={'relative'}
      sx={{
        '@media (max-width: 768px)': {
          padding: '10% 5% 5% 5%',
        },
      }}
    >
      <Box
        position={'absolute'}
        top={'0'}
        left={'50px'}
        display={'flex'}
        alignItems={'center'}
        gap={'10px'}
        sx={{ cursor: 'pointer' }}
        onClick={handleBackClick}
      >
        <ArrowBackIcon /> Back
      </Box>
      {/* Main Heading */}
      <Typography
        variant="h4"
        gutterBottom
        mb={5}
        sx={{
          '@media (max-width: 500px)': {
            marginTop: '20px',
          },
        }}
      >
        Privacy Policy
      </Typography>

      {/* Sections */}
      <Section number={1} title="INTRODUCTION">
        <Typography variant="body1" gutterBottom>
          SignWise.com (&quot;SignWise,&quot; &quot;we,&quot; &quot;our,&quot; or &quot;us&quot;),
          owned by Signwise Inc., respects your privacy and is committed to protecting it. This
          Privacy Policy explains our policies and procedures regarding the collection, use,
          disclosure, and sharing of personal information when you use our website
          (www.signwise.com) and related services. This notice complies with the California Online
          Privacy Protection Act (CalOPPA) and other applicable privacy laws.
        </Typography>
      </Section>

      <Section number={2} title="INFORMATION WE COLLECT">
        <Typography variant="body1" gutterBottom>
          We collect information that you voluntarily provide to us when you register for an
          account, express interest in obtaining information about our services, or otherwise
          contact us. The personal information we collect depends on your interaction with our
          platform and may include:
        </Typography>
        <Typography variant="h6" fontSize={'17px'} mt={'20px'}>
          For Business Users (Service Providers):
        </Typography>
        <Typography
          variant="body1"
          component="ul"
          gutterBottom
          sx={{
            listStyle: 'none', // Remove default bullet points
          }}
        >
          <li style={{ position: 'relative', paddingLeft: '20px' }}>
            <span style={{ position: 'absolute', left: 0 }}>-</span>
            Business and personal name
          </li>
          <li style={{ position: 'relative', paddingLeft: '20px' }}>
            <span style={{ position: 'absolute', left: 0 }}>-</span>
            Business formation year and details
          </li>
          <li style={{ position: 'relative', paddingLeft: '20px' }}>
            <span style={{ position: 'absolute', left: 0 }}>-</span>
            Email address and contact information
          </li>
          <li style={{ position: 'relative', paddingLeft: '20px' }}>
            <span style={{ position: 'absolute', left: 0 }}>-</span>
            Physical business address
          </li>
          <li style={{ position: 'relative', paddingLeft: '20px' }}>
            <span style={{ position: 'absolute', left: 0 }}>-</span>
            Business photographs and media
          </li>
          <li style={{ position: 'relative', paddingLeft: '20px' }}>
            <span style={{ position: 'absolute', left: 0 }}>-</span>
            Business description and service information
          </li>
          <li style={{ position: 'relative', paddingLeft: '20px' }}>
            <span style={{ position: 'absolute', left: 0 }}>-</span>
            Payment information when applicable
          </li>
          <li style={{ position: 'relative', paddingLeft: '20px' }}>
            <span style={{ position: 'absolute', left: 0 }}>-</span>
            Communication preferences
          </li>
          <li style={{ position: 'relative', paddingLeft: '20px' }}>
            <span style={{ position: 'absolute', left: 0 }}>-</span>
            Any additional information you choose to provide
          </li>
        </Typography>

        <Typography variant="h6" fontSize={'17px'} mt={'20px'}>
          For Individual Users (Customers):
        </Typography>
        <Typography variant="body1" component="ul" gutterBottom>
          <li style={{ position: 'relative', paddingLeft: '20px' }}>
            <span style={{ position: 'absolute', left: 0 }}>-</span>
            Name and contact information
          </li>
          <li style={{ position: 'relative', paddingLeft: '20px' }}>
            <span style={{ position: 'absolute', left: 0 }}>-</span>
            Email address
          </li>
          <li style={{ position: 'relative', paddingLeft: '20px' }}>
            <span style={{ position: 'absolute', left: 0 }}>-</span>
            Phone number
          </li>
          <li style={{ position: 'relative', paddingLeft: '20px' }}>
            <span style={{ position: 'absolute', left: 0 }}>-</span>
            Service request details and preferences
          </li>
          <li style={{ position: 'relative', paddingLeft: '20px' }}>
            <span style={{ position: 'absolute', left: 0 }}>-</span>
            Message content when contacting service providers
          </li>
          <li style={{ position: 'relative', paddingLeft: '20px' }}>
            <span style={{ position: 'absolute', left: 0 }}>-</span>
            Location information when searching for services
          </li>
          <li style={{ position: 'relative', paddingLeft: '20px' }}>
            <span style={{ position: 'absolute', left: 0 }}>-</span>
            Usage data and platform interaction details
          </li>
        </Typography>
      </Section>

      <Section number={3} title="HOW WE USE YOUR INFORMATION">
        <Typography variant="body1" gutterBottom>
          We use the collected information for various purposes central to providing, maintaining,
          and improving our services, including:
        </Typography>
        <Typography variant="body1" component="ul" gutterBottom mt={'20px'}>
          <li style={{ position: 'relative', paddingLeft: '20px' }}>
            <span style={{ position: 'absolute', left: 0 }}>-</span>
            Facilitating connections between service providers and potential customers
          </li>
          <li style={{ position: 'relative', paddingLeft: '20px' }}>
            <span style={{ position: 'absolute', left: 0 }}>-</span>
            Creating and managing your account
          </li>
          <li style={{ position: 'relative', paddingLeft: '20px' }}>
            <span style={{ position: 'absolute', left: 0 }}>-</span>
            Providing customer support and responding to inquiries
          </li>
          <li style={{ position: 'relative', paddingLeft: '20px' }}>
            <span style={{ position: 'absolute', left: 0 }}>-</span>
            Processing transactions and sending related information
          </li>
          <li style={{ position: 'relative', paddingLeft: '20px' }}>
            <span style={{ position: 'absolute', left: 0 }}>-</span>
            Sending administrative information, updates, and service notifications
          </li>
          <li style={{ position: 'relative', paddingLeft: '20px' }}>
            <span style={{ position: 'absolute', left: 0 }}>-</span>
            Maintaining and optimizing the security of our platform
          </li>
          <li style={{ position: 'relative', paddingLeft: '20px' }}>
            <span style={{ position: 'absolute', left: 0 }}>-</span>
            Analyzing usage patterns to improve user experience
          </li>
          <li style={{ position: 'relative', paddingLeft: '20px' }}>
            <span style={{ position: 'absolute', left: 0 }}>-</span>
            Complying with legal obligations
          </li>
          <li style={{ position: 'relative', paddingLeft: '20px' }}>
            <span style={{ position: 'absolute', left: 0 }}>-</span>
            Resolving disputes and enforcing our agreements
          </li>
        </Typography>
      </Section>

      <Section number={4} title="INFORMATION SHARING AND DISCLOSURE">
        <Typography variant="body1" gutterBottom>
          We may share your personal information in the following situations:
        </Typography>
        <Typography variant="body1" gutterBottom mt={'20px'}>
          Business Operations: We may share your information with service providers, subcontractors,
          and other third parties we use to support our business operations. These parties are
          contractually obligated to keep personal information confidential and use it only for the
          purposes for which we disclose it to them.
        </Typography>
        <Typography variant="body1" gutterBottom mt={'20px'}>
          Platform Functionality: When you are a service provider, certain information (such as
          business name, location, and services offered) will be publicly displayed on our platform.
          When you are a customer contacting a service provider, your contact information will be
          shared with the relevant service provider.
        </Typography>
        <Typography variant="body1" gutterBottom mt={'20px'}>
          Legal Requirements: We may disclose your information where required by law, governmental
          request, or when we believe disclosure is necessary to protect our rights, protect your
          safety or the safety of others, investigate fraud, or respond to a government request.
        </Typography>
        <Typography variant="body1" gutterBottom mt={'20px'}>
          Business Transfers: If we are involved in a merger, acquisition, or sale of all or a
          portion of our assets, your information may be transferred as part of that transaction.{' '}
        </Typography>
      </Section>
      <Section number={5} title="YOUR PRIVACY RIGHTS">
        <Typography variant="body1" gutterBottom>
          California residents have the right to request:
        </Typography>
        <Typography variant="body1" component="ul" gutterBottom>
          <Typography variant="body1" component="ul" gutterBottom>
            <li style={{ position: 'relative', paddingLeft: '20px' }}>
              <span style={{ position: 'absolute', left: 0 }}>-</span>
              Information about categories and specific pieces of personal information we have
              collected about them
            </li>
            <li style={{ position: 'relative', paddingLeft: '20px' }}>
              <span style={{ position: 'absolute', left: 0 }}>-</span>
              Categories of sources from which we collect personal information
            </li>
            <li style={{ position: 'relative', paddingLeft: '20px' }}>
              <span style={{ position: 'absolute', left: 0 }}>-</span>
              Business and commercial purposes for collecting or selling personal information
            </li>
            <li style={{ position: 'relative', paddingLeft: '20px' }}>
              <span style={{ position: 'absolute', left: 0 }}>-</span>
              Categories of third parties with whom we share personal information
            </li>
            <li style={{ position: 'relative', paddingLeft: '20px' }}>
              <span style={{ position: 'absolute', left: 0 }}>-</span>
              Deletion of personal information (subject to certain exceptions)
            </li>
            <li style={{ position: 'relative', paddingLeft: '20px' }}>
              <span style={{ position: 'absolute', left: 0 }}>-</span>
              Opt-out of the sale of personal information
            </li>
          </Typography>
        </Typography>
        <Typography variant="body1" gutterBottom mt={'20px'}>
          We will not discriminate against you for exercising your privacy rights. To exercise these
          rights, please contact us using the information provided in Section 12.
        </Typography>
      </Section>
      <Section number={6} title="DO NOT TRACK SIGNALS">
        <Typography variant="body1" gutterBottom>
          Our platform responds to and honors Do Not Track signals transmitted by web browsers. When
          we detect a Do Not Track signal, we disable any tracking mechanisms that may be in
          operation, except those necessary for basic platform functionality.
        </Typography>
      </Section>
      <Section number={7} title="COOKIES AND TRACKING TECHNOLOGIES">
        <Typography variant="body1" gutterBottom>
          We use cookies and similar tracking technologies to track activity on our platform and
          maintain certain information. Cookies are files with a small amount of data that may
          include an anonymous unique identifier. You can instruct your browser to refuse all
          cookies or to indicate when a cookie is being sent. However, if you do not accept cookies,
          some portions of our platform may not function properly.
        </Typography>
      </Section>
      <Section number={8} title="DATA RETENTION">
        <Typography variant="body1" gutterBottom>
          We retain personal information for as long as necessary to fulfill the purposes outlined
          in this Privacy Policy, unless a longer retention period is required or permitted by law.
          When determining the retention period, we consider the amount, nature, and sensitivity of
          the information, the potential risk of harm from unauthorized use or disclosure, and the
          purposes for which we process the information.
        </Typography>
      </Section>
      <Section number={9} title="DATA SECURITY">
        <Typography variant="body1" gutterBottom>
          We implement appropriate technical and organizational security measures to protect your
          personal information against unauthorized access, alteration, disclosure, or destruction.
          These measures include internal reviews of our data collection, storage, and processing
          practices and security measures, as well as physical security measures to guard against
          unauthorized access to systems where we store personal data.
        </Typography>
        <Typography variant="body1" gutterBottom mt={'20px'}>
          However, please note that no method of transmission over the Internet or method of
          electronic storage is 100% secure. While we strive to use commercially acceptable means to
          protect your personal information, we cannot guarantee its absolute security. You
          acknowledge that you provide your personal information at your own risk.
        </Typography>
      </Section>
      <Section number={10} title="CHILDREN'S PRIVACY">
        <Typography variant="body1" gutterBottom>
          Our platform is not directed to children under the age of 13, and we do not knowingly
          collect personal information from children under 13. If we become aware that we have
          collected personal information from a child under age 13 without verification of parental
          consent, we will take steps to remove that information from our servers. If you believe we
          might have any information from or about a child under 13, please contact us immediately.
        </Typography>
      </Section>
      <Section number={11} title="CHANGES TO THIS PRIVACY POLICY">
        <Typography variant="body1" gutterBottom>
          We reserve the right to update or modify this Privacy Policy at any time and from time to
          time without prior notice. We will post any changes on this page and, if the changes are
          significant, we will provide a more prominent notice (including, for certain services,
          email notification of privacy policy changes). The date this Privacy Policy was last
          revised is identified at the top of the page. Your continued use of our platform following
          the posting of changes to this Privacy Policy means you accept those changes.
        </Typography>
      </Section>
      {/* Repeat for other sections like "Information Sharing and Disclosure," "Your Privacy Rights," etc. */}

      <Section number={12} title="Contact Information">
        <Typography variant="body1">
          If you have any questions about this Privacy Policy or our privacy practices, or if you
          would like to exercise your privacy rights, please contact us at:
        </Typography>
        <Typography variant="body1" gutterBottom mt={'20px'}>
          SignWise.com <br />
          2261 Market Street STE 10726 <br />
          San Francisco, CA 94114 <br />
          Email: support@signwise.com
        </Typography>
      </Section>
      <Section number={13} title="CALIFORNIA PRIVACY NOTICE">
        <Typography variant="body1" gutterBottom>
          For California residents, this section supplements the information contained in our
          Privacy Policy above.
        </Typography>
        <Typography variant="h6" fontWeight={'600'} fontSize={'17px'} mt={'20px'}>
          Categories of Personal Information We Collect:
        </Typography>
        <Typography variant="body1" gutterBottom>
          We have collected the following categories of personal information from consumers within
          the last twelve (12) months:
        </Typography>
        <Typography
          variant="body1"
          component="ul"
          gutterBottom
          sx={{
            listStyle: 'none', // Remove default bullet points
          }}
        >
          <li style={{ position: 'relative', paddingLeft: '20px' }}>
            <span style={{ position: 'absolute', left: 0 }}>-</span>
            Identifiers (such as name, email address, phone number, address)
          </li>
          <li style={{ position: 'relative', paddingLeft: '20px' }}>
            <span style={{ position: 'absolute', left: 0 }}>-</span>
            Commercial information (such as business details, service records)
          </li>
          <li style={{ position: 'relative', paddingLeft: '20px' }}>
            <span style={{ position: 'absolute', left: 0 }}>-</span>
            Internet or other electronic network activity information
          </li>
          <li style={{ position: 'relative', paddingLeft: '20px' }}>
            <span style={{ position: 'absolute', left: 0 }}>-</span>
            Geolocation data
          </li>
          <li style={{ position: 'relative', paddingLeft: '20px' }}>
            <span style={{ position: 'absolute', left: 0 }}>-</span>
            Professional or employment-related information
          </li>
          <li style={{ position: 'relative', paddingLeft: '20px' }}>
            <span style={{ position: 'absolute', left: 0 }}>-</span>
            Inferences drawn from other personal information
          </li>
        </Typography>
        <Typography variant="h6" fontWeight={'600'} fontSize={'17px'} mt={'20px'}>
          Categories of Sources:
        </Typography>
        <Typography variant="body1" gutterBottom>
          We obtain the categories of personal information listed above from:
        </Typography>
        <Typography
          variant="body1"
          component="ul"
          gutterBottom
          sx={{
            listStyle: 'none', // Remove default bullet points
          }}
        >
          <li style={{ position: 'relative', paddingLeft: '20px' }}>
            <span style={{ position: 'absolute', left: 0 }}>-</span>
            Direct interactions with you
          </li>
          <li style={{ position: 'relative', paddingLeft: '20px' }}>
            <span style={{ position: 'absolute', left: 0 }}>-</span>
            Automated technologies or interactions
          </li>
          <li style={{ position: 'relative', paddingLeft: '20px' }}>
            <span style={{ position: 'absolute', left: 0 }}>-</span>
            Third parties or publicly available sources
          </li>
        </Typography>
        <Typography variant="h6" fontWeight={'600'} fontSize={'17px'} mt={'20px'}>
          Business Purpose for Collecting Information:
        </Typography>
        <Typography variant="body1" gutterBottom>
          We collect and use personal information for the business purposes described in Section 3
          of this Privacy Policy.
        </Typography>
        <Typography variant="h6" fontWeight={'600'} fontSize={'17px'} mt={'20px'}>
          Disclosure of Personal Information:
        </Typography>
        <Typography variant="body1" gutterBottom>
          We may disclose your personal information to third parties for business purposes as
          described in Section 4 of this Privacy Policy. When we disclose personal information for a
          business purpose, we enter into a contract that describes the purpose and requires the
          recipient to keep that personal information confidential and not use it for any purpose
          except performing the contract.
        </Typography>
        <Typography variant="h6" fontWeight={'600'} fontSize={'17px'} mt={'20px'}>
          Sale of Personal Information:
        </Typography>
        <Typography variant="body1" gutterBottom>
          In the preceding twelve (12) months, we have not sold any personal information.
        </Typography>
        <Typography variant="h6" fontWeight={'600'} fontSize={'17px'} mt={'20px'}>
          Your Rights and Choices:
        </Typography>
        <Typography variant="body1" gutterBottom>
          The California Consumer Privacy Act (CCPA) provides California residents with specific
          rights regarding their personal information. You have the right to:
        </Typography>
        <Typography variant="body1" component="ul" gutterBottom>
          <li style={{ position: 'relative', paddingLeft: '20px' }}>
            <span style={{ position: 'absolute', left: 0 }}>-</span>
            Request we disclose information about our collection and use of your personal
            information over the past 12 months
          </li>
          <li style={{ position: 'relative', paddingLeft: '20px' }}>
            <span style={{ position: 'absolute', left: 0 }}>-</span>
            Request we delete any personal information we collected about you
          </li>
          <li style={{ position: 'relative', paddingLeft: '20px' }}>
            <span style={{ position: 'absolute', left: 0 }}>-</span>
            Opt-out of the sale of your personal information
          </li>
          <li style={{ position: 'relative', paddingLeft: '20px' }}>
            <span style={{ position: 'absolute', left: 0 }}>-</span>
            Not be discriminated against for exercising these rights
          </li>
        </Typography>
        <Typography variant="body1" gutterBottom>
          To exercise these rights, please contact us using the information provided in Section 12.
          We will respond to verifiable consumer requests within 45 days.
        </Typography>
      </Section>
      <Section number={14} title="INTERNATIONAL USERS">
        <Typography variant="body1" gutterBottom>
          Our platform is hosted and operated in the United States. If you are using our services
          from outside the United States, please be aware that your information may be transferred
          to, stored, and processed in the United States where our servers are located. By using our
          services, you consent to the transfer of your information to the United States and
          understand that your information will be subject to the laws of the United States, which
          may provide different levels of data protection than your country of residence.
        </Typography>
      </Section>
    </Box>
  );
};

const Section: FC<{ number: number; title: string; children: React.ReactNode }> = ({
  number,
  title,
  children,
}) => (
  <Box mb={4}>
    <Typography variant="h5" gutterBottom ml={2} fontWeight={600} fontSize={'20px'} color={'#000'}>
      {number}. {title}
    </Typography>
    {children}
  </Box>
);

export default PrivacyPolicy;
