import React, { useState } from 'react';
import {
  Modal,
  Box,
  Button,
  Typography,
  FormControl,
  MenuItem,
  Select,
  TextField,
  Autocomplete,
  CircularProgress,
} from '@mui/material';
import CloseIcon from '../../../assets/Phase1a/DesignUpdate/removeImgIcon.svg';
import AddImgsBg from '../../../assets/Phase1a/DesignUpdate/AddImageIllustration.svg';
import AddedImgBg from '../../../assets/Phase1a/DesignUpdate/UploadingImg.png';
import { LocalizationProvider, DatePicker } from '@mui/x-date-pickers';
import { AdapterDayjs } from '@mui/x-date-pickers/AdapterDayjs';
import { DemoContainer } from '@mui/x-date-pickers/internals/demo';
import { Dayjs } from 'dayjs';
import request from '../../../api/request';
import { toast } from 'react-toastify';
import CustomToast from '../../../components/phase1a/common/CustomToast.component';
import { mediaUrl } from '../../../config';
import { IAddress, IAddressResponse } from '../../../types/user';
import { ExpandLess, ExpandMore } from '@mui/icons-material';
import { ReactComponent as CalendarTodayIcon } from '../../../assets/Calender-Icon.svg';

interface AddNewJobShowCaseProps {
  open: boolean;
  handleClose: () => void;
  getCompanyById: () => void;
}

const AddNewJobShowCase: React.FC<AddNewJobShowCaseProps> = ({
  open,
  handleClose,
  getCompanyById,
}) => {
  const userJSON = localStorage.getItem('user');
  const userData = userJSON ? JSON.parse(userJSON) : '';
  console.log('userData', userData);
  const [allAddresses, setAllAddresses] = useState<IAddress[]>([]);
  const [near, setNear] = useState<string>('');
  const [selectedAddress, setSelectedAddress] = useState<any>(null);
  console.log('selectedAddress', selectedAddress);
  const [startDate, setStartDate] = useState<Dayjs | any>(null);
  const [completionDate, setCompletionDate] = useState<Dayjs | any>(null);
  const [loading, setLoading] = useState(false);
  const [dropdownOpen, setDropdownOpen] = useState(false);

  const [jobData, setJobData] = useState({
    photos: [],
    service: '',
    job_location: '',
    start: null,
    end: null,
    description: '',
  });

  const handleImageUpload = async (e: React.ChangeEvent<HTMLInputElement>): Promise<void> => {
    const maxFileSize = 10 * 1024 * 1024; // 10 MB in bytes
    const allowedFormats = ['image/jpeg', 'image/jpg', 'image/png'];
    const files = e.target.files;

    if (files) {
      const validFiles = Array.from(files)
        .filter((file) => {
          if (!allowedFormats.includes(file.type)) {
            console.error(`Invalid file format: ${file.name}`);
            return false; // Skip invalid file format
          }
          if (file.size > maxFileSize) {
            console.error(`File size exceeds 10 MB: ${file.name}`);
            return false; // Skip files that exceed the size limit
          }
          return true;
        })
        .slice(0, 4 - jobData.photos.length);

      for (const file of validFiles) {
        const formData = new FormData();
        formData.append('photo', file);

        try {
          const response = await request('post', `/uploads`, formData);
          const imageUrl = (response as string[])[0]; // Assuming the response contains the image URL
          console.log('🚀 ~ handleImageUpload ~ imageUrl:', imageUrl);

          setJobData((prevData: any) => ({
            ...prevData,
            photos: [...prevData.photos, imageUrl],
          }));
        } catch (error) {
          console.error('Image upload error', error);
          toast(<CustomToast message={'Error uploading image'} type="error" />);
        }
      }
    }
  };

  const handleRemoveImage = (index: number): void => {
    setJobData((prevData) => ({
      ...prevData,
      photos: prevData.photos.filter((_, i) => i !== index),
    }));
  };
  const handleInputChange = (key: string, value: any): void => {
    setJobData((prevData) => ({
      ...prevData,
      [key]: value,
    }));
  };
  // const handleRemoveImage = (index: number): void => {
  //   setImages((prevImages) => prevImages.filter((_, i) => i !== index));
  // };

  const isUploadDisabled = jobData.photos.length >= 4;
  const formatAddressOption = (address: IAddress): string => {
    // const postalCode = address.contexts?.postcode || '';
    const region = address.contexts?.region || '';
    // const country = address.contexts?.country || '';
    const place = address.contexts?.place || '';
    // const city = address.contexts?.city || '';
    // const street = address.contexts?.street || '';
    // const district = address.contexts?.district || '';
    // const locality = address.contexts?.locality || '';
    /* eslint-disable @typescript-eslint/restrict-template-expressions */
    return place && `${place}, ${region}`;
    // return `${place}, ${region}`;
  };
  const handleGetAddress = (value: string): void => {
    request<IAddressResponse>('get', `/address/search?text=${value}`)
      .then((response) => {
        const filteredResults = response?.results?.filter((result) => result?.contexts?.place);
        setAllAddresses(filteredResults);
      })
      .catch((error) => {
        console.log(error);
      });
  };
  const handleAddressSelect = (address: any): void => {
    const region = address.contexts?.region || '';
    // const country = address.contexts?.country || '';
    const place = address.contexts?.place || '';
    setSelectedAddress(address);
    setJobData((prevData) => ({
      ...prevData,
      job_location: `${place}, ${region}`,
    }));
  };
  const handleNearChange = (event: any, value: string | null): void => {
    setNear(value ?? '');
    if (value) {
      handleGetAddress(value);
    }
  };
  const isPostDisabled =
    !jobData?.service ||
    !jobData?.job_location ||
    !jobData?.photos?.length ||
    // !jobData?.description ||
    !startDate ||
    !completionDate;

  const submitData = (): void => {
    // Create a FormData instance
    setLoading(true);
    console.log('jobData', jobData);
    request<any>(
      'patch',
      // eslint-disable-next-line @typescript-eslint/restrict-template-expressions
      `/companies/gallery/${userData?.company?._id}`,
      jobData as unknown as any,
    )
      .then((res) => {
        // Handle successful response
        console.log('Images uploaded successfully', res);
        setLoading(false);
        handleClose();
        getCompanyById();
      })
      .catch((err) => {
        // Handle error response
        console.error('Error uploading images', err);
        setLoading(false);
      });
  };

  // const selectRef = useRef<HTMLDivElement | null>(null);

  const handleIconClick = (): any => {
    setDropdownOpen((prev) => !prev);
  };

  const CustomDropdownIcon = (): any => (
    <div
      onClick={(e) => {
        e.stopPropagation(); // Prevent the default toggle behavior
        handleIconClick();
      }}
      style={{
        marginRight: '14px',
        cursor: 'pointer',
      }}
    >
      {dropdownOpen ? <ExpandLess /> : <ExpandMore />}
    </div>
  );

  return (
    <Modal
      open={open}
      onClose={handleClose}
      aria-labelledby="modal-title"
      aria-describedby="modal-description"
      BackdropProps={{
        style: {
          backdropFilter: 'blur(8px)', // Add the blur effect to the backdrop
          backgroundColor: 'rgba(0, 0, 0, 0.4)', // Optional: to add a dimmed background color
        },
      }}
    >
      <Box
        sx={{
          position: 'absolute',
          outline: 'none',
          top: '50%',
          left: '50%',
          transform: 'translate(-50%, -50%)',
          width: '400px',
          bgcolor: 'background.paper',
          borderRadius: '12px',
          boxShadow: `
        0px 8px 8px -4px rgba(0, 0, 0, 0.2),
        0px 20px 24px -4px rgba(0, 0, 0, 0.15)
      `,
          height: '579px',
          border: 'none',
          '@media (max-width:500px)': {
            width: '90%',
          },
        }}
      >
        <Box
          padding={'24px 24px 0px 24px'}
          width={'100%'}
          fontFamily={'SF UI Display'}
          sx={{
            overflowY: 'scroll',
            height: '490px',
            '&::-webkit-scrollbar': {
              display: 'none', // Hide scrollbar in WebKit browsers (Chrome, Safari)
            },
            '-ms-overflow-style': 'none', // Hide scrollbar in IE and Edge
            'scrollbar-width': 'none', // Hide scrollbar in Firefox
          }}
        >
          <Typography
            id="modal-title"
            mb={2}
            fontWeight={600}
            fontSize={'18px'}
            lineHeight={'28px'}
            color={'#000000'}
            fontFamily={'SF UI Display'}
          >
            Add New Job Showcase
          </Typography>

          <Box
            width="100%"
            sx={{ cursor: isUploadDisabled ? 'not-allowed' : 'pointer' }}
            onClick={() => !isUploadDisabled && document.getElementById('fileInput')?.click()}
          >
            <Box
              width="100%"
              bgcolor="rgba(247, 247, 247, 1)"
              border="rgba(208, 213, 221, 1)rgba(255, 255, 255, 1)"
              borderRadius="12px"
              padding="15px"
              textAlign="center"
              display={'flex'}
              justifyContent={'center'}
              alignItems={'center'}
              flexDirection={'column'}
              mt={4}
            >
              <img src={AddImgsBg} alt="" />
              <Typography
                fontFamily={'SF UI Display'}
                color={'rgba(78, 78, 78, 1)'}
                fontWeight={600}
                mt={2}
              >
                {isUploadDisabled ? '' : 'Add Job Photos'}
              </Typography>
            </Box>
          </Box>

          <input
            id="fileInput"
            type="file"
            accept="image/*"
            style={{ display: 'none' }}
            multiple
            onChange={(e) => {
              void handleImageUpload(e);
            }}
            disabled={isUploadDisabled}
          />

          <Box display="flex" gap={2} mt={2} width={'100%'}>
            {Array.from({ length: 4 }).map((_, index) => {
              const photo = jobData.photos[index]; // Get the photo for this index, or undefined if none

              return (
                <Box
                  key={index}
                  width="77px"
                  height="77px"
                  borderRadius="5px"
                  display="flex"
                  alignItems="center"
                  justifyContent="center"
                  position="relative"
                  sx={{
                    backgroundColor: photo ? 'transparent' : 'white',
                    overflow: 'visible',
                    border: photo ? '' : 'dashed 1px rgba(0, 0, 0, 1)',
                  }}
                >
                  {photo ? (
                    // If there is a valid photo (string URL)
                    <>
                      <img
                        /* eslint-disable @typescript-eslint/restrict-template-expressions */
                        src={`${mediaUrl}/${photo}`} // Use the URL for the uploaded image
                        alt={`Uploaded ${index + 1}`}
                        style={{
                          width: '100%',
                          height: '100%',
                          objectFit: 'cover',
                          borderRadius: photo ? '5px' : '0px',
                        }}
                      />
                      <img
                        src={CloseIcon}
                        alt="Remove"
                        width="16px"
                        height="16px"
                        onClick={() => {
                          handleRemoveImage(index); // Call the remove function
                        }}
                        style={{
                          position: 'absolute',
                          width: '24px',
                          height: '24px',
                          top: '-12px',
                          right: '-9px',
                          zIndex: 1,
                          cursor: 'pointer',
                        }}
                      />
                    </>
                  ) : (
                    // If no valid photo, show the default image
                    <img src={AddedImgBg} alt="Add an image" />
                  )}
                </Box>
              );
            })}
          </Box>

          <Box width="100%" margin="4% 0" borderRadius="8px">
            <Typography
              variant="body1"
              mb={1}
              fontWeight={500}
              fontSize={'14px'}
              color={'black'}
              fontFamily={'SF UI Display'}
            >
              Main Service
            </Typography>
            <FormControl fullWidth sx={{ border: 'none', boxShadow: 'none' }}>
              <Select
                value={jobData?.service || ''}
                open={dropdownOpen}
                onOpen={() => {
                  setDropdownOpen(true);
                }}
                onClose={() => {
                  setDropdownOpen(false);
                }}
                IconComponent={CustomDropdownIcon}
                displayEmpty
                onChange={(e) => {
                  handleInputChange('service', e.target.value);
                }}
                sx={{
                  borderRadius: '8px',
                  border: 'solid 1px rgba(208, 213, 221, 1)',
                  boxShadow: 'none',
                  fontFamily: 'SF UI Display',
                  '& .MuiSelect-select': {
                    border: 'none',
                    padding: '10px',
                    fontSize: '14px',
                    fontFamily: 'SF UI Display',
                    color: jobData?.service ? 'inherit' : 'rgba(150, 150, 150, 1)',
                    fontWeight: jobData?.service ? 'inherit' : 300,
                  },
                  '& .MuiOutlinedInput-notchedOutline': {
                    border: 'none',
                    fontFamily: 'SF UI Display',
                  },
                  '&:before, &:after': {
                    border: 'none',
                  },
                }}
              >
                <MenuItem value="" disabled sx={{ display: dropdownOpen ? 'none' : 'block' }}>
                  Select Service
                </MenuItem>
                <MenuItem value="Sign Fabrication">Sign Fabrication</MenuItem>
                <MenuItem value="Sign Installation">Sign Installation</MenuItem>
                <MenuItem value="Fab & Install">Fab & Install</MenuItem>
                <MenuItem value="Print Production">Print Production</MenuItem>
                <MenuItem value="Print Installation">Print Installation</MenuItem>
                <MenuItem value="Print & Install">Print & Install</MenuItem>
                <MenuItem value="Wrap Installation">Wrap Installation</MenuItem>
              </Select>
            </FormControl>
          </Box>

          <Box width="100%" margin="4% 0" borderRadius="8px">
            <Typography
              variant="body1"
              mb={1}
              fontWeight={500}
              fontSize={'14px'}
              color={'black'}
              fontFamily={'SF UI Display'}
            >
              Job Location
            </Typography>
            <Autocomplete
              fullWidth
              disableClearable
              freeSolo
              // options={allAddresses.map((address) => address?.contexts?.region)}
              options={allAddresses.map(formatAddressOption)}
              value={near}
              onInputChange={handleNearChange}
              onChange={(event, value): void => {
                handleAddressSelect(
                  allAddresses.find((address) => formatAddressOption(address) === value) ?? null,
                );
              }}
              renderInput={(params) => (
                <TextField
                  {...params}
                  InputProps={{
                    ...params.InputProps,
                    endAdornment: null, // Ensure no end adornment is displayed
                  }}
                  placeholder="City, State or Province"
                  onChange={() => {
                    setJobData((prevData) => ({
                      ...prevData,
                      job_location: '',
                    }));
                  }}
                  sx={{
                    '& .MuiOutlinedInput-input': {
                      padding: '10px',
                      fontSize: '14px',
                    },
                    '& .MuiOutlinedInput-notchedOutline': {
                      border: 'solid 1px rgba(208, 213, 221, 1)',
                      borderRadius: '8px',
                    },
                    '& .MuiAutocomplete-inputRoot': {
                      padding: '13px 10px',
                    },
                    '& .MuiOutlinedInput-root .MuiAutocomplete-input': {
                      padding: '0px',
                    },
                    '&:before, &:after': {
                      border: 'none',
                    },
                  }}
                  type="search"
                />
              )}
            />
          </Box>

          <Box
            width="100%"
            margin="4% 0"
            borderRadius="8px"
            display={'flex'}
            justifyContent={'space-between'}
            sx={{
              '@media (max-width:500px)': {
                flexDirection: 'column',
              },
            }}
          >
            <Box
              width="48%"
              sx={{
                '@media (max-width:500px)': {
                  width: '100%',
                },
              }}
            >
              <Typography
                variant="body1"
                mb={1}
                fontWeight={500}
                fontSize="14px"
                color="black"
                fontFamily={'SF UI Display'}
              >
                Start Date
              </Typography>

              <LocalizationProvider dateAdapter={AdapterDayjs}>
                <DemoContainer components={['DatePicker']} sx={{ width: '100%' }}>
                  <DatePicker
                    // label="Basic date picker"
                    value={startDate}
                    onChange={(newValue) => {
                      handleInputChange('start', newValue ? newValue.toISOString() : null);
                      setStartDate(newValue);
                    }}
                    format="MM/DD/YYYY" // Change this line
                    // minDate={dayjs()}
                    maxDate={completionDate}
                    slotProps={{
                      textField: {
                        fullWidth: true,
                        placeholder: 'Select Date', // Adding the placeholder here
                        sx: {
                          minWidth: '100% !important',
                          width: '100%',
                          '& .MuiOutlinedInput-root': {
                            width: '100%',
                            borderRadius: '8px',
                          },
                          '& .MuiOutlinedInput-root .MuiOutlinedInput-input': {
                            padding: '13px 0px 13px 10px',
                          },
                        },
                      },
                      openPickerIcon: {
                        component: CalendarTodayIcon, // Custom icon for the calendar button
                        sx: {
                          color: '#ffffff',
                        },
                      },
                    }}
                    sx={{
                      width: '100%',
                      '& .MuiFormControl-root': {
                        width: '100%',
                      },
                      '& .MuiOutlinedInput-root': {
                        width: '100%',
                        borderRadius: '8px',
                      },
                    }}
                  />
                </DemoContainer>
              </LocalizationProvider>
            </Box>
            <Box
              width="48%"
              sx={{
                '@media (max-width:500px)': {
                  width: '100%',
                  mt: 4,
                },
              }}
            >
              <Typography
                variant="body1"
                mb={1}
                fontWeight={500}
                fontSize="14px"
                color="black"
                fontFamily={'SF UI Display'}
              >
                Completion Date
              </Typography>
              <LocalizationProvider dateAdapter={AdapterDayjs}>
                <DemoContainer components={['DatePicker']} sx={{ width: '100%' }}>
                  <DatePicker
                    value={completionDate}
                    onChange={(newValue) => {
                      handleInputChange('end', newValue ? newValue.toISOString() : null);
                      setCompletionDate(newValue);
                    }}
                    format="MM/DD/YYYY" // Change this line
                    minDate={startDate}
                    slotProps={{
                      textField: {
                        fullWidth: true,
                        placeholder: 'Select Date', // Adding the placeholder here
                        sx: {
                          width: '100%',
                          minWidth: '100% !important',
                          '& .MuiOutlinedInput-root': {
                            width: '100%',
                            borderRadius: '8px',
                          },
                          '& .MuiOutlinedInput-root .MuiOutlinedInput-input': {
                            padding: '13px 0px 13px 10px',
                          },
                        },
                      },
                      openPickerIcon: {
                        component: CalendarTodayIcon, // Custom icon for the calendar button
                        sx: {
                          color: '#ffffff',
                        },
                      },
                    }}
                    sx={{
                      width: '100%',
                      '& .MuiFormControl-root': {
                        width: '100%',
                      },
                      '& .MuiOutlinedInput-root': {
                        width: '100%',
                        borderRadius: '8px',
                      },
                    }}
                  />
                </DemoContainer>
              </LocalizationProvider>
            </Box>
          </Box>

          <Box width="100%" margin="4% 0" borderRadius="8px">
            <Typography
              variant="body1"
              mb={1}
              fontWeight={500}
              fontSize={'14px'}
              color={'black'}
              fontFamily={'SF UI Display'}
            >
              Job Description
            </Typography>
            <TextField
              fullWidth
              placeholder="Describe the job here..."
              multiline
              rows={4}
              value={jobData?.description}
              inputProps={{ maxLength: 2000 }}
              onChange={(e) => {
                handleInputChange('description', e.target.value);
              }}
              sx={{
                '& .MuiOutlinedInput-input': {
                  padding: '10px',
                  fontSize: '14px',
                },
                '& .MuiInputBase-root': {
                  padding: '0px 0px',
                },
                '& .MuiOutlinedInput-notchedOutline': {
                  border: 'solid 1px rgba(208, 213, 221, 1)',
                  borderRadius: '8px',
                },

                '&:before, &:after': {
                  border: 'none',
                },
              }}
              variant="outlined"
            />
          </Box>
        </Box>
        <Box display={'flex'} justifyContent={'space-between'} px={'24px'} pt={'10px'}>
          <Button
            variant="contained"
            color="primary"
            onClick={handleClose}
            sx={{
              width: '48%',
              padding: '10px 0',
              borderRadius: '12px',
              border: 'solid 1px rgba(208, 213, 221, 1)',
              fontWeight: 600,
              fontSize: '16px',
              fontFamily: 'SF UI Display',
              boxShadow: 'none',
              mt: 1,
              textTransform: 'capitalize',
              bgcolor: 'transparent',
              color: 'black',
              '&:hover': {
                backgroundColor: 'transparent', // Keeps the same background color on hover
              },
            }}
          >
            Cancel
          </Button>
          <Button
            variant="contained"
            color="primary"
            onClick={submitData}
            sx={{
              width: '48%',
              padding: '10px 0',
              borderRadius: '12px',
              border: 'none',
              fontWeight: 600,
              fontSize: '16px',
              boxShadow: 'none',
              fontFamily: 'SF UI Display',
              mt: 1,
              textTransform: 'capitalize',
              bgcolor: '#00BD57',
              '&:hover': {
                backgroundColor: '#00BD57', // Keeps the same background color on hover
              },
            }}
            disabled={loading || isPostDisabled}
          >
            {loading ? <CircularProgress size={15} color="inherit" /> : 'Post'}
          </Button>
        </Box>
      </Box>
    </Modal>
  );
};

export default AddNewJobShowCase;
