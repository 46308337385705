export const specialTitles = [
  'ceo', // Chief Executive Officer
  'coo', // Chief Operating Officer
  'cfo', // Chief Financial Officer
  'cto', // Chief Technology Officer
  'cmo', // Chief Marketing Officer
  'cio', // Chief Information Officer
  'cso', // Chief Security Officer / Chief Strategy Officer
  'cdo', // Chief Digital Officer / Chief Data Officer
  'cpo', // Chief Product Officer / Chief People Officer
  'cro', // Chief Revenue Officer / Chief Risk Officer
  'cho', // Chief Happiness Officer / Chief Human Resources Officer
  'cco', // Chief Customer Officer / Chief Communications Officer
  'cko', // Chief Knowledge Officer
  'cao', // Chief Administrative Officer / Chief Analytics Officer
  'clo', // Chief Legal Officer / Chief Learning Officer
  'cmoo', // Chief Medical Operations Officer
  'ciso', // Chief Information Security Officer
  'ccro', // Chief Compliance and Risk Officer
  'cgo', // Chief Growth Officer
  'csmo', // Chief Sales and Marketing Officer
];
