import React, { FC, useState, useEffect } from 'react';
import {
  Autocomplete,
  Box,
  Button,
  createFilterOptions,
  TextField,
  Typography,
  // useMediaQuery,
} from '@mui/material';
import HeaderSignCompany from '../../../components/phase1a/local/Header.component';
// import SearchCardImg from '../../../assets/nationalSignCompany/SearchCardImgGQ.svg';
// import SearchCardImg2 from '../../../assets/nationalSignCompany/SearchCardImg2GQ.svg';
// import ChooseCardImg from '../../../assets/nationalSignCompany/homeCardImg2.svg';
// import PartnerCardImg1 from '../../../assets/nationalSignCompany/PartnerCardImg1GQ.svg';
// import PartnerCardImg2 from '../../../assets/nationalSignCompany/PartnerCardImg2GQ.svg';
import ServiceIcon1 from '../../../assets/serviceFilter/serviceFilter1.svg';
import ServiceIcon2 from '../../../assets/serviceFilter/serviceFilter2.svg';
import ServiceIcon3 from '../../../assets/serviceFilter/serviceFilter3.svg';
import ServiceIcon4 from '../../../assets/serviceFilter/serviceFilter4.svg';
import ServiceIcon5 from '../../../assets/serviceFilter/serviceFilter5.svg';
import printProduction from '../../../assets/serviceFilter/serviceFilter6.svg';
import PrintInstallation from '../../../assets/serviceFilter/serviceFilter7.svg';
import WrapInstallation from '../../../assets/serviceFilter/serviceFilter8.svg';
import defaultCompany from '../../../assets/defaultCompany.png';

import { useNavigate } from 'react-router-dom';
import request from '../../../api/request';
import { IAddress, IAddressResponse } from '../../../types/user';
import { toast } from 'react-toastify';
import CustomToast from '../../../components/phase1a/common/CustomToast.component';
import { mediaUrl } from '../../../config';
// Stable branch for Production 1.4.0
// const cardData = [
//   {
//     title: 'Search',
//     subHeading: 'Browse through over 10k Local Sign Companies',
//     desp: 'Explore our network local vendors and find a company you can trust in record time.',
//     Img: SearchCardImg,
//     Img2: SearchCardImg2,
//   },
//   {
//     title: 'Choose',
//     subHeading: 'Read in-depth feedback from others on their past work',
//     desp: 'Use verified reviews, official screenings and job portfolios to select a vetted vendor.',
//     Img: ChooseCardImg,
//     Img2: undefined,
//   },
//   {
//     title: 'Partner',
//     subHeading: 'Keep track of great ones and discard the rest',
//     desp: 'Make sure your vendors stay compliant and get notifications on any new issues.',
//     Img: PartnerCardImg1,
//     Img2: PartnerCardImg2,
//   },
// ];

const typographyItems = [
  { name: 'local', text: 'Local Companies', searchTerm: '' },
  { name: 'national', text: 'National Companies', searchTerm: 'Installation' },
  // { name: 'wholesaler', text: 'Wholesale Companies', searchTerm: 'Fabrication' },
  { name: 'supplier', text: 'Suppliers', searchTerm: 'Service' },
  { name: 'distributor', text: 'Distributors', searchTerm: 'Surveys/Permits' },
];

const LocalSearch: FC = () => {
  const navigate = useNavigate();
  const [activeIndex, setActiveIndex] = useState<number | null>(0);
  // const [searchTerm, setSearchTerm] = useState<string>(typographyItems[0].searchTerm);
  const [allAddresses, setAllAddresses] = useState<IAddress[]>([]);
  console.log('allAddresses', allAddresses, setActiveIndex);
  const [selectedAddress, setSelectedAddress] = useState<any>(null);
  const [category, setCategory] = useState<any>([]);
  const [companiesNames, setCompaniesNames] = useState<any>([]);
  const [currentLocation, setCurrentLocation] = useState<any>();
  const [myLocation, setMyLocation] = useState<any>({
    city: '',
    state: '',
    country: '',
  });
  console.log('companiesNames', companiesNames);
  console.log('myLocation', myLocation);
  const [near, setNear] = useState<string>('');
  const [type, setType] = useState<string>('');
  const [coordinates, setCoordinates] = useState<{ lan?: number; lat?: number }>({
    lan: undefined,
    lat: undefined,
  });
  console.log(near, 'selectedAddress', selectedAddress);

  const [search, setSearch] = useState<any>(undefined);
  const [searchTypo, setSearchTypo] = useState<any>({});
  const [companyId, setCompanyId] = useState<string>('');
  const states = ['Canada', 'United States'];
  console.log('currentLocation', currentLocation);
  console.log(coordinates, 'coordinates search231312', currentLocation);
  console.log(companyId);
  useEffect(() => {
    if (activeIndex !== null) {
      setType(typographyItems[activeIndex].name);
    }
  }, [activeIndex]);
  // const handleClick = (index: number): void => {
  //   setActiveIndex(index);
  //   setSearch(undefined);
  //   setSearchTypo({});
  //   setNear('');
  //   // setSearchTerm(typographyItems[index].searchTerm);
  //   // setText(typographyItems[index].text);
  // };

  // const handleSearchTermChange = (event: React.ChangeEvent<HTMLInputElement>): void => {
  //   setSearchTerm(event.target.value);
  // };
  // const handleCurrentLocation = (): void => {
  //   request('post', '/address/currentLocation', {
  //     long: coordinates?.lan,
  //     lat: coordinates?.lat,
  //   })
  //     .then((res: any) => {
  //       const locationParts = res?.location.split(',').map((part: any) => part.trim());
  //       setMyLocation({
  //         city: locationParts[0] || '',
  //         state: locationParts[1] || '',
  //         country: locationParts[2] || '',
  //       });

  //       console.log('currentLocation', res);
  //     })
  //     .catch((err) => {
  //       console.log('err', err);
  //     });
  // };

  const handleGetAddress = (value: string): void => {
    request<IAddressResponse>('get', `/address/search?text=${value}`)
      .then((response) => {
        const filteredResults = response?.results?.filter((result) => result?.contexts?.place);
        setAllAddresses(filteredResults);
      })
      .catch((error) => {
        console.log(error);
      });
  };
  // const uniqueServices = [
  //   { name: 'Full Service Companies', logo: 'fullserviceicon' },
  //   { name: 'Fabricators', logo: 'fabricatorsicon' },
  //   { name: 'Installers', logo: 'installerIcon' },
  //   { name: 'Service', logo: 'serviceIcon' },
  //   { name: 'Surveys', logo: 'surveyIcon' },
  // ];
  const uniqueServices = [
    { name: 'Sign Fabrication', logo: 'fabricatorsicon' },
    { name: 'Sign Installation', logo: 'installerIcon' },
    { name: 'Print Production', logo: 'printersIcon' },
    { name: 'Print Installation', logo: 'printInstallersIcon' },
    { name: 'Wrap Installation', logo: 'wrapInstallersIcon' },
    { name: 'Permits', logo: 'permitsIcon' },
    { name: 'Surveys', logo: 'surveyIcon' },
    { name: 'Service', logo: 'serviceIcon' },
  ];
  const getCompaniesName = (): void => {
    if (type?.length) {
      request('get', `companies/searchAutoCompleteList/${type}`)
        .then((response: any) => {
          console.log(response, 'companiesNames res');
          const { uniqueNames } = response;
          if (type === 'local') {
            setCompaniesNames([...uniqueServices, ...uniqueNames]);
          } else {
            setCompaniesNames(uniqueNames);
          }
        })
        .catch((error) => {
          console.log(error, 'companiesNames err');

          console.log(error);
        });
    }
  };
  useEffect(() => {
    if (type?.length) {
      getCompaniesName();
      console.log('companiesNames type', type);
    }
  }, [type?.length]);

  const handleNearChange = (event: any, value: string | null): void => {
    setNear(value ?? '');
    if (value) {
      handleGetAddress(value);
    }
  };
  const formatAddressOption = (address: IAddress): string => {
    // const postalCode = address.contexts?.postcode || '';
    const region = address.contexts?.region || '';
    // const country = address.contexts?.country || '';
    const place = address.contexts?.place || '';
    // const city = address.contexts?.city || '';
    // const street = address.contexts?.street || '';
    // const district = address.contexts?.district || '';
    // const locality = address.contexts?.locality || '';
    /* eslint-disable @typescript-eslint/restrict-template-expressions */
    return place && `${place}, ${region}`;
    // return `${place}, ${region}`;
  };
  const handleAddressSelect = (address: any): void => {
    setSelectedAddress(address);
  };

  const handleSearch = (): void => {
    // Perform search logic here let locationParam = here;
    // if (!search) {
    //   toast.error('Find field is required.');
    //   return;
    // }
    if (!selectedAddress && !search?.id) {
      if (type === 'local' && currentLocation === undefined) {
        // toast.error('Must select a nearby location or enable your current location');
        toast(
          <CustomToast
            message={'Must select a nearby location or enable your current location'}
            type="error"
          />,
        );

        if ('geolocation' in navigator) {
          navigator.geolocation.getCurrentPosition(function (position) {
            console.log(position, 'kasgffkajfgf');
            setCurrentLocation(position);
          });
        } else {
          console.log('Geolocation is not available in your browser.');
        }
        return;
      }

      if (type === 'supplier' || type === 'distributor') {
        if (!searchTypo?.name?.length && !near) {
          // toast.error('Category is required');
          toast(<CustomToast message={'Category is required'} type="error" />);
          return;
        }
      }
    }

    let locationParam;

    if (coordinates.lan !== undefined && coordinates.lat !== undefined) {
      locationParam = `${coordinates.lan},${coordinates.lat}`;
    }
    const formatSearchString = (str: string): string => {
      if (!str) return ''; // Handle cases where str might be null or undefined
      // Trim, lowercase, and replace spaces with hyphens
      const formattedStr = str.toLowerCase().replace(/\s+/g, '-').trim();
      switch (formattedStr) {
        case 'fabricators':
          return 'sign-fabricators';
        case 'installers':
          return 'sign-installers';
        case 'full-service-companies': // Handle the formatted string directly
          return 'full-service';
        default:
          return formattedStr;
      }
    };
    /* eslint-disable @typescript-eslint/restrict-template-expressions */
    // navigate(
    //   `/companylisting?type=${type}&location=[${locationParam ?? ''}]&search=${search}&additional=${
    //     near?.split(',')[0] ?? ''
    //   }`,
    // navigate(`/companylisting${search ? `/${search}` : ''}${near ? `/${near}` : ''}`, {
    const paramType =
      type === 'local'
        ? 'local-sign-company'
        : type === 'national'
        ? 'national-sign-company'
        : type === 'supplier'
        ? 'supplier'
        : 'distributor';
    if (search?.id) {
      navigate(`/${paramType}/${formatSearchString(search?.name)}`, {
        state: {
          searchID: search?.id,
          type,
          search,
          coords: `[${locationParam ?? ''}]`,
          near:
            near !== ''
              ? near
              : type === 'local' && myLocation?.city
              ? `${myLocation?.city}, ${myLocation?.state}`
              : '',
          searchTypo,
          myCurrLoc: myLocation,
        },
      });
      return;
    }
    let url = '/search';
    const formattedSearch =
      search || searchTypo ? formatSearchString(search?.name ?? searchTypo.name) : '';
    // let find = '';
    const country =
      selectedAddress?.contexts?.country === 'United States'
        ? 'us'
        : selectedAddress?.contexts?.country === 'Canada'
        ? 'canada'
        : '';

    if (type === 'local') {
      const searchName = search?.name?.toLowerCase();
      const searchTypoName = searchTypo?.name?.toLowerCase();

      const serviceNames = [
        'service',
        'fabricators',
        'installers',
        'surveys',
        'full service companies',
      ];
      const find =
        serviceNames.includes(searchName) || serviceNames.includes(searchTypoName)
          ? 'service'
          : 'company';
      if (selectedAddress && formattedSearch) {
        url += `?${find}=${encodeURIComponent(
          formattedSearch,
        )}&location=${country}&city=${encodeURIComponent(
          formatSearchString(selectedAddress?.contexts?.place),
        )}&state=${encodeURIComponent(formatSearchString(selectedAddress?.contexts?.region))}`;
      } else if (!formattedSearch && (selectedAddress || locationParam)) {
        url += selectedAddress
          ? `?location=${country}&city=${encodeURIComponent(
              formatSearchString(selectedAddress?.contexts?.place),
            )}&state=${encodeURIComponent(formatSearchString(selectedAddress?.contexts?.region))}`
          : `?location=${encodeURIComponent(
              formatSearchString(myLocation?.country),
            )}&city=${encodeURIComponent(
              formatSearchString(myLocation?.city),
            )}&state=${encodeURIComponent(formatSearchString(myLocation?.state))}
              `;
      } else if (formattedSearch) {
        url += `?${find}=${encodeURIComponent(formattedSearch)}&location=${encodeURIComponent(
          formatSearchString(myLocation?.country),
        )}&city=${encodeURIComponent(
          formatSearchString(myLocation?.city),
        )}&state=${encodeURIComponent(formatSearchString(myLocation?.state))}
              `;
      }
    }
    if (type === 'national') {
      const formattedNear = near ? formatSearchString(near) : '';
      const locations =
        near === 'Canada' ? 'canada' : near === 'United States' ? 'us' : formattedNear;
      if (near && formattedSearch) {
        url += `?company=${encodeURIComponent(formattedSearch)}&location=${encodeURIComponent(
          locations,
        )}`;
      } else if (!near && !formattedSearch) {
        url += '?national-sign-companies';
      } else if (near) {
        url += `?location=${encodeURIComponent(locations)}`;
      } else if (formattedSearch) {
        url += `?company=${encodeURIComponent(formattedSearch)}`;
      }
    }
    if (type === 'supplier' || type === 'distributor') {
      const formattedNear = near ? formatSearchString(near) : '';
      if (near && formattedSearch) {
        url += `?company=${encodeURIComponent(formattedSearch)}&category=${encodeURIComponent(
          formattedNear,
        )}`;
      } else if (near) {
        url += `?category=${encodeURIComponent(formattedNear)}`;
      } else if (formattedSearch) {
        url += `?company=${encodeURIComponent(formattedSearch)}`;
      }
    }
    console.log(search, '8888888');
    navigate(url, {
      state: {
        nearData: selectedAddress
          ? `${selectedAddress?.contexts?.place}, ${selectedAddress?.contexts?.region}`
          : type === 'local'
          ? `${myLocation?.city}, ${myLocation?.state}`
          : near !== ''
          ? near
          : '',
        myCurrLoc: myLocation,
        companyType: type,
        locationsParam: `[${locationParam ?? ''}]`,
        searchData: search ?? searchTypo,
        searchText: searchTypo,
        addSelected: selectedAddress,
      },
    });
  };
  const getCategories = (): void => {
    request('get', `/companies/categories`)
      .then((res: any) => {
        const categories: string[] = res?.categories.reduce((acc: string[], obj: any) => {
          acc.push(obj?.category);

          obj.subCategories.forEach((subCategory: string) => {
            acc.push(subCategory);
          });

          return acc;
        }, []);
        setCategory(categories);
      })
      .catch((err) => {
        console.log('err', err);
      });
  };
  const handleCategoryChange = (event: any, value: any): void => {
    setNear(value ?? '');
  };
  const handleSearchName = (event: any, value: any): void => {
    console.log('valuuue', value?.id);
    setCompanyId(value?.id ?? '');
    setSearch(value ?? '');
  };
  const handleInputSearch = (event: React.SyntheticEvent, newInputValue: string): void => {
    console.log('valuuue2', newInputValue);

    setSearchTypo({ name: newInputValue, logo: '' });
    if (newInputValue === '') {
      setSearch(undefined);
    }
  };
  const handleStateChange = (event: any, value: any): void => {
    setNear(value ?? '');
  };

  useEffect(() => {
    getCategories();
  }, []);
  // useEffect(() => {
  //   if (coordinates?.lan) handleCurrentLocation();
  // }, [coordinates]);
  useEffect(() => {
    if (selectedAddress?.coordinates?.length === 2) {
      const [lan, lat] = selectedAddress.coordinates;
      setCoordinates({ lan, lat });
    } else {
      setCoordinates({ lan: undefined, lat: undefined });
    }
    // setNear(
    //   selectedAddress?.contexts?.city ||
    //     selectedAddress?.contexts?.region ||
    //     selectedAddress?.contexts?.street,
    // );
  }, [selectedAddress?.coordinates]);
  useEffect(() => {
    if (currentLocation?.coords?.latitude) {
      request('post', '/address/currentLocation', {
        long: currentLocation?.coords?.longitude,
        lat: currentLocation?.coords?.latitude,
      })
        .then((res: any) => {
          const locationParts = res?.location.split(',').map((part: any) => part.trim());
          setMyLocation({
            city: locationParts[0] || '',
            state: locationParts[1] || '',
            country: locationParts[2] || '',
          });
          setCoordinates({
            lan: currentLocation?.coords?.longitude,
            lat: currentLocation?.coords?.latitude,
          });

          console.log('currentLocation', res);
        })
        .catch((err) => {
          console.log('err', err);
        });
      // setCoordinates({
      //   lan: currentLocation?.coords?.longitude,
      //   lat: currentLocation?.coords?.latitude,
      // });
    }
    // else {
    //   setCoordinates({ lan: undefined, lat: undefined });
    // }
  }, [currentLocation?.coords?.latitude]);
  useEffect(() => {
    if ('geolocation' in navigator) {
      navigator.permissions
        .query({ name: 'geolocation' })
        .then(function (result) {
          if (result.state === 'granted') {
            navigator.geolocation.getCurrentPosition(function (position) {
              console.log(position, 'kasgffkajfgf');
              setCurrentLocation(position);
            });
          } else {
            // Permission is 'prompt' or 'denied'
            console.log('Geolocation permission is not granted');
          }
        })
        .catch(function (error) {
          console.error('Error querying geolocation permissions:', error);
        });
    }
  }, []);

  // const isMobile = useMediaQuery('(max-width:768px)');
  const filterOptions = createFilterOptions({
    limit: 8,
    stringify: (option: any) => option?.name,
  });
  console.log(filterOptions);

  const handleClick = (): void => {
    window.open('https://signwise-blog.ghost.io/get-started/', '_blank');
    // setOpenPopup(2);
    // #Beta
    // if (!userData) {
    //   setOpenPopup(1); // Set state to render LoginPopup
    // } else if (!userData?.company) {
    //   toast(<CustomToast message={'Complete your Sign-Up process first.'} type="error" />);
    // } else {
    //   setOpenPopup(2); // Show second popup
    // }
  };

  return (
    <Box width="100%">
      <HeaderSignCompany type="local" />
      <Box
        width={'fit-content'}
        display={'flex'}
        flexDirection={'column'}
        alignItems={'center'}
        margin={'auto'}
        height={'100vh'}
        justifyContent={'center'}
        // sx={{
        //   '@media (max-width: 900px)': {
        //     padding: '5% 3%',
        //   },
        // }}
      >
        <Box
          display={'flex'}
          flexDirection={'column'}
          alignItems={'center'}
          justifyContent={'center'}
          gap={'2px'}
          position={'relative'}
        >
          <Box
            bgcolor={'#E9E8E8'}
            width={'384px'}
            display={'flex'}
            gap={'10px'}
            padding={'1%'}
            borderRadius={'8px'}
            justifyContent={'center'}
            alignItems={'center'}
            position={'absolute'}
            top={'-75%'}
            sx={{
              '@media (max-width: 600px)': {
                top: '-60%',
                width: '288px',
                height: '37px',
                gap: '8px',
              },
            }}
          >
            <Typography
              fontFamily="SF UI Display"
              fontSize={'16px'}
              fontWeight={'600'}
              lineHeight={'19.09px'}
              textAlign={'center'}
              color={'#fff'}
              bgcolor={'#006BEE'}
              padding={'2%'}
              borderRadius={'6px'}
              sx={{
                '@media (max-width: 600px)': {
                  fontSize: '12px',
                  padding: '1.5%',
                  lineHeight: '14.32px',
                  // width: '82%',
                  // lineHeight: '20px',
                },
              }}
            >
              New
            </Typography>
            <Typography
              fontFamily="SF UI Display"
              fontSize={'16px'}
              fontWeight={'600'}
              lineHeight={'19.09px'}
              textAlign={'center'}
              color={'#000000'}
              sx={{
                '@media (max-width: 600px)': {
                  fontSize: '12px',
                  // width: '82%',
                  lineHeight: '14.32px',

                  // lineHeight: '20px',
                },
              }}
            >
              Getting started with SignWise.
            </Typography>
            <Typography
              onClick={handleClick}
              sx={{
                fontWeight: '700',
                fontSize: '16px',
                fontFamily: 'SF UI Display',
                textDecoration: 'underline',
                border: 'none',
                outline: 'none',
                cursor: 'pointer',
                '@media (max-width: 600px)': {
                  fontSize: '12px',
                  // width: '82%',
                  // lineHeight: '20px',
                  lineHeight: '14.32px',
                },
              }}
            >
              Read Now
            </Typography>
          </Box>
          <Typography
            fontFamily="SF UI Display"
            fontSize={'60px'}
            fontWeight={'900'}
            lineHeight={'71px'}
            textAlign={'center'}
            color={'#000000'}
            sx={{
              '@media (max-width: 1200px)': {
                fontSize: '48px',
                // fontWeight: '800',
                lineHeight: '56px',
              },
              '@media (max-width: 900px)': {
                fontSize: '38px',
                // fontWeight: '700',
                lineHeight: '40px',
              },
              '@media (max-width: 600px)': {
                fontSize: '24px',
                lineHeight: '52px',
                // fontWeight: '600',
                // marginTop: '2%',
              },
            }}
          >
            {/* The Sign Industry Network.{' '} */}
            Find the right sign partner.
          </Typography>
          <Typography
            fontFamily="SF UI Display"
            fontSize={'24px'}
            fontWeight={'300'}
            lineHeight={'40px'}
            textAlign={'center'}
            color={'#000000'}
            sx={{
              '@media (max-width: 1200px)': {
                fontSize: '20px',
              },
              '@media (max-width: 900px)': {
                fontSize: '17px',
              },
              '@media (max-width: 600px)': {
                fontSize: '16px',
                width: '82%',
                lineHeight: '20px',
              },
            }}
          >
            {/* Search, discover and review the companies you work with.{' '} */}
            Search, discover and choose the best local companies to work with.
          </Typography>
        </Box>
        <Box
          width={'100%'}
          display={'flex'}
          flexDirection={'column'}
          alignItems={'center'}
          margin={'7% 0'}
          gap={'30px'}
          sx={{
            // '@media (max-width: 1200px)': {
            //   width: '70%',
            // },
            // '@media (max-width: 900px)': {
            //   width: '80%',
            // },
            // '@media (max-width: 768px)': {
            //   width: '95%',
            // },
            '@media (max-width: 600px)': {
              marginTop: '10%',
            },
          }}
        >
          {/* <Box
            width={'90%'}
            display={'flex'}
            justifyContent={'space-between'}
            alignItems={'center'}
            gap={'32px'}
            sx={{
              '@media (max-width: 768px)': {
                width: '65%',
              },
              '@media (max-width: 500px)': {
                width: '95%',
                overflowX: 'scroll',
                '&::-webkit-scrollbar': {
                  display: 'none',
                },
                '-ms-overflow-style': 'none',
                'scrollbar-width': 'none',
              },
            }}
          >
            {typographyItems.map((item, index) => (
              <Box
                key={index}
                onClick={(): void => {
                  handleClick(index);
                }}
                sx={{ cursor: 'pointer', display: 'inline-block' }}
              >
                <Typography
                  fontFamily="SF UI Display"
                  fontSize={'18px'}
                  fontWeight={activeIndex === index ? '500' : '400'}
                  lineHeight={'21px'}
                  color={activeIndex === index ? '#00BD57' : '#000000'}
                  whiteSpace={'nowrap'}
                  padding={'5px 0'}
                  borderBottom={
                    activeIndex === index ? '3px solid #00BD57' : '3px solid transparent'
                  }
                  sx={{
                    '@media (max-width: 1200px)': {
                      fontSize: '16px',
                    },
                    '@media (max-width: 900px)': {
                      fontSize: '14px',
                    },
                  }}
                >
                  {isMobile ? item?.text.split(' ')[0] : item?.text}
                </Typography>
              </Box>
            ))}
          </Box> */}

          <Box
            display={'flex'}
            width={'100%'}
            sx={{
              '@media (max-width: 770px)': {
                display: 'none',
              },
            }}
          >
            <Box
              display={'flex'}
              width={'100%'}
              borderBottom={'1px solid #D7D8DD'}
              borderTop={'1px solid #D7D8DD'}
              borderLeft={'1px solid #D7D8DD'}
              sx={{
                borderTopLeftRadius: '5px',
                borderBottomLeftRadius: '5px',
              }}
            >
              <Box
                display={'flex'}
                alignItems={'center'}
                width={'57%'}
                borderRight={'1px solid #D7D8DD'}
              >
                <Typography
                  fontSize={'16px'}
                  fontWeight={'700'}
                  fontFamily={'SFProDisplay'}
                  color={'#00BD57'}
                  padding={'0 0 0 3%'}
                  sx={{
                    '@media (max-width: 900px)': {
                      fontSize: '14px',
                    },
                  }}
                >
                  Find
                </Typography>
                <Autocomplete
                  fullWidth
                  ListboxProps={{ style: { maxHeight: '15rem' } }}
                  freeSolo
                  disableClearable
                  options={companiesNames}
                  value={search ?? searchTypo}
                  onChange={handleSearchName}
                  onInputChange={handleInputSearch}
                  getOptionLabel={(option: any) => option?.name || ''}
                  filterOptions={(options, state) => {
                    if (state?.inputValue === '' && type !== 'local') {
                      return []; // Return an empty array if the input is empty
                    }
                    const inputValue = state.inputValue.toLowerCase();

                    const startsWithMatch = options.filter((option) =>
                      option.name?.toLowerCase().startsWith(inputValue),
                    );

                    // Filter for options containing the input value but not starting with it
                    const containsMatch = options.filter(
                      (option) =>
                        option.name?.toLowerCase().includes(inputValue) &&
                        !option.name?.toLowerCase().startsWith(inputValue),
                    );

                    // Combine the filtered results, prioritize starting matches first
                    const filteredOptions = [...startsWithMatch, ...containsMatch];

                    return filteredOptions.slice(0, 8);
                    // return options
                    //   .filter((option) => option.name?.toLowerCase().startsWith(inputValue))
                    //   .slice(0, 8);

                    // const filtered = filterOptions(options, state);
                    // return filtered?.slice(0, 8); // Ensures the maximum number of options is limited to 5
                  }}
                  renderOption={(props: any, option: any) => (
                    <Box
                      component="li"
                      {...props}
                      sx={{ display: 'flex', alignItems: 'center', gap: '15px' }}
                    >
                      <img
                        style={{ width: '40px', height: '40px', objectFit: 'cover' }}
                        src={
                          option?.logo
                            ? option?.logo === 'fabricatorsicon'
                              ? ServiceIcon2
                              : option?.logo === 'installerIcon'
                              ? ServiceIcon3
                              : option?.logo === 'surveyIcon'
                              ? ServiceIcon5
                              : option?.logo === 'serviceIcon'
                              ? ServiceIcon4
                              : option?.logo === 'fullserviceicon'
                              ? ServiceIcon1
                              : option?.logo === 'printersIcon'
                              ? printProduction
                              : option?.logo === 'printInstallersIcon'
                              ? PrintInstallation
                              : option?.logo === 'wrapInstallersIcon'
                              ? WrapInstallation
                              : option?.logo === 'permitsIcon'
                              ? ServiceIcon1
                              : `${mediaUrl}/${option?.logo}`
                            : defaultCompany
                        }
                      />
                      {option?.name}
                    </Box>
                  )}
                  renderInput={(params) => (
                    <TextField
                      {...params}
                      placeholder={
                        activeIndex !== 0
                          ? 'Enter Name [Optional]'
                          : 'Installers, Fabricators, Printers, Service....'
                      }
                      sx={{
                        width: '100%',
                        fontFamily: 'SF UI Display',
                        fontSize: '15px !important',
                        fontWeight: '400',
                        lineHeight: '24px',
                        color: '#667085',
                        '.MuiOutlinedInput-input': {
                          padding: '0px 10px 0px 0px !important',
                          fontSize: '15px ',
                        },
                        '& .MuiOutlinedInput-root': {
                          '& fieldset': {
                            border: 'none',
                            outline: 'none',
                          },
                        },
                        '@media (max-width: 900px)': {
                          fontSize: '14px',
                        },
                      }}
                      type="search"
                    />
                  )}
                />
                {/* <TextField
                  placeholder={
                    activeIndex !== 0
                      ? 'Enter Name [Optional]'
                      : 'Installers, Fabricators, Service, Permitt...'
                  }
                  value={search}
                  onChange={(e): void => {
                    setSearch(e.target.value);
                  }}
                  sx={{
                    width: '100%',
                    fontSize: '16px',
                    fontWeight: '400',
                    lineHeight: '24px',
                    color: '#667085',
                    '.MuiOutlinedInput-input': {
                      padding: '16.5px 10px 16.5px 5px',
                    },
                    '& .MuiOutlinedInput-root': {
                      '& fieldset': {
                        border: 'none',
                        outline: 'none',
                      },
                    },
                    '@media (max-width: 900px)': {
                      fontSize: '14px',
                    },
                  }}
                  type="search"
                /> */}
              </Box>
              <Box display={'flex'} alignItems={'center'} width={'55%'}>
                <Typography
                  fontSize={'16px'}
                  fontWeight={'700'}
                  fontFamily={'SFProDisplay'}
                  color={'#00BD57'}
                  padding={'0 0 0 3%'}
                  sx={{
                    '@media (max-width: 900px)': {
                      fontSize: '14px',
                    },
                  }}
                >
                  {activeIndex === 1
                    ? 'Location'
                    : activeIndex === 2 || activeIndex === 3
                    ? 'Category'
                    : 'Near'}
                </Typography>
                {activeIndex === 1 ? (
                  <Autocomplete
                    fullWidth
                    options={states}
                    getOptionLabel={(option: string) => option}
                    value={near}
                    onInputChange={handleNearChange}
                    onChange={handleStateChange}
                    renderInput={(params) => (
                      <TextField
                        {...params}
                        placeholder="North America"
                        variant="outlined"
                        sx={{
                          width: '100%',
                          fontSize: '16px',
                          fontWeight: '400',
                          lineHeight: '24px',
                          color: '#667085',
                          borderRadius: '8px',
                          '@media (max-width: 900px)': {
                            fontSize: '14px',
                          },
                          '.MuiOutlinedInput-input': {
                            padding: '10px 14px',
                          },
                          '& .MuiOutlinedInput-root': {
                            '& fieldset': {
                              border: 'none',
                              outline: 'none',
                            },
                          },

                          '& .MuiAutocomplete-input': {
                            padding: '7.5px 4px 7.5px 0px !important',
                          },
                        }}
                      />
                    )}
                  />
                ) : activeIndex === 2 || activeIndex === 3 ? (
                  <Autocomplete
                    fullWidth
                    // options={category}
                    // getOptionLabel={(option: any) => option?.category ?? near}
                    options={category}
                    // getOptionLabel={(option: any) => (option.subCategory || option.category) ?? near}
                    value={near}
                    // name="category"
                    onChange={handleCategoryChange}
                    renderInput={(params) => (
                      <TextField
                        {...params}
                        placeholder="Software, Equipment, Displays..."
                        variant="outlined"
                        sx={{
                          width: '100%',
                          fontSize: '16px',
                          fontWeight: '400',
                          lineHeight: '24px',
                          color: '#667085',
                          borderRadius: '8px',
                          '@media (max-width: 900px)': {
                            fontSize: '14px',
                          },
                          '.MuiOutlinedInput-input': {
                            padding: '10px 14px',
                          },
                          '& .MuiOutlinedInput-root': {
                            '& fieldset': {
                              border: 'none',
                              outline: 'none',
                            },
                          },
                          '& .MuiAutocomplete-input': {
                            padding: '7.5px 4px 7.5px 0px !important',
                          },
                        }}
                      />
                    )}
                  />
                ) : (
                  <Autocomplete
                    fullWidth
                    disableClearable
                    freeSolo
                    // options={allAddresses.map((address) => address?.contexts?.region)}
                    options={allAddresses.map(formatAddressOption)}
                    value={near}
                    onInputChange={handleNearChange}
                    onChange={(event, value): void => {
                      handleAddressSelect(
                        allAddresses.find((address) => formatAddressOption(address) === value) ??
                          null,
                      );
                    }}
                    renderInput={(params) => (
                      <TextField
                        {...params}
                        InputProps={{
                          ...params.InputProps,
                          endAdornment: null, // Ensure no end adornment is displayed
                        }}
                        placeholder={
                          activeIndex === 1
                            ? 'North America'
                            : activeIndex === 3
                            ? 'Software, Equipment, Displays...'
                            : 'City & State or Province'
                        }
                        sx={{
                          width: '100%',
                          fontSize: '16px',
                          fontWeight: '400',
                          lineHeight: '24px',
                          color: '#667085',
                          '@media (max-width: 900px)': {
                            fontSize: '14px',
                          },
                          '.MuiOutlinedInput-input': {
                            padding: '16.5px 10px 16.5px 5px',
                          },
                          '& .MuiOutlinedInput-root': {
                            '& fieldset': {
                              border: 'none',
                              outline: 'none',
                            },
                          },
                          '& .MuiAutocomplete-input': {
                            padding: '7.5px 4px 7.5px 0px !important',
                          },
                        }}
                        type="search"
                      />
                    )}
                  />
                )}
              </Box>{' '}
            </Box>

            <Button
              sx={{
                width: '14%',
                paddingTop: '5px',
                paddingLeft: '5px',
                border: '1px solid #000000',
                fontFamily: 'SF UI Display',
                fontSize: '16px',
                fontWeight: '600',
                lineHeight: '19.09px',
                textAlign: 'center',
                color: '#ffffff',
                backgroundColor: 'transparent', // White background for top and left padding area
                borderTopLeftRadius: '0',
                borderBottomLeftRadius: '0',
                textTransform: 'capitalize',
                position: 'relative',
                overflow: 'visible', // Allow overflow of pseudo-element
                '&::before': {
                  content: '""',
                  position: 'absolute',
                  top: '3px', // Align with the padding
                  left: '3px', // Align with the padding
                  right: '-3px', // Extend 5px to the right for overflow
                  bottom: '-3px', // Extend 5px to the bottom for overflow
                  backgroundColor: '#00BD57', // Green color for overflow
                  zIndex: -1,
                  borderRadius: 'inherit',
                },
                '&:hover::before': {
                  backgroundColor: '#00BD57', // Ensure hover behavior matches overflow
                },
                '@media (max-width: 900px)': {
                  fontWeight: '500',
                },
              }}
              onClick={handleSearch}
            >
              Search
            </Button>
          </Box>
          <Box
            display={'none'}
            width={'100%'}
            flexDirection={'column'}
            gap={'15px'}
            alignItems={'center'}
            sx={{
              '@media (max-width: 770px)': {
                display: 'flex',
              },
            }}
          >
            <Box
              width={'300px'}
              display={'flex'}
              alignItems={'center'}
              border={'1px solid #D7D8DD'}
              borderRadius={'5px'}
              sx={{
                '@media (max-width: 768px)': {
                  width: '70%',
                },
                '@media (max-width: 500px)': {
                  width: '90%',
                },
              }}
            >
              <Typography
                fontSize={'16px'}
                lineHeight={'19px'}
                fontWeight={'700'}
                fontFamily={'SFProDisplay'}
                color={'#00BD57'}
                padding={'18px 0 18px 3%'}
                sx={{
                  '@media (max-width: 900px)': {
                    fontSize: '14px',
                  },
                  '@media (max-width: 500px)': {
                    fontSize: '16px',
                  },
                }}
              >
                Find
              </Typography>
              <Autocomplete
                fullWidth
                freeSolo
                disableClearable
                options={companiesNames}
                value={search ?? searchTypo}
                onChange={handleSearchName}
                onInputChange={handleInputSearch}
                getOptionLabel={(option: any) => option?.name || ''}
                filterOptions={(options, state) => {
                  if (state?.inputValue === '' && type !== 'local') {
                    return []; // Return an empty array if the input is empty
                  }
                  const inputValue = state.inputValue.toLowerCase();
                  const startsWithMatch = options.filter((option) =>
                    option.name?.toLowerCase().startsWith(inputValue),
                  );

                  // Filter for options containing the input value but not starting with it
                  const containsMatch = options.filter(
                    (option) =>
                      option.name?.toLowerCase().includes(inputValue) &&
                      !option.name?.toLowerCase().startsWith(inputValue),
                  );

                  // Combine the filtered results, prioritize starting matches first
                  const filteredOptions = [...startsWithMatch, ...containsMatch];

                  return filteredOptions.slice(0, 8);
                  // return options
                  //   .filter((option) => option.name?.toLowerCase().startsWith(inputValue))
                  //   .slice(0, 8);
                  // const filtered = filterOptions(options, state);
                  // return filtered?.slice(0, 8); // Ensures the maximum number of options is limited to 5
                }}
                renderOption={(props: any, option: any) => (
                  <Box
                    component="li"
                    {...props}
                    sx={{ display: 'flex', alignItems: 'center', gap: '15px' }}
                  >
                    <img
                      style={{ width: '40px', height: '40px', objectFit: 'cover' }}
                      src={
                        option?.logo
                          ? option?.logo === 'fabricatorsicon'
                            ? ServiceIcon2
                            : option?.logo === 'installerIcon'
                            ? ServiceIcon3
                            : option?.logo === 'surveyIcon'
                            ? ServiceIcon5
                            : option?.logo === 'serviceIcon'
                            ? ServiceIcon4
                            : option?.logo === 'fullserviceicon'
                            ? ServiceIcon1
                            : option?.logo === 'printersIcon'
                            ? printProduction
                            : option?.logo === 'printInstallersIcon'
                            ? PrintInstallation
                            : option?.logo === 'wrapInstallersIcon'
                            ? WrapInstallation
                            : option?.logo === 'permitsIcon'
                            ? ServiceIcon1
                            : `${mediaUrl}/${option?.logo}`
                          : defaultCompany
                      }
                    />
                    {option?.name}
                  </Box>
                )}
                renderInput={(params) => (
                  <TextField
                    {...params}
                    placeholder={
                      activeIndex !== 0
                        ? 'Enter Name [Optional]'
                        : 'Installers, Fabricators, Printers, Service, Full Service...'
                    }
                    sx={{
                      width: '100%',
                      fontSize: '15px !important',
                      fontWeight: '400',
                      fontFamily: 'SF UI Display',
                      lineHeight: '24px',
                      color: '#667085',
                      '.MuiOutlinedInput-input': {
                        padding: '0px 10px 0px 0px !important',
                        fontSize: '15px ',
                      },
                      '& .MuiOutlinedInput-root': {
                        '& fieldset': {
                          border: 'none',
                          outline: 'none',
                        },
                      },
                      '@media (max-width: 900px)': {
                        fontSize: '14px',
                      },
                    }}
                    type="search"
                  />
                )}
              />
            </Box>
            <Box
              display={'flex'}
              alignItems={'center'}
              width={'300px'}
              border={'1px solid #D7D8DD'}
              borderRadius={'5px'}
              sx={{
                '@media (max-width: 768px)': {
                  width: '70%',
                },
                '@media (max-width: 500px)': {
                  width: '90%',
                },
              }}
            >
              <Typography
                fontSize={'16px'}
                fontWeight={'700'}
                fontFamily={'SFProDisplay'}
                lineHeight={'19px'}
                color={'#00BD57'}
                padding={'11.5px 0 11.5px 3%'}
                sx={{
                  '@media (max-width: 900px)': {
                    fontSize: '14px',
                  },
                  '@media (max-width: 500px)': {
                    fontSize: '16px',
                  },
                }}
              >
                {activeIndex === 1
                  ? 'Location'
                  : activeIndex === 2 || activeIndex === 3
                  ? 'Category'
                  : 'Near'}
              </Typography>
              {activeIndex === 1 ? (
                <Autocomplete
                  fullWidth
                  options={states}
                  getOptionLabel={(option: string) => option}
                  value={near}
                  onInputChange={handleNearChange}
                  onChange={handleStateChange}
                  renderInput={(params) => (
                    <TextField
                      {...params}
                      placeholder="North America"
                      variant="outlined"
                      sx={{
                        width: '100%',
                        fontSize: '16px',
                        fontWeight: '400',
                        lineHeight: '24px',
                        color: '#667085',
                        borderRadius: '8px',
                        '@media (max-width: 900px)': {
                          fontSize: '14px',
                        },
                        '.MuiOutlinedInput-input': {
                          padding: '10px 14px',
                        },
                        '& .MuiOutlinedInput-root': {
                          '& fieldset': {
                            border: 'none',
                            outline: 'none',
                          },
                        },
                        '& .MuiAutocomplete-input': {
                          padding: '7.5px 4px 7.5px 0px !important',
                        },
                      }}
                    />
                  )}
                />
              ) : activeIndex === 2 || activeIndex === 3 ? (
                <Autocomplete
                  fullWidth
                  // options={category}
                  // getOptionLabel={(option: any) => option?.category ?? near}
                  options={category}
                  // getOptionLabel={(option: any) => (option.subCategory || option.category) ?? near}
                  value={near}
                  // name="category"
                  onChange={handleCategoryChange}
                  renderInput={(params) => (
                    <TextField
                      {...params}
                      placeholder="Software, Equipment..."
                      variant="outlined"
                      sx={{
                        width: '100%',
                        fontSize: '16px',
                        fontWeight: '400',
                        lineHeight: '24px',
                        color: '#667085',
                        borderRadius: '8px',
                        '@media (max-width: 900px)': {
                          fontSize: '14px',
                        },
                        '.MuiOutlinedInput-input': {
                          padding: '10px 14px',
                        },
                        '& .MuiOutlinedInput-root': {
                          '& fieldset': {
                            border: 'none',
                            outline: 'none',
                          },
                        },
                        '& .MuiAutocomplete-input': {
                          padding: '7.5px 4px 7.5px 0px !important',
                        },
                      }}
                    />
                  )}
                />
              ) : (
                <Autocomplete
                  fullWidth
                  disableClearable
                  freeSolo
                  // options={allAddresses.map((address) => address?.contexts?.region)}
                  options={allAddresses.map(formatAddressOption)}
                  value={near}
                  onInputChange={handleNearChange}
                  onChange={(event, value): void => {
                    handleAddressSelect(
                      allAddresses.find((address) => formatAddressOption(address) === value) ??
                        null,
                    );
                  }}
                  renderInput={(params) => (
                    <TextField
                      {...params}
                      InputProps={{
                        ...params.InputProps,
                        endAdornment: null, // Ensure no end adornment is displayed
                      }}
                      placeholder={
                        activeIndex === 1
                          ? 'North America'
                          : activeIndex === 3
                          ? 'Software, Equipment...'
                          : 'City & State or Province'
                      }
                      sx={{
                        width: '100%',
                        fontSize: '16px',
                        fontWeight: '400',
                        lineHeight: '24px',
                        color: '#667085',
                        '@media (max-width: 900px)': {
                          fontSize: '14px',
                        },
                        '.MuiOutlinedInput-input': {
                          padding: '16.5px 5px',
                        },
                        '& .MuiOutlinedInput-root': {
                          '& fieldset': {
                            border: 'none',
                            outline: 'none',
                          },
                        },
                        '& .MuiAutocomplete-input': {
                          padding: '7.5px 4px 7.5px 0px !important',
                        },
                      }}
                      type="search"
                    />
                  )}
                />
              )}
            </Box>{' '}
            <Button
              sx={{
                marginTop: '14px',
                width: '300px',
                paddingTop: '16.5px',
                paddingBottom: '16.5px',
                border: '1px solid #000000',
                borderRadius: '5px',
                fontFamily: 'SF UI Display',
                fontSize: '16px',
                fontWeight: '600',
                lineHeight: '19.09px',
                textAlign: 'center',
                color: '#ffffff',
                backgroundColor: 'transparent', // White background for top and left padding area
                // borderTopLeftRadius: '0',
                // borderBottomLeftRadius: '0',
                textTransform: 'capitalize',
                position: 'relative',
                overflow: 'visible', // Allow overflow of pseudo-element
                '&::before': {
                  content: '""',
                  position: 'absolute',
                  top: '4px', // Align with the padding
                  left: '4px', // Align with the padding
                  right: '-3px', // Extend 5px to the right for overflow
                  bottom: '-3px', // Extend 5px to the bottom for overflow
                  backgroundColor: '#00BD57', // Green color for overflow
                  zIndex: -1,
                  borderRadius: 'inherit',
                },
                '&:hover::before': {
                  backgroundColor: '#00BD57', // Ensure hover behavior matches overflow
                },
                '@media (max-width: 900px)': {
                  fontWeight: '500',
                  width: '300px',
                },
                '@media (max-width: 430px)': {
                  width: '90%',
                  fontWeight: '600',
                },
                '@media (max-width: 400px)': {
                  width: '90%',
                },
              }}
              // sx={{
              //   marginTop: '26px',
              //   paddingTop: '12px',
              //   paddingBottom: '12px',
              //   width: '300px',
              //   bgcolor: '#00BD57',
              //   borderRadius: '5px',
              //   fontFamily: 'SF UI Display',
              //   fontSize: '16px',
              //   fontWeight: '600',
              //   lineheight: '19.09px',
              //   textAlign: 'center',
              //   color: '#ffffff',
              //   textTransform: 'capitalize',
              //   '&:hover': {
              //     bgcolor: '#00BD57',
              //   },
              //   '@media (max-width: 900px)': {
              //     fontWeight: '500',
              //     width: '300px',
              //   },
              //   '@media (max-width: 430px)': {
              //     width: '90%',
              //   },
              //   '@media (max-width: 400px)': {
              //     width: '90%',
              //   },
              // }}
              onClick={handleSearch}
            >
              Search
            </Button>
          </Box>
        </Box>
      </Box>
      {/* <Box
        width={'100%'}
        display={'flex'}
        justifyContent={'center'}
        gap={'3%'}
        // marginTop={'2%'}
        sx={{
          '@media (max-width: 768px)': {
            display: 'flex',
            flexDirection: 'column',
            alignItems: 'center',
            gap: '15px',
            margin: '0 0 10% 0',
          },
        }}
      >
        {cardData.map((item, index) => (
          <Box
            key={index}
            width={'27%'}
            bgcolor={'#00BD57'}
            padding={' 1% 2% 2%2%'}
            borderRadius={'10px'}
            sx={{
              // '@media (max-width: 900px)': {
              //   width: '32%',
              // },
              '@media (max-width: 768px)': {
                width: '60%',
              },
              '@media (max-width: 500px)': {
                width: '80%',
                padding: '4%',
              },
            }}
          >
            <Typography
              fontFamily={'SF UI Display'}
              fontSize={'24px'}
              fontWeight={'800'}
              lineHeight={'29px'}
              color={'#FFFFFF'}
              sx={{
                '@media (max-width: 1200px)': {
                  fontSize: '22px',
                },
                '@media (max-width: 900px)': {
                  fontSize: '20px',
                },
              }}
            >
              {item.title}
            </Typography>
            <Typography
              fontFamily={'Poppins'}
              fontSize={'18px'}
              fontWeight={'800'}
              lineHeight={'27px'}
              color={'#FFFFFF'}
              margin={'4% 0'}
              sx={{
                '@media (max-width: 1200px)': {
                  fontSize: '17px',
                  fontWeight: '700',
                },
                '@media (max-width: 900px)': {
                  fontSize: '16px',
                  fontWeight: '600',
                  lineHeight: '24px',
                },
              }}
            >
              {item.subHeading}
            </Typography>
            <Typography
              fontFamily="SF UI Display"
              fontSize={'16px'}
              fontWeight={'400'}
              lineHeight={'19px'}
              color={'#FFFFFF'}
              sx={{
                '@media (max-width: 900px)': {
                  fontSize: '15px',
                },
              }}
            >
              {item.desp}
            </Typography>
            <Box
              width={'100%'}
              marginTop={'5%'}
              sx={{
                '@media (max-width: 900px)': {
                  marginTop: '8%',
                },
              }}
            >
              <img src={item?.Img} style={{ width: '100%' }} alt={`${item.title} image 1`} />
            </Box>
            {item.Img2 && (
              <Box width={'100%'}>
                <img src={item?.Img2} style={{ width: '100%' }} alt={`${item.title} image 2`} />
              </Box>
            )}
          </Box>
        ))}
      </Box> */}
    </Box>
  );
};

export default LocalSearch;
