import React, { FC, useEffect, useRef, useState } from 'react';
import {
  Box,
  Chip,
  //  Rating,
  Typography,
} from '@mui/material';
import { mediaUrl } from '../../../config';
import defaultCompany from '../../../assets/defaultCompany.png';
// import LicenseIdIcon from '../../../assets/nationalSignCompany/licenseIdIcon.svg';
import ResponseTime from '../../../assets/beta/response_time.svg';
import Availability from '../../../assets/beta/availability.svg';
// import CheckmarkFilled from '../../../assets/nationalSignCompany/checkmarkFilled.svg';
// import Complaint from '../../../assets/complaint.svg';
// import CommentOutlinedIcon from '@mui/icons-material/CommentOutlined';
// import StarBorderIcon from '@mui/icons-material/StarBorder';
// import { styled } from '@mui/material/styles';
import { useNavigate } from 'react-router-dom';

interface ListingCardProps {
  item?: any;
  key?: any;
  type?: any;
  search?: any;
  additional?: any;
  coordinates?: any;
  near?: any;
  searchTypo?: any;
  myLocation?: any;
}
// const CustomStarBorderIcon = styled(StarBorderIcon)({
//   color: '#E8E8E8', // Set the outline color to white
//   stroke: '#E8E8E8', // Ensure the outline is white
//   strokeWidth: 0.5, // Adjust stroke width as needed
// });
const ListingCard: FC<ListingCardProps> = ({
  item,
  key,
  type,
  search,
  additional,
  coordinates,
  near,
  searchTypo,
  myLocation,
}) => {
  const nav = useNavigate();
  const [isExpanded, setIsExpanded] = useState(false);
  const [isTruncated, setIsTruncated1] = useState(false);
  const descriptionRef = useRef<HTMLDivElement>(null);
  const description = item?.companyInfo?.description || '';
  const [visibleItems, setVisibleItems] = useState<string[]>([]);
  const [hiddenCount, setHiddenCount] = useState<number>(0);
  const [visibleItems1, setVisibleItems1] = useState<string[]>([]);
  const [hiddenCount1, setHiddenCount1] = useState<number>(0);
  const containerRef = useRef<HTMLDivElement>(null); // Explicitly typing the ref
  const containerRef1 = useRef<HTMLDivElement>(null); // Explicitly typing the ref
  const [showFullDescription, setShowFullDescription] = useState(false);

  const toggleDescription = (): void => {
    setShowFullDescription(!showFullDescription);
  };

  const slicedDescription =
    /* eslint-disable @typescript-eslint/restrict-template-expressions */
    description.length > 120 ? `${description.slice(0, 120)}...` : description;

  const innerWidth = window.innerWidth;
  console.log('innerWidth', innerWidth);
  const sliceNum =
    innerWidth >= 1500
      ? 145
      : innerWidth >= 1400
      ? 140
      : innerWidth >= 1300
      ? 120
      : innerWidth > 900
      ? 95
      : innerWidth >= 768
      ? 120
      : 150;
  console.log('sliceNum', sliceNum);

  useEffect(() => {
    const element = descriptionRef.current;
    if (element && description.length > 140) {
      setIsTruncated1(element.scrollHeight > element.clientHeight);
    }
  }, [description]);

  // Effect for the second ref
  //   useEffect(() => {
  //     const element = descriptionRef2.current;
  //     if (element && description.length > 140) {
  //       setIsTruncated2(element.scrollHeight > element.clientHeight);
  //     }
  //   }, [description]);

  const handleToggle = (): void => {
    setIsExpanded((prev) => !prev);
  };

  const categoryArray = item?.companyInfo?.category.reduce((acc: any, item: any) => {
    acc.push(item.name, ...item.subCategory);
    return acc;
  }, []);

  console.log('categoryArray', categoryArray);

  const renderDescription = (): any => {
    if (!description || description.length <= 140) return description; // No buttons for short descriptions

    if (!isTruncated || isExpanded) {
      return (
        <>
          {description}
          <span
            onClick={(e) => {
              e.stopPropagation();
              handleToggle();
            }}
            style={{
              color: '#00BD57',
              fontFamily: 'SF UI Display',
              fontSize: '16px',
              fontWeight: '700',
            }}
          >
            {' '}
            Show Less
          </span>
        </>
      );
    }
    const truncatedText = description.slice(0, sliceNum); // Adjust slice length as needed
    return (
      <>
        {truncatedText}...
        <span
          onClick={(e) => {
            e.stopPropagation();
            handleToggle();
          }}
          style={{
            color: '#00BD57',
            fontFamily: 'SF UI Display',
            fontSize: '16px',
            fontWeight: '700',
          }}
        >
          {' '}
          Learn More
        </span>
      </>
    );
  };

  const formatSearchString = (str: string): string => {
    if (!str) return ''; // Handle cases where str might be null or undefined
    const formattedStr = str.toLowerCase().replace(/\s+/g, '-').trim();
    switch (formattedStr) {
      case 'fabricators':
        return 'sign-fabricators';
      case 'installers':
        return 'sign-installers';
      case 'full-service-companies': // Handle the formatted string directly
        return 'full-service';
      default:
        return formattedStr;
    }
  };
  useEffect(() => {
    if (containerRef.current) {
      const containerWidth = containerRef.current.offsetWidth; // Now TypeScript knows this is an HTMLDivElement
      let totalWidth = 0;
      const itemsToShow: string[] = [];

      for (let i = 0; i < categoryArray.length; i++) {
        // Measure the width of each item
        const itemWidth = getTextWidth(categoryArray[i]) + 16; // adding 16px for padding and margin

        if (totalWidth + itemWidth <= containerWidth) {
          itemsToShow.push(categoryArray[i]);
          totalWidth += itemWidth;
        } else {
          setHiddenCount(categoryArray.length - i);
          break;
        }
      }

      setVisibleItems(itemsToShow);
    }
  }, []);
  const getTextWidth = (text: string): number => {
    // Temporary element to measure text width
    const canvas = document.createElement('canvas');
    const context = canvas.getContext('2d');
    if (context) {
      context.font = '14px Arial'; // Match the font size and family you are using
      const textMetrics = context.measureText(text);
      return textMetrics.width;
    }
    return 0;
  };
  useEffect(() => {
    if (containerRef1.current) {
      const containerWidth = containerRef1.current.offsetWidth; // Now TypeScript knows this is an HTMLDivElement
      let totalWidth = 0;
      const itemsToShow: string[] = [];

      for (let i = 0; i < categoryArray.length; i++) {
        // Measure the width of each item
        const itemWidth = getTextWidth1(categoryArray[i]) + 16; // adding 16px for padding and margin

        if (totalWidth + itemWidth <= containerWidth) {
          itemsToShow.push(categoryArray[i]);
          totalWidth += itemWidth;
        } else {
          setHiddenCount1(categoryArray.length - i);
          break;
        }
      }

      setVisibleItems1(itemsToShow);
    }
  }, []);
  const getTextWidth1 = (text: string): number => {
    // Temporary element to measure text width
    const canvas = document.createElement('canvas');
    const context = canvas.getContext('2d');
    if (context) {
      context.font = '14px Arial'; // Match the font size and family you are using
      const textMetrics = context.measureText(text);
      return textMetrics.width;
    }
    return 0;
  };

  console.log('comp list item', item);
  return (
    <Box
      key={key}
      onClick={() => {
        const paramType =
          type === 'local'
            ? 'local-sign-company'
            : type === 'national'
            ? 'national-sign-company'
            : type === 'supplier'
            ? 'supplier'
            : 'distributor';
        nav(`/${paramType}/${formatSearchString(item?.companyInfo?.name)}`, {
          state: {
            item,
            type,
            search,
            additional,
            coords: coordinates ?? '',
            near,
            searchTypo,
            myCurrLoc: myLocation,
          },
        });
      }}
      width={'100%'}
      padding={'2%'}
      border={'1px solid #E8E8E8'}
      borderRadius={'10px'}
      display={'flex'}
      alignItems={'flex-start'}
      gap={'27px'}
      position={'relative'}
      overflow={'hidden'}
      sx={{
        '@media (max-width: 768px)': {
          flexWrap: 'wrap',
          gap: '0 15px',
          alignItems: 'center',
          padding: '15px',
        },
        cursor: 'pointer',
        transition: 'all 0.3s ease-in-out',
        '&:hover': {
          border: '1px solid transparent', // Keep the border width constant
          boxShadow: '0 0 0 1px #E8E8E8, 0 0 0 2px rgba(0, 0, 0, 1)',
        },
      }}
    >
      <Box
        width={'158px'}
        height={'158px'}
        sx={{
          '@media (max-width: 768px)': {
            width: '100px',
            height: '100px',
          },
          '@media (max-width:600px)': {
            width: '57px',
            height: '57px',
          },
        }}
      >
        <img
          src={
            /* eslint-disable @typescript-eslint/restrict-template-expressions */
            item?.companyInfo?.logo ? `${mediaUrl}/${item?.companyInfo?.logo}` : defaultCompany
          }
          style={{
            width: '100%',
            height: '100%',
            objectFit: 'cover',
            borderRadius: '10px',
          }}
          alt="logo"
        />
      </Box>
      <Box
        width={'80%'}
        sx={{
          '@media (max-width: 768px)': {
            width: '70%',
          },
        }}
      >
        <Box width={'100%'}>
          <Typography
            fontSize={'24px'}
            fontWeight={'800'}
            fontFamily={'SF UI Display'}
            color={'#000000'}
            lineHeight={'18px'}
            mb={'10px'}
            sx={{
              '@media (max-width: 768px)': {
                fontSize: '16px',
                width: '100%',
                lineHeight: '1.2',
              },
            }}
          >
            {item?.companyInfo?.name}
          </Typography>
          <Typography
            fontSize={'20px'}
            fontWeight={'300'}
            fontFamily={'SF UI Display'}
            color={'#000000'}
            lineHeight={'24px'}
            sx={{
              '@media (max-width: 768px)': {
                fontSize: '16px',
                width: '100%',
                // lineHeight: '24px',
              },
            }}
          >
            Local Sign Company
          </Typography>
          {/* <Box
            width={'100%'}
            display={'flex'}
            alignItems={'center'}
            justifyContent={'left'}
            gap={'0 10px'}
            sx={{
              '@media (max-width: 768px)': {
                flexWrap: 'wrap',
                gap: '0 10px',
              },
              '@media (max-width: 500px)': {
                gap: '0 5px',
              },
            }}
          >
            <Rating
              name="hover-feedback"
              value={
                typeof item?.averageReviewRating?.overall === 'number'
                  ? item.averageReviewRating.overall
                  : parseFloat(item?.averageReviewRating?.overall) || 0
              }
              precision={0.1}
              sx={{
                color: !item?.averageReviewRating
                  ? '#9E9E9E'
                  : // : item?.averageReviewRating?.overall >= 4.6
                  // ? '#3040CE'
                  item?.averageReviewRating?.overall >= 4
                  ? '#00BD57'
                  : item?.averageReviewRating?.overall >= 3
                  ? '#FFB800'
                  : '#FD3E14',
                fontSize: '20px',
                '@media (max-width: 768px)': {
                  fontSize: '17px',
                },
              }}
              // size="small"
              readOnly
              emptyIcon={<CustomStarBorderIcon fontSize="inherit" />}
            />
            {item?.averageReviewRating?.overall > 0 ? (
              <Typography
                fontSize={'16px'}
                fontWeight={'600'}
                fontFamily={'SF UI Display'}
                color={'#000'}
              >
                {(Math.floor(item?.averageReviewRating?.overall * 10) / 10).toFixed(1)}
              </Typography>
            ) : (
              ''
            )}

            <Typography
              fontSize={'16px'}
              fontWeight={'400'}
              fontFamily={'SF UI Display'}
              color={'#777'}
              sx={{
                '@media (max-width: 768px)': {
                  fontSize: '14px',
                },
              }}
            >
              {`(${item?.averageReviewRating?.count ?? '0'} reviews)`}
            </Typography>
          </Box> */}
        </Box>
        {item?.companyInfo?.owner &&
        item?.companyInfo?.companyType !== 'supplier' &&
        item?.companyInfo?.companyType !== 'distributor' ? (
          <Box
            display={
              !item?.companyInfo?.responce_time?.length &&
              item?.companyInfo?.available_for_work !== 'Yes'
                ? 'none'
                : 'flex'
            }
            alignItems={'center'}
            width={'100%'}
            gap={'5%'}
            margin={'2% 0'}
            sx={{
              '@media (max-width: 768px)': {
                display: 'none',
              },
              '@media (max-width: 500px)': {
                margin: '4% 0',
              },
            }}
          >
            {item?.companyInfo?.responce_time?.length && (
              <Box display={'flex'} alignItems={'center'} gap={'8px'}>
                <img src={ResponseTime} alt="Checkmark Filled" />
                <Typography
                  fontSize={'14px'}
                  fontWeight={'600'}
                  fontFamily={'Poppins'}
                  color={'#000000'}
                  textTransform={'capitalize'}
                  sx={{
                    // '@media (max-width: 768px)': {
                    //   fontWeight: '400',
                    // },
                    '@media (max-width: 600px)': {
                      fontSize: '12px',
                    },
                  }}
                >
                  {/* #{item?._id.slice(-5)} */}
                  {item?.companyInfo?.responce_time} Response
                  {/* 48hr Response */}
                </Typography>
              </Box>
            )}

            {item?.companyInfo?.available_for_work === 'Yes' && (
              <Box display={'flex'} alignItems={'center'} gap={'8px'}>
                <img src={Availability} alt="Checkmark Filled" />
                <Typography
                  fontSize={'14px'}
                  fontWeight={'600'}
                  fontFamily={'Poppins'}
                  color={'#000000'}
                  textTransform={'capitalize'}
                  sx={{
                    // '@media (max-width: 768px)': {
                    //   fontWeight: '400',
                    // },
                    '@media (max-width: 600px)': {
                      fontSize: '12px',
                    },
                  }}
                >
                  Available For Work
                </Typography>
              </Box>
            )}

            {/* <Box
              display={'flex'}
              alignItems={'center'}
              gap={'8px'}
              // sx={{
              //   '@media (max-width: 500px)': {
              //     flexDirection: 'column',
              //   },
              // }}
            >
              <img src={!item?.complaint ? CheckmarkFilled : Complaint} alt="Checkmark Filled" />
              <Typography
                fontSize={'14px'}
                fontWeight={'500'}
                fontFamily={'Poppins'}
                color={'#000000'}
                sx={{
                  '@media (max-width: 768px)': {
                    fontWeight: '400',
                  },
                  '@media (max-width: 600px)': {
                    fontSize: '12px',
                  },
                }}
              >
                
                {!item?.complaint ? 'No Complaints' : 'Complaints'}
              </Typography>
            </Box> */}
          </Box>
        ) : (
          <Box display={'none'}></Box>
        )}

        <Box
          display={'flex'}
          width={'100%'}
          gap={'8px'}
          margin={
            item?.companyInfo?.companyType !== 'supplier' ||
            item?.companyInfo?.companyType !== 'distributor'
              ? '10px 0'
              : '0'
          }
          sx={{
            '@media (max-width: 768px)': {
              alignItems: 'center',
              display: 'none',
            },
          }}
        >
          <Typography
            fontSize={'16px'}
            fontWeight={'300'}
            fontFamily={'SF UI Display'}
            color={'#000'}
            ref={descriptionRef}
            sx={{
              marginTop:
                !item?.companyInfo?.responce_time?.length &&
                item?.companyInfo?.available_for_work !== 'Yes'
                  ? '9px'
                  : '0px',
              '@media (max-width: 768px)': {
                fontSize: '14px',
                display: 'none',
              },

              overflow: 'hidden',
              textOverflow: 'ellipsis',
              display: '-webkit-box',
              WebkitBoxOrient: 'vertical',
              WebkitLineClamp: isExpanded ? 'none' : 2,
              maxWidth: '100%',
              cursor: 'pointer',
            }}
          >
            {renderDescription()}
          </Typography>
        </Box>
        {(item?.companyInfo?.companyType === 'supplier' ||
          item?.companyInfo?.companyType === 'distributor') &&
        item?.companyInfo?.owner ? (
          <Box
            ref={containerRef}
            width={'80%'}
            display="flex"
            alignItems="center"
            gap={1}
            sx={{
              '@media (max-width: 768px)': {
                display: 'none',
              },
            }}
          >
            {visibleItems?.map((item, index) => (
              <Chip
                sx={{
                  backgroundColor: '#F7F7F7',
                  borderRadius: '50px',
                  color: '#000',
                  textAlign: 'center',
                  fontFamily: 'SF UI Display',
                  fontSize: '14px',
                  fontWeight: '500',
                }}
                key={index}
                label={item}
              />
            ))}
            {hiddenCount > 0 && (
              <Chip
                sx={{
                  backgroundColor: '#F7F7F7',
                  borderRadius: '50px',
                }}
                label={`+${hiddenCount}`}
              />
            )}
          </Box>
        ) : (
          ''
        )}
      </Box>
      <Box
        display={'none'}
        width={'100%'}
        sx={{
          '@media (max-width: 768px)': {
            display: 'flex',
            flexWrap: 'wrap',
          },
        }}
      >
        {item?.companyInfo?.owner &&
          item?.companyInfo?.companyType !== 'supplier' &&
          item?.companyInfo?.companyType !== 'distributor' && (
            <Box
              display={'flex'}
              alignItems={'center'}
              width={'100%'}
              gap={'5%'}
              sx={{
                '@media (max-width: 768px)': {
                  marginTop: '10px',
                },
              }}
            >
              {item?.companyInfo?.responce_time?.length && (
                <Box display={'flex'} alignItems={'center'} gap={'8px'}>
                  <img src={ResponseTime} alt="Checkmark Filled" />
                  <Typography
                    fontSize={'14px'}
                    fontWeight={'600'}
                    fontFamily={'Poppins'}
                    color={'#000000'}
                    textTransform={'capitalize'}
                    sx={{
                      // '@media (max-width: 768px)': {
                      //   fontWeight: '400',
                      // },
                      '@media (max-width: 600px)': {
                        fontSize: '12px',
                      },
                    }}
                  >
                    {/* #{item?._id.slice(-5)} */}
                    {item?.companyInfo?.responce_time} Response
                    {/* 48hr Response */}
                  </Typography>
                </Box>
              )}

              {item?.companyInfo?.available_for_work === 'Yes' && (
                <Box display={'flex'} alignItems={'center'} gap={'8px'}>
                  <img src={Availability} alt="Checkmark Filled" />
                  <Typography
                    fontSize={'14px'}
                    fontWeight={'600'}
                    fontFamily={'Poppins'}
                    color={'#000000'}
                    textTransform={'capitalize'}
                    sx={{
                      // '@media (max-width: 768px)': {
                      //   fontWeight: '400',
                      // },
                      '@media (max-width: 600px)': {
                        fontSize: '12px',
                      },
                    }}
                  >
                    Available For Work
                  </Typography>
                </Box>
              )}
              {/* <Box display={'flex'} alignItems={'center'} gap={'8px'}>
                <img src={LicenseIdIcon} alt="Checkmark Filled" />
                <Typography
                  fontSize={'14px'}
                  fontWeight={'500'}
                  fontFamily={'Poppins'}
                  color={'#000000'}
                  textTransform={'uppercase'}
                  sx={{
                    '@media (max-width: 768px)': {
                      fontWeight: '400',
                    },
                    '@media (max-width: 600px)': {
                      fontSize: '12px',
                    },
                  }}
                >
                  #{item?._id.slice(-5)}
                </Typography>
              </Box> */}
              {/* <Box display={'flex'} alignItems={'center'} gap={'8px'}>
                <img src={!item?.complaint ? CheckmarkFilled : Complaint} alt="Checkmark Filled" />
                <Typography
                  fontSize={'14px'}
                  fontWeight={'500'}
                  fontFamily={'Poppins'}
                  color={'#000000'}
                  sx={{
                    '@media (max-width: 768px)': {
                      fontWeight: '400',
                    },
                    '@media (max-width: 600px)': {
                      fontSize: '12px',
                    },
                  }}
                >
                  {!item?.complaint ? 'No Complaints' : 'Complaints'}
                </Typography>
              </Box> */}
            </Box>
          )}
        <Box
          display={description?.length ? 'flex' : 'none'}
          width={'100%'}
          gap={'8px'}
          margin={
            item?.companyInfo?.companyType !== 'supplier' ||
            item?.companyInfo?.companyType !== 'distributor'
              ? '10px 0'
              : '0'
          }
          sx={{
            '@media (max-width: 768px)': {
              alignItems: 'center',
            },
          }}
        >
          <Typography
            fontSize={'16px'}
            fontWeight={'300'}
            fontFamily={'SF UI Display'}
            color={'#000'}
            sx={{
              '@media (max-width: 768px)': {
                fontSize: '14px',
              },

              maxWidth: '100%',
              cursor: 'pointer',
            }}
          >
            {showFullDescription ? description : slicedDescription}
            {description.length > 120 && (
              <span
                onClick={(e) => {
                  e.stopPropagation();
                  toggleDescription();
                }}
                style={{
                  color: '#00BD57',
                  fontFamily: 'SF UI Display',
                  //   fontSize: '16px',
                  fontWeight: '700',
                }}
              >
                {showFullDescription ? ' Show less' : ' Learn more'}
              </span>
            )}
          </Typography>
        </Box>
        {(item?.companyInfo?.companyType === 'supplier' ||
          item?.companyInfo?.companyType === 'distributor') &&
        item?.companyInfo?.owner ? (
          <Box
            ref={containerRef1}
            width={'85%'}
            display={
              // item?.companyInfo?.companyType === 'supplier' ||
              // item?.companyInfo?.companyType === 'distributor'
              //   ? 'flex'
              //   :
              'none'
            }
            alignItems="center"
            gap={1}
          >
            {visibleItems1?.map((item, index) => (
              <Chip key={index} label={item} />
            ))}
            {hiddenCount1 > 0 && <Chip label={`+${hiddenCount1}`} />}
          </Box>
        ) : (
          ''
        )}
        {(item?.companyInfo?.companyType === 'supplier' ||
          item?.companyInfo?.companyType === 'distributor') &&
        item?.companyInfo?.owner ? (
          <Box
            width={'100%'}
            display={
              item?.companyInfo?.companyType === 'supplier' ||
              item?.companyInfo?.companyType === 'distributor'
                ? 'flex'
                : 'none'
            }
            alignItems="center"
            gap={1}
            sx={{
              overflowX: 'scroll',
              scrollbarWidth: 'none', // For Firefox
              '&::-webkit-scrollbar': {
                display: 'none', // For Chrome, Safari, and Edge
              },
            }}
          >
            {categoryArray?.map((item: any, index: number) => (
              <Chip key={index} label={item} />
            ))}
          </Box>
        ) : (
          ''
        )}
      </Box>
    </Box>
  );
};

export default ListingCard;
