import { Box } from '@mui/material';
import React, { FC, useEffect, useState } from 'react';
import HeaderSignCompany from '../../components/phase1a/local/Header.component';
import CreateCompany from '../../components/phase1a/addBusiness/CreateCompany.component';
import { toast } from 'react-toastify';
import CustomToast from '../../components/phase1a/common/CustomToast.component';
import UserForm from '../../components/phase1a/addBusiness/UserForm.component';
import ConfirmAccount from '../../components/phase1a/addBusiness/ConfirmAccount.component';
import CompanyType from '../../components/phase1a/addBusiness/CompanyType.component';
import CompanyServices from '../../components/phase1a/addBusiness/CompanyServices.component';
import CompanyDetails from '../../components/phase1a/addBusiness/CompanyDetails.component';
import ServiceArea from '../../components/phase1a/addBusiness/ServiceArea.component';
import Verification from '../../components/phase1a/addBusiness/Verification.component';
import { useLocation, useNavigate } from 'react-router-dom';
import CompanyCategory from '../../components/phase1a/addBusiness/CompanyCategory.component';
import request from '../../api/request';
import { UpdateStore } from '../../context/store';

const initailData = {
  company: {
    companyInfo: {
      name: '',
      address: {
        street: '',
        city: '',
        state: '',
        zip: '',
        address: '',
        country: '',
      },
      phone: '',
      formattedNo: '',
      email: '',
      website: '',
      domain: '',
      description: '',
      location: {
        type: 'Point',
        coordinates: [],
      },
      logo: '',
      companyType: 'local',
      category: [],
      formation_year: '',
      licence: '',
      company_size: '',
      union_shop: '',
      ul_list: '',
      available_for_work: '',
      responce_time: '',
    },
    services: [
      {
        service: '',
        percentage: null,
        subService: [''],
        logo: '',
      },
    ],
    serviceArea: [
      {
        location: {
          type: 'Polygon',
          coordinates: [[]],
        },
        distance: null, // in miles
      },
    ],
    serviceAreaDescription: '',
    profileCompleted: true,
  },
  user: {
    first_name: '',
    last_name: '',
    email: '',
    job_title: '',
    password: '',
    company: '',
  },
};
const AddBusiness: FC = () => {
  const nav = useNavigate();
  const updateStore = UpdateStore();
  const userJSON = localStorage.getItem('user');
  const localData = userJSON ? JSON.parse(userJSON) : '';
  const location = useLocation();
  const { companyData, screenNumber } = location.state || {};
  const [companyId, setCompanyId] = useState<any>(companyData ?? '');
  const [otp, setOtp] = React.useState('');
  const [loading, setLoading] = React.useState(false);
  const [otpLoader, setOtpLoader] = React.useState(false);

  console.log('companyId', companyId);
  const [userData, setUserData] = useState<any>({
    first_name: '',
    last_name: '',
    email: '',
    job_title: '',
    password: '',
  });
  const [account, setAccount] = useState<any>(initailData);
  console.log('account Data', account);
  const [screenId, setScreenId] = useState<number>(screenNumber ?? 1);
  const handleOtp = (): void => {
    setOtpLoader(true);
    request('post', '/auth/generateOTP?length=4', {
      id: account?.user?.email,
      userWithoutCompany: true,
    })
      .then(() => {
        setOtpLoader(false);
        /* eslint-disable @typescript-eslint/restrict-template-expressions */
        toast(
          <CustomToast message={`OTP has been resent to ${account?.user?.email}`} type="success" />,
        );
      })
      .catch((err) => {
        setOtpLoader(false);
        const errmessage = err?.response?.data?.message
          ? err?.response?.data?.message
          : 'User Not found';
        toast(<CustomToast message={errmessage} type="error" />);
        console.log('res national 24');
      });
  };
  const handleLogin = (): void => {
    // if (screenId === 3) return;
    const data = { id: account?.user?.email, otp };
    setLoading(true);
    request('post', '/auth/login', data)
      .then((res: any) => {
        setLoading(false);
        localStorage.setItem('token', res.token);
        localStorage.setItem('user', JSON.stringify(res.user));
        localStorage.setItem('pending', 'true');
        if (res?.user) {
          updateStore?.({ user: res?.user });
        }
        console.log('res?.user?.type', res?.user);
        setAccount({
          ...account,
          company: {
            ...account?.company,
            companyInfo: {
              ...account?.company.companyInfo,
              companyType: 'local',
            },
          },
        });
        setScreenId(5);

        // if (
        //   (res?.user?.company?.companyInfo?.companyType === 'supplier' ||
        //     res?.user?.company?.companyInfo?.companyType === 'distributor') &&
        //   res?.user?.type === 'member'
        // ) {
        //   nav('/localpageowner/SettingPagesetup');
        // } else {
        //   nav('/localpageowner/ReviewsDashoard');
        // }
        // toast(<CustomToast message={'You have logged in successfully.'} type="success" />);
      })
      .catch(() => {
        setLoading(false);
        toast(<CustomToast message={'Invalid OTP'} type="error" />);
      });
  };

  const isYearValid = (year: string): boolean => {
    const yearNumber = Number(year);
    const currentYear = new Date().getFullYear();
    if (year.length < 4) {
      return false;
    }
    if (yearNumber > currentYear) {
      return false;
    } else {
      return true;
    }
  };

  const handleNextClick = (): void => {
    if (screenId === 1) {
      if (!account?.company?.companyInfo?.name?.length) {
        toast(<CustomToast message={'Enter your company name'} type="error" />);
      } else {
        if (companyId?.length) {
          nav('/claim-company', { state: { companyData: companyId } });
          setScreenId(2);
        } else {
          nav('/add-business');
          setScreenId(2);
        }
      }
    }
    if (screenId === 2) {
      if (!account?.user?.first_name?.length) {
        toast(<CustomToast message={'Enter First Name'} type="error" />);
      } else if (!account?.user?.last_name?.length) {
        toast(<CustomToast message={'Enter Name'} type="error" />);
      } else {
        setLoading(true);
        const user = {
          first_name: account?.user?.first_name,
          last_name: account?.user?.last_name,
          email: account?.user?.email,
          job_title: account?.user?.job_title,
          password: account?.user?.password,
          ...(companyData?.length
            ? { company: companyData }
            : { businessName: account?.company?.companyInfo?.name }),
        };
        request('post', '/users/addUserForNewBusiness', { user })
          .then((response: any) => {
            console.log('res national 21', response);
            if (response) {
              console.log('res national 22', response);

              request('post', '/auth/generateOTP?length=4', {
                id: response?.user?.email,
                userWithoutCompany: true,
              })
                .then(() => {
                  setLoading(false);
                  console.log('res national 23 Form submitted with email:', response?.user?.email);
                  // /* eslint-disable @typescript-eslint/restrict-template-expressions */
                  // toast(
                  //   <CustomToast
                  //     message={`OTP has been sent to ${response?.user?.email}`}
                  //     type="success"
                  //   />,
                  // );
                  setScreenId(3);
                })
                .catch((err) => {
                  const errmessage = err?.response?.data?.message
                    ? err?.response?.data?.message
                    : 'User Not found';
                  toast(<CustomToast message={errmessage} type="error" />);
                  console.log('res national 24');
                  setLoading(false);
                });
            }
          })
          .catch((error: any) => {
            console.log(error);
            console.log('res national 25');
            toast(<CustomToast message={error.response.data.message} type={'error'} />);
            setLoading(false);
          });
        // setScreenId(3);
      }
    }
    if (screenId === 3) {
      // setScreenId(4);
      handleLogin();
    }
    if (screenId === 4) {
      if (!account?.company?.companyInfo?.companyType?.length) {
        toast(<CustomToast message={'Choose company type'} type="error" />);
      } else if (account?.company?.companyInfo?.companyType === 'local') {
        setScreenId(5);
      } else if (
        account?.company?.companyInfo?.companyType === 'supplier' ||
        account?.company?.companyInfo?.companyType === 'distributor'
      ) {
        setScreenId(6);
      } else {
        setScreenId(7);
      }
    }
    if (screenId === 5) {
      if (!account?.company?.services[0]?.service?.length) {
        toast(<CustomToast message={'Choose at least one service'} type="error" />);
      } else {
        saveTempData();
        setScreenId(7);
      }
    }
    if (screenId === 6) {
      if (!account?.company?.companyInfo?.category?.length) {
        toast(<CustomToast message={'Choose at least 1 category'} type="error" />);
      } else {
        setScreenId(7);
      }
    }
    if (screenId === 7) {
      if (!account?.company?.companyInfo?.location?.coordinates?.length) {
        toast(<CustomToast message={'Select Address'} type="error" />);
      } else if (!isYearValid?.(account?.company?.companyInfo?.formation_year)) {
        toast(<CustomToast message={'Please Enter Valid Year'} type="error" />);
      } else if (account?.company?.companyInfo?.companyType === 'local') {
        setScreenId(8);
        saveTempData();
      } else {
        setScreenId(9);
      }
    }
    if (screenId === 8) {
      setScreenId(9);
    }
    if (screenId === 9) {
      // nav('/');
      if (account?.user?.company?.length) {
        setLoading(true);
        if (
          (localData?.company?.companyInfo?.companyType === 'supplier' ||
            localData?.company?.companyInfo?.companyType === 'distributor') &&
          localData?.type === 'member'
        ) {
          nav('/localpageowner/SettingPagesetup');
        } else {
          nav('/localpageowner/job-gallery');
        }
        // request('post', '/users/addYourBusiness', {
        //   user: {
        //     first_name: account?.user?.first_name,
        //     last_name: account?.user?.last_name,
        //     email: account?.user?.email,
        //     job_title: account?.user?.job_title,
        //     password: account?.user?.password,
        //     company: account?.user?.company,
        //   },
        // })
        //   .then((response: any) => {
        //     console.log('res national 21', response);
        //     if (response) {
        //       console.log('res national 22', response);

        //       request('post', '/auth/generateOTP?length=4', { id: response?.user?.email })
        //         .then(() => {
        //           setLoading(false);
        //           console.log('res national 23 Form submitted with email:', response?.user?.email);
        //           /* eslint-disable @typescript-eslint/restrict-template-expressions */
        //           toast(
        //             <CustomToast
        //               message={`OTP generated and sent successfully to ${response?.user?.email}`}
        //               type="success"
        //             />,
        //           );

        //           setScreenId(3);
        //         })
        //         .catch((err) => {
        //           const errmessage = err?.response?.data?.message
        //             ? err?.response?.data?.message
        //             : 'User Not found';
        //           toast(<CustomToast message={errmessage} type="error" />);
        //           console.log('res national 24');
        //           setLoading(false);
        //         });
        //     }
        //   })
        //   .catch((error: any) => {
        //     console.log(error);
        //     console.log('res national 25');
        //     setLoading(false);
        //   });
      } else if (account?.company?.companyInfo?.companyType === 'national') {
        setLoading(true);

        request('post', '/users/addYourBusiness', {
          company: {
            companyInfo: {
              name: localData?.businessName ?? account?.company?.companyInfo?.name,
              address: account?.company?.companyInfo?.address,
              phone: account?.company?.companyInfo?.phone,
              email: account?.company?.companyInfo?.email,
              website: account?.company?.companyInfo?.website,
              domain: account?.company?.companyInfo?.domain,
              description: account?.company?.companyInfo?.description,
              location: account?.company?.companyInfo?.location,
              logo: account?.company?.companyInfo?.logo,
              companyType: account?.company?.companyInfo?.companyType,
            },
            serviceAreaDescription: '',
            profileCompleted: true,
          },
          user: localData?._id,
        })
          .then((response: any) => {
            console.log('res national 21', response);
            localStorage.setItem('token', response.token);
            localStorage.setItem('user', JSON.stringify(response.user));
            if (response?.user) {
              updateStore?.({ user: response?.user });
            }
            if (
              (response?.user?.company?.companyInfo?.companyType === 'supplier' ||
                response?.user?.company?.companyInfo?.companyType === 'distributor') &&
              response?.user?.type === 'member'
            ) {
              nav('/localpageowner/SettingPagesetup');
            } else {
              nav('/localpageowner/job-gallery');
            }
            setLoading(false);
          })
          .catch((error: any) => {
            console.log(error);
            setLoading(false);

            console.log('res national 25');
          });
        console.log('res national 26');
      } else if (account?.company?.companyInfo?.companyType === 'local') {
        setLoading(true);

        request('post', '/users/addYourBusiness', {
          company: {
            companyInfo: {
              name: localData?.businessName ?? account?.company?.companyInfo?.name,
              address: account?.company?.companyInfo?.address,
              phone: account?.company?.companyInfo?.phone,
              email: account?.company?.companyInfo?.email,
              website: account?.company?.companyInfo?.website,
              domain: account?.company?.companyInfo?.domain,
              description: account?.company?.companyInfo?.description,
              location: account?.company?.companyInfo?.location,
              logo: account?.company?.companyInfo?.logo,
              companyType: account?.company?.companyInfo?.companyType,
              formation_year: account?.company?.companyInfo?.formation_year,
              licence: account?.company?.companyInfo?.licence,
              company_size: account?.company?.companyInfo?.company_size,
              union_shop: account?.company?.companyInfo?.union_shop,
              ul_list: account?.company?.companyInfo?.ul_list,
              available_for_work: account?.company?.companyInfo?.available_for_work,
              responce_time: account?.company?.companyInfo?.responce_time,
            },
            services: account?.company?.services,
            serviceArea: account?.company?.serviceArea,
            serviceAreaDescription: '',
            profileCompleted: true,
          },
          user: localData?._id,
        })
          .then((response: any) => {
            console.log('account Data respoonse', response);
            localStorage.setItem('token', response.token);
            localStorage.setItem('user', JSON.stringify(response.user));
            if (response?.user) {
              updateStore?.({ user: response?.user });
            }
            if (
              (response?.user?.company?.companyInfo?.companyType === 'supplier' ||
                response?.user?.company?.companyInfo?.companyType === 'distributor') &&
              response?.user?.type === 'member'
            ) {
              nav('/localpageowner/SettingPagesetup');
            } else {
              nav('/localpageowner/job-gallery');
            }
            setLoading(false);
          })
          .catch((error: any) => {
            setLoading(false);

            console.log(error);
          });
      } else {
        setLoading(true);
        request('post', 'users/addYourBusiness', {
          company: {
            companyInfo: {
              name: localData?.businessName ?? account?.company?.companyInfo?.name,
              address: account?.company?.companyInfo?.address,
              phone: account?.company?.companyInfo?.phone,
              email: account?.company?.companyInfo?.email,
              website: account?.company?.companyInfo?.website,
              domain: account?.company?.companyInfo?.domain,
              description: account?.company?.companyInfo?.description,
              location: account?.company?.companyInfo?.location,
              logo: account?.company?.companyInfo?.logo,
              companyType: account?.company?.companyInfo?.companyType,
              category: account?.company?.companyInfo?.category,
            },
            serviceAreaDescription: '',
            profileCompleted: true,
          },
          user: localData?._id,
        })
          .then((response: any) => {
            console.log('account Data respoonse', response);
            localStorage.setItem('token', response.token);
            localStorage.setItem('user', JSON.stringify(response.user));
            if (response?.user) {
              updateStore?.({ user: response?.user });
            }
            if (
              (response?.user?.company?.companyInfo?.companyType === 'supplier' ||
                response?.user?.company?.companyInfo?.companyType === 'distributor') &&
              response?.user?.type === 'member'
            ) {
              nav('/localpageowner/SettingPagesetup');
            } else {
              nav('/localpageowner/job-gallery');
            }
            setLoading(false);
          })
          .catch((error: any) => {
            setLoading(false);
            console.log(error);
          });
      }
    }
  };
  const handleCancelClick = (): void => {
    if (screenId === 9) {
      setScreenId(8);
    }
    if (screenId === 8) {
      setScreenId(7);
    }
    if (screenId === 7) {
      if (account?.company?.companyInfo?.companyType === 'local') {
        setScreenId(5);
      } else if (
        account?.company?.companyInfo?.companyType === 'supplier' ||
        account?.company?.companyInfo?.companyType === 'distributor'
      ) {
        setScreenId(6);
      } else {
        setScreenId(4);
      }
    }
    if (screenId === 6) {
      setScreenId(4);
    }
    if (screenId === 5) {
      setScreenId(4);
    }
    if (screenId === 4 && !screenNumber) {
      setScreenId(3);
    }
    if (screenId === 3) {
      setScreenId(2);
      // nav('/');
    }
    if (screenId === 2) {
      setScreenId(1);
    }
  };
  const handleCompanyDetails = (): void => {
    if (companyId?.length || companyData?.length) {
      /* eslint-disable @typescript-eslint/restrict-template-expressions */
      const url = `/companies/${companyId ?? companyData?.length}`;
      request('get', url)
        .then((response: any) => {
          console.log('respoonse', response);
          setAccount({
            ...account,
            company: {
              ...account?.company,
              services: response.company.services ?? [],
              companyInfo: {
                ...account?.company?.companyInfo,
                formation_year: response?.company?.companyInfo?.formation_year ?? '',
                licence: response?.company?.companyInfo?.licence ?? '',
                companyType: response?.company?.companyInfo?.companyType ?? '',
                company_size: response?.company?.companyInfo?.company_size ?? '',
                address: {
                  street: response.company.companyInfo.address.street ?? '',
                  city: response.company.companyInfo.address.city ?? '',
                  state: response.company.companyInfo.address.state ?? '',
                  zip: response.company.companyInfo.address.zip ?? '',
                  address: response.company.companyInfo.address.address ?? '',
                  country: response.company.companyInfo.address.country ?? '',
                },
                category: response.company.companyInfo.category ?? [],
                description: response.company.companyInfo.description ?? '',
                phone: response.company.companyInfo.phone ?? '',
                formattedNo: response.company.companyInfo.phone ?? '',
                logo: response.company.companyInfo.logo,
                name: response?.company?.companyInfo?.name,
                email: response.company.companyInfo.email ?? '',
                domain: response.company.companyInfo.domain ?? '',
                website: response.company.companyInfo.website ?? '',
                union_shop: response.company.companyInfo.union_shop ?? '',
                ul_list: response.company.companyInfo.ul_list ?? '',
                available_for_work: response.company.companyInfo.available_for_work ?? '',
                responce_time: response.company.companyInfo.responce_time ?? '',
                location: {
                  type: 'Point',
                  coordinates: [
                    response.company.companyInfo.location.coordinates[0],
                    response.company.companyInfo.location.coordinates[1],
                  ],
                },
              },
              serviceArea: response.company.serviceArea,
            },
            user: {
              ...account?.user,
              company: companyId,
            },
          });
        })
        .catch((error: any) => {
          console.log(error);
        });
    } else {
      setAccount({
        ...initailData,
        company: {
          ...initailData?.company,
          companyInfo: {
            ...initailData?.company?.companyInfo,
            name: account?.company?.companyInfo?.name,
          },
        },
      });
    }
  };

  const saveTempData = (): void => {
    request('post', `/companies/cashing`, {
      data: account?.company,
    })
      .then((res) => {
        console.log(res);
      })
      .catch((err) => {
        console.log(err);
      });
  };

  const getTempData = (): void => {
    request('get', `/companies/cashing`)
      .then((res: any) => {
        console.log(res.company.data, 'res');
        setAccount((prev: any) => ({ ...prev, company: res.company.data }));
      })
      .catch((err) => {
        console.log(err);
      });
  };

  useEffect(() => {
    handleCompanyDetails();
  }, [companyId, companyData?.length]);

  useEffect(() => {
    if (companyData?.length) {
      setCompanyId(companyData);
    }
  }, [companyData?.length]);

  useEffect(() => {
    if (screenNumber && localData) {
      setScreenId(screenNumber);
      if (typeof localData?.email === 'string' && localData?.email.includes('@')) {
        const email = localData?.email as string; // Explicitly cast to string
        const emailDomain = email.substring(email.lastIndexOf('@') + 1);
        setAccount({
          ...account,
          company: {
            ...account?.company,
            companyInfo: {
              ...account?.company?.companyInfo,
              domain: emailDomain,
              name: localData?.businessName,
            },
          },
        });
      }
    }
  }, [screenNumber]);

  useEffect(() => {
    // Scroll to the top of the page when the component mounts
    window.scrollTo({
      top: 0,
      behavior: 'smooth', // Enables smooth scrolling
    });
    if (screenId === 9) {
      saveTempData();
    }
  }, [screenId]); // Empty dependency array ensures this runs only on mount

  useEffect(() => {
    if (localData?._id) {
      getTempData();
    }
  }, [localData?._id]);

  return (
    <Box
      width="100%"
      bgcolor={'#FFF'}
      // minHeight={'100vh'}
      // sx={{
      //   overflowY: 'scroll',
      // }}
    >
      <HeaderSignCompany screenId={screenId} />
      <Box
        // marginTop={'80px'}
        width={'100%'}
        // padding={'13% 8% 5% 8%'}
        height="calc(100vh - 80px)"
        // marginTop={'80px'}
        marginBottom={'auto'}
        padding={'0% 8% 0% 8%'}
        display={'flex'}
        alignItems={'center'}
        justifyContent={'center'}
        bgcolor={'#F7F7F7'}
        sx={{
          '@media (max-width: 1200px)': {
            padding: '0 8% 0 8%',
            // overflowY: 'scroll',
          },
          '@media (max-width: 520px)': {
            padding: '5%',
            minHeight: 'calc(100vh - 80px)',
            height: 'unset',
            // overflowY: 'scroll',
          },
        }}
      >
        {screenId === 1 && (
          <CreateCompany
            account={account}
            setAccount={setAccount}
            onNextClick={handleNextClick}
            companyId={companyId}
            setCompanyId={setCompanyId}
          />
        )}
        {screenId === 2 && (
          <UserForm
            account={account}
            loading={loading}
            setAccount={setAccount}
            userData={userData}
            companyId={companyId}
            setUserData={setUserData}
            onNextClick={handleNextClick}
            handleCancelClick={handleCancelClick}
          />
        )}
        {screenId === 3 && (
          <ConfirmAccount
            otp={otp}
            otpLoader={otpLoader}
            loading={loading}
            setOtp={setOtp}
            account={account}
            setAccount={setAccount}
            onNextClick={handleNextClick}
            handleOtp={handleOtp}
            handleCancelClick={handleCancelClick}
          />
        )}
        {screenId === 4 && (
          <CompanyType
            screenNumber={screenNumber}
            account={account}
            companyId={companyId}
            setAccount={setAccount}
            onNextClick={handleNextClick}
            handleCancelClick={handleCancelClick}
          />
        )}
        {screenId === 5 && (
          <CompanyServices
            account={account}
            companyId={companyId}
            setAccount={setAccount}
            onNextClick={handleNextClick}
            handleCancelClick={handleCancelClick}
          />
        )}
        {screenId === 6 && (
          <CompanyCategory
            account={account}
            companyId={companyId}
            setAccount={setAccount}
            onNextClick={handleNextClick}
            handleCancelClick={handleCancelClick}
          />
        )}
        {screenId === 7 && (
          <CompanyDetails
            // handleCompanyDetails={handleCompanyDetails}
            account={account}
            companyId={companyId}
            setAccount={setAccount}
            onNextClick={handleNextClick}
            handleCancelClick={handleCancelClick}
          />
        )}
        {screenId === 8 && (
          <ServiceArea
            account={account}
            companyId={companyId}
            setAccount={setAccount}
            onNextClick={handleNextClick}
            handleCancelClick={handleCancelClick}
          />
        )}
        {screenId === 9 && (
          <Verification
            loading={loading}
            account={account}
            companyId={companyId}
            setAccount={setAccount}
            onNextClick={handleNextClick}
            handleCancelClick={handleCancelClick}
          />
        )}
      </Box>
    </Box>
  );
};

export default AddBusiness;
