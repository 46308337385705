import React, { FC, useEffect, useState } from 'react';
import {
  Box,
  Button,
  Divider,
  // Rating,
  Typography,
  // Avatar,
  useMediaQuery,
  // Tooltip,
  // IconButton,
} from '@mui/material';
import reliabilityIcon from '../../../assets/dashboard/reliabilityIcon.svg';
import CommunicationIcon from '../../../assets/dashboard/communicationIcon.svg';
import QualityIcon from '../../../assets/dashboard/qualityIcon.svg';
// import LeadIcon from '../../../assets/dashboard/leadIcon.svg';
import QualityIcon2 from '../../../assets/compensation.svg';
import RecommendIcon from '../../../assets/Recommend.svg';
import { ReactComponent as RequestQuoteIcon } from '../../../assets/message.svg';
// import { ReactComponent as GradeOutlinedIcon } from '../../../assets/starimg.svg';
// import { ReactComponent as FeedbackOutlinedIcon } from '../../../assets/file.svg';
import { ReactComponent as IosShareIcon } from '../../../assets/shareimg.svg';
import { ReactComponent as WEbIcon } from '../../../assets/website.svg';
import VerificationPopup from '../../../Popups/phase1a/local/ReviewPopup';
import HeaderSignCompany from '../../../components/phase1a/local/Header.component';
import GradeIcon from '@mui/icons-material/Grade';
// import DoneIcon from '../../../assets/checkmark.svg';
import Success from '../../../assets/Phase1a/DesignUpdate/toastSuccess.svg';
import SuccessMobile from '../../../assets/Phase1a/DesignUpdate/mobileSign.svg';
import CalenderIcon from '../../../assets/beta/calender_icon.svg';
import usaStatesAndCanadaProvinces from '../../../utils/states';

// import { styled } from '@mui/material/styles';
// import Tabs from '@mui/material/Tabs';
// import Tab from '@mui/material/Tab';
import LinearProgress from '@mui/material/LinearProgress'; // , { linearProgressClasses }
// import moment from 'moment';
import ReviewModal from '../../../Popups/phase1a/local/ReviewModal.popups';
import ReviewModalNational from '../../../Popups/phase1a/national/ReviewModal.popups';
// import Claimedicon from '../../../assets/claimed.svg';
// import UnClaimedicon from '../../../assets/unclaimIcon.svg';
import ContactPopup from '../../../Popups/phase1a/local/Contact.popups';
import { useLocation, useNavigate, useParams } from 'react-router-dom';
import request from '../../../api/request';
import defaultCompany from '../../../assets/defaultCompany.png';
import { mediaUrl } from '../../../config';
import { toast } from 'react-toastify';
// import SignInPopup from '../../../Popups/phase1a/local/SignIn.popups';
import SubmitReview from '../../../Popups/phase1a/DesignUpdateModal/SubmitReviewPopup';

// import ArrowForwardIosIcon from '@mui/icons-material/ArrowForwardIos';
// import ArrowBackIosIcon from '@mui/icons-material/ArrowBackIos';
// import CompanyLogoRating from '../../../assets/CompanyRatingLogo.svg';
import ServiceAreaIcon from '../../../assets/beta/servicearea.svg';
import Availability from '../../../assets/beta/availability.svg';
// import MapWithMultipleMarkers from '../../../components/phase1a/local/MapMultipleMarkers';

// import Avatarimg from '../../../assets/Avatarimg.svg';
// import ReviewCard from '../../../components/phase1a/common/ReviewCard.component';
// import StarIcon from '@mui/icons-material/Star';
// import NewCardIcon from '../../../assets/Phase1a/DesignUpdate/Graphix.png';
// import StarBorderIcon from '@mui/icons-material/StarBorder';
import ClaimedListing from '../../../Popups/phase1a/DesignUpdateModal/ClaimedListing.popup';
import SignInPopup from '../../../Popups/phase1a/local/SignIn.popups';
import CustomToast from '../../../components/phase1a/common/CustomToast.component';
import NationalIconUpdate from '../../../assets/Phase1a/DesignUpdate/NationalIconUpdate.svg';
import LocalIconUpdate from '../../../assets/Phase1a/DesignUpdate/LocalIconUpdate.svg';
import LineDetail from '../../../assets/Line_Detail.svg';
import dayjs from 'dayjs';
import EditServiceAreaMap from '../../../layout/phase1a/settingComponents/EditServiceAreaMap';
// import PenddingApproval from '../../../Popups/phase1a/DesignUpdateModal/PenddingApproval.popup';

// interface TabPanelProps {
//   children?: React.ReactNode;
//   index: number;
//   value: number;
// }

// function CustomTabPanel(props: TabPanelProps): JSX.Element {
//   const { children, value, index, ...other } = props;

//   return (
//     <div
//       role="tabpanel"
//       hidden={value !== index}
//       id={`simple-tabpanel-${index}`}
//       aria-labelledby={`simple-tab-${index}`}
//       {...other}
//     >
//       {value === index && (
//         <Box width={'100%'} padding={'2% 0'}>
//           <Typography>{children}</Typography>
//         </Box>
//       )}
//     </div>
//   );
// }

// const BorderLinearProgress = styled(LinearProgress)(({ theme }) => ({
//   height: 10,
//   borderRadius: 2,
//   [`&.${linearProgressClasses.colorPrimary}`]: {
//     border: '1px solid #E0E1E2',
//     backgroundColor: '#E0E1E2',
//   },
//   [`& .${linearProgressClasses.bar}`]: {
//     borderRadius: 2,
//     backgroundColor: '#9E9E9E ',
//   },
// }));
// const getColor = (rating: any): any => {
//   if (!rating) return '#9E9E9E';
//   if (rating >= 4) return '#00BD57';
//   if (rating >= 3) return '#FFB800';
//   return '#FD3E14';
// };

const LocalSignCompany: FC = () => {
  const navigate = useNavigate();
  const location = useLocation();
  const { ctype, name, id } = useParams();
  const { item, type, search, additional, near, coords, searchTypo, searchID, myCurrLoc, hash } =
    location.state || {};
  const userJSON = localStorage.getItem('user');
  const reviewStatus = localStorage.getItem('reviewStatus');
  const token = localStorage.getItem('token');
  const userData = userJSON ? JSON.parse(userJSON) : '';
  const companyId = item?._id;
  const [companyData, setCompanyData] = useState<any>(null);
  // const [isModalOpen, setIsModalOpen] = useState(false);
  const [alternativeCompany, setalternativeCompany] = useState<any>(null);
  const [myLocation, setMyLocation] = useState<any>(myCurrLoc);
  const [coordinates, setCoordinates] = useState<string | undefined>(coords ?? '');
  console.log('alternativeCompanyalternativeCompany', alternativeCompany);
  console.log('setalternativeCompanyaas type', type);
  const [companyReviews, setCompanyReviews] = useState<any>(null);
  const [userDetail, setUserDetail] = useState<any>(null);
  // const grayStar = <StarIcon style={{ color: '#B8B8B8' }} fontSize="inherit" />;
  const approvedReviews = companyReviews?.receivedReview?.filter(
    (review: { review_status: string }) => review?.review_status === 'approved',
  );
  // const recommendations = approvedReviews
  //   ?.filter((review: { review_type: string }) => review.review_type === 'recommendation')
  //   .sort(
  //     (a: { createdAt: string | number | Date }, b: { createdAt: string | number | Date }) =>
  //       new Date(b.createdAt).getTime() - new Date(a.createdAt).getTime(),
  //   );

  // const complaints = approvedReviews
  //   ?.filter((review: { review_type: string }) => review?.review_type === 'complaint')
  //   .sort(
  //     (a: { createdAt: string | number | Date }, b: { createdAt: string | number | Date }) =>
  //       new Date(b.createdAt).getTime() - new Date(a.createdAt).getTime(),
  //   );
  console.log('companyReviews?.companyData', companyData);
  console.log('companyReviews?.companyData Alt', alternativeCompany);
  // const [value, setValue] = React.useState(0);
  const [openReviewModal, setOpenReviewModal] = useState(false);
  const [openSignInModal, setOpenSignInModal] = useState(false);
  const [openReviewLogin, setOpenReviewLogin] = useState(false);
  // const [showFullFeedback, setShowFullFeedback] = useState(false);
  const [openContactPopup, setOpenContactPopup] = useState(false);
  const [verificationPopup, setVerificationPopup] = useState(false); // State for verification popup
  const [claimStatus, setClaimStatus] = useState(false);
  const [reviewSuccess, setReviewSuccess] = useState(false);

  // console.log('setValuesetValue', setValue);
  console.log('verificationPopup', claimStatus);
  const [isClaimed, setIsClaimed] = useState<boolean>(companyData?.companyInfo?.owner);
  const convertRatingToTenScale = (ratingOutOfFive: number): string => {
    const ratingOutOfTen = ratingOutOfFive * 2;
    const flooredRating = Math.floor(ratingOutOfTen * 10) / 10;
    const formattedRating = Number.isInteger(flooredRating)
      ? flooredRating.toString()
      : flooredRating.toFixed(1);
    return `${formattedRating}/10`;
  };
  console.log('setIsClaimed', setIsClaimed);

  const calculateYears = (year: number): string => {
    const date = new Date().getFullYear();
    switch (date - year) {
      case 0:
        return year.toString();
      case 1:
        return 'A Year Ago';

      default:
        return `${date - year} Years Ago`;
    }
  };

  // const reviewsSectionRef = useRef<HTMLDivElement>(null);

  const closeReviewSuccess = (): void => {
    setReviewSuccess(false);
    localStorage.removeItem('reviewStatus');
  };
  // const scrollToReviews = (): void => {
  //   if (reviewsSectionRef.current) {
  //     (reviewsSectionRef.current as HTMLElement).scrollIntoView({ behavior: 'smooth' });
  //   }
  // };
  const abrev = (region: string): string | undefined => {
    const abr = usaStatesAndCanadaProvinces.find(
      (item) => item.name.toLowerCase() === region?.toLowerCase(),
    );
    return abr?.abbreviation;
  };

  const data = [
    {
      iconSrc: reliabilityIcon,
      Icon: (
        <GradeIcon
          fontSize="large"
          sx={{
            color: !companyData?.averageReviewRating?.reliability
              ? '#9E9E9E'
              : companyData?.averageReviewRating?.reliability >= 4
              ? '#00BD57'
              : companyData?.averageReviewRating?.reliability >= 3
              ? '#FFB800'
              : '#FD3E14',
          }}
        />
      ),
      color: !companyData?.averageReviewRating?.reliability
        ? '#9E9E9E'
        : companyData?.averageReviewRating?.reliability >= 4
        ? '#00BD57'
        : companyData?.averageReviewRating?.reliability >= 3
        ? '#FFB800'
        : '#FD3E14',

      title:
        companyData?.companyInfo?.companyType === 'local'
          ? 'Reliability'
          : companyData?.companyInfo?.companyType === 'national'
          ? 'Professionalism'
          : 'Quality',
      rating: companyData?.averageReviewRating
        ? companyData?.averageReviewRating?.reliability
        : '--',
      skeletonHeight: '21px',
    },
    {
      iconSrc: CommunicationIcon,
      Icon: (
        <GradeIcon
          fontSize="large"
          sx={{
            color: !companyData?.averageReviewRating?.communication
              ? '#9E9E9E'
              : companyData?.averageReviewRating?.communication >= 4
              ? '#00BD57'
              : companyData?.averageReviewRating?.communication >= 3
              ? '#FFB800'
              : '#FD3E14',
          }}
        />
      ),
      color: !companyData?.averageReviewRating?.communication
        ? '#9E9E9E'
        : companyData?.averageReviewRating?.communication >= 4
        ? '#00BD57'
        : companyData?.averageReviewRating?.communication >= 3
        ? '#FFB800'
        : '#FD3E14',
      title:
        companyData?.companyInfo?.companyType === 'local'
          ? 'Communication'
          : companyData?.companyInfo?.companyType === 'national'
          ? 'Communication'
          : 'Customer Service',
      rating: companyData?.averageReviewRating
        ? companyData?.averageReviewRating?.communication
        : '--',
      skeletonHeight: '21px',
    },
    {
      // iconSrc: companyData?.companyInfo?.companyType === 'local' ? QualityIcon : QualityIcon2,
      // national icon change
      iconSrc:
        companyData?.companyInfo?.companyType === 'local'
          ? QualityIcon
          : companyData?.companyInfo?.companyType === 'national'
          ? NationalIconUpdate
          : QualityIcon2,
      Icon: (
        <GradeIcon
          fontSize="large"
          sx={{
            color: !companyData?.averageReviewRating?.work_quality
              ? '#9E9E9E'
              : companyData?.averageReviewRating?.work_quality >= 4
              ? '#00BD57'
              : companyData?.averageReviewRating?.work_quality >= 3
              ? '#FFB800'
              : '#FD3E14',
          }}
        />
      ),
      color: !companyData?.averageReviewRating?.work_quality
        ? '#9E9E9E'
        : companyData?.averageReviewRating?.work_quality >= 4
        ? '#00BD57'
        : companyData?.averageReviewRating?.work_quality >= 3
        ? '#FFB800'
        : '#FD3E14',
      title:
        companyData?.companyInfo?.companyType === 'local'
          ? 'Work Quality'
          : companyData?.companyInfo?.companyType === 'national'
          ? 'Compensation'
          : 'Cost',
      rating: companyData?.averageReviewRating
        ? companyData?.averageReviewRating?.work_quality
        : '--',
      skeletonHeight: '21px',
    },
    {
      // change local icon
      iconSrc:
        companyData?.companyInfo?.companyType === 'local'
          ? LocalIconUpdate
          : companyData?.companyInfo?.companyType === 'national'
          ? QualityIcon2
          : RecommendIcon,
      Icon: (
        <GradeIcon
          fontSize="large"
          sx={{
            color: !companyData?.averageReviewRating?.lead_time
              ? '#9E9E9E'
              : companyData?.averageReviewRating?.lead_time >= 4
              ? '#00BD57'
              : companyData?.averageReviewRating?.lead_time >= 3
              ? '#FFB800'
              : '#FD3E14',
          }}
        />
      ),
      color: !companyData?.averageReviewRating?.lead_time
        ? '#9E9E9E'
        : companyData?.averageReviewRating?.lead_time >= 4
        ? '#00BD57'
        : companyData?.averageReviewRating?.lead_time >= 3
        ? '#FFB800'
        : '#FD3E14',
      title:
        companyData?.companyInfo?.companyType === 'local'
          ? 'On-Time Projects'
          : companyData?.companyInfo?.companyType === 'national'
          ? 'On-Time Payments'
          : 'Recommendations',
      rating: companyData?.averageReviewRating
        ? companyData?.companyInfo?.companyType === 'supplier' ||
          companyData?.companyInfo?.companyType === 'distributor'
          ? convertRatingToTenScale(companyData?.averageReviewRating?.lead_time)
          : companyData?.averageReviewRating?.lead_time === 5
          ? 'Always'
          : companyData?.averageReviewRating?.lead_time >= 4
          ? 'Often'
          : companyData?.averageReviewRating?.lead_time >= 3
          ? 'Sometimes'
          : companyData?.averageReviewRating?.lead_time >= 2
          ? 'Rarely'
          : companyData?.averageReviewRating?.lead_time > 0
          ? 'Never'
          : ''
        : companyData?.companyInfo?.companyType === 'supplier' ||
          companyData?.companyInfo?.companyType === 'distributor'
        ? '--'
        : '---',
      skeletonHeight: '21px',
    },
  ];
  console.log('datadatadata', data);
  // const handleToggleFeedback = (): void => {
  //   setShowFullFeedback((prev) => !prev);
  // };
  const handleCloseReviewModal = (): void => {
    setOpenReviewModal(false);
  };
  const handleCloseSignInModal = (): void => {
    setOpenSignInModal(false);
  };
  const handleCloseReviewLogin = (): void => {
    setOpenReviewLogin(false);
  };

  // const handleChange = (event: React.SyntheticEvent, newValue: number): void => {
  //   setValue(newValue);
  // };
  const getCompanyReviews = (): void => {
    if (companyData?._id) {
      /* eslint-disable @typescript-eslint/restrict-template-expressions */
      request('get', `/review/companyReview/${companyData?._id}`)
        .then((response: any) => {
          setCompanyReviews(response);
        })
        .catch((error: any) => {
          console.log(error);
        });
    }
  };
  const getUserById = (): void => {
    request('get', `/auth/userDetails`)
      .then((res: any) => {
        setUserDetail(res?.user);
      })
      .catch((err) => {
        console.log('err', err);
      });
  };
  useEffect(() => {
    if (userData?._id) {
      getUserById();
    }
  }, [userData?._id]);
  useEffect(() => {
    if (myCurrLoc) {
      setMyLocation(myCurrLoc);
    }
  }, [myCurrLoc]);

  const handleButtonClick = (action: string | undefined): void => {
    if (action === 'contact') {
      setOpenContactPopup(true);

      // if (!userData) {
      //   setOpenSignInModal(true);
      // } else if (!userData?.company) {
      //   navigate('/add-business', { state: { screenNumber: 5 } });
      //   // toast(<CustomToast message={'First complete your Sign-Up process.'} type="error" />);
      // } else if (claimStatus || !userData?.company?.companyInfo?.verification) {
      //   setVerificationPopup(true);
      // } else {
      //   setOpenContactPopup(true);
      // }
    }
    if (action === 'review') {
      if (!userData) {
        setOpenReviewLogin(true);
      } else if (!userData?.company) {
        navigate('/add-business', { state: { screenNumber: 4 } });
        // toast(<CustomToast message={'First complete your Sign-Up process.'} type="error" />);
      } else if (claimStatus || !userData?.company?.companyInfo?.verification) {
        setVerificationPopup(true);
      } else {
        navigate('/submit-review', { state: { companyData: companyData } });
      }
    }

    if (action === 'complaint' && userData?._id) {
      setOpenReviewModal(true);
    }
    if (action === 'complaint' && !userData?._id) {
      setOpenSignInModal(true);
    }
    if (action === 'share') {
      const currentUrl = window.location.href;
      navigator.clipboard
        .writeText(`${currentUrl}/${companyId ?? searchID}`)
        .then(() => {
          // toast.success('URL Copied To Clipboard');
          toast(<CustomToast message={'URL Copied To Clipboard'} type="success" />);
        })
        .catch((err) => {
          console.error('Failed to copy: ', err);
        });
    }
    if (action === 'website') {
      const website = companyData?.companyInfo?.website;
      if (website) {
        window.open(`https://${website}`, '_blank');
      }
    }
  };

  // const handleClick = (): void => {
  //   // eslint-disable-next-line @typescript-eslint/no-unused-expressions
  //   setIsClaimed;
  // };

  function handleCloseContactPopup(): void {
    setOpenContactPopup(false);
  }
  // const handleCloseModal = (): void => {
  //   setIsModalOpen(false);
  // };
  const handleCompanyDetails = (): void => {
    if (companyId?.length || searchID?.length) {
      const url = `/companies/${companyId ?? searchID}`;
      console.log('url', url);
      request('get', url)
        .then((response: any) => {
          console.log('respoonse', response);
          setCompanyData(response?.company);
          setalternativeCompany(response?.alternative_companies);
        })
        .catch((error: any) => {
          console.log(error);
        });
    }
  };
  const ClaimedData = [
    { text: 'Contact Now', iconSrc: <RequestQuoteIcon />, action: 'contact' },
    // { text: 'Review Us', iconSrc: <GradeOutlinedIcon />, action: 'review' },
    // { text: 'File A Complaint', iconSrc: <FeedbackOutlinedIcon />, action: 'complaint' },
    { text: 'Website', iconSrc: <WEbIcon />, action: 'website' },
    { text: 'Share', iconSrc: <IosShareIcon style={{ width: '17px' }} />, action: 'share' },
  ];
  const SupplierOwnerButtons = [
    { text: 'Contact Now', iconSrc: <RequestQuoteIcon />, action: 'contact' },
    { text: 'Website', iconSrc: <WEbIcon />, action: 'website' },
    { text: 'Share', iconSrc: <IosShareIcon style={{ width: '17px' }} />, action: 'share' },
  ];
  // const SupplierNoOwnerButtons = [
  //   { text: 'Share', iconSrc: <IosShareIcon style={{ width: '17px' }} />, action: 'share' },
  // ];

  const unclaimedbuttonData = [
    // { text: 'Review Us', iconSrc: <GradeOutlinedIcon />, action: 'review' },
    // { text: 'File A Complaint', iconSrc: <FeedbackOutlinedIcon />, action: 'complaint' },
    { text: 'Share', iconSrc: <IosShareIcon style={{ width: '17px' }} />, action: 'share' },
  ];
  const isOwner = companyData?.companyInfo?.owner;
  const companyType = companyData?.companyInfo?.companyType;
  const userCompanyType = userData?.company?.companyInfo?.companyType;

  let ButtonDisplay;

  const isSupplierOrDistributor =
    userCompanyType === 'supplier' || userCompanyType === 'distributor';
  const isSameCompanyType = companyType === userCompanyType;

  if (!isSameCompanyType && isSupplierOrDistributor) {
    ButtonDisplay = SupplierOwnerButtons;
  } else if (!isSameCompanyType) {
    ButtonDisplay = isOwner ? ClaimedData : unclaimedbuttonData;
  } else {
    ButtonDisplay = SupplierOwnerButtons;
  }

  useEffect(() => {
    handleCompanyDetails();
  }, [companyId?.length, searchID?.length]);
  useEffect(() => {
    getCompanyReviews();
  }, [companyData?._id]);

  const isMobile = useMediaQuery('(max-width:1000px)');
  // const formatSearchString: any = (str: string): string => {
  //   if (!str) return ''; // Handle cases where str might be null or undefined
  //   // Trim, lowercase, and replace spaces with hyphens
  //   const formattedStr = str.toLowerCase().replace(/\s+/g, '-').trim();
  //   return formattedStr;
  // };
  // const containerRef = useRef<HTMLDivElement>(null);
  // const [showLeftArrow, setShowLeftArrow] = useState(false);
  // const [showRightArrow, setShowRightArrow] = useState(true);

  // const handleScroll = (direction: string): void => {
  //   if (containerRef.current) {
  //     const itemWidth = 300;
  //     if (direction === 'right') {
  //       containerRef.current.scrollLeft += itemWidth;
  //     } else {
  //       containerRef.current.scrollLeft -= itemWidth;
  //     }
  //     updateArrows();
  //   }
  // };

  // const updateArrows = (): void => {
  //   if (containerRef.current) {
  //     const { scrollLeft, scrollWidth, clientWidth } = containerRef.current;
  //     setShowLeftArrow(scrollLeft > 0);
  //     setShowRightArrow(scrollLeft < scrollWidth - clientWidth - 1); // Adjusted calculation
  //   }
  // };

  useEffect(() => {
    if (userDetail?.company?.companyInfo?.verification?.status) {
      localStorage.setItem('user', JSON.stringify(userDetail));
    }
  }, [userDetail]);
  useEffect(() => {
    if (reviewStatus) {
      setReviewSuccess(true);
    }
  }, [reviewStatus]);
  useEffect(() => {
    const pathPattern = /^\/[^/]+\/[^/]+\/[^/]+$/;
    const pathPattern1 = /^\/[^/]+\/[^/]+\/[^/]+\/review$/;
    // const pathPattern2 = /^\/[^/]+\/[^/]+\/[^/]+#review-[a-f0-9]{24}$/;

    if (pathPattern.test(location.pathname)) {
      const { hash } = location;
      if (hash) {
        navigate(`/${ctype}/${name}`, {
          state: {
            searchID: id,
            hash: hash,
            type:
              ctype === 'local-sign-company'
                ? 'local'
                : ctype === 'national-sign-company'
                ? 'national'
                : ctype === 'supplier'
                ? 'supplier'
                : 'distributor',
          },
        });
      } else {
        /* eslint-disable @typescript-eslint/restrict-template-expressions */
        navigate(`/${ctype}/${name}`, {
          state: {
            searchID: id,
            type:
              ctype === 'local-sign-company'
                ? 'local'
                : ctype === 'national-sign-company'
                ? 'national'
                : ctype === 'supplier'
                ? 'supplier'
                : 'distributor',
          },
        });
      }
    }
    if (pathPattern1.test(location.pathname)) {
      if (!userData) {
        navigate(`/${ctype}/${name}`, { state: { searchID: id } });
        setOpenReviewLogin(true);
      } else {
        const paramType =
          userData?.company?.companyInfo?.companyType === 'local'
            ? 'local-sign-company'
            : userData?.company?.companyInfo?.companyType === 'national'
            ? 'national-sign-company'
            : userData?.company?.companyInfo?.companyType === 'supplier'
            ? 'supplier'
            : 'distributor';
        if (ctype !== paramType) {
          request('get', `/companies/${id}`)
            .then((res: any) => {
              navigate('/submit-review', { state: { companyData: res?.company } });
            })
            .catch((err) => {
              console.log(err);
            });
        } else {
          navigate(`/${ctype}/${name}`, {
            state: {
              searchID: id,
              type:
                ctype === 'local-sign-company'
                  ? 'local'
                  : ctype === 'national-sign-company'
                  ? 'national'
                  : ctype === 'supplier'
                  ? 'supplier'
                  : 'distributor',
            },
          });
        }
      }
    }
    // if (pathPattern2.test(location.pathname + location.hash)) {
    //   /* eslint-disable @typescript-eslint/restrict-template-expressions */
    //   const { hash } = location;
    //   if (hash) {
    //     const element = document.getElementById(hash.replace('#', ''));
    //     if (element) {
    //       element.scrollIntoView({ behavior: 'smooth' });
    //     }
    //   }
    // }
  }, [location.pathname, navigate, ctype, name, id]);
  useEffect(() => {
    if (hash && approvedReviews) {
      const element = document.getElementById(hash.replace('#', ''));
      if (element) {
        // Scroll the element into view smoothly
        element.scrollIntoView({ behavior: 'smooth' });

        // Add a timeout to adjust the scroll position after scrolling
        setTimeout(() => {
          const yOffset = -100; // Offset of 150px
          const y = element.getBoundingClientRect().top + window.pageYOffset + yOffset;

          // Scroll to the position with the offset
          window.scrollTo({ top: y, behavior: 'smooth' });
        }, 300); // Delay to ensure scrollIntoView finishes
      }
    }
  }, [hash, approvedReviews]);
  useEffect(() => {
    if (token?.length) {
      request('get', `/companies/claim/checkRequest`)
        .then((res: any) => {
          if (res?.claim?.status === 'pending') {
            // setIsModalOpen(true);
            setClaimStatus(true);
          }
        })
        .catch((err) => {
          console.log('err', err);
        });
    }
  }, [token?.length]);
  function getStateAbbreviation(additional: string): string {
    if (!additional) return '';

    // Split the 'additional' string by comma and trim any whitespace
    const parts = additional.split(',').map((part: string) => part.trim());

    // Extract the city name (first part) and the state name (last part)
    const cityName = parts[0];
    const stateName = parts[parts?.length - 1];

    // Find the state or province in the array and get its abbreviation
    const state = usaStatesAndCanadaProvinces.find(
      (item: any) => item?.name?.toLowerCase() === stateName?.toLowerCase(),
    );

    // Return formatted string as "City, State Abbreviation"
    return state ? `${cityName}, ${state?.abbreviation}` : additional;
  }
  console.log('hash', hash);

  const [isExpanded, setIsExpanded] = useState<Record<number, boolean>>({});
  const maxLength = 150;

  const handleToggle = (index: number): void => {
    setIsExpanded((prevExpanded) => ({
      ...prevExpanded,
      [index]: !prevExpanded[index], // Toggle the state for the specific index
    }));
  };

  useEffect(() => {
    const cor = `[${companyData?.companyInfo?.location?.coordinates[0]}, ${companyData?.companyInfo?.location?.coordinates[1]}]`;
    setCoordinates(cor);
  }, [companyData]);

  return (
    <>
      <HeaderSignCompany
        type={type}
        listingGrid
        search={search}
        additional={additional}
        near={near}
        coordinates={coordinates}
        searchTypo={searchTypo}
        myLocation={myLocation}
        setMyLocation={setMyLocation}
        setCoordinates={setCoordinates}
        companyData={companyData}
      />
      {companyData?.companyInfo?.companyType === 'local' ? (
        <ReviewModal
          companyData={companyData}
          open={openReviewModal}
          onClose={handleCloseReviewModal}
        />
      ) : (
        <ReviewModalNational
          companyData={companyData}
          open={openReviewModal}
          onClose={handleCloseReviewModal}
        />
      )}
      <SignInPopup open={openSignInModal} onClose={handleCloseSignInModal} />
      <ClaimedListing
        open={openReviewLogin}
        handleClose={handleCloseReviewLogin}
        setOpenSignInModal={setOpenSignInModal}
        setOpenReviewLogin={setOpenReviewLogin}
      />
      <ContactPopup
        open={openContactPopup}
        onClose={handleCloseContactPopup}
        companyData={companyData}
      />
      <VerificationPopup
        claimStatus={claimStatus}
        open={verificationPopup}
        onClose={() => {
          setVerificationPopup(false);
        }}
      />
      <SubmitReview open={reviewSuccess} handleClose={closeReviewSuccess} />
      {/* <PenddingApproval open={isModalOpen} handleClose={handleCloseModal} user={userData} /> */}

      {companyData ? (
        <Box
          width="100%"
          padding={'3% 10%'}
          display="flex"
          justifyContent="space-between"
          marginTop={'80px'}
          position={'relative'}
          sx={{
            '@media (max-width: 1440px)': {
              padding: '3% 5%',
            },
            '@media (max-width: 1200px)': {
              padding: '3%',
            },
            '@media (max-width: 1000px)': {
              flexDirection: 'column-reverse',
            },
          }}
        >
          <Box
            width="67%"
            sx={{
              '@media (max-width: 1000px)': {
                width: '100%',
                display: 'flex',
                flexDirection: 'column',
                gap: '25px',
              },
              '@media (max-width: 768px)': {
                gap: '15px',
              },
            }}
          >
            {!isMobile ? (
              <>
                <Box width="100%" display="flex" gap={'30px'} alignItems={'start'}>
                  <Box
                    width="126px"
                    height={'126px'}
                    sx={{
                      '@media (max-width: 500px)': {
                        width: '25%',
                      },
                    }}
                  >
                    <img
                      src={
                        /* eslint-disable @typescript-eslint/restrict-template-expressions */ companyData
                          ?.companyInfo?.logo
                          ? `${mediaUrl}/${companyData?.companyInfo?.logo}`
                          : defaultCompany
                      }
                      alt="Logo"
                      style={{
                        width: '100%',
                        height: '100%',
                        objectFit: 'cover',
                        borderRadius: '10px',
                      }}
                    />
                  </Box>
                  <Box width="75%" display={'flex'} gap={'15px'} flexDirection={'column'}>
                    <Box width="100%" display={'flex'} flexDirection={'column'}>
                      <Typography
                        fontSize="40px"
                        fontFamily="SF UI Display"
                        fontWeight="800"
                        lineHeight="48px"
                        color="#000000"
                      >
                        {companyData?.companyInfo?.name}
                      </Typography>
                      {/* #Beta */}
                      {/* <Box
                        width={'100%'}
                        display={'flex'}
                        alignItems={'center'}
                        justifyContent={'left'}
                        gap={'5px'}
                      >
                        <Rating
                          name="hover-feedback"
                          value={
                            typeof companyData?.averageReviewRating?.overall === 'number'
                              ? companyData.averageReviewRating.overall
                              : parseFloat(companyData?.averageReviewRating?.overall) || 0
                          }
                          precision={0.1}
                          sx={{
                            color: !companyData?.averageReviewRating
                              ? '#9E9E9E'
                              : // : companyData?.averageReviewRating?.overall >= 4.6
                              // ? '#3040CE'
                              companyData?.averageReviewRating?.overall >= 4
                              ? '#00BD57'
                              : companyData?.averageReviewRating?.overall >= 3
                              ? '#FFB800'
                              : '#FD3E14',
                            fontSize: '31px',
                          }}
                          // size="small"
                          readOnly
                          // emptyIcon={<CustomStarBorderIcon fontSize="inherit" />}
                          emptyIcon={grayStar} // Custom gray star for empty rating
                          icon={
                            !companyData?.averageReviewRating ? (
                              grayStar
                            ) : (
                              <StarIcon
                                fontSize="inherit"
                                sx={{
                                  color: !companyData?.averageReviewRating
                                    ? '#9E9E9E'
                                    : companyData?.averageReviewRating?.overall >= 4
                                    ? '#00BD57'
                                    : companyData?.averageReviewRating?.overall >= 3
                                    ? '#FFB800'
                                    : '#FD3E14',
                                }}
                              />
                            )
                          } // Gray filled stars if rating is 0
                        />

                        <Typography
                          fontSize={'32px'}
                          fontWeight={700}
                          fontFamily={'SF UI Display'}
                          color={'#000'}
                          sx={{
                            '@media (max-width: 768px)': {
                              fontSize: '18px',
                              fontWeight: '700',
                            },
                            '@media (max-width: 500px)': {
                              fontSize: '15px',
                              fontWeight: '600',
                            },
                          }}
                        >
                      

                          {companyData?.averageReviewRating?.overall > 0
                            ? (
                                Math.floor(companyData?.averageReviewRating?.overall * 10) / 10
                              ).toFixed(1)
                            : ''}
                        </Typography>
                        <Typography
                          fontSize={'20px'}
                          fontWeight={'400'}
                          fontFamily={'SF UI Display'}
                          color={'#777'}
                          sx={{
                            '@media (max-width: 768px)': {
                              fontSize: '18px',
                              fontWeight: '700',
                            },
                            '@media (max-width: 500px)': {
                              fontSize: '15px',
                              fontWeight: '600',
                            },
                          }}
                        >
                          ({approvedReviews?.length > 0 ? approvedReviews?.length : '0'}{' '}
                          {approvedReviews?.length <= 1 ? 'Review' : 'Reviews'})
                        </Typography>
                      </Box> */}
                      <Typography
                        fontSize="32px"
                        fontFamily="SF UI Display"
                        fontWeight="300"
                        lineHeight="32px"
                        color="#000000"
                        textTransform={'capitalize'}
                        // onClick={(): void => {
                        //   handleClick();
                        // }}
                        sx={{
                          '@media (max-width: 768px)': {
                            fontSize: '26px',
                          },
                          '@media (max-width: 500px)': {
                            fontSize: '22px',
                          },
                        }}
                      >
                        {companyData?.companyInfo?.companyType === 'local' ||
                        companyData?.companyInfo?.companyType === 'national'
                          ? `${companyData?.companyInfo?.companyType} Sign Company`
                          : `${companyData?.companyInfo?.companyType} Company`}
                      </Typography>
                      <Box
                        display={'flex'}
                        alignItems={'center'}
                        width={'100%'}
                        gap={'21px'}
                        margin={'3% 0 0% 0'}
                        sx={{
                          '@media (max-width: 768px)': {
                            display: 'none',
                          },
                          '@media (max-width: 500px)': {
                            margin: '4% 0',
                          },
                        }}
                      >
                        {companyData?.serviceArea[0]?.distance && (
                          <Box display={'flex'} alignItems={'center'} gap={'8px'}>
                            <img
                              style={{
                                width: '19px',
                                height: '24px',
                              }}
                              src={ServiceAreaIcon}
                              alt="Checkmark Filled"
                            />
                            <Typography
                              fontSize={'14px'}
                              fontWeight={'600'}
                              fontFamily={'Poppins'}
                              color={'#000000'}
                              textTransform={'capitalize'}
                              sx={{
                                '@media (max-width: 768px)': {
                                  fontWeight: '400',
                                },
                                '@media (max-width: 600px)': {
                                  fontSize: '12px',
                                },
                              }}
                            >
                              Service Area: {companyData?.serviceArea[0]?.distance} Mi
                            </Typography>
                          </Box>
                        )}
                        {companyData?.companyInfo?.available_for_work === 'Yes' ? (
                          <img src={LineDetail} alt="line" />
                        ) : (
                          ''
                        )}

                        {companyData?.companyInfo?.available_for_work === 'Yes' && (
                          <Box display={'flex'} alignItems={'center'} gap={'8px'}>
                            <img
                              style={{
                                width: '19px',
                                height: '24px',
                              }}
                              src={Availability}
                              alt="Checkmark Filled"
                            />
                            <Typography
                              fontSize={'14px'}
                              fontWeight={'500'}
                              fontFamily={'Poppins'}
                              color={'#000000'}
                              textTransform={'capitalize'}
                              sx={{
                                '@media (max-width: 768px)': {
                                  fontWeight: '400',
                                },
                                '@media (max-width: 600px)': {
                                  fontSize: '12px',
                                },
                              }}
                            >
                              Available For Work
                            </Typography>
                          </Box>
                        )}
                      </Box>
                    </Box>

                    {/* <Box display="flex" gap="10px" alignItems={'center'}>
                      <Tooltip
                        arrow
                        leaveDelay={300}
                        title={
                          <Box padding={'8px'} margin={'0'} bgcolor={'#000'}>
                            <Typography
                              color={'#D0D5DD'}
                              fontFamily={'SF UI Display'}
                              fontSize={'14px'}
                              fontWeight={'500'}
                              lineHeight={'18px'}
                            >
                              This page is currently unclaimed by Visible Graphics. Do you work
                              here?{' '}
                            </Typography>
                            <Typography
                              color={'#00BD57'}
                              fontFamily={'SF UI Display'}
                              fontSize={'14px'}
                              fontWeight={'500'}
                              lineHeight={'18px'}
                              onClick={() => {
                                if (companyData?.companyInfo?.owner) return;
                                navigate('/claim-company', {
                                  state: { companyData: companyData?._id },
                                });
                              }}
                              sx={{ textDecoration: 'underline', cursor: 'pointer' }}
                            >
                              Claim this page now.
                            </Typography>
                          </Box>
                        }
                        PopperProps={{
                          modifiers: [
                            {
                              name: 'offset',
                              options: {
                                offset: [0, 15], // Adjusts tooltip's Y position (20px below the image)
                              },
                            },
                          ],
                        }}
                        componentsProps={{
                          tooltip: {
                            sx: {
                              backgroundColor: '#000', // Tooltip background
                              color: 'white', // Text color inside the tooltip
                              padding: '12px', // Remove default padding
                              borderRadius: '8px',
                              display: companyData?.companyInfo?.owner ? 'none' : 'block',
                            },
                          },
                          arrow: {
                            sx: {
                              color: '#000', // Match the arrow color with the tooltip background
                              fontSize: '20px',
                            },
                          },
                        }}
                      >
                        <Box display="flex" gap="10px" alignItems={'center'}>
                          <img
                            src={companyData?.companyInfo?.owner ? Claimedicon : UnClaimedicon}
                            alt="Claimedicon"
                            style={{
                              cursor: companyData?.companyInfo?.owner ? 'default' : 'pointer',
                            }}
                          />

                          <Typography
                            fontSize="16px"
                            fontFamily="SF UI Display"
                            fontWeight="600"
                            lineHeight="19.09px"
                            letterSpacing={'0.0938em'}
                            color={companyData?.companyInfo?.owner ? '#000000' : '#696969'}
                            sx={{
                              cursor: companyData?.companyInfo?.owner ? 'default' : 'pointer',
                            }}
                            onClick={() => {
                              if (companyData?.companyInfo?.owner) return;
                              navigate('/claim-company', {
                                state: { companyData: companyData?._id },
                              });
                            }}
                          >
                            {companyData?.companyInfo?.owner ? 'Claimed Page' : 'Unclaimed Page'}
                          </Typography>
                        </Box>
                      </Tooltip>

                      <Divider
                        orientation="vertical"
                        variant="middle"
                        flexItem
                        sx={{ marginTop: '0', marginBottom: '0' }}
                      />
                      <Typography
                        fontSize="16px"
                        fontFamily="SF UI Display"
                        fontWeight="600"
                        lineHeight="19.09px"
                        color="#00BD57"
                        letterSpacing={'0.0938em'}
                        onClick={scrollToReviews}
                        textTransform={'capitalize'}
                        sx={{
                          cursor: 'pointer',
                        }}
                      >
                        {companyData?.companyInfo?.companyType === 'local' ||
                        companyData?.companyInfo?.companyType === 'national'
                          ? `${companyData?.companyInfo?.companyType} Sign Company`
                          : `${companyData?.companyInfo?.companyType} Company`}
                      </Typography>
                    </Box> */}
                  </Box>
                </Box>
                <Box
                  width="100%"
                  display="flex"
                  alignItems="center"
                  gap="15px"
                  margin={'3% 0'}
                  sx={{
                    justifyContent: isClaimed ? 'center' : 'start',

                    '@media (max-width: 500px)': {
                      display: 'grid',
                      gridTemplateColumns: '1fr 1fr',
                    },
                  }}
                >
                  {ButtonDisplay?.map(
                    (item, index) =>
                      ((item?.text === 'Website' && companyData?.companyInfo?.website.length) ||
                        item?.text !== 'Website') && (
                        <Button
                          key={index}
                          sx={{
                            border: '1px solid #E8E8E8',
                            color: 'black',
                            bgcolor: '#ffffff',
                            // #Beta
                            // color: item?.text === 'Contact Now' ? '#ffffff' : 'black',
                            // bgcolor: item?.text === 'Contact Now' ? '#00BD57' : '#ffffff',
                            fontFamily: 'SF UI Display',
                            fontWeight: '600',
                            fontSize: '16px',
                            display: 'flex',
                            alignItems: 'center',

                            gap: '10px',
                            textTransform: 'capitalize',
                            padding: '5px 10px',
                            '@media (max-width: 768px)': {
                              fontSize: '15px',
                            },
                            '@media (max-width: 500px)': {
                              fontSize: '13px',
                              fontWeight: '500',
                            },
                            // #Beta
                            // '&:hover': {
                            //   bgcolor: item?.text === 'Contact Now' ? '#00BD57' : '',
                            //   color: item?.text === 'Contact Now' ? '#ffffff' : '',
                            // },
                          }}
                          onClick={(): void => {
                            // if (userData?._id) {
                            // User is logged in and verified, show the contact popup
                            handleButtonClick(item?.action);
                            // }
                            // else {
                            //   setOpenSignInModal(true);
                            // }
                            // if (
                            //   userData?._id &&
                            //   !userData?.company?.companyInfo?.verification?.status
                            // ) {
                            //   // User is logged in but not verified, show verification popup
                            //   handleButtonClick(item?.action);
                            // }
                            // if (!userData?._id) {
                            //   // User is not logged in, show login popup
                            //   setOpenSignInModal(true);
                            // }
                          }}
                        >
                          {item.iconSrc}

                          {item.text}
                        </Button>
                      ),
                  )}
                </Box>
                {/* #Beta */}
                {/* <Box width="100%" mt={'6%'}>
                  <Typography
                    fontFamily="SF UI Display"
                    fontWeight="800"
                    fontSize="20px"
                    lineHeight="23px"
                    color="black"
                  >
                    Highlights
                  </Typography>
                  <Box
                    width="100%"
                    padding="5% 0"
                    display="flex"
                    alignItems="center"
                    gap={'10px'}
                    sx={{
                      '@media (max-width: 1440px)': {
                        gap: '20px',
                      },
                      '@media (max-width: 1200px)': {
                        gap: '30px',
                      },
                      '@media (max-width: 768px)': {
                        display: 'grid',
                        gridTemplateColumns: '1fr 1fr 1fr',
                      },
                      '@media (max-width: 550px)': {
                        gridTemplateColumns: '1fr 1fr',
                      },
                    }}
                  >
                    {data?.map((item, index) => (
                      <Box
                        key={index}
                        width="16%"
                        display="flex"
                        justifyContent="center"
                        alignItems="center"
                        flexDirection="column"
                        gap="8px"
                        sx={{
                          '@media (max-width: 1000px)': {
                            width: '22%',
                          },
                          '@media (max-width: 768px)': {
                            width: '100%',
                          },
                        }}
                      >
                        <img
                          src={item.iconSrc}
                          alt="icon"
                          style={{ width: '35px', height: '35px' }}
                        />
                        <Box
                          width="100%"
                          display="flex"
                          justifyContent={'center'}
                          alignItems="center"
                          gap="3px"
                        >
                          {item.title !== 'Recommendations' &&
                            item?.title !== 'On-Time Projects' &&
                            item?.Icon}
                          <Typography
                            fontFamily="SF UI Display"
                            fontWeight="700"
                            //  taiga ticket  #393 Highlights Spacing Needs Correction
                            fontSize="25px"
                            lineHeight="39px"
                            color={item?.color ? item?.color : '#00BD57'}
                          >
                            {item.title === 'Payment Terms' &&
                            companyData?.companyInfo?.companyType === 'national'
                              ? 'Net 30'
                              : item.title === 'Payment Terms' &&
                                companyData?.companyInfo?.companyType !== 'national'
                              ? 'Monthly'
                              : typeof item?.rating === 'number'
                              ? (Math.floor(item?.rating * 10) / 10).toFixed(1)
                              : item?.rating}
                          </Typography>
                        </Box>
                        <Typography
                          fontFamily="SF UI Display"
                          fontSize="16px"
                          textAlign="center"
                          whiteSpace="pre"
                          lineHeight="19px"
                          fontWeight="500"
                          color="#000000"
                        >
                          {item?.title}
                        </Typography>
                      </Box>
                    ))}
                  </Box>
                </Box> */}
                {companyData?.companyInfo?.description?.length ? (
                  <Box width="94%" mt="5%">
                    <Typography
                      fontFamily="SF UI Display"
                      fontWeight="800"
                      fontSize="20px"
                      lineHeight="23px"
                      color="#000000"
                    >
                      Overview{' '}
                    </Typography>
                    <Box
                      display="flex"
                      alignItems="center"
                      gap="30px"
                      margin={'3% 0'}
                      sx={{
                        whiteSpaceCollapse: 'preserve',
                      }}
                    >
                      <Typography
                        fontFamily="SF UI Display"
                        fontWeight="300"
                        fontSize="16px"
                        lineHeight="19.09px"
                        color="#000000"
                      >
                        {companyData?.companyInfo?.description ?? ''}
                      </Typography>
                    </Box>
                  </Box>
                ) : (
                  ''
                )}

                {companyData?.companyInfo?.companyType === 'local' && (
                  <Box width="100%" mt="1%">
                    <Typography
                      fontFamily="SF UI Display"
                      fontWeight="800"
                      fontSize="20px"
                      lineHeight="23px"
                      color="black"
                      mt={6}
                    >
                      Services
                    </Typography>
                    <Box
                      display="flex"
                      alignItems="center"
                      gap="30px"
                      margin={'4% 0'}
                      flexWrap={'wrap'}
                      sx={{
                        '@media (max-width:500px)': {
                          gap: '10px',
                        },
                      }}
                    >
                      {companyData?.services?.map((item: any, index: number) => (
                        <Box
                          key={index}
                          display="flex"
                          alignItems="center"
                          gap="8px"
                          sx={{
                            '@media (max-width:500px)': {
                              margin: '5px 0',
                            },
                          }}
                        >
                          <img src={Success} alt="tick" />
                          <Typography
                            fontFamily="SF UI Display"
                            fontWeight="700"
                            fontSize="16px"
                            lineHeight="19px"
                            color="#000000"
                          >
                            {item?.service}
                          </Typography>
                        </Box>
                      ))}
                    </Box>
                  </Box>
                )}
                {companyData?.workGallery?.length ? (
                  <Box width="100%" mt="1%">
                    <Typography
                      fontFamily="SF UI Display"
                      fontWeight="800"
                      fontSize="20px"
                      lineHeight="23px"
                      color="black"
                      margin={'50px 0'}
                    >
                      Job Experience
                    </Typography>
                    {companyData?.workGallery
                      ?.sort(
                        (a: any, b: any) =>
                          new Date(b.start).getTime() - new Date(a.start).getTime(),
                      )
                      .map((item: any, index: number) => (
                        <Box
                          key={index}
                          width={'100%'}
                          margin={'30px 0'}
                          paddingBottom={'30px'}
                          borderBottom={
                            index !== companyData.workGallery.length - 1
                              ? '1px solid #DCDEDF'
                              : 'none'
                          }
                        >
                          <Typography
                            fontFamily="SF UI Display"
                            fontWeight="700"
                            fontSize="18px"
                            lineHeight="21px"
                            color="rgba(0, 0, 0, 1)"
                          >
                            {item?.service} - {getStateAbbreviation(item?.job_location)}
                          </Typography>
                          <Box display={'flex'} alignItems={'center'} gap={'9px'} margin={'5px 0'}>
                            <img style={{ width: '25px', height: '25px' }} src={CalenderIcon} />

                            <Typography
                              fontFamily="SF UI Display"
                              fontWeight="400"
                              fontSize="14px"
                              lineHeight="16px"
                              color="rgba(0, 0, 0, 1)"
                            >
                              {dayjs(item.start).format('MMM Do, YYYY')} -{' '}
                              {dayjs(item.end).format('MMM Do, YYYY')}
                            </Typography>
                          </Box>
                          <Typography
                            fontFamily="SF UI Display"
                            fontWeight="300"
                            fontSize="16px"
                            lineHeight="19px"
                            color="rgba(0, 0, 0, 1)"
                            margin="25px 0"
                          >
                            {isExpanded[index]
                              ? item?.description
                              : // eslint-disable-next-line @typescript-eslint/restrict-plus-operands
                                item?.description?.slice(0, maxLength)}
                            {item?.description?.length > maxLength && (
                              <span
                                style={{
                                  fontFamily: 'SF UI Display',
                                  color: '#00BD57',
                                  textDecoration: 'underline',
                                  cursor: 'pointer',
                                  marginLeft: '5px',
                                }}
                                onClick={() => {
                                  handleToggle(index);
                                }}
                              >
                                {isExpanded[index] ? 'Show Less' : 'See More'}
                              </span>
                            )}
                          </Typography>
                          <Box width={'100%'} display={'flex'} gap={'10px'}>
                            {item?.photos.map((pic: any, index: number) => {
                              return (
                                <Box
                                  key={index}
                                  minWidth={item?.photos?.length > 2 ? '175px' : '300px'}
                                  height={item?.photos?.length > 2 ? '130px' : '225px'}
                                  borderRadius={'8px'}
                                  overflow={'hidden'}
                                >
                                  <img
                                    style={{ width: '100%', height: '100%', objectFit: 'cover' }}
                                    src={`${mediaUrl}/${pic}`}
                                  />
                                </Box>
                              );
                            })}
                          </Box>
                        </Box>
                      ))}
                  </Box>
                ) : (
                  ''
                )}
              </>
            ) : (
              <>
                <Box
                  width="100%"
                  display="flex"
                  gap={'30px'}
                  alignItems={'start'}
                  sx={{
                    '@media (max-width: 600px)': {
                      gap: '15px',
                      mt: 2,
                    },
                  }}
                >
                  <Box
                    width="126px"
                    height={'126px'}
                    sx={{
                      '@media (max-width: 500px)': {
                        width: '75px',
                        height: '75px',
                      },
                    }}
                  >
                    <img
                      src={
                        /* eslint-disable @typescript-eslint/restrict-template-expressions */ companyData
                          ?.companyInfo?.logo
                          ? `${mediaUrl}/${companyData?.companyInfo?.logo}`
                          : defaultCompany
                      }
                      alt="Logo"
                      style={{
                        width: '100%',
                        height: '100%',
                        objectFit: 'cover',
                        borderRadius: '10px',
                      }}
                    />
                  </Box>
                  <Box width="75%" display={'flex'} gap={'15px'} flexDirection={'column'}>
                    <Box width="100%" display={'flex'} flexDirection={'column'}>
                      <Typography
                        fontSize="30px"
                        fontFamily="SF UI Display"
                        fontWeight="800"
                        lineHeight="57px"
                        color="#000000"
                        sx={{
                          '@media (max-width: 650px)': {
                            fontSize: '25px',
                            lineHeight: '25px',
                          },
                          '@media (max-width: 500px)': {
                            fontSize: '20px',
                            // lineHeight: '27px',
                          },
                          '@media (max-width: 400px)': {
                            fontSize: '18px',
                            lineHeight: '20px',
                          },
                        }}
                      >
                        {companyData?.companyInfo?.name}
                      </Typography>
                      {/* #Beta */}

                      {/* <Box
                        width={'100%'}
                        display={'flex'}
                        alignItems={'center'}
                        justifyContent={'left'}
                        gap={'10px'}
                        sx={{
                          '@media (max-width: 500px)': {
                            width: '50%',
                            justifyContent: 'space-between',
                          },
                        }}
                      >
                        <Rating
                          name="hover-feedback"
                          value={
                            typeof companyData?.averageReviewRating?.overall === 'number'
                              ? companyData.averageReviewRating.overall
                              : parseFloat(companyData?.averageReviewRating?.overall) || 0
                          }
                          precision={0.1}
                          sx={{
                            color: !companyData?.averageReviewRating
                              ? '#9E9E9E'
                              : // : companyData?.averageReviewRating?.overall >= 4.6
                              // ? '#3040CE'
                              companyData?.averageReviewRating?.overall >= 4
                              ? '#00BD57'
                              : companyData?.averageReviewRating?.overall >= 3
                              ? '#FFB800'
                              : '#FD3E14',
                            fontSize: '24px',
                            '@media (max-width: 500px)': {
                              fontSize: '21px',
                            },
                          }}
                          // size="small"
                          readOnly
                          // emptyIcon={<CustomStarBorderIcon fontSize="inherit" />}
                          emptyIcon={grayStar} // Custom gray star for empty rating
                          icon={
                            !companyData?.averageReviewRating ? (
                              grayStar
                            ) : (
                              <StarIcon
                                fontSize="inherit"
                                sx={{
                                  color: !companyData?.averageReviewRating
                                    ? '#9E9E9E'
                                    : companyData?.averageReviewRating?.overall >= 4
                                    ? '#00BD57'
                                    : companyData?.averageReviewRating?.overall >= 3
                                    ? '#FFB800'
                                    : '#FD3E14',
                                }}
                              />
                            )
                          } // Gray filled stars if rating is 0
                        />
                        <Box
                          display={'flex'}
                          gap={'10px'}
                          sx={{
                            '@media (max-width: 500px)': {
                              width: '50%',
                              alignItems: 'center',
                              whiteSpace: 'pre',
                            },
                          }}
                        >
                          <Typography
                            fontSize={'25px'}
                            fontWeight={'600'}
                            fontFamily={'SF UI Display'}
                            color={'#000'}
                            sx={{
                              '@media (max-width: 768px)': {
                                fontSize: '18px',
                                fontWeight: '700',
                              },
                              '@media (max-width: 500px)': {
                                fontSize: '15px',
                                fontWeight: '600',
                              },
                            }}
                          >
                            {companyData?.averageReviewRating?.overall > 0
                              ? (
                                  Math.floor(companyData?.averageReviewRating?.overall * 10) / 10
                                ).toFixed(1)
                              : ''}
                          </Typography>
                          <Typography
                            fontSize={'20px'}
                            fontWeight={'400'}
                            fontFamily={'SF UI Display'}
                            color={'#777'}
                            sx={{
                              '@media (max-width: 768px)': {
                                fontSize: '18px',
                                fontWeight: '700',
                              },
                              '@media (max-width: 500px)': {
                                fontSize: '14px',
                                fontWeight: '600',
                              },
                            }}
                          >
                            ({approvedReviews?.length > 0 ? approvedReviews?.length : '0'}{' '}
                            {approvedReviews?.length <= 1 ? 'Review' : 'Reviews'})
                          </Typography>
                        </Box>
                      </Box> */}
                      <Typography
                        fontSize="32px"
                        fontFamily="SF UI Display"
                        fontWeight="300"
                        lineHeight="32px"
                        color="#000000"
                        textTransform={'capitalize'}
                        // onClick={(): void => {
                        //   handleClick();
                        // }}
                        sx={{
                          '@media (max-width: 768px)': {
                            fontSize: '26px',
                          },
                          '@media (max-width: 500px)': {
                            fontSize: '16px',
                          },
                        }}
                      >
                        {companyData?.companyInfo?.companyType === 'local' ||
                        companyData?.companyInfo?.companyType === 'national'
                          ? `${companyData?.companyInfo?.companyType} Sign Company`
                          : `${companyData?.companyInfo?.companyType} Company`}
                      </Typography>
                      <Typography
                        display={'none'}
                        fontSize="16px"
                        fontFamily="SF UI Display"
                        fontWeight="300"
                        lineHeight="32px"
                        color="#000000"
                        textTransform={'capitalize'}
                        sx={{
                          '@media (max-width: 768px)': {
                            display: 'block',
                          },
                        }}
                      >
                        {companyData?.companyInfo?.address?.street},{' '}
                        {companyData?.companyInfo?.address?.state}
                      </Typography>
                      <Box
                        display={'flex'}
                        alignItems={'center'}
                        width={'100%'}
                        gap={'5%'}
                        margin={'3% 0 2% 0'}
                        sx={{
                          '@media (max-width: 768px)': {
                            display: 'none',
                          },
                          '@media (max-width: 500px)': {
                            margin: '4% 0',
                          },
                        }}
                      >
                        {companyData?.serviceArea[0]?.distance && (
                          <Box display={'flex'} alignItems={'center'} gap={'8px'}>
                            <img src={ServiceAreaIcon} alt="Checkmark Filled" />
                            <Typography
                              fontSize={'14px'}
                              fontWeight={'500'}
                              fontFamily={'Poppins'}
                              color={'#000000'}
                              textTransform={'capitalize'}
                              sx={{
                                '@media (max-width: 768px)': {
                                  fontWeight: '400',
                                },
                                '@media (max-width: 600px)': {
                                  fontSize: '12px',
                                },
                              }}
                            >
                              Service Area: {companyData?.serviceArea[0]?.distance} Mi
                            </Typography>
                          </Box>
                        )}
                        {companyData?.companyInfo?.available_for_work === 'Yes' ? (
                          <img src={LineDetail} alt="line" />
                        ) : (
                          ''
                        )}
                        {companyData?.companyInfo?.available_for_work === 'Yes' && (
                          <Box display={'flex'} alignItems={'center'} gap={'8px'}>
                            <img src={Availability} alt="Checkmark Filled" />
                            <Typography
                              fontSize={'14px'}
                              fontWeight={'500'}
                              fontFamily={'Poppins'}
                              color={'#000000'}
                              textTransform={'capitalize'}
                              sx={{
                                '@media (max-width: 768px)': {
                                  fontWeight: '400',
                                },
                                '@media (max-width: 600px)': {
                                  fontSize: '12px',
                                },
                              }}
                            >
                              Available For Work
                            </Typography>
                          </Box>
                        )}
                      </Box>
                    </Box>
                    {/* <Box
                      display="flex"
                      gap="10px"
                      alignItems={'center'}
                      sx={{
                        '@media (max-width: 500px)': {
                          display: 'none',
                        },
                      }}
                    >
                      <Tooltip
                        arrow
                        leaveDelay={300}
                        title={
                          <Box padding={'8px'} margin={'0'} bgcolor={'#000'}>
                            <Typography
                              color={'#D0D5DD'}
                              fontFamily={'SF UI Display'}
                              fontSize={'14px'}
                              fontWeight={'500'}
                              lineHeight={'18px'}
                            >
                              This page is currently unclaimed by Visible Graphics. Do you work
                              here?{' '}
                            </Typography>
                            <Typography
                              color={'#00BD57'}
                              fontFamily={'SF UI Display'}
                              fontSize={'14px'}
                              fontWeight={'500'}
                              lineHeight={'18px'}
                              onClick={() => {
                                if (companyData?.companyInfo?.owner) return;
                                navigate('/claim-company', {
                                  state: { companyData: companyData?._id },
                                });
                              }}
                              sx={{ textDecoration: 'underline', cursor: 'pointer' }}
                            >
                              Claim this page now.
                            </Typography>
                          </Box>
                        }
                        PopperProps={{
                          modifiers: [
                            {
                              name: 'offset',
                              options: {
                                offset: [0, 15], // Adjusts tooltip's Y position (20px below the image)
                              },
                            },
                          ],
                        }}
                        componentsProps={{
                          tooltip: {
                            sx: {
                              backgroundColor: '#000', // Tooltip background
                              color: 'white', // Text color inside the tooltip
                              padding: '12px', // Remove default padding
                              borderRadius: '8px',
                              display: companyData?.companyInfo?.owner ? 'none' : 'block',
                            },
                          },
                          arrow: {
                            sx: {
                              color: '#000', // Match the arrow color with the tooltip background
                              fontSize: '20px',
                            },
                          },
                        }}
                      >
                        <Box display="flex" gap="10px" alignItems={'center'}>
                          <img
                            src={companyData?.companyInfo?.owner ? Claimedicon : UnClaimedicon}
                            alt="Claimedicon"
                            style={{
                              cursor: companyData?.companyInfo?.owner ? 'default' : 'pointer',
                            }}
                          />
                          <Typography
                            fontSize="16px"
                            fontFamily="SF UI Display"
                            fontWeight="600"
                            lineHeight="19.09px"
                            letterSpacing={'0.0938em'}
                            color={companyData?.companyInfo?.owner ? '#000000' : '#696969'}
                            sx={{
                              '@media (max-width: 500px)': {
                                fontSize: '14px',
                              },
                            }}
                          >
                            {companyData?.companyInfo?.owner ? 'Claimed Page' : 'Unclaimed Page'}
                          </Typography>
                        </Box>
                      </Tooltip>

                      <Divider
                        orientation="vertical"
                        variant="middle"
                        flexItem
                        sx={{ marginTop: '0', marginBottom: '0' }}
                      />
                      <Typography
                        fontSize="16px"
                        fontFamily="SF UI Display"
                        fontWeight="600"
                        lineHeight="19.09px"
                        color="#00BD57"
                        letterSpacing={'0.0938em'}
                        textTransform={'capitalize'}
                        onClick={scrollToReviews}
                        sx={{
                          cursor: 'pointer',
                          '@media (max-width: 500px)': {
                            fontSize: '14px',
                          },
                        }}
                      >
                        {companyData?.companyInfo?.companyType === 'local' ||
                        companyData?.companyInfo?.companyType === 'national'
                          ? `${companyData?.companyInfo?.companyType} Sign Company`
                          : `${companyData?.companyInfo?.companyType} Company`}
                      </Typography>
                    </Box> */}
                  </Box>
                </Box>
                {/* <Box
                  display="none"
                  gap="10px"
                  // justifyContent={'center'}
                  alignItems={'center'}
                  pl={3}
                  sx={{
                    '@media (max-width: 500px)': {
                      display: 'flex',
                      mt: '-2%',
                    },
                  }}
                >
                  <Tooltip
                    arrow
                    leaveDelay={300}
                    title={
                      <Box padding={'8px'} margin={'0'} bgcolor={'#000'}>
                        <Typography
                          color={'#D0D5DD'}
                          fontFamily={'SF UI Display'}
                          fontSize={'14px'}
                          fontWeight={'500'}
                          lineHeight={'18px'}
                        >
                          This page is currently unclaimed by Visible Graphics. Do you work here?{' '}
                        </Typography>
                        <Typography
                          color={'#00BD57'}
                          fontFamily={'SF UI Display'}
                          fontSize={'14px'}
                          fontWeight={'500'}
                          lineHeight={'18px'}
                          onClick={() => {
                            if (companyData?.companyInfo?.owner) return;
                            navigate('/claim-company', {
                              state: { companyData: companyData?._id },
                            });
                          }}
                          sx={{ textDecoration: 'underline', cursor: 'pointer' }}
                        >
                          Claim this page now.
                        </Typography>
                      </Box>
                    }
                    PopperProps={{
                      modifiers: [
                        {
                          name: 'offset',
                          options: {
                            offset: [0, 15], // Adjusts tooltip's Y position (20px below the image)
                          },
                        },
                      ],
                    }}
                    componentsProps={{
                      tooltip: {
                        sx: {
                          backgroundColor: '#000', // Tooltip background
                          color: 'white', // Text color inside the tooltip
                          padding: '12px', // Remove default padding
                          borderRadius: '8px',
                          display: companyData?.companyInfo?.owner ? 'none' : 'block',
                        },
                      },
                      arrow: {
                        sx: {
                          color: '#000', // Match the arrow color with the tooltip background
                          fontSize: '20px',
                        },
                      },
                    }}
                  >
                    <Box display={'flex'} alignItems={'center'} gap="10px">
                      <img
                        src={companyData?.companyInfo?.owner ? Claimedicon : UnClaimedicon}
                        alt="Claimedicon"
                        style={{
                          width: '16px',
                          height: '16px',
                        }}
                      />
                      <Typography
                        fontSize="16px"
                        fontFamily="SF UI Display"
                        fontWeight="600"
                        lineHeight="19.09px"
                        letterSpacing={'0.0938em'}
                        color={companyData?.companyInfo?.owner ? '#000000' : '#696969'}
                        sx={{
                          '@media (max-width: 500px)': {
                            fontSize: '14px',
                          },
                        }}
                      >
                        {companyData?.companyInfo?.owner ? 'Claimed Page' : 'Unclaimed Page'}
                      </Typography>
                    </Box>
                  </Tooltip>
                  <Divider
                    orientation="vertical"
                    variant="middle"
                    flexItem
                    sx={{ marginTop: '0', marginBottom: '0' }}
                  />
                  <Typography
                    fontSize="16px"
                    fontFamily="SF UI Display"
                    fontWeight="600"
                    lineHeight="19.09px"
                    color="#00BD57"
                    letterSpacing={'0.0938em'}
                    textTransform={'capitalize'}
                    onClick={scrollToReviews}
                    sx={{
                      cursor: 'pointer',
                      '@media (max-width: 500px)': {
                        fontSize: '14px',
                      },
                    }}
                  >
                    {companyData?.companyInfo?.companyType === 'local' ||
                    companyData?.companyInfo?.companyType === 'national'
                      ? `${companyData?.companyInfo?.companyType} Sign Company`
                      : `${companyData?.companyInfo?.companyType} Company`}
                  </Typography>
                </Box> */}
                <Box
                  display={'none'}
                  alignItems={'center'}
                  width={'100%'}
                  gap={'5%'}
                  sx={{
                    '@media (max-width: 768px)': {
                      display: 'flex',
                    },
                  }}
                >
                  {companyData?.serviceArea[0]?.distance && (
                    <Box display={'flex'} alignItems={'center'} gap={'8px'}>
                      <img src={ServiceAreaIcon} alt="Checkmark Filled" />
                      <Typography
                        fontSize={'14px'}
                        fontWeight={'600'}
                        fontFamily={'Poppins'}
                        color={'#000000'}
                        textTransform={'capitalize'}
                        sx={{
                          '@media (max-width: 768px)': {
                            fontWeight: '600',
                            fontSize: '14px',
                          },
                          // '@media (max-width: 600px)': {
                          // },
                        }}
                      >
                        Service Area: {companyData?.serviceArea[0]?.distance} Mi
                      </Typography>
                    </Box>
                  )}

                  {companyData?.companyInfo?.available_for_work === 'Yes' ? (
                    <img src={LineDetail} alt="line" />
                  ) : (
                    ''
                  )}
                  {companyData?.companyInfo?.available_for_work === 'Yes' && (
                    <Box display={'flex'} alignItems={'center'} gap={'8px'}>
                      <img src={Availability} alt="Checkmark Filled" />
                      <Typography
                        fontSize={'14px'}
                        fontWeight={'500'}
                        fontFamily={'Poppins'}
                        color={'#000000'}
                        textTransform={'capitalize'}
                        sx={{
                          '@media (max-width: 768px)': {
                            fontWeight: '600',
                            fontSize: '14px',
                          },
                          // '@media (max-width: 600px)': {
                          //   fontSize: '12px',
                          // },
                        }}
                      >
                        Available For Work
                      </Typography>
                    </Box>
                  )}
                </Box>
                <Box
                  width="100%"
                  display="flex"
                  alignItems="center"
                  gap="15px"
                  margin={'2% 0 35px 0'}
                  sx={{
                    justifyContent: isClaimed ? 'center' : 'start',
                    '@media (max-width: 1024px)': {
                      display: 'flex',
                      flexWrap: 'wrap',
                    },
                    '@media (max-width: 500px)': {
                      display: 'grid',
                      gridTemplateColumns: '1fr 1fr',
                    },
                  }}
                >
                  {ButtonDisplay?.map(
                    (item, index) =>
                      ((item?.text === 'Website' && companyData?.companyInfo?.website.length) ||
                        item?.text !== 'Website') && (
                        <Button
                          key={index}
                          sx={{
                            border: '1px solid #E8E8E8',
                            bgcolor: '#ffffff',
                            color: '#000000',
                            // #Beta

                            // color: item?.text === 'Contact Now' ? '#ffffff' : 'black',
                            // bgcolor: item?.text === 'Contact Now' ? '#00BD57' : '#ffffff',
                            fontFamily: 'SF UI Display',
                            fontWeight: '600',
                            fontSize: '16px',
                            display: 'flex',
                            alignItems: 'center',

                            gap: '10px',
                            textTransform: 'capitalize',
                            padding: '5px 10px',
                            '@media (max-width: 768px)': {
                              fontSize: '15px',
                            },
                            '@media (max-width: 500px)': {
                              fontSize: '14px',
                              fontWeight: '600',
                            },
                            // #Beta

                            // '&:hover': {
                            //   bgcolor: item?.text === 'Contact Now' ? '#00BD57' : '',
                            //   color: item?.text === 'Contact Now' ? '#ffffff' : '',
                            // },
                          }}
                          onClick={(): void => {
                            // if (userData?._id) {
                            // User is logged in and verified, show the contact popup
                            handleButtonClick(item?.action);
                            // } else {
                            //   setOpenSignInModal(true);
                            // }
                            // if (
                            //   userData?._id &&
                            //   !userData?.company?.companyInfo?.verification?.status
                            // ) {
                            //   // User is logged in but not verified, show verification popup
                            //   handleButtonClick(item?.action);
                            // }
                            // if (!userData?._id) {
                            //   // User is not logged in, show login popup
                            //   setOpenSignInModal(true);
                            // }
                          }}
                        >
                          {item.iconSrc}

                          {item.text}
                        </Button>
                      ),
                  )}
                </Box>
                {/* mobile alt companies */}
                {/* <Box
                  display={'none'}
                  sx={{
                    '@media (max-width: 1000px)': {
                      display: 'flex',
                    },
                  }}
                > */}
                {/* <Box width={'100%'}>
                    <Typography
                      fontFamily={'SF UI Display'}
                      fontSize={'18px'}
                      fontStyle={'normal'}
                      lineHeight={'normal'}
                      color={'black'}
                      fontWeight={800}
                      textTransform={'capitalize'}
                    >
                      {companyData?.companyInfo?.companyType === 'local'
                        ? 'Local Sign Company Alternatives'
                        : companyData?.companyInfo?.companyType === 'national'
                        ? 'Top National Alternatives'
                        : companyData?.companyInfo?.companyType === 'supplier'
                        ? 'Supplier Alternatives'
                        : 'Distributor Alternatives'}
                    </Typography> */}

                {/* {alternativeCompany.length > 0 ? ( */}
                {/* alternativeCompany.map((company: any) => ( */}
                {/* <Box
                          key={company?._id}
                          // border="1px solid #E8E8E8"
                          borderRadius="10px"
                          mt={2}
                          // bgcolor={'red'}
                          padding="3% 4% 3% 1%"
                          display="flex"
                          gap="16px"
                          alignItems="center"
                          justifyContent={'space-between'}
                          width={'100%'}
                          sx={{ cursor: 'pointer' }}
                          onClick={() => {
                            const paramType =
                              company?.companyInfo?.companyType === 'local'
                                ? 'local-sign-company'
                                : company?.companyInfo?.companyType === 'national'
                                ? 'national-sign-company'
                                : company?.companyInfo?.companyType === 'supplier'
                                ? 'supplier'
                                : 'distributor';
                            if (company?._id) {
                              navigate(
                                `/${paramType}/${formatSearchString(company?.companyInfo?.name)}`,
                                {
                                  state: {
                                    searchID: company?._id,
                                    type: company?.companyInfo?.companyType,
                                  },
                                },
                              );
                              request('get', `/companies/${company?._id}`)
                                .then((response: any) => {
                                  setCompanyData(response?.company);
                                  setalternativeCompany(response?.alternative_companies);
                                })
                                .catch((error: any) => {
                                  console.log(error);
                                });
                            }
                          }}
                        > */}
                {/* <Box display={'flex'} gap={'16px'} width={'100%'} alignItems={'center'}>
                            <Box width="57px" height="57px">
                              <img
                                src={
                                  company?.companyInfo?.logo
                                    ? `${mediaUrl}/${company?.companyInfo?.logo}`
                                    : defaultCompany
                                }
                                alt={company?.name || 'Default Company'}
                                style={{ width: '100%', height: '100%', borderRadius: '10px' }}
                              />
                            </Box>
                            <Box>
                              <Box width="60%">
                                <Typography
                                  fontFamily="SF UI Display"
                                  fontSize="16px"
                                  fontStyle="normal"
                                  fontWeight={700}
                                  lineHeight="normal"
                                  color="black"
                                >
                                  {company?.companyInfo?.name}
                                </Typography>
                              </Box>
                              <Box
                                width={'100%'}
                                display={'flex'}
                                alignItems={'center'}
                                justifyContent={'left'}
                                gap={'10px'}
                                sx={{
                                  '@media (max-width: 500px)': {
                                    width: '50%',
                                    justifyContent: 'space-between',
                                  },
                                }}
                              >
                                <Rating
                                  name="hover-feedback"
                                  value={
                                    typeof company?.averageReviewRating?.overall === 'number'
                                      ? company?.averageReviewRating.overall
                                      : parseFloat(company?.averageReviewRating?.overall) || 0
                                  }
                                  precision={0.1}
                                  sx={{
                                    color: !company?.averageReviewRating
                                      ? '#9E9E9E'
                                      : // : company?.averageReviewRating?.overall >= 4.6
                                      // ? '#3040CE'
                                      company?.averageReviewRating?.overall >= 4
                                      ? '#00BD57'
                                      : company?.averageReviewRating?.overall >= 3
                                      ? '#FFB800'
                                      : '#FD3E14',
                                    fontSize: '24px',
                                    '@media (max-width: 500px)': {
                                      fontSize: '21px',
                                    },
                                  }}
                                  // size="small"
                                  readOnly
                                  // emptyIcon={<CustomStarBorderIcon fontSize="inherit" />}
                                  emptyIcon={grayStar} // Custom gray star for empty rating
                                  icon={
                                    !company?.averageReviewRating ? (
                                      grayStar
                                    ) : (
                                      <StarIcon
                                        fontSize="inherit"
                                        sx={{
                                          color: !company?.averageReviewRating
                                            ? '#9E9E9E'
                                            : company?.averageReviewRating?.overall >= 4
                                            ? '#00BD57'
                                            : company?.averageReviewRating?.overall >= 3
                                            ? '#FFB800'
                                            : '#FD3E14',
                                        }}
                                      />
                                    )
                                  } // Gray filled stars if rating is 0
                                />
                                <Box
                                  display={'flex'}
                                  gap={'10px'}
                                  alignItems="center"
                                  sx={{
                                    '@media (max-width: 500px)': {
                                      width: '50%',
                                      alignItems: 'center',
                                      whiteSpace: 'pre',
                                    },
                                  }}
                                >
                                  <Typography
                                    fontSize={'25px'}
                                    fontWeight={'600'}
                                    fontFamily={'SF UI Display'}
                                    color={'#000'}
                                    sx={{
                                      '@media (max-width: 768px)': {
                                        fontSize: '18px',
                                        fontWeight: '700',
                                      },
                                      '@media (max-width: 500px)': {
                                        fontSize: '16px',
                                        fontWeight: '600',
                                      },
                                    }}
                                  >
                                    {company?.averageReviewRating?.overall > 0
                                      ? (
                                          Math.floor(company?.averageReviewRating?.overall * 10) /
                                          10
                                        ).toFixed(1)
                                      : ''}
                                  </Typography>
                                  <Typography
                                    fontFamily="SF UI Display"
                                    fontSize="14px"
                                    fontStyle="normal"
                                    fontWeight={400}
                                    lineHeight="normal"
                                    color="#777"
                                  >
                                    ({company?.averageReviewRating?.count ?? '0'}{' '}
                                    {company?.averageReviewRating?.count <= 1
                                      ? 'Review'
                                      : 'Reviews'}
                                    )
                                  </Typography>
                                </Box>
                              </Box>
                            </Box>
                          </Box> */}

                {/* <Box
                            width="45px"
                            height="60px"
                            bgcolor="#F7F7F7"
                            display="flex"
                            justifyContent="center"
                            alignItems="center"
                            flexDirection="column"
                            borderRadius="5px"
                          >
                       
                            <Typography
                              fontFamily="SF UI Display"
                              fontSize="16px"
                              fontStyle="normal"
                              fontWeight={700}
                              lineHeight="normal"
                              color="#000"
                            >
                              {company?.averageReviewRating?.overall?.toFixed(1) ?? '0.0'}
                            </Typography>
                          </Box> */}
                {/* </Box> */}
                {/* )) */}
                {/* ) : ( */}
                {/* <Typography
                  fontFamily="SF UI Display"
                  fontSize="16px"
                  fontStyle="normal"
                  fontWeight={700}
                  lineHeight="normal"
                  color="black"
                  mt={2}
                >
                  No Companies found
                </Typography> */}
                {/* )} */}
                {/* </Box> */}
                {/* </Box> */}
                {/* <Box width="100%" mt={'6%'}>
                  <Typography
                    fontFamily="SF UI Display"
                    fontWeight="800"
                    fontSize="20px"
                    lineHeight="23px"
                    color="black"
                    sx={{
                      '@media (max-width: 1440px)': {
                        gap: '20px',
                      },
                    }}
                  >
                    Highlights
                  </Typography>
                  <Box
                    position="relative"
                    width="100%"
                    display={'flex'}
                    alignItems={'center'}
                    justifyContent={'center'}
                  >
                    <Box
                      // ref={containerRef}
                      width="100%"
                      padding="5% 0"
                      display="flex"
                      alignItems="center"
                      gap="10px"
                      sx={{
                        '@media (max-width: 1440px)': {
                          gap: '20px',
                        },
                        '@media (max-width: 1200px)': {
                          gap: '30px',
                        },
                        '@media (max-width: 768px)': {
                          width: '100%',
                        },
                        '@media (max-width: 500px)': {
                          flexWrap: 'wrap',
                          justifyContent: 'space-between',
                          alignItems: 'center',
                          gap: '10px',
                        },
                      }}
                    >
                      {data?.map((item, index) => (
                        <Box
                          key={index}
                          width="16%"
                          display="flex"
                          justifyContent="center"
                          alignItems="center"
                          flexDirection="column"
                          gap="8px"
                          sx={{
                            '@media (max-width: 1000px)': {
                              width: '22%',
                            },
                            '@media (max-width: 500px)': {
                              width: '48%',
                              height: '137px',
                              borderRadius: '10px',
                              bgcolor: '#F7F7F7',
                            },
                          }}
                        >
                          <img
                            src={item.iconSrc}
                            alt="icon"
                            style={{ width: '38px', height: '38px' }}
                          />
                          <Box
                            width="100%"
                            display="flex"
                            justifyContent={'center'}
                            alignItems="center"
                            gap="3px"
                          >
                            {item.title !== 'Recommendations' &&
                              item?.title !== 'On-Time Projects' &&
                              item?.Icon}
                            <Typography
                              fontFamily="SF UI Display"
                              fontWeight="700"
                              fontSize="25px"
                              lineHeight="normal"
                              color={item?.color ? item?.color : '#00BD57'}
                            >
                              {item.title === 'Payment Terms' &&
                              companyData?.companyInfo?.companyType === 'national'
                                ? 'Net 30'
                                : item.title === 'Payment Terms' &&
                                  companyData?.companyInfo?.companyType !== 'national'
                                ? 'Monthly'
                                : typeof item?.rating === 'number'
                                ? (Math.floor(item?.rating * 10) / 10).toFixed(1)
                                : item?.rating}
                            </Typography>
                          </Box>
                          <Typography
                            noWrap
                            fontFamily="SF UI Display"
                            fontSize="16px"
                            textAlign="center"
                            whiteSpace="pre"
                            lineHeight="normal"
                            fontWeight="500"
                            color="#000000"
                          >
                            {item?.title}
                          </Typography>
                        </Box>
                      ))}
                    </Box>
                  </Box>
                </Box> */}
                {companyData?.companyInfo?.description?.length ? (
                  <Box
                    width="94%"
                    mt="1%"
                    display={'flex'}
                    flexDirection={'column'}
                    alignItems={'start'}
                    justifyContent={'start'}
                    gap={'40px'}
                  >
                    <Typography
                      fontFamily="SF UI Display"
                      fontWeight="800"
                      fontSize="20px"
                      lineHeight="23px"
                      color="#000000"
                    >
                      Overview{' '}
                    </Typography>
                    <Box display="flex" alignItems="center" gap="30px" margin={'0 0 4% 0'}>
                      <Typography
                        fontFamily="SF UI Display"
                        fontWeight="300"
                        fontSize="16px"
                        lineHeight="19.09px"
                        color="#000000"
                      >
                        {companyData?.companyInfo?.description ?? ''}
                      </Typography>
                    </Box>
                  </Box>
                ) : (
                  ''
                )}
                {companyData?.companyInfo?.companyType === 'local' && (
                  <Box
                    width="100%"
                    mt="1%"
                    sx={{
                      '@media (max-width:500px)': {
                        marginTop: '4%',
                        display: 'flex',
                        flexDirection: 'column',
                        alignItems: 'start',
                        justifyContent: 'start',
                        gap: '40px',
                      },
                    }}
                  >
                    <Typography
                      fontFamily="SF UI Display"
                      fontWeight="800"
                      fontSize="20px"
                      lineHeight="23px"
                      color="black"
                    >
                      Services
                    </Typography>
                    <Box
                      display="flex"
                      alignItems="center"
                      gap="30px"
                      // margin={'4% 0'}
                      flexWrap={'wrap'}
                      sx={{
                        '@media (max-width:500px)': {
                          gap: '15px',
                        },
                      }}
                    >
                      {companyData?.services?.map((item: any, index: number) => (
                        <Box
                          key={index}
                          display="flex"
                          alignItems="center"
                          gap="8px"
                          sx={{
                            '@media (max-width:500px)': {
                              margin: '5px 0',
                              width: '47%',
                            },
                          }}
                        >
                          <img src={SuccessMobile} alt="tick" />
                          <Typography
                            fontFamily="SF UI Display"
                            fontWeight="500"
                            fontSize="14px"
                            lineHeight="17px"
                            color="#000000"
                          >
                            {item?.service}
                          </Typography>
                        </Box>
                      ))}
                    </Box>
                  </Box>
                )}
                <Box
                  width="30%"
                  display="flex"
                  gap="20px"
                  flexDirection="column"
                  sx={{
                    '@media (max-width: 1000px)': {
                      marginTop: '30px',
                      width: '100%',
                      marginBottom: '3%',
                      gap: '40px',
                      // display: 'none',
                    },
                  }}
                >
                  {companyData?.companyInfo?.companyType !== 'local' ? (
                    <Box
                      width={'100%'}
                      border="solid 1px #E8E8E8"
                      borderRadius="10px"
                      overflow={'hidden'}
                    >
                      <Box width="100%" height={'38vh'}>
                        {/* <MapWithMultipleMarkers companyData={companyData} /> */}
                        <EditServiceAreaMap
                          radiusInMiles={companyData?.serviceArea[0]?.distance ?? 150}
                          data={companyData?.companyInfo?.location}
                          // setData={setData}
                        />
                      </Box>
                      <Box
                        padding="5% 3%"
                        display="flex"
                        justifyContent="space-between"
                        alignItems="center"
                      >
                        <Typography
                          fontSize="16px"
                          fontWeight="600"
                          lineHeight="19px"
                          fontFamily="SF UI Display"
                        >
                          HQ Location
                        </Typography>
                        <Typography
                          fontSize="16px"
                          fontWeight="300"
                          lineHeight="19px"
                          fontFamily="SF UI Display"
                        >
                          {companyData?.companyInfo?.address?.state},{' '}
                          {companyData?.companyInfo?.address?.country}
                        </Typography>
                      </Box>
                    </Box>
                  ) : (
                    <>
                      <Typography
                        fontFamily="SF UI Display"
                        fontWeight="800"
                        fontSize="20px"
                        lineHeight="23px"
                        color="black"
                        margin={'4% 0'}
                        sx={{
                          '@media (max-width: 1000px)': {
                            margin: '10px 0 0 0',
                          },
                        }}
                      >
                        Company Details
                      </Typography>
                      <Box
                        width="100%"
                        // border="solid 1px #E8E8E8"
                        // borderRadius="5px"
                        // padding="5%"
                        mb={'3%'}
                        display={'flex'}
                        flexDirection={'column'}
                        gap={'30px'}
                      >
                        {/* <Typography
                        fontFamily="SF UI Display"
                        fontWeight="800"
                        fontSize="20px"
                        lineHeight="23px"
                        color="black"
                      >
                        Service Area
                      </Typography>
                      <Divider sx={{ bgcolor: '#EBEEF1', width: '100%', margin: '4% 0' }} /> */}
                        {companyData?.companyInfo?.licence && (
                          <Box
                            display="flex"
                            justifyContent="space-between"
                            alignItems="center"
                            padding="2% 0"
                            gap={'30px'}
                          >
                            <Typography
                              fontSize="14px"
                              fontWeight="500"
                              lineHeight="17px"
                              fontFamily="SF UI Display"
                            >
                              Contractor License #
                            </Typography>

                            <Typography
                              fontSize="16px"
                              fontWeight="300"
                              lineHeight="14px"
                              fontFamily="SF UI Display"
                            >
                              {companyData?.companyInfo?.licence}
                            </Typography>
                          </Box>
                        )}
                        {/* <Divider sx={{ bgcolor: '#EBEEF1', width: '100%', margin: '4% 0' }} /> */}
                        <Box
                          display="flex"
                          justifyContent="space-between"
                          alignItems="center"
                          gap="10px"
                          padding="2% 0"
                        >
                          <Typography
                            fontSize="14px"
                            fontWeight="500"
                            lineHeight="17px"
                            fontFamily="SF UI Display"
                          >
                            Formation
                          </Typography>
                          <Typography
                            fontSize="16px"
                            fontWeight="300"
                            lineHeight="14px"
                            fontFamily="SF UI Display"
                          >
                            {' '}
                            {calculateYears(Number(companyData?.companyInfo?.formation_year))}
                          </Typography>
                        </Box>
                        {/* <Divider sx={{ bgcolor: '#EBEEF1', width: '100%', margin: '4% 0' }} /> */}
                        <Box
                          display="flex"
                          justifyContent="space-between"
                          alignItems="center"
                          gap="10px"
                          padding="2% 0"
                        >
                          <Typography
                            fontSize="14px"
                            fontWeight="500"
                            lineHeight="17px"
                            fontFamily="SF UI Display"
                          >
                            Company Size
                          </Typography>
                          <Typography
                            fontSize="16px"
                            fontWeight="300"
                            lineHeight="14px"
                            fontFamily="SF UI Display"
                            textTransform={'capitalize'}
                          >
                            {companyData?.companyInfo?.company_size}
                          </Typography>
                        </Box>
                        {/* <Divider sx={{ bgcolor: '#EBEEF1', width: '100%', margin: '4% 0' }} /> */}
                        <Box
                          display="flex"
                          justifyContent="space-between"
                          alignItems="center"
                          gap="10px"
                          padding="2% 0"
                        >
                          <Typography
                            fontSize="14px"
                            fontWeight="500"
                            lineHeight="17px"
                            fontFamily="SF UI Display"
                          >
                            Union Shop
                          </Typography>
                          <Typography
                            fontSize="16px"
                            fontWeight="300"
                            lineHeight="14px"
                            fontFamily="SF UI Display"
                          >
                            {companyData?.companyInfo?.union_shop}
                          </Typography>
                        </Box>
                        {/* <Divider sx={{ bgcolor: '#EBEEF1', width: '100%', margin: '4% 0' }} /> */}
                        {companyData?.services?.some(
                          (service: any) => service.service === 'Fabricators',
                        ) && (
                          <Box
                            display="flex"
                            justifyContent="space-between"
                            alignItems="center"
                            gap="10px"
                            padding="2% 0"
                          >
                            <Typography
                              fontSize="14px"
                              fontWeight="500"
                              lineHeight="17px"
                              fontFamily="SF UI Display"
                            >
                              UL Listed
                            </Typography>
                            <Typography
                              fontSize="16px"
                              fontWeight="300"
                              lineHeight="14px"
                              fontFamily="SF UI Display"
                            >
                              {companyData?.companyInfo?.ul_list}
                            </Typography>
                          </Box>
                        )}
                        {/* {companyData?.services?.some(
                          (service: any) => service.service === 'Fabricators',
                        ) && <Divider sx={{ bgcolor: '#EBEEF1', width: '100%', margin: '4% 0' }} />} */}
                        <Box
                          display="flex"
                          justifyContent="space-between"
                          alignItems="center"
                          gap="10px"
                          padding="2% 0"
                        >
                          <Typography
                            fontSize="14px"
                            fontWeight="500"
                            lineHeight="17px"
                            fontFamily="SF UI Display"
                          >
                            Response Time
                          </Typography>
                          <Typography
                            fontSize="16px"
                            fontWeight="300"
                            lineHeight="14px"
                            fontFamily="SF UI Display"
                          >
                            Typically {companyData?.companyInfo?.responce_time}
                          </Typography>
                        </Box>
                      </Box>
                    </>
                    // <>
                    //   <Box
                    //     width={'100%'}
                    //     border="solid 1px #E8E8E8"
                    //     borderRadius="10px"
                    //     overflow={'hidden'}
                    //   >
                    //     <Box width="100%" height={'38vh'}>
                    //       {/* <MapWithMultipleMarkers companyData={companyData} /> */}
                    //       <EditServiceAreaMap
                    //         radiusInMiles={companyData?.serviceArea[0]?.distance ?? 150}
                    //         data={companyData?.companyInfo?.location}
                    //         // setData={setData}
                    //       />
                    //     </Box>
                    //     <Box
                    //       padding="5% 3%"
                    //       display="flex"
                    //       justifyContent="space-between"
                    //       alignItems="center"
                    //     >
                    //       <Typography
                    //         fontSize="16px"
                    //         fontWeight="600"
                    //         lineHeight="19px"
                    //         fontFamily="SF UI Display"
                    //       >
                    //         Location
                    //       </Typography>
                    //       <Typography
                    //         fontSize="16px"
                    //         fontWeight="300"
                    //         lineHeight="19px"
                    //         fontFamily="SF UI Display"
                    //       >
                    //         {companyData?.companyInfo?.address?.state},{' '}
                    //         {companyData?.companyInfo?.address?.country}
                    //       </Typography>
                    //     </Box>
                    //   </Box>
                    //   {companyData?.companyInfo?.owner && !companyData?.serviceArea[0].distance && (
                    //     <Box
                    //       width="100%"
                    //       border="solid 1px #E8E8E8"
                    //       borderRadius="5px"
                    //       padding="5%"
                    //     >
                    //       <Typography
                    //         fontFamily="SF UI Display"
                    //         fontWeight="800"
                    //         fontSize="20px"
                    //         lineHeight="23px"
                    //         color="black"
                    //       >
                    //         Service Area
                    //       </Typography>
                    //       <Divider sx={{ bgcolor: '#EBEEF1', width: '100%', margin: '4% 0' }} />
                    //       <Box
                    //         display="flex"
                    //         justifyContent="space-between"
                    //         alignItems="center"
                    //         padding="2% 0"
                    //         gap={'30px'}
                    //       >
                    //         <Typography
                    //           fontSize="16px"
                    //           fontWeight="600"
                    //           lineHeight="19px"
                    //           fontFamily="SF UI Display"
                    //         >
                    //           States
                    //         </Typography>

                    //         <Typography
                    //           fontSize="16px"
                    //           fontWeight="300"
                    //           lineHeight="19px"
                    //           fontFamily="SF UI Display"
                    //         >
                    //           {companyData?.serviceArea
                    //             ?.map((item: { name: any }) => item?.name)
                    //             .join(', ')}
                    //         </Typography>
                    //       </Box>
                    //       <Divider sx={{ bgcolor: '#EBEEF1', width: '100%', margin: '4% 0' }} />
                    //       <Box
                    //         display="flex"
                    //         justifyContent="space-between"
                    //         flexDirection="column"
                    //         gap="10px"
                    //         padding="2% 0"
                    //       >
                    //         <Typography
                    //           fontSize="16px"
                    //           fontWeight="600"
                    //           lineHeight="19px"
                    //           fontFamily="SF UI Display"
                    //         >
                    //           Details
                    //         </Typography>
                    //         <Typography
                    //           fontSize="16px"
                    //           fontWeight="300"
                    //           lineHeight="19px"
                    //           fontFamily="SF UI Display"
                    //         >
                    //           {companyData?.serviceAreaDescription}
                    //         </Typography>
                    //       </Box>
                    //     </Box>
                    //   )}
                    // </>
                  )}
                </Box>
                {companyData?.workGallery?.length ? (
                  <Box width="100%" mt="1%">
                    <Typography
                      fontFamily="SF UI Display"
                      fontWeight="800"
                      fontSize="20px"
                      lineHeight="23px"
                      color="black"
                      margin={'50px 0'}
                      sx={{
                        '@media (max-width: 1000px)': {
                          margin: '30px 0 50px 0',
                        },
                      }}
                    >
                      Job Experience
                    </Typography>
                    {companyData?.workGallery
                      ?.sort(
                        (a: any, b: any) =>
                          new Date(b.start).getTime() - new Date(a.start).getTime(),
                      )
                      .map((item: any, index: number) => (
                        <Box
                          key={item._id}
                          width={'100%'}
                          margin={'30px 0'}
                          paddingBottom={'30px'}
                          borderBottom={
                            index !== companyData.workGallery.length - 1
                              ? '1px solid #DCDEDF'
                              : 'none'
                          }
                        >
                          <Typography
                            fontFamily="SF UI Display"
                            fontWeight="700"
                            fontSize="18px"
                            lineHeight="21px"
                            color="rgba(0, 0, 0, 1)"
                          >
                            {item?.service} - {item?.job_location}
                          </Typography>
                          <Box display={'flex'} alignItems={'center'} gap={'9px'} margin={'5px 0'}>
                            <img style={{ width: '25px', height: '25px' }} src={CalenderIcon} />

                            <Typography
                              fontFamily="SF UI Display"
                              fontWeight="400"
                              fontSize="14px"
                              lineHeight="16px"
                              color="rgba(0, 0, 0, 1)"
                            >
                              {dayjs(item.start).format('MMM Do, YYYY')} -{' '}
                              {dayjs(item.end).format('MMM Do, YYYY')}
                            </Typography>
                          </Box>
                          <Typography
                            fontFamily="SF UI Display"
                            fontWeight="300"
                            fontSize="16px"
                            lineHeight="19px"
                            color="rgba(0, 0, 0, 1)"
                            margin={'25px 0'}
                          >
                            {isExpanded[index]
                              ? item?.description
                              : // eslint-disable-next-line @typescript-eslint/restrict-plus-operands
                                item?.description?.slice(0, maxLength)}
                            {item?.description?.length > maxLength && (
                              <span
                                style={{
                                  fontFamily: 'SF UI Display',
                                  color: '#00BD57',
                                  textDecoration: 'underline',
                                  cursor: 'pointer',
                                  marginLeft: '5px',
                                }}
                                onClick={() => {
                                  handleToggle(index);
                                }}
                              >
                                {isExpanded[index] ? 'Show Less' : 'See More'}
                              </span>
                            )}
                          </Typography>
                          <Box
                            width={'100%'}
                            display={'flex'}
                            gap={'10px'}
                            sx={{
                              overflowX: 'scroll',
                            }}
                          >
                            {item?.photos.map((pic: any) => {
                              return (
                                <Box
                                  key={pic}
                                  minWidth={item?.photos?.length > 2 ? '175px' : '300px'}
                                  height={item?.photos?.length > 2 ? '130px' : '225px'}
                                  borderRadius={'8px'}
                                  overflow={'hidden'}
                                  // marginRight={index !== item?.photos.length - 1 ? '10px' : '0px'}
                                >
                                  <img
                                    style={{
                                      width: '100%',
                                      height: '100%',
                                      objectFit: 'cover',
                                    }}
                                    src={`${mediaUrl}/${pic}`}
                                  />
                                </Box>
                              );
                            })}
                          </Box>
                        </Box>
                      ))}
                  </Box>
                ) : (
                  ''
                )}
              </>
            )}
            {/* #Beta */}
            {/* <Box width="100%" mt={'6%'} ref={reviewsSectionRef}>
              <Box
                width={'100%'}
                margin={'2% 0 3% 0'}
                display={'flex'}
                alignItems={'center'}
                justifyContent={'space-between'}
              >
                <Typography
                  fontFamily="SF UI Display"
                  fontWeight="800"
                  fontSize="20px"
                  lineHeight="23px"
                  color="black"
                >
                  Reviews
                </Typography>
                {companyData?.companyInfo?.companyType !==
                userData?.company?.companyInfo?.companyType ? (
                  userData?.company?.companyInfo?.companyType !== 'supplier' &&
                  userData?.company?.companyInfo?.companyType !== 'distributor' ? (
                    <Button
                      sx={{
                        fontFamily: 'SF UI Display',
                        fontSize: '16px',
                        fontWeight: '700',
                        lineHeight: '19px',
                        color: '#000000',
                        border: '1px solid #C4C4C4',
                        borderRadius: '5px',
                        whiteSpace: 'nowrap',
                        textTransform: 'capitalize',
                        padding: '1.5% 2%',
                        '@media (max-width: 500px)': {
                          color: '#00BD57',
                          textDecoration: 'underline',
                          border: 'none',
                        },
                      }}
                      onClick={(): void => {
                        if (!userData?._id) {
                          setOpenReviewLogin(true);
                        } else if (!userData?.company) {
                          navigate('/add-business', { state: { screenNumber: 4 } });
                          // toast(
                          //   <CustomToast
                          //     message={'First complete your Sign-Up process.'}
                          //     type="error"
                          //   />,
                          // );
                        } else if (
                          userData?.company &&
                          (!userData?.company?.companyInfo?.verification?.status || claimStatus)
                        ) {
                          setVerificationPopup(true);
                        } else if (
                          userData?._id &&
                          userData?.company?.companyInfo?.verification?.status &&
                          !claimStatus
                        ) {
                          navigate('/submit-review', { state: { companyData: companyData } });
                        }
                      }}
                    >
                      Submit Review
                    </Button>
                  ) : (
                    ''
                  )
                ) : (
                  ''
                )}
              </Box>
              <Box width="100%">
                <CustomTabPanel value={value} index={0}>
                  {approvedReviews?.map((item: any, index: number) => (
                    <ReviewCard
                      id={`review-${item?._id}`}
                      item={item}
                      key={index}
                      companyData={companyData}
                      claimStatus={claimStatus}
                      setOpenSignInModal={setOpenSignInModal}
                      getReviews={getCompanyReviews}
                    />
                  ))}
                </CustomTabPanel>
                <CustomTabPanel value={value} index={1}>
                  {complaints?.map((item: any, index: number) => (
                    <Box
                      key={index}
                      width={'100%'}
                      border={'1px solid #DCDEDF'}
                      borderRadius={'5px'}
                      marginBottom={'3%'}
                      sx={{
                        '@media (max-width: 1000px)': {
                          margin: '5% 0',
                        },
                      }}
                    >
                      <Box
                        width={'100%'}
                        display={'flex'}
                        alignItems={'center'}
                        justifyContent={'space-between'}
                        padding={'2%'}
                        borderBottom={'1px solid #DCDEDF'}
                        sx={{
                          '@media (max-width: 1000px)': {
                            flexDirection: 'column',
                            alignItems: 'start',
                            justifyContent: 'start',
                          },
                        }}
                      >
                        <Box display={'flex'} alignItems={'center'} gap={'10px'}>
                          <Box display={'flex'} flexDirection={'column'}>
                            <Typography
                              fontFamily="SF UI Display"
                              fontWeight="700"
                              fontSize="18px"
                              lineHeight="21px"
                              color="#000000"
                            >
                              {item?.services[0]} Review
                            </Typography>
                            <Typography
                              fontFamily="SF UI Display"
                              fontWeight="400"
                              fontSize="16px"
                              lineHeight="19.09px"
                              color="#000000"
                            >
                              {companyData?.companyInfo?.companyType !== 'local' ? (
                                moment(item?.createdAt).format('MMMM Do, YYYY')
                              ) : (
                                <>
                                  {moment(item?.job_start_date).format('MMM Do')} -{' '}
                                  {moment(item?.job_completion_date).format('MMM Do')}
                                </>
                              )}
                            </Typography>
                          </Box>
                        </Box>

                        <Box
                          display={'flex'}
                          gap={'10px'}
                          sx={{
                            '@media (max-width: 1000px)': {
                              margin: '20px 0',
                              width: '100%',
                            },
                          }}
                        >
                          {!isMobile && (
                            <Avatar
                              src={
                                item?.user?.avatar ? `${mediaUrl}/${item?.user?.avatar}` : Avatarimg
                              }
                            />
                          )}
                          <Box
                            display={'flex'}
                            flexDirection={'column'}
                            sx={{
                              '@media (max-width: 1000px)': {
                                flexDirection: 'row',
                                gap: '30px',
                              },
                            }}
                          >
                            <Typography
                              fontFamily="SF UI Display"
                              fontWeight="600"
                              fontSize="15px"
                              lineHeight="20px"
                              color="#000000"
                              textTransform={'capitalize'}
                              sx={{
                                '@media (max-width: 1000px)': {
                                  display: 'flex',
                                  alignItems: 'center',
                                  flexDirection: 'row',
                                  gap: '10px',
                                },
                              }}
                            >
                              {isMobile ? (
                                <Avatar
                                  sx={{
                                    width: '25px',
                                    height: '25px',
                                  }}
                                  src={
                                    item?.user?.avatar
                                      ? `${mediaUrl}/${item?.user?.avatar}`
                                      : Avatarimg
                                  }
                                />
                              ) : (
                                ''
                              )}
                              {item?.user?.name
                                ? item?.user?.name
                                : item?.user?.job_title
                                ? item?.user?.job_title
                                : item?.user?.designation}
                            </Typography>
                            <Typography
                              fontFamily="SF UI Display"
                              fontWeight="500"
                              fontSize="14px"
                              lineHeight="18px"
                              color="#606060"
                              textTransform={'capitalize'}
                              sx={{
                                '@media (max-width: 1000px)': {
                                  display: 'flex',
                                  alignItems: 'center',
                                  flexDirection: 'row',
                                  gap: '10px',
                                },
                              }}
                            >
                              {isMobile ? (
                                <Avatar
                                  sx={{
                                    width: '25px',
                                    height: '25px',
                                    borderRadius: '0',
                                  }}
                                  src={CompanyLogoRating}
                                />
                              ) : (
                                ''
                              )}
                              {item?.user?.company === 'local' || item?.user?.company === 'national'
                                ? `${item?.user?.company} Sign Company`
                                : item?.user?.company}
                            </Typography>
                          </Box>
                        </Box>
                      </Box>
                      <Box
                        width={'100%'}
                        display={'flex'}
                        sx={{
                          '@media (max-width: 768px)': {
                            flexDirection: 'column',
                          },
                        }}
                      >
                        <Box
                          width={'30%'}
                          display={'flex'}
                          alignItems={'center'}
                          justifyContent={'center'}
                          flexDirection={'column'}
                          gap={'10px'}
                          padding={'2% 0'}
                          borderRight={'1px solid #DCDEDF'}
                          sx={{
                            '@media (max-width: 768px)': {
                              borderRight: 'none',
                              width: '100%',
                              flexDirection: 'column-reverse',
                              justifyContent: 'start',
                              alignItems: 'start',
                              padding: '2%',
                            },
                          }}
                        >
                          <Box
                            display={'flex'}
                            alignItems={'center'}
                            gap={'5px'}
                            sx={{
                              '@media (max-width: 768px)': {
                                flexDirection: 'row-reverse',
                              },
                            }}
                          >
                            <Typography
                              fontFamily="SF UI Display"
                              fontWeight="800"
                              fontSize="26px"
                              lineHeight="31px"
                              color="#000000"
                            >
                              {typeof item?.overall_rating === 'number'
                                ? (Math.floor(item?.overall_rating * 10) / 10).toFixed(1)
                                : '--'}
                            </Typography>
                            <Rating
                              value={item?.overall_rating}
                              readOnly
                              precision={0.1}
                              sx={{
                                color: !item?.overall_rating
                                  ? '#9E9E9E'
                                  : item?.overall_rating >= 4
                                  ? '#00BD57'
                                  : item?.overall_rating >= 3
                                  ? '#FFB800'
                                  : '#FD3E14',
                              }}
                            />
                          </Box>
                          <Typography
                            fontFamily="SF UI Display"
                            fontWeight="400"
                            fontSize="20px"
                            lineHeight="24px"
                            color="#000000"
                          >
                            Overall Review Ratings
                          </Typography>
                        </Box>
                        <Box
                          width={'70%'}
                          display={'flex'}
                          padding={'2%'}
                          flexWrap={'wrap'}
                          justifyContent={'space-between'}
                          sx={{
                            '@media (max-width: 768px)': {
                              width: '100%',
                              display: 'grid',
                              gridTemplateColumns: '1fr 1fr',
                              gap: '25px',
                            },
                          }}
                        >
                          <Box
                            width={'47%'}
                            margin={'2% 0'}
                            // sx={{
                            //   '@media (max-width: 768px)': {
                            //     width: '98%',
                            //     margin: '4% 0',
                            //   },
                            // }}
                          >
                            <Box
                              width={'100%'}
                              display={'flex'}
                              alignItems={'center'}
                              justifyContent={'space-between'}
                            >
                              <Typography
                                fontFamily="SF UI Display"
                                fontWeight="700"
                                fontSize="16px"
                                lineHeight="19px"
                                color="#000000"
                              >
                                {companyData?.companyInfo?.companyType === 'local'
                                  ? 'Reliability'
                                  : companyData?.companyInfo?.companyType === 'national'
                                  ? 'Professionalism'
                                  : 'Quality'}
                              </Typography>
                              <Typography
                                fontFamily="SF UI Display"
                                fontWeight="500"
                                fontSize="16px"
                                lineHeight="19px"
                                color="#000000"
                              >
                                {typeof item?.reliability === 'number'
                                  ? Number.isInteger(item?.reliability)
                                    ? item?.reliability.toString()
                                    : (Math.floor(item?.reliability * 10) / 10).toFixed(1)
                                  : '--'}
                                /5
                              </Typography>
                            </Box>
                            <Box width={'100%'} marginTop={'3%'}>
                              <BorderLinearProgress
                                sx={{
                                  [`& .${linearProgressClasses.bar}`]: {
                                    backgroundColor: getColor(item?.reliability),
                                  },
                                }}
                                variant="determinate"
                                value={(item?.reliability / 5) * 100}
                              />
                            </Box>
                          </Box>
                          <Box
                            width={'47%'}
                            margin={'2% 0'}
                            // sx={{
                            //   '@media (max-width: 768px)': {
                            //     width: '98%',
                            //   },
                            // }}
                          >
                            <Box
                              width={'100%'}
                              display={'flex'}
                              alignItems={'center'}
                              justifyContent={'space-between'}
                            >
                              <Typography
                                fontFamily="SF UI Display"
                                fontWeight="700"
                                fontSize="16px"
                                lineHeight="19px"
                                color="#000000"
                              >
                                {companyData?.companyInfo?.companyType === 'local'
                                  ? 'Communication'
                                  : companyData?.companyInfo?.companyType === 'national'
                                  ? 'Communication'
                                  : 'Customer Service'}
                              </Typography>
                              <Typography
                                fontFamily="SF UI Display"
                                fontWeight="500"
                                fontSize="16px"
                                lineHeight="19px"
                                color="#000000"
                              >
                                {typeof item?.communication === 'number'
                                  ? Number.isInteger(item?.communication)
                                    ? item?.communication.toString()
                                    : (Math.floor(item?.communication * 10) / 10).toFixed(1)
                                  : '--'}
                                /5
                              </Typography>
                            </Box>
                            <Box width={'100%'} marginTop={'3%'}>
                              <BorderLinearProgress
                                sx={{
                                  [`& .${linearProgressClasses.bar}`]: {
                                    backgroundColor: getColor(item?.communication),
                                  },
                                }}
                                variant="determinate"
                                value={(item?.communication / 5) * 100}
                              />
                            </Box>
                          </Box>
                          <Box
                            width={'47%'}
                            margin={'2% 0'}
                            // sx={{
                            //   '@media (max-width: 768px)': {
                            //     width: '98%',
                            //   },
                            // }}
                          >
                            <Box
                              width={'100%'}
                              display={'flex'}
                              alignItems={'center'}
                              justifyContent={'space-between'}
                            >
                              <Typography
                                fontFamily="SF UI Display"
                                fontWeight="700"
                                fontSize="16px"
                                lineHeight="19px"
                                color="#000000"
                              >
                                {companyData?.companyInfo?.companyType === 'local'
                                  ? 'Work Quality'
                                  : companyData?.companyInfo?.companyType === 'national'
                                  ? 'Compensation'
                                  : 'Cost'}
                              </Typography>
                              <Typography
                                fontFamily="SF UI Display"
                                fontWeight="500"
                                fontSize="16px"
                                lineHeight="19px"
                                color="#000000"
                              >
                                {typeof item?.work_quality === 'number'
                                  ? Number.isInteger(item?.work_quality)
                                    ? item?.work_quality.toString()
                                    : (Math.floor(item?.work_quality * 10) / 10).toFixed(1)
                                  : '--'}
                                /5
                              </Typography>
                            </Box>
                            <Box width={'100%'} marginTop={'3%'}>
                              <BorderLinearProgress
                                sx={{
                                  [`& .${linearProgressClasses.bar}`]: {
                                    backgroundColor: getColor(item?.work_quality),
                                  },
                                }}
                                variant="determinate"
                                value={(item?.work_quality / 5) * 100}
                              />
                            </Box>
                          </Box>
                          <Box
                            width={'47%'}
                            margin={'2% 0'}
                            sx={{
                              '@media (max-width: 768px)': {
                                width: '98%',
                              },
                            }}
                          >
                            <Box
                              width={'100%'}
                              display={'flex'}
                              alignItems={'center'}
                              justifyContent={'space-between'}
                            >
                              <Typography
                                fontFamily="SF UI Display"
                                fontWeight="700"
                                fontSize="16px"
                                lineHeight="19px"
                                color="#000000"
                              >
                                {companyData?.companyInfo?.companyType === 'local'
                                  ? 'Lead Time'
                                  : companyData?.companyInfo?.companyType === 'national'
                                  ? !isMobile
                                    ? 'On-Time Payments'
                                    : 'On-Time Pay'
                                  : 'Recommendations'}
                              </Typography>
                              <Typography
                                fontFamily="SF UI Display"
                                fontWeight="500"
                                fontSize="16px"
                                lineHeight="19px"
                                color="#000000"
                              >
                                {typeof item?.lead_time === 'number'
                                  ? Number.isInteger(item?.lead_time)
                                    ? item?.lead_time.toString()
                                    : (Math.floor(item?.lead_time * 10) / 10).toFixed(1)
                                  : '--'}
                                /5
                              </Typography>
                            </Box>
                            <Box width={'100%'} marginTop={'3%'}>
                              <BorderLinearProgress
                                sx={{
                                  [`& .${linearProgressClasses.bar}`]: {
                                    backgroundColor: getColor(item?.lead_time),
                                  },
                                }}
                                variant="determinate"
                                value={(item?.lead_time / 5) * 100}
                              />
                            </Box>
                          </Box>
                        </Box>
                      </Box>
                      {item.feedback && (
                        <Box width={'100%'} borderTop={'1px solid #DCDEDF'} padding={'2%'}>
                          <Typography
                            fontFamily="SF UI Display"
                            fontWeight="700"
                            fontSize="16px"
                            lineHeight="19px"
                            color="#000000"
                          >
                            Feedback
                          </Typography>
                          <Typography
                            fontFamily="SF UI Display"
                            fontWeight="400"
                            fontSize="14px"
                            lineHeight="16px"
                            color="#000000"
                            marginTop="1.5%"
                          >
                            {!showFullFeedback && item?.feedback?.length > 200
                              ? `"${item?.feedback.slice(0, 200)}..."`
                              : `"${item?.feedback}"`}
                            {item?.feedback?.length > 200 && (
                              <Button
                                onClick={handleToggleFeedback}
                                sx={{
                                  fontSize: '14px',
                                  fontWeight: '600',
                                  fontFamily: 'SF UI Display',
                                  color: '#00BD57',
                                  textTransform: 'capitalize',
                                  padding: '0',
                                  margin: '0 0 0 5px',
                                  textDecoration: 'underline',
                                }}
                              >
                                {showFullFeedback ? 'Show Less' : 'Read Full Review'}
                              </Button>
                            )}
                          </Typography>
                        </Box>
                      )}
                    </Box>
                  ))}
                </CustomTabPanel>
              </Box>
            </Box> */}
          </Box>

          {!isMobile ? (
            <Box
              width="30%"
              display="flex"
              gap="20px"
              flexDirection="column"
              sx={{
                '@media (max-width: 1000px)': {
                  marginTop: '30px',
                  width: '100%',
                  marginBottom: '3%',
                },
              }}
            >
              {companyData?.companyInfo?.companyType === 'local' && (
                <>
                  <Box
                    width={'100%'}
                    border="solid 1px #E8E8E8"
                    borderRadius="10px"
                    overflow={'hidden'}
                  >
                    <Box width={'100%'} height={'30vh'}>
                      {/* <MapWithMultipleMarkers companyData={companyData} /> */}
                      <EditServiceAreaMap
                        radiusInMiles={companyData?.serviceArea[0]?.distance ?? 150}
                        data={companyData?.companyInfo?.location}
                        // setData={setData}
                      />
                    </Box>
                    <Box
                      padding="5% 3%"
                      display="flex"
                      justifyContent="space-between"
                      alignItems="center"
                    >
                      <Typography
                        fontSize="16px"
                        fontWeight="600"
                        lineHeight="19px"
                        fontFamily="SF UI Display"
                      >
                        Location
                      </Typography>
                      <Typography
                        fontSize="16px"
                        fontWeight="300"
                        lineHeight="19px"
                        fontFamily="SF UI Display"
                      >
                        {companyData?.companyInfo?.address?.street ??
                          companyData?.companyInfo?.address?.place}
                        ,{' '}
                        {abrev(companyData?.companyInfo?.address?.state) ??
                          companyData?.companyInfo?.address?.region}
                      </Typography>
                    </Box>
                  </Box>
                  <Typography
                    fontFamily="SF UI Display"
                    fontWeight="800"
                    fontSize="20px"
                    lineHeight="23px"
                    color="black"
                    margin={'35px 0 2% 0'}
                  >
                    Company Details
                  </Typography>
                  <Box
                    width="100%"
                    border="solid 1px #E8E8E8"
                    borderRadius="10px"
                    padding="24px 12px"
                    mb={'3%'}
                  >
                    {/* <Typography
                        fontFamily="SF UI Display"
                        fontWeight="800"
                        fontSize="20px"
                        lineHeight="23px"
                        color="black"
                      >
                        Service Area
                      </Typography>
                      <Divider sx={{ bgcolor: '#EBEEF1', width: '100%', margin: '4% 0' }} /> */}
                    {companyData?.companyInfo?.licence && (
                      <>
                        <Box
                          display="flex"
                          justifyContent="space-between"
                          alignItems="center"
                          padding="2% 0"
                          gap={'30px'}
                        >
                          <Typography
                            fontSize="16px"
                            fontWeight="600"
                            lineHeight="19px"
                            fontFamily="SF UI Display"
                          >
                            Contractor License #
                          </Typography>

                          <Typography
                            fontSize="16px"
                            fontWeight="300"
                            lineHeight="19px"
                            fontFamily="SF UI Display"
                          >
                            {companyData?.companyInfo?.licence}
                          </Typography>
                        </Box>
                        <Divider sx={{ bgcolor: '#EBEEF1', width: '100%', margin: '24px 0' }} />
                      </>
                    )}
                    <Box
                      display="flex"
                      justifyContent="space-between"
                      alignItems="center"
                      gap={'30px'}
                      // padding="2% 0"
                    >
                      <Typography
                        fontSize="16px"
                        fontWeight="600"
                        lineHeight="19px"
                        fontFamily="SF UI Display"
                      >
                        Formation
                      </Typography>
                      <Typography
                        fontSize="16px"
                        fontWeight="300"
                        lineHeight="19px"
                        fontFamily="SF UI Display"
                      >
                        {' '}
                        {calculateYears(Number(companyData?.companyInfo?.formation_year))}
                      </Typography>
                    </Box>
                    <Divider sx={{ bgcolor: '#EBEEF1', width: '100%', margin: '24px 0' }} />
                    <Box
                      display="flex"
                      justifyContent="space-between"
                      alignItems="center"
                      gap={'30px'}
                      // padding="2% 0"
                    >
                      <Typography
                        fontSize="16px"
                        fontWeight="600"
                        lineHeight="19px"
                        fontFamily="SF UI Display"
                      >
                        Company Size
                      </Typography>
                      <Typography
                        fontSize="16px"
                        fontWeight="300"
                        lineHeight="19px"
                        fontFamily="SF UI Display"
                        textTransform={'capitalize'}
                      >
                        {companyData?.companyInfo?.company_size}
                      </Typography>
                    </Box>
                    <Divider sx={{ bgcolor: '#EBEEF1', width: '100%', margin: '24px 0' }} />
                    <Box
                      display="flex"
                      justifyContent="space-between"
                      alignItems="center"
                      gap={'30px'}
                      // padding="2% 0"
                    >
                      <Typography
                        fontSize="16px"
                        fontWeight="600"
                        lineHeight="19px"
                        fontFamily="SF UI Display"
                      >
                        Union Shop
                      </Typography>
                      <Typography
                        fontSize="16px"
                        fontWeight="300"
                        lineHeight="19px"
                        fontFamily="SF UI Display"
                      >
                        {companyData?.companyInfo?.union_shop}
                      </Typography>
                    </Box>
                    <Divider sx={{ bgcolor: '#EBEEF1', width: '100%', margin: '24px 0' }} />
                    {companyData?.services?.some(
                      (service: any) => service.service === 'Fabricators',
                    ) && (
                      <Box
                        display="flex"
                        justifyContent="space-between"
                        alignItems="center"
                        gap="10px"
                        padding="2% 0"
                      >
                        <Typography
                          fontSize="16px"
                          fontWeight="600"
                          lineHeight="19px"
                          fontFamily="SF UI Display"
                        >
                          UL Listed
                        </Typography>
                        <Typography
                          fontSize="16px"
                          fontWeight="300"
                          lineHeight="19px"
                          fontFamily="SF UI Display"
                        >
                          {companyData?.companyInfo?.ul_list}
                        </Typography>
                      </Box>
                    )}
                    {companyData?.services?.some(
                      (service: any) => service.service === 'Fabricators',
                    ) && <Divider sx={{ bgcolor: '#EBEEF1', width: '100%', margin: '4% 0' }} />}
                    <Box
                      display="flex"
                      justifyContent="space-between"
                      alignItems="center"
                      gap="10px"
                      padding="2% 0"
                    >
                      <Typography
                        fontSize="16px"
                        fontWeight="600"
                        lineHeight="19px"
                        fontFamily="SF UI Display"
                      >
                        Response Time
                      </Typography>
                      <Typography
                        fontSize="16px"
                        fontWeight="300"
                        lineHeight="19px"
                        fontFamily="SF UI Display"
                      >
                        Typically {companyData?.companyInfo?.responce_time}
                      </Typography>
                    </Box>
                  </Box>
                </>
              )}

              {/* <Box
                sx={{
                  '@media (max-width: 1000px)': {
                    display: 'none',
                  },
                }}
              >
                <Typography
                  fontFamily={'SF UI Display'}
                  fontSize={'20px'}
                  fontStyle={'normal'}
                  lineHeight={'normal'}
                  color={'black'}
                  fontWeight={800}
                  textTransform={'capitalize'}
                >
                  {companyData?.companyInfo?.companyType === 'local'
                    ? 'Local Sign Company Alternatives'
                    : companyData?.companyInfo?.companyType === 'national'
                    ? 'Top National Alternatives'
                    : companyData?.companyInfo?.companyType === 'supplier'
                    ? 'Supplier Alternatives'
                    : 'Distributor Alternatives'}
                </Typography>

                {alternativeCompany.length > 0 ? (
                  alternativeCompany.map((company: any) => (
                    <Box
                      key={company.id}
                      border="1px solid #E8E8E8"
                      borderRadius="10px"
                      mt={3}
                      padding="3% 0% 3% 4%"
                      display="flex"
                      gap="10px"
                      alignItems="center"
                      sx={{ cursor: 'pointer' }}
                      onClick={() => {
                        const paramType =
                          company?.companyInfo?.companyType === 'local'
                            ? 'local-sign-company'
                            : company?.companyInfo?.companyType === 'national'
                            ? 'national-sign-company'
                            : company?.companyInfo?.companyType === 'supplier'
                            ? 'supplier'
                            : 'distributor';
                        if (company?._id) {
                          navigate(
                            `/${paramType}/${formatSearchString(company?.companyInfo?.name)}`,
                            {
                              state: {
                                searchID: company?._id,
                                type: company?.companyInfo?.companyType,
                              },
                            },
                          );
                          request('get', `/companies/${company?._id}`)
                            .then((response: any) => {
                              setCompanyData(response?.company);
                              setalternativeCompany(response?.alternative_companies);
                            })
                            .catch((error: any) => {
                              console.log(error);
                            });
                        }
                      }}
                    >
                      <Box width="57px" height="57px">
                        <img
                          src={
                            company?.companyInfo?.logo
                              ? `${mediaUrl}/${company?.companyInfo?.logo}`
                              : defaultCompany
                          }
                          alt={company?.name || 'Default Company'}
                          style={{ width: '100%', height: '100%', borderRadius: '10px' }}
                        />
                      </Box>
                      <Box width="60%">
                        <Typography
                          fontFamily="SF UI Display"
                          fontSize="16px"
                          fontStyle="normal"
                          fontWeight={700}
                          lineHeight="normal"
                          color="black"
                        >
                          {company?.companyInfo?.name}
                        </Typography>
                        <Typography
                          fontFamily="SF UI Display"
                          fontSize="14px"
                          fontStyle="normal"
                          fontWeight={400}
                          lineHeight="normal"
                          color="#777"
                        >
                          {company?.averageReviewRating?.count ?? '0'}{' '}
                          {company?.averageReviewRating?.count <= 1 ? 'Review' : 'Reviews'}
                        </Typography>
                      </Box>
                      <Box
                        width="45px"
                        height="60px"
                        bgcolor="#F7F7F7"
                        display="flex"
                        justifyContent="center"
                        alignItems="center"
                        flexDirection="column"
                        borderRadius="5px"
                      >
                        <StarIcon
                          sx={{
                            color: !company?.averageReviewRating
                              ? '#9E9E9E'
                              : company?.averageReviewRating?.overall >= 4
                              ? '#00BD57'
                              : company?.averageReviewRating?.overall >= 3
                              ? '#FFB800'
                              : '#FD3E14',
                            fontSize: '24px',
                          }}
                        />
                        <Typography
                          fontFamily="SF UI Display"
                          fontSize="16px"
                          fontStyle="normal"
                          fontWeight={700}
                          lineHeight="normal"
                          color="#000"
                        >
                          {company?.averageReviewRating?.overall?.toFixed(1) ?? '0.0'}
                        </Typography>
                      </Box>
                    </Box>
                  ))
                ) : (
                  <Typography
                    fontFamily="SF UI Display"
                    fontSize="16px"
                    fontStyle="normal"
                    fontWeight={700}
                    lineHeight="normal"
                    color="black"
                    mt={2}
                  >
                    No Companies found
                  </Typography>
                )}
              </Box> */}
            </Box>
          ) : (
            ''
          )}
        </Box>
      ) : (
        <Box padding={'3% 10%'} marginTop={'80px'} position={'relative'}>
          <LinearProgress color="inherit" />
        </Box>
      )}
    </>
  );
};

export default LocalSignCompany;
