import React, { FC, useEffect, useRef, useState } from 'react';
import {
  Avatar,
  Box,
  Button,
  CircularProgress,
  Modal,
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableHead,
  TableRow,
  TextField,
  Typography,
} from '@mui/material';
import DefaultAvatar from '../../../assets/Phase1a/SignUp/avatar.svg';
import CloseIcon from '@mui/icons-material/Close';
import User from '../../../types/user';
import BorderColorIcon from '@mui/icons-material/BorderColorOutlined';
import DeleteForeverOutlinedIcon from '@mui/icons-material/DeleteOutlineOutlined';
import ArrowDownwardOutlinedIcon from '@mui/icons-material/ArrowDownwardOutlined';
import AddIcon from '@mui/icons-material/Add';
import { mediaUrl } from '../../../config';
// import { Link } from 'react-router-dom';
import request from '../../../api/request';
import { toast } from 'react-toastify';
import CustomToast from '../../../components/phase1a/common/CustomToast.component';
import { specialTitles } from '../../../utils/commonData';

interface ITeamMembers {
  teamMembers?: any;
  parsedUser: User;
  getTeamMembersById?: () => void;
}

interface Userr {
  first_name: string;
  last_name: string;
  company?: string;
  email: string;
  photo: string;
  job_title: string;
  claim?: boolean;
}
const TeamMembers: FC<ITeamMembers> = ({ teamMembers, parsedUser, getTeamMembersById }) => {
  const fileInputRef = useRef<HTMLInputElement>(null);
  const [open, setOpen] = useState(false);
  const [selectedUser, setSelectedUser] = useState<any>(null);
  const [openDeleteConfirmation, setOpenDeleteConfirmation] = useState(false);
  const [openAddUserPopup, setOpenAddUserPopup] = useState(false);
  const [isFormValid, setIsFormValid] = useState(false);
  const [loading, setLoading] = useState(false);
  // Function to check form validity
  const checkFormValidity = (): void => {
    if (
      selectedUser?.first_name?.trim() === '' ||
      selectedUser?.last_name?.trim() === '' ||
      selectedUser?.job_title?.trim() === ''
      // Add other validations as needed
    ) {
      setIsFormValid(false);
    } else {
      setIsFormValid(true);
    }
  };
  useEffect(() => {
    checkFormValidity();
  }, [selectedUser]);
  const initialState = {
    first_name: '',
    last_name: '',
    email: '',
    photo: '',
    job_title: '',
    company: parsedUser?.company?._id,
    claim: false,
  };
  const [newUser, setNewUser] = useState<Userr>(initialState);

  console.log('teamMembers', newUser);
  const handleOpenAddUserPopup = (): void => {
    setOpenAddUserPopup(true);
  };

  const handleCloseAddUserPopup = (): void => {
    setOpenAddUserPopup(false);
    setNewUser(initialState);
  };

  const handleAddUser = (): void => {
    console.log('newUser', newUser);
    const emailDomain = newUser.email.split('@')[1].toLowerCase();
    const companyDomain = parsedUser?.company?.companyInfo?.domain.toLowerCase();
    console.log(companyDomain, '= emailDomain =', emailDomain);
    // Compare the email domain with the company's domain
    if (emailDomain !== companyDomain) {
      // toast.error('The email domain does not match the company domain');
      toast(
        <CustomToast message={'The email domain does not match the company domain'} type="error" />,
      );

      return;
    }
    request('post', `users/addMember`, newUser)
      .then((res) => {
        // toast.success('User Added Successfully');
        toast(<CustomToast message={'User Added Successfully'} type="success" />);

        console.log('res', res);
        getTeamMembersById?.();
        handleCloseAddUserPopup();
        // setSelectedCompany('');
      })
      .catch((err) => {
        // toast.error(err?.response?.data?.message);
        toast(<CustomToast message={err?.response?.data?.message} type="error" />);
        console.log('err', err);
      });
  };
  const handleOpenDeleteConfirmation = (user: any): void => {
    setSelectedUser(user);
    setOpenDeleteConfirmation(true);
  };

  const handleCloseDeleteConfirmation = (): void => {
    setSelectedUser(null);
    setOpenDeleteConfirmation(false);
  };

  const handleAvatarClick = (): void => {
    fileInputRef.current?.click();
  };
  // const handleImageUpload = (e: React.ChangeEvent<HTMLInputElement>): void => {
  //   // setLoading(false);
  //   const file = e.target.files?.[0];
  //   if (file) {
  //     const formData = new FormData();
  //     formData.append('photo', file);
  //     request('post', `/uploads`, formData)
  //       .then((res) => {
  //         console.log(res,"res>>")
  //         const imageUrl = (res as string[])[0];

  //         setSelectedUser((prevData: any) => ({
  //           ...prevData,
  //           photo: imageUrl,
  //         }));
  //       })
  //       .catch(() => {
  //         console.error('Image upload error');
  //         // toast.error('error');
  //       });
  //   }
  // };
  const handleImageUpload = (e: React.ChangeEvent<HTMLInputElement>): void => {
    // setLoading(false);
    const file = e.target.files?.[0];
    if (file) {
      const formData = new FormData();
      formData.append('photo', file);
      request('post', `/uploads`, formData)
        .then((res) => {
          const imageUrl = (res as string[])[0];

          setSelectedUser((prevData: any) => ({
            ...prevData,
            photo: imageUrl,
          }));
          setNewUser((prevData: any) => ({
            ...prevData,
            photo: imageUrl,
          }));
        })
        .catch(() => {
          // console.error('Image upload error');
          toast(<CustomToast message={'Image upload error'} type="error" />);
          // toast.error('error');
        });
    }
  };
  console.log('selectedUser', selectedUser);

  const handleOpen = (user: any): void => {
    setSelectedUser(user);
    setOpen(true);
  };

  const handleClose = (): void => {
    setOpen(false);
    setSelectedUser(null);
  };

  const handleInputChange = (e: React.ChangeEvent<HTMLInputElement>): void => {
    const { name, value } = e.target;
    setSelectedUser((prev: any) => {
      return {
        ...prev,
        [name]: value,
      };
    });
  };

  // const handleImageChange = (e: React.ChangeEvent<HTMLInputElement>): void => {
  //   if (e.target.files?.[0]) {

  //       setSelectedUser({ ...selectedUser, photo: e.target?.files[0] });

  //   }
  // };

  const [page, setPage] = useState(1);
  const itemsPerPage = 5;
  const totalPages = Math.ceil(teamMembers?.length / itemsPerPage);

  const handlePreviousPage = (): void => {
    setPage((prevPage) => Math.max(prevPage - 1, 1));
  };

  const handleNextPage = (): void => {
    setPage((prevPage) => Math.min(prevPage + 1, totalPages));
  };

  const startIndex = (page - 1) * itemsPerPage;
  const endIndex = startIndex + itemsPerPage;
  const paginatedRows = teamMembers?.slice(startIndex, endIndex);

  const handleDeleteCompany = (): void => {
    // eslint-disable-next-line @typescript-eslint/restrict-template-expressions
    request('delete', `/users/${selectedUser?._id}`)
      .then((res: any) => {
        console.log('delete user res', res);
        // toast.success('User deleted successfully');
        toast(<CustomToast message={'User deleted successfully'} type="success" />);
        getTeamMembersById?.();
        handleCloseDeleteConfirmation();
      })
      .catch((err) => {
        console.log('err', err);
      });
  };
  console.log(selectedUser, 'selectedUser');
  const validateUser = (): boolean => {
    const emailRegex = /^[\w-]+(\.[\w-]+)*@([\w-]+\.)+[a-zA-Z]{2,7}$/;
    const isValidEmail = emailRegex.test(newUser?.email || '');

    if (!newUser?.first_name?.length) {
      // toast.error('Enter First Name');
      toast(<CustomToast message={'Enter First Name'} type="error" />);
      return false;
    } else if (!newUser?.last_name?.length) {
      // toast.error('Enter Last Name');
      toast(<CustomToast message={'Enter Last Name'} type="error" />);
      return false;
    } else if (!newUser?.email?.length) {
      // toast.error('Enter Email');
      toast(<CustomToast message={'Enter Email'} type="error" />);

      return false;
    } else if (!isValidEmail) {
      // toast.error('Enter Valid Email');
      toast(<CustomToast message={'Enter Valid Email'} type="error" />);

      return false;
    } else if (!newUser?.company?.length) {
      // toast.error('Choose Company');
      toast(<CustomToast message={'Choose Company'} type="error" />);

      return false;
    } else if (!newUser?.job_title?.length) {
      // toast.error('Enter Job Title');
      toast(<CustomToast message={'Enter Job Title'} type="error" />);
      return false;
    }

    return true;
  };
  const handleEditMember = (): void => {
    setLoading(true);
    const body = {
      first_name: selectedUser?.first_name,
      last_name: selectedUser?.last_name,
      job_title: selectedUser?.job_title,
      photo: selectedUser?.photo,
    };
    /* eslint-disable @typescript-eslint/restrict-template-expressions */
    console.log('bodybody', body);
    request('put', `/users/${selectedUser?._id}`, body)
      .then((res: any) => {
        // toast.success('Profile Updated Successfully');
        toast(<CustomToast message={'Profile Updated Successfully'} type="success" />);
        console.log('res', res);
        setSelectedUser((prev: any) => ({
          ...prev,
          first_name: res.user.first_name,
          last_name: res.user.last_name,
          job_title: res.user.job_title,
          photo: res.user.photo,
        }));
        parsedUser.first_name = res.user.first_name;
        parsedUser.last_name = res.user.last_name;
        parsedUser.photo = res.user.photo;
        parsedUser.job_title = res.user.job_title;
        localStorage.setItem('user', JSON.stringify(parsedUser));
        getTeamMembersById?.();
        handleClose();
        setLoading(false);
      })
      .catch((err) => {
        // toast.error(err?.response?.data?.message);
        toast(<CustomToast message={err?.response?.data?.message} type="success" />);
        setLoading(false);
        console.log('err', err);
      });
  };
  useEffect(() => {
    setNewUser((prevReview) => ({
      ...prevReview,
      company: parsedUser?.company?._id,
    }));
  }, [parsedUser?.company?._id]);
  useEffect(() => {
    getTeamMembersById?.();
  }, []);

  return (
    <Box
      width={'100%'}
      marginBottom={'2%'}
      borderRadius={'12px'}
      border={'1px solid #EAECF0'}
      bgcolor={'#FFF'}
      boxShadow={'0px 1px 2px 0px rgba(16, 24, 40, 0.05)'}
      minHeight={'50vh'}
      display={'flex'}
      flexDirection={'column'}
      justifyContent={'space-between'}
    >
      <Box width={'100%'}>
        <Box
          display={'flex'}
          width={'100%'}
          padding={'20px 24px'}
          borderBottom={'1px solid #EAECF0'}
          alignItems={'center'}
          justifyContent={'space-between'}
        >
          <Box>
            <Typography
              color={'#101828'}
              fontFamily={'SF UI Display'}
              fontSize={'18px'}
              fontWeight={'700'}
              lineHeight={'28px'}
            >
              Team members
            </Typography>
          </Box>
          {parsedUser?.type === 'owner' ? (
            <Button
              sx={{
                bgcolor: 'black',
                border: '1px solid #000000',
                borderRadius: '8px',
                padding: '10px 14px',
                color: '#ffffff',
                textTransform: 'capitalize',
                fontSize: '14px',
                fontWeight: '600',
                lineHeight: '20px',
                display: 'flex',
                alignItems: 'center',
                flexDirection: 'row-reverse',
                gap: '5px',
                '&:hover': {
                  bgcolor: 'black',
                },
              }}
              onClick={handleOpenAddUserPopup}
            >
              Add User
              <AddIcon />
            </Button>
          ) : (
            ''
          )}
        </Box>
        <Box width={'100%'}>
          <TableContainer>
            <Table aria-label="simple table">
              <TableHead>
                <TableRow>
                  <TableCell
                    sx={{
                      width: '30%',
                      fontSize: '12px',
                      fontWeight: '500',
                      lineHeight: '18px',
                      color: '#475467',
                      fontFamily: 'SF UI Display',
                      whiteSpace: 'nowrap',
                      padding: '0 24px',
                    }}
                  >
                    Team members
                  </TableCell>
                  <TableCell
                    sx={{
                      width: '10%',
                      fontSize: '12px',
                      fontWeight: '500',
                      lineHeight: '18px',
                      color: '#475467',
                      fontFamily: 'SF UI Display',
                      whiteSpace: 'nowrap',

                      gap: '20px',
                    }}
                  >
                    Status
                    <ArrowDownwardOutlinedIcon sx={{ fontSize: '15px', ml: 1 }} />
                  </TableCell>
                  <TableCell
                    sx={{
                      width: '30%',
                      fontSize: '12px',
                      fontWeight: '500',
                      lineHeight: '18px',
                      color: '#475467',
                      fontFamily: 'SF UI Display',
                      whiteSpace: 'nowrap',
                    }}
                  >
                    Email address
                  </TableCell>
                  <TableCell
                    sx={{
                      width: '30%',
                      fontSize: '12px',
                      fontWeight: '500',
                      lineHeight: '18px',
                      color: '#475467',
                      fontFamily: 'SF UI Display',
                      whiteSpace: 'nowrap',
                    }}
                  >
                    Role
                  </TableCell>
                </TableRow>
              </TableHead>
              {paginatedRows?.length ? (
                <TableBody sx={{ widows: '100%' }}>
                  {paginatedRows?.map((item: any, index: number) => (
                    <TableRow
                      key={index}
                      sx={{
                        '&:last-child td, &:last-child th': {
                          borderBottom: '1px solid rgba(224, 224, 224, 1)',
                        },
                        '&:hover': { backgroundColor: '#FADE701A' },
                      }}
                    >
                      <TableCell
                        sx={{
                          width: '30%',
                          fontSize: '14px',
                          fontWeight: '500',
                          color: '#101828',
                          fontFamily: 'Urbanist',
                          whiteSpace: 'nowrap',
                          padding: '16px 24px',
                        }}
                        component="th"
                        scope="row"
                      >
                        <Box width={'100%'} display={'flex'} alignItems={'center'} gap={'12px'}>
                          <Box
                            display={'flex'}
                            justifyContent={'flex-start'}
                            alignItems={'center'}
                            gap={'7px'}
                          >
                            <Avatar
                              // eslint-disable-next-line @typescript-eslint/restrict-template-expressions
                              src={item.photo ? `${mediaUrl}/${item?.photo}` : DefaultAvatar}
                            />
                            <Box>
                              <Typography
                                color={'#101828'}
                                fontFamily={'SF UI Display'}
                                fontSize={'14px'}
                                fontWeight={'500'}
                                lineHeight={'20px'}
                              >
                                {item.first_name} {item?.last_name}
                              </Typography>
                              <Typography
                                color={'#475467'}
                                fontFamily={'SF UI Display'}
                                fontSize={'12px'}
                                fontWeight={'400'}
                                lineHeight={'20px'}
                                sx={{
                                  textTransform: specialTitles.includes(
                                    item?.job_title.toLowerCase(),
                                  )
                                    ? 'uppercase'
                                    : 'capitalize',
                                }}
                              >
                                {item?.job_title ? item.job_title : 'NA'}
                              </Typography>
                            </Box>
                          </Box>
                        </Box>
                      </TableCell>
                      <TableCell
                        sx={{
                          width: '10%',
                          fontSize: '14px',
                          fontWeight: '500',
                          color: '#101828',
                          fontFamily: 'Urbanist',
                        }}
                      >
                        <Box
                          display={'flex'}
                          alignItems={'center'}
                          gap={'4px'}
                          border={
                            item?.status === 'Offline' ? '1px solid black' : '1px solid black'
                          }
                          borderRadius={'16px'}
                          padding={'2px 6px'}
                          width={'fit-content'}
                          bgcolor={item?.status === 'Offline' ? 'transparent' : 'black'}
                        >
                          <Box
                            width={'8px'}
                            height={'8px'}
                            borderRadius={'4px'}
                            bgcolor={item?.status === 'Offline' ? 'black' : 'white'}
                          />
                          <Typography
                            color={item?.status === 'Offline' ? 'black' : 'white'}
                            fontFamily={'SF UI Display'}
                            fontSize={'12px'}
                            fontWeight={'500'}
                            textTransform={'capitalize'}
                            lineHeight={'16px'}
                          >
                            {item?.status === 'Offline' ? item?.status : 'Active'}
                          </Typography>
                        </Box>
                      </TableCell>
                      <TableCell
                        sx={{
                          width: '30%',
                          fontSize: '14px',
                          fontWeight: '500',
                          color: '#101828',
                          fontFamily: 'SF UI Display',
                        }}
                      >
                        {item.email}
                      </TableCell>
                      <TableCell
                        sx={{
                          width: '30%',
                          fontSize: '14px',
                          fontWeight: '500',
                          color: '#101828',
                          fontFamily: 'Urbanist',
                        }}
                      >
                        <Box
                          width={'100%'}
                          display={'flex'}
                          alignItems={'center'}
                          justifyContent={'space-between'}
                          gap={'15px'}
                        >
                          <Box
                            display={'flex'}
                            justifyContent={'flex-start'}
                            alignItems={'center'}
                            gap={'4px'}
                            padding={'4px 10px'}
                            border={'1px solid #101828'}
                            borderRadius={'16px'}
                            whiteSpace={'nowrap'}
                          >
                            <Typography
                              color={'#101828'}
                              fontFamily={'SF UI Display'}
                              fontSize={'12px'}
                              fontWeight={'500'}
                              lineHeight={'16px'}
                              textTransform={'capitalize'}
                            >
                              {item.type === 'owner'
                                ? 'Page owner'
                                : item.type === 'member'
                                ? 'Team member'
                                : item.type}
                            </Typography>
                          </Box>
                          {parsedUser?.type === 'owner' ? (
                            <>
                              <Box display={'flex'} alignItems={'center'} gap={'5px'}>
                                <Box
                                  onClick={() => {
                                    handleOpen(item);
                                  }}
                                  sx={{
                                    width: '24px',
                                    height: '24px',
                                    borderRadius: '5px',
                                    // bgcolor: '#F2F4F7',
                                    display: 'flex',
                                    justifyContent: 'center',
                                    alignItems: 'center',
                                    cursor: 'pointer',
                                    // '&:hover': {
                                    //   backgroundColor: 'skyblue',
                                    //   '& svg': {
                                    //     color: 'white',
                                    //   },
                                    // },
                                  }}
                                >
                                  <BorderColorIcon sx={{ color: 'black', fontSize: '18px' }} />
                                </Box>
                                <Box
                                  sx={{
                                    width: '24px',
                                    height: '24px',
                                    borderRadius: '5px',
                                    // bgcolor: '#F2F4F7',
                                    display: 'flex',
                                    justifyContent: 'center',
                                    alignItems: 'center',
                                    cursor: 'pointer',
                                    // '&:hover': {
                                    //   backgroundColor: 'red',
                                    //   '& svg': {
                                    //     color: 'white',
                                    //   },
                                    // },
                                  }}
                                >
                                  <DeleteForeverOutlinedIcon
                                    onClick={() => {
                                      handleOpenDeleteConfirmation(item);
                                    }}
                                    sx={{ color: 'black', fontSize: '18px' }}
                                  />
                                </Box>
                              </Box>
                            </>
                          ) : (
                            ''
                          )}
                        </Box>
                      </TableCell>
                    </TableRow>
                  ))}
                </TableBody>
              ) : (
                <Box
                  minHeight={'45vh'}
                  display={'flex'}
                  alignItems={'center'}
                  justifyContent={'center'}
                >
                  <CircularProgress sx={{ color: '#101828' }} />
                </Box>
              )}
            </Table>
          </TableContainer>
        </Box>
      </Box>

      <Box
        display={'flex'}
        justifyContent={'space-between'}
        alignItems={'center'}
        padding={'20px 24px'}
        borderTop={'1px solid #EAECF0'}
      >
        <Box display={'flex'} alignItems={'center'} gap={'12px'}>
          <Button
            variant="outlined"
            onClick={handlePreviousPage}
            disabled={page === 1}
            sx={{
              backgroundColor: 'transparent',
              border: '1px solid #D0D5DD',
              fontSize: '14px',
              fontWeight: '600',
              lineHeight: '20px',
              color: '#344054',
              padding: '8px 12px',
              borderRadius: '8px',
              textTransform: 'capitalize',
              '&.Mui-disabled': {
                borderColor: '#E0E0E0',
                color: '#E0E0E0',
              },
            }}
          >
            Previous
          </Button>
          <Button
            variant="outlined"
            onClick={handleNextPage}
            disabled={page === totalPages}
            sx={{
              backgroundColor: 'transparent',
              border: '1px solid #D0D5DD',
              fontSize: '14px',
              fontWeight: '600',
              lineHeight: '20px',
              color: '#344054',
              padding: '8px 12px',
              borderRadius: '8px',
              textTransform: 'capitalize',
              '&.Mui-disabled': {
                borderColor: '#E0E0E0',
                color: '#E0E0E0',
              },
            }}
          >
            Next
          </Button>
        </Box>
        Page {page} of {Math.ceil(teamMembers?.length / itemsPerPage)}
      </Box>

      <Modal open={open} onClose={handleClose}>
        <Box
          sx={{
            position: 'absolute',
            top: '50%',
            left: '50%',
            transform: 'translate(-50%, -50%)',
            width: '50%',
            bgcolor: 'background.paper',
            boxShadow: 24,
            p: 4,
            borderRadius: '12px',
            '@media (max-width: 900px)': {
              width: '60%',
            },
            '@media (max-width: 750px)': {
              width: '70%',
            },
            '@media (max-width: 600px)': {
              width: '80%',
            },
            '@media (max-width: 500px)': {
              width: '90%',
            },
          }}
        >
          <Box
            width={'100%'}
            display={'flex'}
            justifyContent={'space-between'}
            alignItems={'center'}
            mb={3}
          >
            <Typography variant="h6" component="h2" fontFamily={'SF UI Display'} fontWeight={'700'}>
              Edit User
            </Typography>
            <CloseIcon onClick={handleClose} sx={{ cursor: 'pointer' }} />
          </Box>
          <Box
            mt={3}
            mb={3}
            margin="normal"
            marginBottom={3}
            display={'flex'}
            alignItems={'center'}
            gap={'10px'}
          >
            <Avatar
              onClick={handleAvatarClick}
              style={{ cursor: 'pointer', width: 56, height: 56, marginRight: 2 }}
              src={selectedUser?.photo ? `${mediaUrl}/${selectedUser?.photo}` : DefaultAvatar}
            />
            {/* {user?.photo && (
            <img
              src={CloseIconBg}
              alt="Remove"
              onClick={handleRemoveImage}
              style={{
                position: 'absolute',
                top: 0,
                left: '71px',
                width: '30px',
                height: '30px',
                zIndex: 1,
                cursor: 'pointer',
              }}
            />
          )} */}
            <input
              type="file"
              ref={fileInputRef}
              style={{ display: 'none' }}
              onChange={handleImageUpload}
              accept="image/*"
            />
            <Button
              variant="contained"
              component="label"
              sx={{
                width: '150px',
                textTransform: 'capitalize',
                bgcolor: '#00BD57',
                '&:hover': {
                  bgcolor: '#00BD57',
                },
              }}
            >
              Change Photo
              <input type="file" accept="image/*" hidden onChange={handleImageUpload} />
            </Button>
          </Box>
          <TextField
            label="First Name"
            name="first_name"
            value={selectedUser?.first_name || ''}
            onChange={handleInputChange}
            fullWidth
            margin="normal"
          />
          <TextField
            label="Last Name"
            name="last_name"
            value={selectedUser?.last_name || ''}
            onChange={handleInputChange}
            fullWidth
            margin="normal"
          />
          <TextField
            label="Job Title"
            name="job_title"
            value={selectedUser?.job_title || ''}
            onChange={handleInputChange}
            fullWidth
            margin="normal"
          />

          <Button
            variant="contained"
            color="primary"
            sx={{
              width: '20%',
              textTransform: 'capitalize',
              bgcolor: '#00BD57',
              mt: 3,
              '&:hover': {
                bgcolor: '#00BD57',
              },
            }}
            onClick={() => {
              // Save changes logic here
              // handleClose();
              handleEditMember();
            }}
            disabled={!isFormValid}
          >
            {loading ? <CircularProgress size={15} color="inherit" /> : 'Update'}
          </Button>
        </Box>
      </Modal>
      {/* delete popup */}
      <Modal open={openDeleteConfirmation} onClose={handleCloseDeleteConfirmation}>
        <Box
          sx={{
            position: 'absolute',
            top: '50%',
            left: '50%',
            transform: 'translate(-50%, -50%)',
            width: 400,
            bgcolor: 'background.paper',
            boxShadow: 24,
            p: 4,
            borderRadius: '12px',
          }}
        >
          <Typography variant="h6" gutterBottom>
            Are you sure you want to delete?
          </Typography>
          <Box
            mt={2}
            width={'100%'}
            display={'flex'}
            justifyContent="center"
            alignItems={'center'}
            gap={'20px'}
          >
            <Button
              onClick={handleCloseDeleteConfirmation}
              variant="outlined"
              color="primary"
              sx={{
                textTransform: 'capitalize',
                border: 'solid 1px black',
                color: 'black',
                bgcolor: 'white',
                '&:hover': {
                  bgcolor: 'white',
                  border: 'solid 1px black',
                },
              }}
            >
              Cancel
            </Button>
            <Button
              variant="contained"
              color="secondary"
              sx={{
                textTransform: 'capitalize',
                border: 'solid 1px red',
                color: 'white',
                bgcolor: 'red',
                '&:hover': {
                  bgcolor: 'red',
                  border: 'solid 1px red',
                },
              }}
              onClick={handleDeleteCompany}
            >
              Delete
            </Button>
          </Box>
        </Box>
      </Modal>
      {/* add user popup */}
      <Modal open={openAddUserPopup} onClose={handleCloseAddUserPopup}>
        <Box
          sx={{
            position: 'absolute',
            top: '50%',
            left: '50%',
            transform: 'translate(-50%, -50%)',
            width: '50%',
            bgcolor: 'background.paper',
            boxShadow: 24,
            p: 4,
            borderRadius: '12px',
            '@media (max-width: 900px)': {
              width: '60%',
            },
            '@media (max-width: 750px)': {
              width: '70%',
            },
            '@media (max-width: 600px)': {
              width: '80%',
            },
            '@media (max-width: 500px)': {
              width: '90%',
            },
          }}
        >
          <Box display={'flex'} justifyContent={'space-between'} alignItems={'center'}>
            <Typography variant="h6" gutterBottom fontFamily={'SF UI Display'} fontWeight={'700'}>
              Add User
            </Typography>
            <CloseIcon
              onClick={handleCloseAddUserPopup}
              sx={{
                cursor: 'pointer',
              }}
            />
          </Box>
          <Box
            mt={3}
            mb={3}
            margin="normal"
            marginBottom={3}
            display={'flex'}
            alignItems={'center'}
            gap={'10px'}
          >
            <Avatar
              onClick={handleAvatarClick}
              style={{ cursor: 'pointer', width: 56, height: 56, marginRight: 2 }}
              // eslint-disable-next-line @typescript-eslint/restrict-template-expressions
              src={
                selectedUser?.photo
                  ? typeof selectedUser?.photo === 'object'
                    ? URL.createObjectURL(selectedUser?.photo)
                    : `${mediaUrl}/${selectedUser?.photo}`
                  : DefaultAvatar
              }
            />
            <input
              type="file"
              ref={fileInputRef}
              style={{ display: 'none' }}
              onChange={handleImageUpload}
              accept="image/*"
            />
            <Button
              variant="contained"
              component="label"
              sx={{
                width: '150px',
                textTransform: 'capitalize',
                bgcolor: '#00BD57',
                '&:hover': {
                  bgcolor: '#00BD57',
                },
              }}
            >
              Change Photo
              <input type="file" accept="image/*" hidden onChange={handleImageUpload} />
            </Button>
          </Box>
          <TextField
            label="First Name"
            variant="outlined"
            fullWidth
            value={newUser.first_name}
            onChange={(e) => {
              setNewUser({ ...newUser, first_name: e.target.value });
            }}
            style={{ marginBottom: '16px' }}
          />
          <TextField
            label="Last Name"
            variant="outlined"
            fullWidth
            value={newUser.last_name}
            onChange={(e) => {
              setNewUser({ ...newUser, last_name: e.target.value });
            }}
            style={{ marginBottom: '16px' }}
          />
          <TextField
            label="Job Title"
            variant="outlined"
            fullWidth
            value={newUser.job_title}
            onChange={(e) => {
              setNewUser({ ...newUser, job_title: e.target.value });
            }}
            style={{ marginBottom: '16px' }}
          />
          <TextField
            label="Email"
            variant="outlined"
            fullWidth
            value={newUser.email}
            onChange={(e) => {
              setNewUser({ ...newUser, email: e.target.value });
            }}
            InputProps={{
              inputProps: {
                pattern: '[a-zA-Z0-9._%+-]+@[a-zA-Z0-9.-]+\\.[a-zA-Z]{2,}',
                title: 'Enter a valid email address',
              },
            }}
            style={{ marginBottom: '16px' }}
          />

          <Box mt={2}>
            <Button
              // onClick={handleAddUser}
              onClick={() => {
                if (validateUser()) {
                  handleAddUser();
                  handleClose();
                }
              }}
              variant="contained"
              sx={{
                width: '20%',
                textTransform: 'capitalize',
                bgcolor: '#00BD57',
                '&:hover': {
                  bgcolor: '#00BD57',
                },
              }}
            >
              Save
            </Button>
          </Box>
        </Box>
      </Modal>
    </Box>
  );
};

export default TeamMembers;
