import React, { FC, useEffect, useState } from 'react';
import {
  Autocomplete,
  Box,
  Button,
  CircularProgress,
  InputAdornment,
  TextField,
  Typography,
} from '@mui/material';
import Success from '../../../assets/Phase1a/DesignUpdate/toastSuccess.svg';
import AddIcon from '../../../assets/Phase1a/addBusiness/add-jobtitle.svg';
import request from '../../../api/request';
import { toast } from 'react-toastify';
import CustomToast from '../common/CustomToast.component';
import { specialTitles } from '../../../utils/commonData';
// import { checkUserExistsByEmail } from '../../../utils/emailChecker';

interface userFormProps {
  loading?: any;
  account: any;
  userData: any;
  companyId?: any;
  setAccount: React.Dispatch<React.SetStateAction<any>>;
  setUserData?: React.Dispatch<React.SetStateAction<any>>;
  onNextClick?: () => void;
  handleCancelClick?: () => void;
}
// const JobTitles = ['CEO', 'Manager', 'Dev'];
// Custom Toast Component
const UserForm: FC<userFormProps> = ({
  userData,
  loading,
  account,
  setAccount,
  companyId,
  onNextClick,
  handleCancelClick,
  setUserData,
}) => {
  const [jobTitleSelected, setJobTitleSelected] = useState(
    account?.user?.job_title?.length && true,
  );
  console.log('jobTitleSelected', jobTitleSelected);
  // const [userExist, setUserExist] = useState(false);
  // console.log('userExist', userExist);
  const [dropdownOpen, setDropdownOpen] = useState(false);
  // const [domainAvailable, setDomainAvailable] = useState(true);
  // const [domainCompany, setDomainCompany] = useState<any>([]);
  const [jobTitles, setJobTitles] = useState<any>([]);
  console.log('jobTitles', jobTitles);
  const validateEmail = (email: string): boolean => {
    // Simple email validation regex pattern
    const emailRegex = /^[^\s@]+@[^\s@]+\.[^\s@]+$/;
    return emailRegex.test(email);
  };

  const getJobTitles = (): void => {
    /* eslint-disable @typescript-eslint/restrict-template-expressions */
    request('get', `/users/getUserJobTitles`)
      .then((res: any) => {
        const uniqueJobTitles = Array.from(
          new Set(
            res?.jobTitles
              ?.filter((title: string) => title && title.trim().length > 0)
              .map((title: string) => title.toLowerCase()),
          ),
        );
        setJobTitles(uniqueJobTitles);
        // const filteredJobTitles = res?.jobTitles?.filter(
        //   (title: string) => title && title.trim().length > 0,
        // );
        // setJobTitles(filteredJobTitles);
      })
      .catch((err) => {
        console.log(err);
      });
  };

  const emailDomainsVerify = [
    'gmail.com',
    'outlook.com',
    'yahoo.com',
    'icloud.com',
    'aol.com',
    'protonmail.com',
    'zoho.com',
    'mail.com',
    'yandex.com',
    'gmx.com',
  ];

  const checkDomain = (): void => {
    if (!jobTitleSelected) {
      showToast('Select or add job title', 'error');
      return;
    }
    if (
      companyId?.length &&
      account?.company?.companyInfo?.domain !== account?.user?.email?.split?.('@')[1]
    ) {
      showToast(`Email should be of domain ${account?.company?.companyInfo?.domain}`, 'error');
      return;
    }

    const email = account?.user?.email;

    if (!validateEmail(email)) {
      showToast(`Invalid email`, 'error');
      return;
    }
    // const emailDomain = extractDomainFromEmail(email);

    if (!companyId?.length && !emailDomainsVerify.includes(email?.split?.('@')[1])) {
      request('get', `/companies/checkUserAndCompnayDomain/${email}`)
        .then(() => {
          validateNext();
        })
        .catch((err) => {
          console.log(err, 'err.response');
          showToast(err.response.data.message, 'error');
        });
    } else {
      validateNext();
    }
  };

  const showToast = (message: string, type: 'error' | 'success'): void => {
    toast(<CustomToast message={message} type={type} />);
  };

  const validateNext = (): void => {
    // Validate domain against company info

    // // Check if the domain already exists
    // if (!companyId?.length && domainCompany[0]?.companyInfo?.domain === domain) {
    //   showToast('A company with this domain already exists.', 'error');
    //   return;
    // }

    // // Check if user already exists
    // if (!companyId?.length && checkUserExistsByEmail(account?.user?.email)) {
    //   showToast('User already exists', 'error');
    //   return;
    // }

    // Update account with the current domain
    setAccount?.({
      ...account,
      company: {
        ...account?.company,
        companyInfo: {
          ...account?.company?.companyInfo,
          domain: account?.user?.email?.split?.('@')[1],
        },
      },
    });
    onNextClick?.();
  };

  useEffect(() => {
    getJobTitles();
    window.scrollTo(0, 0);
  }, []);
  // useEffect(() => {
  //   if (companyId?.length) return;
  //   if (domainCompany[0]?.companyInfo?.domain === domain) {
  //     console.log('domainCompany', domainCompany, domain);
  //     setDomainAvailable(false);
  //     toast('company with this domain already exist');
  //   } else {
  //     setDomainAvailable(true);
  //   }
  // }, [domainCompany]);
  // useEffect(() => {
  //   if (!companyId?.length) return;
  //   if (account?.company?.companyInfo?.domain === domain) {
  //     console.log('domainCompany', domainCompany, domain);
  //     setDomainAvailable(true);
  //   } else {
  //     toast(`email should be of domain ${account?.company?.companyInfo?.domain}`);
  //     setDomainAvailable(false);
  //   }
  // }, [domain, account?.user?.email]);
  const buttonDisable = (): boolean => {
    return (
      !account?.user?.first_name?.length ||
      !account?.user?.last_name?.length ||
      !validateEmail(account?.user?.email) ||
      // !domainAvailable ||
      // !jobTitleSelected ||
      !account?.user?.job_title?.length ||
      account?.user?.password?.length <= 7 ||
      loading
    );
  };
  return (
    <Box width={'100%'} display={'flex'} alignItems={'center'} justifyContent={'center'}>
      <Box
        width={'50%'}
        borderRadius={'10px'}
        bgcolor={'#FFF'}
        boxShadow={'0px 1px 4px 0px rgba(148, 148, 148, 0.25)'}
        padding={'3%'}
        display={'flex'}
        justifyContent={'center'}
        sx={{
          '@media (max-width: 1200px)': {
            width: '60%',
          },
          '@media (max-width: 900px)': {
            width: '75%',
          },
          '@media (max-width: 768px)': {
            width: '85%',
            padding: '20px',
          },
          '@media (max-width: 600px)': {
            width: '100%',
          },
        }}
      >
        <Box width={'100%'} display={'flex'} flexDirection={'column'} gap={'10px'}>
          <Typography
            color={'#00BD57'}
            fontFamily={'SF UI Display'}
            fontSize={'16px'}
            fontWeight={'700'}
            lineHeight={'normal'}
            textTransform={'uppercase'}
          >
            Account
          </Typography>
          <Typography
            color={'#000000'}
            fontFamily={'SF UI Display'}
            fontSize={'24px'}
            fontWeight={'800'}
            lineHeight={'normal'}
            sx={{
              '@media (max-width: 600px)': {
                fontSize: '22px',
              },
            }}
          >
            Welcome! Let’s create your account.
          </Typography>
          <Typography
            color={'#000000'}
            fontFamily={'SF UI Display'}
            fontSize={'16px'}
            fontWeight={'400'}
            lineHeight={'20px'}
            sx={{
              '@media (max-width: 600px)': {
                fontSize: '15px',
              },
            }}
          >
            This will allow you to manage your page, respond to reviews and connect with others.
          </Typography>
          <Box
            width={'100%'}
            display={'flex'}
            justifyContent={'space-between'}
            flexWrap={'wrap'}
            margin={'15px 0'}
          >
            <Box
              width={'47%'}
              margin={'12px 0'}
              sx={{
                '@media (max-width: 520px)': {
                  width: '100%',
                },
              }}
            >
              <Typography
                color={'#000000'}
                fontFamily={'SF UI Display'}
                fontSize={'14px'}
                fontWeight={'500'}
                lineHeight={'20px'}
              >
                First Name{' '}
                <span
                  style={{
                    // color: !account?.user?.first_name?.length ? 'red' : '#00BD57',
                    color: '#00BD57',
                  }}
                >
                  *
                </span>
              </Typography>
              <TextField
                fullWidth
                value={account?.user?.first_name}
                onChange={(e) => {
                  setAccount?.({
                    ...account,
                    user: {
                      ...account?.user,
                      first_name: e.target.value,
                    },
                  });
                }}
                inputProps={{
                  maxLength: 50, // Character limit set here
                }}
                sx={{
                  borderRadius: '8px',
                  border: '1px solid #D0D5DD',
                  background: '#FFF',
                  boxShadow: '0px 1px 2px 0px rgba(16, 24, 40, 0.05)',
                  display: 'flex',
                  alignItems: 'center',
                  gap: '8px',
                  marginTop: '6px',
                  '.MuiOutlinedInput-input': {
                    padding: '8px 12px',
                  },
                  '& .MuiOutlinedInput-root': {
                    '& fieldset': {
                      border: '1px solid transparent',
                      outline: 'none',
                    },
                    '&:hover fieldset': {
                      borderColor: 'transparent',
                    },
                    '&.Mui-focused fieldset': {
                      borderColor: 'transparent',
                    },
                    '&.MuiInputBase-root:focus-visible': {
                      borderColor: 'transparent',
                    },
                  },
                }}
              />
            </Box>
            <Box
              width={'47%'}
              margin={'12px 0'}
              sx={{
                '@media (max-width: 520px)': {
                  width: '100%',
                },
              }}
            >
              <Typography
                color={'#000000'}
                fontFamily={'SF UI Display'}
                fontSize={'14px'}
                fontWeight={'500'}
                lineHeight={'20px'}
              >
                Last Name{' '}
                <span
                  style={{
                    // color: !account?.user?.last_name?.length ? 'red' : '#00BD57',
                    color: '#00BD57',
                  }}
                >
                  *
                </span>
              </Typography>
              <TextField
                fullWidth
                inputProps={{
                  maxLength: 50, // Character limit set here
                }}
                value={account?.user?.last_name}
                onChange={(e) => {
                  setAccount?.({
                    ...account,
                    user: {
                      ...account?.user,
                      last_name: e.target.value,
                    },
                  });
                }}
                sx={{
                  borderRadius: '8px',
                  border: '1px solid #D0D5DD',
                  background: '#FFF',
                  boxShadow: '0px 1px 2px 0px rgba(16, 24, 40, 0.05)',
                  display: 'flex',
                  alignItems: 'center',
                  gap: '8px',
                  marginTop: '6px',
                  '.MuiOutlinedInput-input': {
                    padding: '8px 12px',
                  },
                  '& .MuiOutlinedInput-root': {
                    '& fieldset': {
                      border: '1px solid transparent',
                      outline: 'none',
                    },
                    '&:hover fieldset': {
                      borderColor: 'transparent',
                    },
                    '&.Mui-focused fieldset': {
                      borderColor: 'transparent',
                    },
                    '&.MuiInputBase-root:focus-visible': {
                      borderColor: 'transparent',
                    },
                  },
                }}
              />
            </Box>{' '}
            <Box
              width={'47%'}
              margin={'12px 0'}
              sx={{
                '@media (max-width: 520px)': {
                  width: '100%',
                },
              }}
            >
              <Typography
                color={'#000000'}
                fontFamily={'SF UI Display'}
                fontSize={'14px'}
                fontWeight={'500'}
                lineHeight={'20px'}
              >
                Work Email{' '}
                <span
                  style={{
                    // color: !validateEmail(account?.user?.email) ? 'red' : '#00BD57'
                    color: '#00BD57',
                  }}
                >
                  *
                </span>
              </Typography>
              <TextField
                fullWidth
                type="email"
                sx={{
                  borderRadius: '8px',
                  border: '1px solid #D0D5DD',
                  background: '#FFF',
                  boxShadow: '0px 1px 2px 0px rgba(16, 24, 40, 0.05)',
                  display: 'flex',
                  alignItems: 'center',
                  gap: '8px',
                  marginTop: '6px',
                  '.MuiOutlinedInput-input': {
                    padding: '8px 12px',
                  },
                  '& .MuiOutlinedInput-root': {
                    '& fieldset': {
                      border: '1px solid transparent',
                      outline: 'none',
                    },
                    '&:hover fieldset': {
                      borderColor: 'transparent',
                    },
                    '&.Mui-focused fieldset': {
                      borderColor: 'transparent',
                    },
                    '&.MuiInputBase-root:focus-visible': {
                      borderColor: 'transparent',
                    },
                  },
                }}
                name="email"
                value={account?.user?.email}
                onChange={(e) => {
                  setAccount?.({
                    ...account,
                    user: {
                      ...account?.user,
                      email: e.target.value,
                    },
                  });
                }}
              />
            </Box>{' '}
            <Box
              width={'47%'}
              margin={'12px 0'}
              sx={{
                '@media (max-width: 520px)': {
                  width: '100%',
                },
              }}
            >
              <Typography
                color={'#000000'}
                fontFamily={'SF UI Display'}
                fontSize={'14px'}
                fontWeight={'500'}
                lineHeight={'20px'}
              >
                Job Title{' '}
                <span
                  style={{
                    color: '#00BD57',
                    // color: !jobTitleSelected ? 'red' : '#00BD57'
                  }}
                >
                  *
                </span>
              </Typography>
              <Autocomplete
                fullWidth
                freeSolo
                disableClearable
                autoComplete={false}
                open={dropdownOpen} // Control dropdown state
                onOpen={() => {
                  setDropdownOpen(true);
                }} // Open the dropdown
                onClose={() => {
                  setDropdownOpen(false);
                }} // Close the dropdown
                filterOptions={(options, state) => {
                  if (state?.inputValue === '') {
                    return []; // Return an empty array if the input is empty
                  }
                  // Check if the entered value exists in the options
                  const inputValue = state.inputValue.toLowerCase();
                  const existsInOptions = options.some(
                    (option) => option.toLowerCase() === inputValue,
                  );

                  // Show "Add New" if the value doesn't exist in the options
                  if (!existsInOptions && inputValue.length > 0) {
                    return [
                      '__add_new__',
                      ...options.filter((option) => option.toLowerCase().includes(inputValue)),
                    ];
                  }

                  // Otherwise, return filtered options normally
                  return options.filter((option) => option.toLowerCase().includes(inputValue));
                }}
                options={jobTitles} // Insert the "Add New" option at the start
                getOptionLabel={(option: string | any) => {
                  if (option === '__add_new__') {
                    return ''; // Return empty string for label rendering
                  }
                  return typeof option === 'object' ? option.name : option;
                }}
                value={account?.user?.job_title}
                onChange={(event, value) => {
                  setAccount?.({
                    ...account,
                    user: {
                      ...account?.user,
                      job_title: value,
                    },
                  });
                  setJobTitleSelected(true);
                }}
                renderOption={(props: any, option) => {
                  console.log('props', props);
                  const inputValue = account?.user?.job_title?.toLowerCase() || '';
                  if (
                    option === '__add_new__' &&
                    !jobTitles.some((title: any) => title.toLowerCase() === inputValue)
                  ) {
                    return (
                      <Box
                        key={props?.key} // Explicitly pass key
                        {...props} // Pass className explicitly
                        padding={'15px !important'}
                        sx={{
                          display: 'flex',
                          justifyContent: 'center',
                          cursor: 'pointer',
                          gap: '10px',
                          lineHeight: 'normal',
                        }}
                        onClick={(e: any) => {
                          e.stopPropagation(); // Ensure dropdown doesn't close
                          setJobTitleSelected(true); // Mark as selected
                          setDropdownOpen(false);
                        }} // Call the function to handle "Add New"
                      >
                        <Box width={'32px'} height={'32px'}>
                          <img style={{ width: '100%', height: '100%' }} src={AddIcon} />
                        </Box>
                        <Box width={'80%'}>
                          <Typography
                            color={'#5B5B5B'}
                            fontFamily={'SF UI Display'}
                            fontSize={'16px'}
                            fontWeight={'600'}
                            lineHeight={'normal'}
                          >
                            {account?.user?.job_title ?? ''}
                          </Typography>
                          <Typography
                            color={'#9E9E9E'}
                            fontFamily={'SF UI Display'}
                            fontSize={'14px'}
                            fontWeight={'400'}
                            lineHeight={'normal'}
                          >
                            Add new job title
                          </Typography>
                        </Box>
                      </Box>
                    );
                  }

                  // Default rendering for the other options
                  return (
                    <Box
                      component="li"
                      {...props}
                      onClick={() => {
                        setJobTitleSelected(true); // Explicitly set the state when clicked
                        setAccount?.({
                          ...account,
                          user: {
                            ...account?.user,
                            job_title: option,
                          },
                        });
                        setDropdownOpen(false);
                      }}
                      sx={{
                        display: 'flex',
                        alignItems: 'center',
                        gap: '15px',
                        textTransform: specialTitles.includes(option.toLowerCase())
                          ? 'uppercase'
                          : 'capitalize',
                      }}
                      color={'#5B5B5B'}
                      fontFamily={'SF UI Display'}
                      fontSize={'16px'}
                      fontWeight={'500'}
                      lineHeight={'normal'}
                    >
                      {option}
                    </Box>
                  );
                }}
                renderInput={(params) => (
                  <TextField
                    {...params}
                    sx={{
                      borderRadius: '8px',
                      border: '1px solid #D0D5DD',
                      background: '#FFF',
                      boxShadow: '0px 1px 2px 0px rgba(16, 24, 40, 0.05)',
                      display: 'flex',
                      alignItems: 'center',
                      gap: '8px',
                      marginTop: '6px',
                      padding: '0', // Reset padding here
                      '.MuiOutlinedInput-input': {
                        textTransform: specialTitles.includes(
                          account?.user?.job_title.toLowerCase(),
                        )
                          ? 'uppercase'
                          : 'capitalize',
                        padding: '8px 12px !important',
                      },
                      '& .MuiOutlinedInput-root': {
                        '& fieldset': {
                          border: '1px solid transparent',
                          outline: 'none',
                        },
                        '&:hover fieldset': {
                          borderColor: 'transparent',
                        },
                        '&.Mui-focused fieldset': {
                          borderColor: 'transparent',
                        },
                        '&.MuiInputBase-root:focus-visible': {
                          borderColor: 'transparent',
                        },
                        padding: '0',
                      },
                    }}
                    InputProps={{
                      ...params.InputProps, // Ensure merging of InputProps from params
                      endAdornment:
                        jobTitleSelected && account?.user?.job_title?.length ? (
                          <InputAdornment position="start">
                            <img
                              src={Success}
                              alt="Trade Icon"
                              style={{ width: '16px', height: '16px' }}
                            />
                          </InputAdornment>
                        ) : (
                          false
                        ),
                    }}
                    inputProps={{
                      ...params.inputProps,
                      maxLength: 70, // Character limit set here
                    }}
                    onChange={(e) => {
                      setAccount?.({
                        ...account,
                        user: {
                          ...account?.user,
                          job_title: e.target.value,
                        },
                      });
                      setJobTitleSelected(false); // Reset the selection if the user edits the field
                    }}
                    type="text"
                    autoComplete="off" // Prevents browser autofill
                    id="job-title" // Ensure this is unique
                    name="job_title" // Ensure this is unique
                  />
                )}
              />
            </Box>{' '}
            <Box width={'100%'} margin={'12px 0'}>
              <Typography
                color={'#000000'}
                fontFamily={'SF UI Display'}
                fontSize={'14px'}
                fontWeight={'500'}
                lineHeight={'20px'}
              >
                Password{' '}
                <span
                  style={{
                    //  color: account?.user?.password?.length < 8 ? 'red' : '#00BD57'
                    color: '#00BD57',
                  }}
                >
                  *
                </span>
              </Typography>
              <TextField
                fullWidth
                type="password"
                name="no-autofill-password" // Change the name to avoid autofill
                autoComplete="new-password"
                placeholder="Create a password"
                value={account?.user?.password}
                onChange={(e) => {
                  setAccount?.({
                    ...account,
                    user: {
                      ...account?.user,
                      password: e.target.value,
                    },
                  });
                }}
                sx={{
                  borderRadius: '8px',
                  border: '1px solid #D0D5DD',
                  background: '#FFF',
                  boxShadow: '0px 1px 2px 0px rgba(16, 24, 40, 0.05)',
                  display: 'flex',
                  alignItems: 'center',
                  gap: '8px',
                  marginTop: '6px',
                  '.MuiOutlinedInput-input': {
                    padding: '8px 12px',
                  },
                  '& .MuiOutlinedInput-root': {
                    '& fieldset': {
                      border: '1px solid transparent',
                      outline: 'none',
                    },
                    '&:hover fieldset': {
                      borderColor: 'transparent',
                    },
                    '&.Mui-focused fieldset': {
                      borderColor: 'transparent',
                    },
                    '&.MuiInputBase-root:focus-visible': {
                      borderColor: 'transparent',
                    },
                  },
                }}
              />
              <Typography
                color={'#000000'}
                fontFamily={'SF UI Display'}
                fontSize={'14px'}
                fontWeight={'400'}
                lineHeight={'20px'}
                marginTop={'6px'}
              >
                Must be at least 8 characters.
              </Typography>
            </Box>
          </Box>
          <Box
            width={'100%'}
            display={'flex'}
            alignItems={'center'}
            justifyContent={'space-between'}
            sx={{
              '@media (max-width: 520px)': {
                gap: '10px',
              },
            }}
          >
            <Button
              sx={{
                width: '25%',
                display: 'flex',
                padding: '10px 16px',
                justifyContent: 'center',
                alignItems: 'center',
                gap: '6px',
                borderRadius: '8px',
                border: '1px solid D0D5DD',
                background: '#fff',
                color: '#000000',
                fontFamily: 'SF UI Display',
                fontSize: '16px',
                fontWeight: '600',
                lineHeight: '24px',
                margin: '10px 0',
                boxShadow:
                  'inset 0 0 0 1px rgba(16, 24, 40, 0.18), inset 0 -2px 0 rgba(16, 24, 40, 0.05), 0 1px 2px rgba(16, 24, 40, 0.05)',
                textTransform: 'capitalize',
                '&:hover': {
                  bgcolor: '#fff',
                },
                '@media (max-width: 520px)': {
                  width: '100%',
                },
              }}
              onClick={handleCancelClick}
            >
              Cancel
            </Button>
            <Button
              sx={{
                width: '25%',
                display: 'flex',
                padding: '10px 16px',
                justifyContent: 'center',
                alignItems: 'center',
                gap: '6px',
                borderRadius: '8px',
                bgcolor: buttonDisable() ? '#F7F7F7' : '#00BD57',
                // background: '#00BD57',
                color: buttonDisable() ? '#B8B8B8' : '#fff',
                // color: '#FFF',
                fontFamily: 'SF UI Display',
                fontSize: '16px',
                fontWeight: '600',
                lineHeight: '24px',
                margin: '10px 0',
                textTransform: 'capitalize',
                '&:hover': {
                  bgcolor: buttonDisable() ? '#F7F7F7' : '#00BD57',
                },
                '@media (max-width: 520px)': {
                  width: '100%',
                },
              }}
              disabled={buttonDisable()}
              onClick={checkDomain}
            >
              {loading ? <CircularProgress size={15} color="inherit" /> : 'Next'}
            </Button>
          </Box>
        </Box>
      </Box>
    </Box>
  );
};

export default UserForm;
