import React, { FC } from 'react';
import { Route, Routes } from 'react-router-dom';
// import ClientHome from '../pages/nationalSignCompany/clientAccount/ClientHome.page';
// import SignUp from '../unAuth/SignUp.unAuth';
// import ClientLocalListing from '../pages/nationalSignCompany/clientAccount/ClientLocalListing.page';
// import ClientMapView from '../pages/nationalSignCompany/clientAccount/ClientMapView.page';
// import LocalSignCompany from '../pages/nationalSignCompany/noAccount/LocalSignCompany.page';
// import PagesetupLayout from '../layout/Pagesetup.layout';
// import Home from '../pages/nationalSignCompany/noAccount/Home.page';
// import EditPagesetupLayout from '../layout/EditPagesetup.layout';
// import LocalListing from '../pages/nationalSignCompany/noAccount/LocalListing.page';
// import ClientLocalCompany from '../pages/nationalSignCompany/clientAccount/ClientLocalCompany.page';
// import SignIn from '../unAuth/nationalSignCompany/SignIn.unAuth';
// import Otp from '../unAuth/Otp.unAuth';
// import LocalSignLayout from '../layout/localSignCompanyLayout/LocalSignLayout';
import SettingPagesetupLayout from '../layout/phase1a/SettingPageSetup.layout';
import LocalSignCompany from '../pages/phase1a/local/LocalSignCompany.pages';
import Otp from '../unAuth/phase1a/Otp.unAuth';
import LocalSearch from '../pages/phase1a/local/LocalSearch.pages';
import SignUp from '../unAuth/phase1a/SignUp.unAuth';
import LocalCompanyListing from '../pages/phase1a/local/LocalCompanyListing.pages';
import LocalMapView from '../pages/phase1a/local/LocalMapView.pages';
import LocalPageOwner from '../layout/phase1a/LocalPageOwner.layout';
import LocalPageSetUp from '../layout/phase1a/LocalPageSetUp.layout';
import PersonalInformation from '../unAuth/phase1a/PersonalInformation.unAuth';
import NationalSignCompany from '../pages/phase1a/national/NationalSignCompany.pages';
import SuppliersCompany from '../pages/phase1a/suppliers/SuppliersCompany.page';
import Login from '../unAuth/phase1a/SignIn.unAuth';
import CompanyDetail from '../pages/localSignCompany/CompanyDetailPage';
import SignInManually from '../unAuth/phase1a/SignIn.unAuthManually';
import Review from '../pages/phase1a/review/Review.pages';
import AddBusiness from '../pages/phase1a/AddBusiness.page';
import PrivacyPolicy from '../pages/PrivacyPolicy.page';
import TermsAndConditions from '../pages/TermsAndConditions.page';

interface RoutesProps {
  loggedIn: boolean;
}
const index: FC<RoutesProps> = ({ loggedIn }) => {
  return (
    <Routes>
      <Route path="/*" element={<LocalSearch />} />
      <Route path="/personalinformation" element={<PersonalInformation />} />
      <Route path="/search" element={<LocalCompanyListing />} />
      <Route path="/mapview" element={<LocalMapView />} />
      <Route path="/:ctype/:name/:id/review" element={<LocalSignCompany />} />
      <Route path="/:ctype/:name/:id" element={<LocalSignCompany />} />
      <Route path="/:type/:name" element={<LocalSignCompany />} />
      <Route path="/nationalsigncompany" element={<NationalSignCompany />} />
      <Route path="/supplierscompany" element={<SuppliersCompany />} />
      <Route path="/localpageowner/*" element={<LocalPageOwner />} />
      <Route path="/localpageowner/companyDetail" element={<CompanyDetail />} />
      <Route path="/submit-review" element={<Review />} />
      <Route path="/add-business" element={<AddBusiness />} />
      <Route path="/claim-company" element={<AddBusiness />} />
      <Route path="/privacy-policy" element={<PrivacyPolicy />} />
      <Route path="/terms-and-conditions" element={<TermsAndConditions />} />
      {loggedIn ? (
        <>
          <Route path="/LocalPageSetUp" element={<LocalPageSetUp />} />
          <Route path="/SettingPagesetup" element={<SettingPagesetupLayout />} />
        </>
      ) : (
        <>
          <Route path="/signup" element={<SignUp />} />
          <Route path="/login" element={<Login />} />
          <Route path="/Otp" element={<Otp />} />
          <Route path="/loginmanually" element={<SignInManually />} />
        </>
      )}
    </Routes>
  );
};

export default index;
